import React, { Component } from 'react'
import { connect } from 'react-redux'

import PropTypes from 'prop-types'

import { searchReferences } from 'actions/references'
import ReferenceSelector from 'selectors/references'
import SearchField from 'containers/common/SearchField'


class ReferenceSearchField extends Component {
  static propTypes = {
    isSearching: PropTypes.bool,
    searchReferences: PropTypes.func,
  }

  handleOnChange = (e) => {
    const { value } = e.target
    this.props.searchReferences(value)
  }

  render() {
    return <SearchField {...this.props} onChange={this.handleOnChange} />
  }
}

const mapStateToProps = state => ({
  query: ReferenceSelector.getSearchQuery(state),
  isSearching: ReferenceSelector.isSearching(state),
})

export default connect(mapStateToProps, {
  searchReferences,
})(ReferenceSearchField)
