import React, { PureComponent, Fragment } from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import PropTypes from 'prop-types'
import { get } from 'lodash'

import { requestNote } from 'actions/notes'
import {
  moveProjectItem, expandCollapseProjectItem, createProjectItem,
} from 'actions/project-items'

import modifyClassName from 'helper/modifyClassName'
import { ProjectItemType } from 'helper/constants'

import { OutlineDropzone, OutlineHeadingDropzone, OutlineNoteLinkDropzone } from 'components/project-detail/outline/OutlineDropzone'
import OutlineHeading from 'components/project-detail/outline/OutlineHeading'
import OutlineNoteLink from 'components/project-detail/outline/OutlineNoteLink'

import './style.less'


class OutlineItem extends PureComponent {
  static propTypes = {
    item: PropTypes.shape({
      expanded: PropTypes.bool,
    }),
    level: PropTypes.number,
    lastHeadingPrefix: PropTypes.string,
    moveProjectItem: PropTypes.func,
    expandCollapseProjectItem: PropTypes.func,
    push: PropTypes.func,
    removeProjectItem: PropTypes.func,
    createProjectItem: PropTypes.func,
    onAddHeading: PropTypes.func,
    onEditHeading: PropTypes.func,
    onDeleteHeading: PropTypes.func,
    onProcessChanges: PropTypes.func,
    requestNote: PropTypes.func,
    showHashLinks: PropTypes.bool,
  }

  handleNoteOpen = noteItem => this.props.push(`/notes/${noteItem.note.id}`)

  handleNoteRemove = (noteItem) => {
    const { removeProjectItem: remove, requestNote: fetchNote } = this.props
    this.props.onProcessChanges(async () => {
      await remove(noteItem)
      await fetchNote(noteItem.noteId)
    })
  }

  handleDrop = async (type, parent, item, index) => {
    this.props.onProcessChanges(async () => {
      if (parent.id === item.id) return
      if (type === ProjectItemType.NEW_NOTE_LINK) {
        await this.props.createProjectItem(ProjectItemType.NOTE_LINK, { noteId: item.id }, parent, index)
        await this.props.requestNote(item.id)
      } else {
        await this.props.moveProjectItem(item.id, index, parent.id, item.parentId)
      }
      if (!parent.expanded) this.props.expandCollapseProjectItem(parent.id)
    })
  }

  renderFirstDropzone() {
    const { item, level = 0 } = this.props
    let Dropzone = OutlineDropzone
    if (item.children.length > 0 && item.children[0].type !== ProjectItemType.HEADING) Dropzone = OutlineNoteLinkDropzone
    if (item.type !== ProjectItemType.NOTE && item.children.length > 0) {
      const idx = item.children[0].type === ProjectItemType.COMMENT ? 1 : 0
      return (
        <Dropzone
          parent={item}
          onDrop={this.handleDrop}
          level={level}
          onAddClick={this.props.onAddHeading}
          index={idx}
          isFirst={item.children[0].type === ProjectItemType.COMMENT ? idx === 1 : idx === 0}
        />
      )
    }
    return null
  }

  renderItemContent(item, isFirst, isLast, level, headingPrefix) {
    switch (item.type) {
      case ProjectItemType.HEADING:
        return (
          <OutlineHeading
            item={item}
            isFirst={isFirst}
            isLast={isLast}
            level={level}
            headingPrefix={headingPrefix}
            onDrop={this.handleDrop}
            onDeleteClick={this.props.onDeleteHeading}
            onEditClick={this.props.onEditHeading}
            className="OutlineItemContainer__Item"
            showHashLink={this.props.showHashLinks}
          />
        )
      case ProjectItemType.NOTE_LINK:
        return (
          <OutlineNoteLink
            onOpenClick={this.handleNoteOpen}
            onRemoveClick={this.handleNoteRemove}
            item={item}
            isFirst={isFirst}
            isLast={isLast}
            level={level}
            className="OutlineItemContainer__Item"
            showHashLink={this.props.showHashLinks}
          />
        )
      case ProjectItemType.TEXT:
        return (
          <OutlineNoteLink
            onOpenClick={this.handleNoteOpen}
            item={item}
            isFirst={isFirst}
            isLast={isLast}
            level={level}
            className="OutlineItemContainer__Item"
            showHashLink={this.props.showHashLinks}
          />
        )
      default:
        return null
    }
  }

  render() {
    const { item, level = 0, lastHeadingPrefix = '' } = this.props

    let headingCounter = 0

    return (
      <Fragment key={item.id}>
        { this.renderFirstDropzone() }

        {(item.type === ProjectItemType.HEADING || item.type === ProjectItemType.ROOT) && item.children.map((childItem, childIndex) => {
          const isFirst = childIndex === 0
          const isLast = childIndex === item.children.length - 1
          const headingPrefix = `${lastHeadingPrefix}${headingCounter + 1}.`
          const expandChildren = childItem.expanded && childItem.children.length > 0

          if (childItem.type === ProjectItemType.HEADING) headingCounter += 1

          let DropzoneComponent = OutlineDropzone
          if (childItem.type === ProjectItemType.HEADING) DropzoneComponent = OutlineHeadingDropzone
          if (item.children.length > childIndex + 1 && item.children[childIndex + 1].type !== ProjectItemType.HEADING) {
            DropzoneComponent = OutlineNoteLinkDropzone
          }

          if (!childItem.id
            || (childItem.type === ProjectItemType.TEXT
                && (childItem.noteId === undefined || childItem.noteId === null || get(childItem, 'note.content', null) === null)
            )
            || (childItem.type === ProjectItemType.IMAGE)
          ) {
            return null
          }

          return (
            <Fragment key={childItem.id}>
              <div className={`${modifyClassName('OutlineItemContainer', { isFirst, isLast })} OutlineItemContainer--level${level} `}>
                { this.renderItemContent(childItem, isFirst, isLast, level, headingPrefix) }

                <div className={`OutlineItemContainer__Children OutlineItemContainer__Children--level${level}`}>
                  { expandChildren && (
                    <ConnectedItem
                      item={childItem}
                      index={0}
                      lastHeadingPrefix={headingPrefix}
                      level={level + 1}
                      className=""
                      onAddHeading={this.props.onAddHeading}
                      onEditHeading={this.props.onEditHeading}
                      onDeleteHeading={this.props.onDeleteHeading}
                      onProcessChanges={this.props.onProcessChanges}
                      showHashLinks={this.props.showHashLinks}
                      removeProjectItem={this.props.removeProjectItem}
                    />
                  )}
                  { childItem.type === 'HEADING' && !expandChildren && (
                  <OutlineHeadingDropzone
                    key={`childzone${childItem.id}`}
                    parent={childItem}
                    index={childItem.children.length}
                    level={level + 1}
                    onDrop={this.handleDrop}
                    onAddClick={this.props.onAddHeading}
                  />
                  )}
                </div>
              </div>
              <DropzoneComponent
                key={`d${childItem.id}`}
                parent={item}
                index={childIndex + 1}
                level={level}
                onDrop={this.handleDrop}
                onAddClick={this.props.onAddHeading}
              />
            </Fragment>
          )
        })}
      </Fragment>
    )
  }
}

const ConnectedItem = connect(null, {
  push,
  createProjectItem,
  moveProjectItem,
  expandCollapseProjectItem,
  requestNote,
})(OutlineItem)

export default ConnectedItem
