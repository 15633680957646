import { values, get } from 'lodash'
import SentryIntegration from 'integrations/Sentry'
import * as api from 'api'
import { receiveEntities, removeEntity } from 'actions/entities'
import {
  requestCSL,
  requestCSLOnly,
  requestReferences,
  setCslXml,
  updateAllCitations,
} from 'actions/references'
import { requestNotes, removeNoteFromPage } from 'actions/notes'
import { requestSubscriptionPlans } from 'actions/subscriptions'
import { requestProjects } from 'actions/projects'
import { requestProjectItems } from 'actions/project-items'
import { requestTags } from 'actions/tags'
import { getProfile } from 'selectors/profile'
import { generateReferenceCitations } from 'helper/citation'

export const updateProfile = data => async (dispatch, getState) => {
  const state = getState()
  const profile = getProfile(state)

  let mappedData
  if (data.citationStyle2) {
    mappedData = {
      ...data,
      citationStyle2: {
        ...data.citationStyle2,
        cslId: data.citationStyle2.id,
      },
      citationStyle: data.citationStyle2.filename,
    }
  } else {
    mappedData = { ...data }
  }
  let error
  try {
    const csl = await requestCSLOnly(mappedData.citationStyle)
    await generateReferenceCitations(state.entities.references, csl, get(profile, 'language'))
    await dispatch(setCslXml(csl))
  } catch (e) {
    SentryIntegration.logError(e)
    mappedData.citationStyle2 = profile.citationStyle2
    mappedData.citationStyle = profile.citationStyle
    error = e
  }

  const payload = await api.updateProfile(mappedData)
  await dispatch(receiveEntities(payload))

  if (error) {
    throw error
  } else {
    await dispatch(updateAllCitations())
  }
}

export const updateProfileNoCSL = data => async (dispatch) => {
  const payload = await api.updateProfile(data)
  await dispatch(receiveEntities(payload))
}

export const requestProfile = () => async (dispatch) => {
  const payload = await api.requestProfile()
  const result = await dispatch(receiveEntities(payload))
  const profile = payload.entities.profiles[payload.result]
  await dispatch(requestCSL(get(profile, 'citationStyle2.filename', profile.citationStyle)))
  await dispatch(requestSubscriptionPlans(profile.country))
  return result
}

export const createOnboardingData = type => async (dispatch) => {
  await api.createOnboardingData(type)

  const tasks = [
    dispatch(requestNotes()),
    dispatch(requestReferences()),
    dispatch(requestTags()),
  ]

  tasks.push(dispatch(requestProjects())
    .then(async (payload) => {
      const ids = Object.keys(get(payload, 'payload.entities.projects', []))
      await Promise.all(ids.map(id => dispatch(requestProjectItems(id))))
    }))

  await Promise.all(tasks)
}

export const removeOnboardingData = () => async (dispatch, getState) => {
  await api.removeOnboardingData()
  const state = getState()

  await Promise.all(values(state.entities.references)
    .filter(ref => ref.isInitial)
    .map(ref => dispatch(removeEntity({ entityType: 'references', ...ref }))))

  values(state.entities.notes).forEach((note) => {
    if (note.isInitial) {
      dispatch(removeNoteFromPage({ noteId: note.id }))
      dispatch(removeEntity({ entityType: 'notes', ...note }))
    }
  })

  await Promise.all(values(state.entities.projects)
    .filter(project => project.isInitial)
    .map(project => dispatch(removeEntity({ entityType: 'projects', ...project }))))

  await Promise.all(values(state.entities.projectItems)
    .filter(projectItem => projectItem.isInitial)
    .map(projectItem => dispatch(removeEntity({ entityType: 'projectItems', ...projectItem }))))
  dispatch(requestTags())
}

export const requestEmailVerification = email => async (dispatch) => {
  await api.requestEmailVerification(email)
  const payload = await api.requestProfile()
  await dispatch(receiveEntities(payload))
}

export const sendInvitationEmails = emails => async (dispatch) => {
  await api.sendInvitationEmails(emails)
  const payload = await api.requestProfile()
  await dispatch(receiveEntities(payload))
}

export const deleteAccount = () => async () => api.deleteAccount()
