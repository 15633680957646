import { cognitoGetUser } from 'integrations/AWSCognito'
import SentryIntegration from 'integrations/Sentry'

class Tracker {
  /**
   * Instantiate Tracker with an empty array of trackers
   */
  constructor() {
    this.tracker = []
  }

  /**
   * Register a new tracker by passing the instance of the tracker class
   */
  register(tracker) {
    this.tracker.push(tracker)
  }

  logEvent(appEvent, additionalData = {}, email) {
    let mail = email
    if (!mail) {
      const user = cognitoGetUser()
      if (!user) return
      mail = user.username
    }
    const timestamp = new Date()
    const eventData = { ...additionalData, timestamp, email: mail }
    this.tracker.forEach((track) => {
      try {
        track.logEvent(appEvent, eventData)
      } catch (e) {
        if (e.name !== 'NetworkException') {
          SentryIntegration.logError(e)
        }
      }
    })
  }
}

const tracker = new Tracker()
export default tracker
