import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { fieldPropTypes } from 'redux-form'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import _ from 'lodash'

import Tag, { SmallTag } from 'components/common/Tag'
import AutoSuggestButton from 'components/common/AutoSuggestButton'

import TagSelector from 'selectors/tags'


import './style.less'


const TAG_SUGGESTION_LIMIT = 30

class TagSelectorField extends Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      suggestions: [],
    }
  }

  // Autosuggest will call this function every time you need to update suggestions.
  onSuggestionsFetchRequested = ({ value, reason }) => {
    let suggestions = []

    if (!value || value === '' || reason === 'suggestion-selected') {
      suggestions = this.props.allTags
        .filter(tag => this.props.input.value.indexOf(tag.tag) === -1)
        .slice(0, TAG_SUGGESTION_LIMIT)
    } else {
      suggestions = this.props.allTags.filter(tag => tag.tag.toLowerCase().indexOf(value.toLowerCase()) >= 0
        && this.props.input.value.indexOf(tag.tag) === -1)
        .slice(0, TAG_SUGGESTION_LIMIT)
    }
    this.setState({ suggestions })
  }

  getSuggestionValue = tag => tag.tag

  handleRemoveTag = (tag) => {
    const tags = _.without(this.props.input.value, tag)
    this.props.input.onChange(tags)
  }

  handleSelection = (suggestion) => {
    this.props.input.onChange([...this.props.input.value, suggestion.tag])
  }

  handleCreate = (value) => {
    let tags = value.split(';')
    if (tags.length === 1) {
      tags = value.split(',')
    }
    tags = tags.map(tag => tag.trim())
    this.props.input.onChange([...this.props.input.value, ...tags])
  }

  renderSuggestion = tag => <SmallTag tag={tag.tag} />

  render = () => (
    <div className="TagSelector">
      <div className="TagSelector__Tags">
        { this.props.input.value !== '' && this.props.input.value.map(
          tag => <Tag key={tag} tag={tag} onDelete={this.handleRemoveTag} />,
        )}
      </div>

      <AutoSuggestButton
        title={this.props.t('noteDetailView.tagSelectorField.newTag')}
        suggestions={this.state.suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        onSuggestionSelected={this.handleSelection}
        onCreateSelected={this.handleCreate}
        helpSpotTranslationKey="noteDetailView.tagSelectorField.helpSpot"
        formValue={this.props.input.value}
      />
    </div>
  )
}

TagSelectorField.propTypes = {
  ...fieldPropTypes,
  ...i18nPropTypes,
  allTags: PropTypes.array,
}

const mapStateToProps = state => ({
  allTags: TagSelector.getAll(state),
})

export default connect(mapStateToProps, {})(translate()(TagSelectorField))
