import _, {
  isEqual, values, get,
} from 'lodash'
import { createSelector } from 'reselect'

import { getRawDenormalized } from 'selectors/notes'
import ReferenceSelector from 'selectors/references'
import { getSelectedProjectId } from 'selectors/path'
import { getCitationClusterForSelectedProject } from 'selectors/citation'
import { ProjectItemType } from 'helper/constants'

const projectsSelector = state => _.get(state, 'entities.projects', {})
// const projectItemsSelector = state => _.get(state, 'entities.projectItems', {})

const projectItemsSelector = state => _.get(state, 'entities.projectItems', {})

const getById = (state, id) => _.get(projectsSelector(state), `['${id}']`)
const getSelectedProjectChanged = state => state.project.selectedProjectChanged
const getExpandedProjectItemIds = state => state.projectItem.expandedProjectItems

const getAll = createSelector(
  projectsSelector,
  projects => _.values(projects),
)

const projectExists = state => _.includes(state, `entities.projects['${getSelectedProjectId(state)}']`)

const getSelectedProject = state => get(state, `entities.projects['${getSelectedProjectId(state)}']`)
const getFirstProjectId = state => get(values(projectsSelector(state)), '[0].id')

export const hasInitialProjects = createSelector(
  projectsSelector,
  projects => values(projects).filter(project => project.isInitial).length > 0,
)

export const getMyProjects = createSelector(
  projectsSelector,
  projects => values(projects).filter(project => !project.isInitial),
)

const getInitialProjects = createSelector(
  projectsSelector,
  projects => values(projects).filter(project => project.isInitial),
)

const resolvedItemCache = {}
const projectItemsResolved = createSelector(
  projectItemsSelector,
  getRawDenormalized,
  getExpandedProjectItemIds,
  (projectItems, notes, expandedProjectItemIds) => {
    const result = {}
    Object.keys(projectItems).forEach((itemId) => {
      const item = projectItems[itemId]

      const note = _.get(notes, `['${item.noteId}']`, {})
      const newItem = { ...item, note, expanded: expandedProjectItemIds.indexOf(itemId) >= 0 }
      const cachedItem = resolvedItemCache[itemId]

      if (isEqual(newItem, cachedItem)) {
        result[itemId] = cachedItem
      } else {
        result[itemId] = newItem
        resolvedItemCache[itemId] = newItem
      }
    })
    return result
  },
)

const levelCache = {}
const resolve = (citationCluster, itemIds, projectItems, references) => {
  if (!itemIds || itemIds.length === 0) return []

  return itemIds.map((itemId) => {
    const item = { ...projectItems[itemId] }
    const resolvedReferences = item.referenceIds.map(refId => [refId, references[refId]])
    item.references = _.fromPairs(resolvedReferences)
    item.citationCluster = {
      citations: get(citationCluster, ['projectItems', item.id], []),
      type: get(citationCluster, 'type'),
    }
    item.children = resolve(citationCluster, item.children, projectItems, references)
    const cachedLevel = levelCache[itemId]
    if (isEqual(item, cachedLevel)) {
      return cachedLevel
    }
    levelCache[itemId] = item
    return item
  })
}

const levelCacheHeadings = {}
const resolveHeadings = (itemIds, projectItems) => {
  if (!itemIds || itemIds.length === 0) return []

  return values(_.pickBy(itemIds, itemId => projectItems[itemId] && projectItems[itemId].type === 'HEADING')).map((itemId) => {
    const item = { ...projectItems[itemId] }
    if (item.children && item.children.length > 0) {
      item.children = resolveHeadings(item.children, projectItems)
    }
    const cachedLevel = levelCacheHeadings[itemId]
    if (isEqual(item, cachedLevel)) {
      return cachedLevel
    }
    levelCacheHeadings[itemId] = item
    return item
  })
}

const enhancedItemCache = {}
const projectItemsSelectorEnhanced = createSelector(
  projectItemsResolved,
  getExpandedProjectItemIds,
  (projectItems, expandedProjectItemIds) => {
    const result = {}
    Object.keys(projectItems).forEach((itemId) => {
      const item = projectItems[itemId]

      const newItem = { ...item, expanded: expandedProjectItemIds.indexOf(itemId) >= 0 }
      const cachedItem = enhancedItemCache[itemId]

      if (isEqual(newItem, cachedItem)) {
        result[itemId] = cachedItem
      } else {
        result[itemId] = newItem
        enhancedItemCache[itemId] = newItem
      }
    })
    return result
  },
)


let cachedRoot = {}
const getRootItem = createSelector(
  getSelectedProjectId,
  projectItemsSelector,
  (projectId, projectItems) => {
    const rootItems = _.pickBy(projectItems, item => (item.projectId === projectId && item.type === ProjectItemType.ROOT))
    const [rootItem] = _.values(rootItems)
    if (isEqual(cachedRoot, rootItem)) {
      return cachedRoot
    }
    cachedRoot = { ...rootItem, expanded: true }
    return cachedRoot
  },
)

const getResolvedProjectItemTreeForSelectedProject = createSelector(
  getRootItem,
  projectItemsSelectorEnhanced,
  ReferenceSelector.getRaw,
  getCitationClusterForSelectedProject,
  (rootItem, projectItems, references, citationCluster) => {
    const result = { ...rootItem, children: resolve(citationCluster, rootItem.children, projectItems, references) }
    return result
  },
)

const getResolvedProjectItemTrees = createSelector(
  getAll,
  projectItemsSelectorEnhanced,
  ReferenceSelector.getRaw,
  (projects, projectItems) => {
    const rootItems = _.pickBy(projectItems, item => item.type === ProjectItemType.ROOT)
    const headingItems = _.pickBy(projectItems, item => item.type === ProjectItemType.HEADING)
    const resolvedTrees = values(rootItems).map(rootItem => ({ ...rootItem, children: resolveHeadings(rootItem.children, headingItems) }))
    return resolvedTrees.map(treeItems => ({ project: _.find(projects, { id: treeItems.projectId }), itemTree: treeItems }))
  },
)

const getResolvedProjectItemReferencesForSelectedProject = createSelector(
  getSelectedProjectId,
  projectItemsSelector,
  ReferenceSelector.getRaw,
  getRawDenormalized,
  (projectId, projectItems, references, notes) => {
    const filteredProjecItems = values(projectItems)
      .filter(item => (item.projectId === projectId && (item.type === ProjectItemType.NOTE_LINK || item.type === ProjectItemType.TEXT)))
    const referenceIds = _.uniq(_.flatMap(filteredProjecItems, (item) => {
      const referencesViaNoteLink = get(notes[item.noteId], 'referenceIds', [])
      const referencesViaTextItem = get(item, 'referenceIds', [])
      return [...referencesViaNoteLink, ...referencesViaTextItem]
    }))
    const pairedRefsArray = referenceIds.map(id => [id, references[id]])
    return _.fromPairs(pairedRefsArray)
  },
)


// recursive helper function for getInlineCitationsForSelectedProject
const getInlineCitations = (projectItem) => {
  let citations = []
  let itemCitationIds = []
  let uncitedReferenceIds = []

  if (get(projectItem, 'inlineCitations.length', 0) > 0) {
    // you can have the same note in a project multiple times, so we need to create a temp unique id
    citations = projectItem.inlineCitations
      .filter(citation => projectItem.content && citation && projectItem.content.includes(citation.id))
      .map(citation => ({ ...citation, id: `${citation.id}-${projectItem.id}` }))
    itemCitationIds = projectItem.inlineCitations.map(citation => `${citation.id}-${projectItem.id}`)
  } else if (get(projectItem, 'note.inlineCitations.length', 0) > 0) {
    citations = projectItem.note.inlineCitations
      .filter(citation => projectItem.note && projectItem.note.content && citation && projectItem.note.content.includes(citation.id))
      .map(citation => ({ ...citation, id: `${citation.id}-${projectItem.id}` }))
    itemCitationIds = projectItem.note.inlineCitations.map(citation => `${citation.id}-${projectItem.id}`)
  }

  if (get(projectItem, 'referenceIds.length', 0) > 0) {
    // legacy compability, before we had inline citations, we just stored reference ids
    uncitedReferenceIds = projectItem.referenceIds
  } else if (get(projectItem, 'note.referenceIds.length', 0) > 0) {
    // there may be uncited references in notes
    uncitedReferenceIds = projectItem.note.referenceIds
  }

  let projectItems = { [projectItem.id]: itemCitationIds }
  if (projectItem.children) {
    projectItem.children.forEach((child) => {
      const result = getInlineCitations(child)
      citations = [...citations, ...result.citations]
      projectItems = { ...projectItems, ...result.projectItems }
      uncitedReferenceIds = [...uncitedReferenceIds, ...result.uncitedReferenceIds]
    })
  }
  return { citations, projectItems, uncitedReferenceIds }
}

/**
 * Extract all citation objects from project item hierarchy
 * @param {*} item is the rootItem of the selected project
 * @return returns {
 *   citations: [ <citationSchema>, ... ] in correct order through the whole project
 *   projectItems: {
 *     <projectItemId>: [ <citationId>, <citationId>, ... ]
 *   }
 * }
 */
export const getInlineCitationsForSelectedProject = createSelector(
  getResolvedProjectItemTreeForSelectedProject,
  projectItem => getInlineCitations(projectItem),
)

const isExporting = state => state.project.isExporting

export const getCount = state => Object.keys(projectsSelector(state)).length

export const getNoneInitialCount = createSelector(
  getMyProjects,
  projects => projects.length,
)

export default {
  getById,
  getAll,
  getSelectedProject,
  getSelectedProjectId,
  getFirstProjectId,
  projectExists,
  getSelectedProjectChanged,
  isExporting,
  getCount,
  getNoneInitialCount,
  hasInitialProjects,
  getMyProjects,
  getInitialProjects,
  getResolvedProjectItemTreeForSelectedProject,
  getResolvedProjectItemReferencesForSelectedProject,
  getResolvedProjectItemTrees,
}
