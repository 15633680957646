import * as api from 'api'
import { receiveEntities, removeEntity } from 'actions/entities'
import { EXTERNAL_LIBRARIES, getReferenceFromExternalReference } from 'selectors/references'
import { getSelectedNote } from 'selectors/notes'

import {
  values, debounce,
} from 'lodash'
import tracker from 'tracking/tracker'
import i18n from 'i18n'
import { push } from 'connected-react-router'

import { getProfileLanguage } from 'selectors/profile'
import { createAction } from 'redux-actions'
import { getSelectedCollection } from 'selectors/reference-collections'
import { updateNote } from 'actions/notes'
import {
  generateReferenceCitations, updateReferenceCitation,
} from 'helper/citation'
import { removeScrollPosition } from './scroll-restoration'


export const setCslXml = createAction('SET_CSL_XML')

export const updateExternalSearch = createAction('UPDATE_EXTERNAL_SEARCH')
export const setReferencesSearchQuery = createAction('SET_REFERENCES_SEARCH_QUERY')
export const clearReferenceSearch = createAction('CLEAR_REFERENCE_SEARCH')
export const sortReferences = createAction('SORT_REFERENCE_COLLECTION')

export const updateAllCitations = () => async (dispatch, getState) => {
  const state = getState()
  const { entities: { references }, reference: { csl } } = state
  const processedReferences = await generateReferenceCitations(references, csl, getProfileLanguage(state))
  dispatch(receiveEntities({
    entities: { references: { ...processedReferences } },
  }))
}

const citePayload = (state, payload) => {
  let reference = values(payload.entities.references)[0]
  reference = updateReferenceCitation(reference, state.reference.csl, getProfileLanguage(state))
  return {
    entities: {
      references: {
        [reference.id]: { ...reference },
      },
    },
  }
}

export const requestReferences = () => async (dispatch) => {
  const payload = await api.requestReferences()
  await dispatch(receiveEntities(payload))
  await dispatch(updateAllCitations())
}

export const createReference = data => async (dispatch, getState) => {
  const payload = await api.createReference(data)
  const result = citePayload(getState(), payload)
  await dispatch(receiveEntities(result))
  return values(result.entities.references)[0]
}

export const updateReference = data => async (dispatch, getState) => {
  const payload = await api.updateReference(data)
  const result = citePayload(getState(), payload)
  await dispatch(receiveEntities(result))
  return values(result.entities.references)[0]
}

export const duplicateReference = data => async (dispatch, getState) => {
  if (!window.confirm(i18n.t('references.duplicateReferenceConfirmation'))) {
    return null
  }
  tracker.logEvent('DUPLICATE_REFERENCE')

  const state = getState()
  const { entities = {} } = state
  const { references = {} } = entities
  const reference = references[data.id] // we want to use the unresolved reference from the redux store
  const referenceDuplicate = {
    ...reference,
    title: i18n.t('common.copiedTitle', { title: reference.title }),
    id: undefined,
    _id: undefined,
  }
  const payload = await api.createReference(referenceDuplicate)
  await dispatch(receiveEntities(payload))
  const createdReferenceId = Object.keys(payload.entities.references)[0]
  return dispatch(push(`/references/lib/${createdReferenceId}`))
}

export const upsertReference = data => async (dispatch) => {
  const action = data.id ? updateReference : createReference
  return dispatch(action(data))
}

export const uploadReferenceFiles = files => async () => api.uploadFileToS3(files[0])

export const findUserReference = externalRef => (dispatch, getState) => getReferenceFromExternalReference(externalRef)(getState())

export const createModalReference = data => async (dispatch, getState) => {
  const state = getState()
  const selectedNote = getSelectedNote(state)
  const selectedCollection = getSelectedCollection(state)

  let collections = []
  if (!selectedNote && selectedCollection) {
    collections = [selectedCollection.id]
  }
  const reference = await dispatch(createReference({ ...data, collections }))

  if (selectedNote) {
    // the modal has been opened while linking a new reference, we need to update the note as well
    await dispatch(updateNote({
      ...selectedNote,
      referenceIds: [...selectedNote.referenceIds, reference.id],
    }))
  }
  return reference
}

export const createReferenceFromFile = file => async (dispatch) => {
  const uploadPayload = await api.uploadFileToS3(file)
  const { result } = await api.createReferenceFromData({
    fileId: uploadPayload.fileId,
    type: file.type,
    fileName: file.name,
  })
  const reference = values(result.entities.references)[0]
  return dispatch(createModalReference(reference))
}

export const deleteReference = data => async (dispatch) => {
  await api.deleteReference(data)
  dispatch(removeEntity({ entityType: 'references', ...data }))
}

export const requestCSL = style => async (dispatch) => {
  const csl = await api.requestCSL(style)
  await dispatch(setCslXml(csl))
  await dispatch(updateAllCitations())
}

export const requestCSLOnly = async style => api.requestCSL(style)

const referenceSearch = async (query, dispatch) => Promise.all(EXTERNAL_LIBRARIES.map(async (libraryKey) => {
  dispatch(removeScrollPosition({ id: `EXTERNAL_LIBRARY_SEARCH_SCROLL_${libraryKey}` }))
  dispatch(updateExternalSearch({ libraryKey, state: 'LOADING' }))
  try {
    const payload = await api.searchExternalReferences(libraryKey, query)
    dispatch(updateExternalSearch({ libraryKey, state: 'SUCCESS', searchResult: { ...payload } }))
  } catch (error) {
    dispatch(updateExternalSearch({ libraryKey, state: 'ERROR' }))
  }
}))

const debouncedReferenceSearch = debounce(referenceSearch, 1000)

export const searchReferences = query => async (dispatch) => {
  if (!query || query.length === 0) {
    dispatch(clearReferenceSearch())
  } else {
    dispatch(setReferencesSearchQuery(query))
    debouncedReferenceSearch(query, dispatch)
  }
}
