import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { push } from 'connected-react-router'
import {
  withRouter, Route, Switch, Redirect,
} from 'react-router-dom'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import { showModal, hideModal } from 'actions/modal'

import FontIcon from 'components/common/FontIcon'
import Page from 'components/page/Page'
import PageHeader from 'components/page/PageHeader'
import ProjectDetailTabs from 'components/project-detail/ProjectDetailTabs'
import StructureWorkspace from 'components/project-detail/StructureWorkspace'
import WriteWorkspace from 'components/project-detail/WriteWorkspace'
import { InfoBigToast } from 'containers/toasts/BigToast'

import { setFilter } from 'actions/notes'
import { exportProject } from 'actions/projects'
import ProjectSelector from 'selectors/projects'
import NotesSelector from 'selectors/notes'

import { getSelectedProjectId } from 'selectors/path'
import { PremiumOutlinedButton, OutlinedButtonTooltip } from 'components/common/Button'
import Icon from 'components/common/Icon'
import FeatureFlag from 'containers/FeatureFlag'
import FlyoutReference from 'containers/common/FlyoutReference'

import { showFlyout } from 'actions/flyout'
import ProjectSettingsModal, { MODAL_ID as PROJECT_SETTINGS_MODAL } from 'containers/modal/ProjectSettingsModal'
import ServiceModal, { MODAL_ID as SERVICE_MODAL_ID } from 'containers/modal/ServiceModal'
import PageContent from 'components/page/PageContent'
import HelpSpot from 'containers/HelpSpot'

import FlyoutHeader from 'components/common/FlyoutHeader'
import { ExportButtonWord, ExportButtonBibtex } from 'components/common/ImageButton'

import './style.less'


const EXPORT_TOAST_ID = 'EXPORT_TOAST_ID'

class ProjectDetailPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isExporting: false,
      showProjectExportFlyout: false,
    }
    this.initializeFilter()
  }

  componentDidUpdate = (prevProps) => {
    const { projectId } = this.props
    if (projectId !== prevProps.projectId) {
      this.initializeFilter()
    }
  }

  onExportClick = async (format, outline) => {
    this.setState({ isExporting: true })
    try {
      await this.props.exportProject(this.props.project, format, outline)
      this.props.showModal(EXPORT_TOAST_ID, { content: this.props.t('projectDetailView.exportMailSuccessMsg'), data: { level: 'success' } })
    } catch (error) {
      if (error.name === 'ExportException') {
        this.props.showModal(EXPORT_TOAST_ID, { content: this.props.t('projectDetailView.exportWarningMsg'), data: { level: 'warning' } })
      } else {
        this.props.showModal(EXPORT_TOAST_ID, { content: this.props.t('projectDetailView.exportErrorMsg'), data: { level: 'danger' } })
      }
    }
    this.setState({ isExporting: false })
  }

  getStructureWorkspace = props => <StructureWorkspace {...props} project={this.props.project} />

  initializeFilter = () => {
    if (this.props.filter) return
    this.props.setFilter({
      setForProject: this.props.projectId,
      projectId: this.props.projectId,
      active: true,
    })
  }

  handleExportClick = () => {
    const { showProjectExportFlyout } = this.state
    this.setState({ showProjectExportFlyout: !showProjectExportFlyout })
  }

  handleHideFlyout = () => this.setState({ showProjectExportFlyout: false })

  handleEditClick = () => {
    this.props.showModal(PROJECT_SETTINGS_MODAL, { data: { projectId: this.props.project.id } })
  }

  handleServiceClick = () => {
    this.props.showModal(SERVICE_MODAL_ID, { data: { projectId: this.props.project.id } })
  }

  navigateToProjects = () => {
    this.props.push('/projects')
  }

  renderFlyoutContent = () => (
    <React.Fragment>
      <FlyoutHeader title={this.props.t('projectDetailView.exportFlyout.titleDocument')} />
      <div className="ProjectDetailPage__FlyoutContent">
        <ExportButtonWord onClick={() => this.onExportClick('docx', false)} isLoading={this.state.isExporting} />
        <ExportButtonBibtex onClick={() => this.onExportClick('tex', false)} isLoading={this.state.isExporting} />
      </div>
      <FlyoutHeader className="ProjectDetailPage__FlyoutHeader--second" title={this.props.t('projectDetailView.exportFlyout.titleOutline')} />
      <div className="ProjectDetailPage__FlyoutContent">
        <ExportButtonWord onClick={() => this.onExportClick('docx', true)} isLoading={this.state.isExporting} />
      </div>
    </React.Fragment>
  )

  render() {
    const { selectView, match, t } = this.props

    return (
      <Page className="ProjectDetailPage">
        <PageHeader className="ProjectDetailPage__Header">
          <FeatureFlag feature="service-platform">
            <div className="ProjectDetailPage__HeaderButton">
              <PremiumOutlinedButton circleModifier="circleMedium" onClick={this.handleServiceClick}>
                <FontIcon icon={FontIcon.Icons.faLifeRing} size="2x" />
              </PremiumOutlinedButton>
            </div>
          </FeatureFlag>
          <FlyoutReference
            flyoutClassName="ProjectDetailPage__ProjectExportFlyout"
            showFlyout={this.state.showProjectExportFlyout}
            placement="bottom"
            component={this.renderFlyoutContent()}
            onOutsideClick={this.handleHideFlyout}
          >
            <div className="ProjectDetailPage__HeaderButton">
              <OutlinedButtonTooltip
                circleModifier="circleMedium"
                onClick={this.handleExportClick}
                tooltipText={t('projects.export')}
                tooltipPlacement="bottom"
                isLoading={this.state.isExporting}
              >
                <Icon icon="j" />
              </OutlinedButtonTooltip>
              <HelpSpot
                className="ProjectDetailPage__HelpSpot"
                translationKey="helpSpot.projectSettings.import"
              />
            </div>
          </FlyoutReference>
          <div className="ProjectDetailPage__HeaderButton ProjectDetailPage__HeaderButton--last">
            <OutlinedButtonTooltip
              circleModifier="circleMedium"
              onClick={this.handleEditClick}
              tooltipText={t('projects.settings')}
              tooltipPlacement="bottom"
            >
              <Icon icon="M" />
            </OutlinedButtonTooltip>
            <HelpSpot
              className="ProjectDetailPage__HelpSpot"
              translationKey="helpSpot.projectSettings.edit"
            />
          </div>
          <ProjectDetailTabs onSelectView={selectView} />
        </PageHeader>
        <PageContent>
          <Switch>
            <Route path={`${match.path}/structure`} component={this.getStructureWorkspace} />
            <Route path={`${match.path}/write`} component={WriteWorkspace} />
            <Redirect to={`${match.path}/structure`} />
          </Switch>
        </PageContent>
        <ProjectSettingsModal
          id={PROJECT_SETTINGS_MODAL}
          title={t('projectSettings.editTitle')}
          okLabel={t('common.save')}
          cancelLabel={t('common.cancel')}
        />
        <InfoBigToast
          id={EXPORT_TOAST_ID}
          timeout={3000}
        />
        <ServiceModal id={SERVICE_MODAL_ID} />
      </Page>
    )
  }
}
const mapStateToProps = state => ({
  projectId: getSelectedProjectId(state),
  project: ProjectSelector.getSelectedProject(state),
  filter: NotesSelector.getFilter(state),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  push: path => dispatch(push(path)),
  selectView: (view) => {
    let path = ownProps.location.pathname
    path = path.substring(0, path.lastIndexOf('/'))
    dispatch(push(`${path}/${view}`))
  },
  showFlyout: id => dispatch(showFlyout(id)),
  showModal: (id, payload) => dispatch(showModal(id, payload)),
  hideModal: id => dispatch(hideModal(id)),
  exportProject: (project, format, outline) => dispatch(exportProject(project, format, outline)),
  setFilter: filter => dispatch(setFilter(filter)),
})

ProjectDetailPage.propTypes = {
  match: PropTypes.shape({ path: PropTypes.string }),
  projectId: PropTypes.string,
  project: PropTypes.shape({}),
  selectView: PropTypes.func,
  showFlyout: PropTypes.func,
  setFilter: PropTypes.func.isRequired,
  ...i18nPropTypes,
}

export default translate()(withRouter(connect(mapStateToProps, mapDispatchToProps)(ProjectDetailPage)))
