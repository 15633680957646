export const selector = state => state.search

export const getSearchQuery = state => selector(state).query
export const suggestionIsVisible = state => selector(state).visible
export const isLoading = state => selector(state).isLoading

const getSearchSuggestions = (state) => {
  const trimmed = selector(state).suggestions.map(r => ({
    ...r,
    items: r.type === 'SearchHistory' ? r.items.slice(0, 3) : r.items.slice(0, 6),
  }))
  return trimmed
}

export default {
  getSearchSuggestions,
  getSearchQuery,
  suggestionIsVisible,
  isLoading,
}
