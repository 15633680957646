import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Icon from 'components/common/Icon'
import { OutlinedButton } from 'components/common/Button'
import modifyClassName from 'helper/modifyClassName'

import SplitPane from 'react-split-pane'
import tracker from 'tracking/tracker'

import './style.less'

class SnapSplitPane extends Component {
  static propTypes = {
    children: PropTypes.node,
    snappingRangeEnd: PropTypes.number,
    snappingRangeStart: PropTypes.number,
    primary: PropTypes.string,
    split: PropTypes.string,
    pane1ClassName: PropTypes.string, // Davids idea
    pane2ClassName: PropTypes.string, // Davids idea
    defaultSize: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  }

  static defaultProps = {
    snappingRangeEnd: 300,
    snappingRangeStart: 40,
    primary: 'first',
    split: 'vertical',
    defaultSize: 460,
    children: {},
  }

  constructor(props) {
    super(props)
    this.state = {
      size: undefined,
      dragging: false,
      isOpen: true,
      buttonPosition: props.defaultSize,
    }

    this.width = undefined
    if (props.primary === 'first') {
      this.positionKey = 'left'
      this.getRotation = () => (this.state.isOpen ? 'rotate(90deg)' : 'rotate(-90deg)')
    } else {
      this.positionKey = 'right'
      this.getRotation = () => (this.state.isOpen ? 'rotate(-90deg)' : 'rotate(90deg)')
    }
  }

  handleDragStart = () => {
    this.setState({
      dragging: true,
    })
  }

  handleDragEnd = () => {
    const { snappingRangeStart } = this.props
    this.setState({
      dragging: false,
      isOpen: this.width !== snappingRangeStart,
      size: this.width,
      buttonPosition: this.width || this.state.buttonPosition,
    }, () => {
      this.setState({ size: undefined })
      this.width = undefined
      tracker.logEvent('SPLIT_PANE_MOVED')
    })
  }

  handleDrag = (width) => {
    const { isOpen } = this.state
    const { snappingRangeEnd, snappingRangeStart } = this.props
    if (width <= snappingRangeEnd) {
      if (isOpen) {
        this.width = snappingRangeStart
      } else {
        this.width = snappingRangeEnd
      }
    } else {
      this.width = width
    }
  }

  handleButtonClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const { snappingRangeEnd, snappingRangeStart, defaultSize } = this.props
    const { isOpen } = this.state
    if (isOpen) {
      this.width = snappingRangeStart
    } else {
      this.width = defaultSize || snappingRangeEnd
    }
    this.handleDragEnd()
  }

  render() {
    const {
      children,
      primary,
      snappingRangeStart,
      defaultSize,
      split,
      pane1ClassName,
      pane2ClassName,
    } = this.props
    const { dragging, size, buttonPosition } = this.state
    const buttonStyle = { [this.positionKey]: buttonPosition, margin: '-12px', transform: this.getRotation() }
    return (
      <div className="SnapSplitPane">
        <SplitPane
          split={split}
          defaultSize={defaultSize}
          minSize={snappingRangeStart}
          primary={primary}
          size={dragging ? undefined : size}
          onChange={this.handleDrag}
          onDragStarted={this.handleDragStart}
          onDragFinished={this.handleDragEnd}
          pane1ClassName={pane1ClassName}
          pane2ClassName={pane2ClassName}
        >
          {children}
        </SplitPane>
        <div className={modifyClassName('SnapSplitPane__ButtonContainer', { dragging })} style={buttonStyle}>
          <OutlinedButton className="SnapSplitPane__Button" circleModifier="circleMedium" onClick={this.handleButtonClick}>
            <Icon icon="g" />
          </OutlinedButton>
        </div>
      </div>
    )
  }
}

export default SnapSplitPane
