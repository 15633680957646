import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { debounce } from 'lodash'
import Textarea from 'react-textarea-autosize'

import modifyClassName from 'helper/modifyClassName'

import './style.less'


class WritingHeading extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    if (!prevState.isLocalUpdate) {
      return { titleValue: nextProps.item.title }
    }
    return { isLocalUpdate: false }
  }

  constructor() {
    super()
    this.debouncedSave = debounce(this.handleSave, 1000)
  }

  state = { titleValue: null, isLocalUpdate: false }

  onChange = (event) => {
    this.setState({ titleValue: event.target.value || '', isLocalUpdate: true })
    this.debouncedSave()
  }

  handleTitleKeyPress = (event) => {
    if (event.keyCode !== 13) { // enter/return key
      return
    }
    event.preventDefault()
    event.stopPropagation()
  }

  handleSave = async () => {
    const { updateProjectItem, item } = this.props
    const { titleValue: title } = this.state
    await updateProjectItem({ ...item, title })
    // TODO: handle error
  }

  render() {
    const { item, headingPrefix, level } = this.props
    const { titleValue } = this.state
    const textClassName = modifyClassName('WritingHeading__Text', `level${level}`)
    return (
      <div className={modifyClassName('WritingHeading', `level${level}`)} id={`WritingItem-${item.id}`}>
        <span className={`WritingHeading__TextPrefix ${textClassName}`}>{headingPrefix}</span>
        <Textarea
          onKeyDown={this.handleTitleKeyPress}
          className={`WritingHeading__TextValue ${textClassName}`}
          value={titleValue || ''}
          onChange={this.onChange}
        />
      </div>
    )
  }
}

WritingHeading.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string,
  }),
  level: PropTypes.number,
  headingPrefix: PropTypes.string,
  updateProjectItem: PropTypes.func.isRequired,
}

export default WritingHeading
