import React from 'react'
import PropTypes from 'prop-types'

import './style.less'

const PageSection = ({
  title, icon, children, className,
}) => (
  <div className={`PageSection ${className || ''}`}>
    <div className="PageSection__Container">
      <div className="PageSection__Heading">
        { icon && <div className="PageSection__Icon">{icon}</div>}
        <div className="PageSection__Title">{title}</div>
      </div>
      <div className="PageSection__Content">{children}</div>
    </div>
  </div>
)

PageSection.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
}

export default PageSection
