import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { checkConnection, changeConnectionState, states } from 'actions/connection'
import ConnectionSelector from 'selectors/connection'
import { DangerSimpleToast } from 'containers/toasts/SimpleToast'
import FontIcon from 'components/common/FontIcon'

import { propTypes as i18nPropTypes, translate } from 'i18n'

const unsupportedUserAgentsPattern = /Windows.*Chrome|Windows.*Firefox|Linux.*Chrome/
const inBrowser = typeof navigator !== 'undefined'


class OfflineToast extends Component {
  componentDidMount() {
    window.addEventListener('online', this.goOnline)
    window.addEventListener('offline', this.goOffline)

    this.props.checkConnection()
    if (inBrowser && unsupportedUserAgentsPattern.test(navigator.userAgent)) {
      this.timer = setInterval(this.checkConnection, 5000)
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer)
    window.removeEventListener('online', this.goOnline)
    window.removeEventListener('offline', this.goOffline)
  }

  goOnline = () => this.props.changeConnectionState(states.ONLINE)

  goOffline = () => this.props.changeConnectionState(states.OFFLINE)

  checkConnection = () => {
    this.props.checkConnection()
  }

  render() {
    return (
      <DangerSimpleToast
        disableCloseButton
        id="OFFLINE_TOAST"
        className="OfflineToast"
        isVisible={this.props.connectionState === states.OFFLINE}
        text={this.props.t('toasts.noConnection')}
        icon={<FontIcon icon={FontIcon.Icons.faExclamationCircle} />}
      />
    )
  }
}

OfflineToast.propTypes = {
  connectionState: PropTypes.string,
  checkConnection: PropTypes.func,
  changeConnectionState: PropTypes.func,
  ...i18nPropTypes,
}

const mapStateToProps = state => ({
  connectionState: ConnectionSelector.getConnectionState(state),
})

export default connect(mapStateToProps, {
  checkConnection,
  changeConnectionState,
})(translate()(OfflineToast))
