import React from 'react'
import PropTypes from 'prop-types'

import modifyClassName from 'helper/modifyClassName'

import './style.less'


const Table = ({ children, boxed }) => (
  <table className={modifyClassName('Table', { boxed })}>
    {children}
  </table>
)

Table.propTypes = {
  children: PropTypes.node,
  boxed: PropTypes.bool,
}

export default Table
