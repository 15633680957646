import { createSelector } from 'reselect'
import { values, find } from 'lodash'

import { partitionScores } from 'helper/utils'
import { getProfile } from 'selectors/profile'


export const getScoreEntity = state => state.entities.scores
export const getChallengeEntity = state => state.challenge

export const getScores = createSelector(
  getScoreEntity,
  getProfile,
  (scores, profile) => values(scores).sort((a, b) => b.value - a.value).map((data, idx) => ({
    ...data,
    order: idx,
    isMyScore: profile && profile.educationalInstituteId === data.id,
  })),
)

export const getPartitionedScores = createSelector(
  getScores,
  (scores) => {
    const partitioned = partitionScores(scores)
    return partitioned && {
      ...partitioned,
      myInstitute: find(scores, 'isMyScore'),
    }
  },
)

export const getIsEarlyBird = createSelector(
  getChallengeEntity,
  challenge => challenge.isEarlyBird,
)
