import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { propTypes as i18nPropTypes, translate } from 'i18n'

import {
  createReferenceCollection,
} from 'actions/reference-collections'
import { getReferenceCollections, getSelectedCollection } from 'selectors/reference-collections'

import { ReferenceCollectionItem, ReferenceCollectionItemDropzone } from 'containers/references/ReferenceCollectionItem'
import { OutlinedAddInputButton } from 'components/common/InputButton'

import './style.less'

class ReferenceCollectionContainer extends Component {
  static propTypes = {
    ...i18nPropTypes,
    selectedCollection: PropTypes.shape({
      id: PropTypes.string,
    }),
    collections: PropTypes.array,
    createReferenceCollection: PropTypes.func,
  }

  handleSubmit = async inputValue => this.props.createReferenceCollection({ name: inputValue })

  renderEmptyState() {
    return <div className="ReferenceCollectionContainer__EmptyDescription">{this.props.t('references.collections.emptyDescription')}</div>
  }

  render() {
    const { t, collections, selectedCollection } = this.props
    return (
      <div className="ReferenceCollectionContainer">
        <ReferenceCollectionItem
          collection={{ name: t('references.collections.all') }}
          active={!selectedCollection}
        />
        { collections && collections.length === 0 && this.renderEmptyState() }
        <div className="ReferenceCollectionContainer__SubCollections">
          { collections.map(collection => (
            <ReferenceCollectionItemDropzone
              className="ReferenceCollectionContainer__SubCollection"
              key={`${collection.id}${collection.name}`}
              collection={collection}
              active={selectedCollection && collection.id === selectedCollection.id}
              editable
            />
          ))}
        </div>
        <OutlinedAddInputButton
          className="ReferenceCollectionContainer__AddButton"
          onSubmit={this.handleSubmit}
        >
          {t('references.collections.addCollection')}
        </OutlinedAddInputButton>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  collections: getReferenceCollections(state),
  selectedCollection: getSelectedCollection(state),
})

export default connect(
  mapStateToProps,
  {
    createReferenceCollection,
  },
)(translate()(ReferenceCollectionContainer))
