
import { createSelector } from 'reselect'
import { get } from 'lodash'
import { getSelectedProjectId, getSelectedNoteId } from 'selectors/path'


export const noteCitationClustersSelector = state => state.citation.noteClusters
export const projectCitationClustersSelector = state => state.citation.projectClusters

/**
 *
 * @param {*} projectId the currently selected project id
 * @param {*} clusters all citation clusters in the store { <projectId>: {...cluster} }
 * @return {*} {
 *    type: String,
 *    bibliographyEntries: list of <div>'s
 *    projectItems: { <projectItemId>: [ { citationId, data: <div>, index }, ...] }
 * }
 */
export const getCitationClusterForSelectedProject = createSelector(
  getSelectedProjectId,
  projectCitationClustersSelector,
  (projectId, clusters) => {
    const cluster = get(clusters, projectId)
    if (cluster) {
      const projectItems = {}
      Object.keys(cluster.projectItems).forEach((projectItemId) => {
        projectItems[projectItemId] = cluster.projectItems[projectItemId]
          .filter(citationId => cluster.citations[citationId])
          .map(citationId => cluster.citations[citationId])
      })
      return {
        ...cluster,
        projectItems,
        bibliographyEntries: cluster.bibliography.meta.referenceIds.map(id => cluster.bibliography.entries[id]),
      }
    }
    return {}
  },
)


/**
 *
 * @param {*} noteId the currently selected note id
 * @param {*} clusters all citation clusters in the store { <noteId>: {...cluster} }
 * @return {*} {
 *    type: String,
 *    citations: [ { citationId, data: <div>, index }, ...]
 *    bibliographyEntries: [ <div>, <div>, ...]
 * }
 */
export const getCitationClusterForSelectedNote = createSelector(
  getSelectedNoteId,
  noteCitationClustersSelector,
  (noteId, clusters) => {
    const cluster = get(clusters, noteId)
    if (cluster) {
      return {
        ...cluster,
        citations: cluster.citationIds.filter(id => cluster.citations[id]).map(id => cluster.citations[id]),
        bibliographyEntries: cluster.bibliography.meta.referenceIds.map(id => cluster.bibliography.entries[id]),
      }
    }
    return {}
  },
)
