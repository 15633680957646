import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { debounce } from 'lodash'

import { translate } from 'i18n'
import { requestSvg } from 'api'

import Flyout from 'containers/common/Flyout'
import { PrimaryButton } from 'components/common/Button'
import Spinner from 'components/common/Spinner'
import Textarea from 'components/TextareaAutosize'

import './style.less'


class AddFormulaFlyout extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    hide: PropTypes.func.isRequired,
    onAddFormula: PropTypes.func,
    data: PropTypes.shape({ formula: PropTypes.string }),
  }

  state = {
    value: null, isLoading: false, isLoadingPreview: false, error: null,
  }

  debouncedRefreshSvgData = debounce(() => this.refreshSvgData(), 500)

  constructor(props) {
    super(props)
    this.state.value = props && props.data ? props.data.formula : null
  }

  componentDidMount() {
    const { value } = this.state
    if (!value) { return }
    this.debouncedRefreshSvgData()
  }

  refreshSvgData = async () => {
    const { value } = this.state
    if (!value) {
      return
    }
    try {
      this.setState({ isLoadingPreview: true, error: null })
      const svg = await requestSvg(value)
      this.setState({ svg })
    } catch (err) {
      this.setState({ error: err, isLoadingPreview: false, svg: null })
    } finally {
      this.setState({ isLoadingPreview: false })
    }
  }


  submitFormula = async () => {
    const { onAddFormula, data: { elementId } = {} } = this.props
    const { value } = this.state

    if (!onAddFormula) {
      return
    }
    try {
      this.setState({ isLoading: true, error: null })
      const elementData = await requestSvg(value)
      onAddFormula(value, elementData, elementId)
      this.props.hide()
    } catch (err) {
      this.setState({ error: err, isLoading: false })
    }
  }

  onValueChange = (e) => {
    e.stopPropagation()
    e.preventDefault()
    this.setState({ value: e.target.value })
    this.debouncedRefreshSvgData()
  }

  handleKeyPress = async (e) => {
    if (e.key !== 'Escape') { return }
    await this.props.hide()
  }

  htmlDecode(input) {
    const e = document.createElement('div')
    e.innerHTML = input
    return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue
  }

  render() {
    const {
      svg, value, isLoading, error, isLoadingPreview,
    } = this.state
    const { t, data: { width, elementId, formula } } = this.props
    return (
      <div className="AddFormulaFlyout" style={{ width }}>
        <div className="AddFormulaFlyout__Content">
          <Textarea
            className="AddFormulaFlyout__Input"
            placeholder={t('editor.addFormula')}
            onFocus={e => e.stopPropagation()}
            onBlur={e => e.stopPropagation()}
            onClick={e => e.stopPropagation()}
            type="text"
            value={value || ''}
            onChange={this.onValueChange}
            onKeyDown={this.handleKeyPress}
            maxRows={8}
            autoFocus
          />
          {isLoadingPreview ? <Spinner className="AddFormulaFlyout__Preview AddFormulaFlyout__Spinner" /> : (
            svg && <div className="AddFormulaFlyout__Preview" dangerouslySetInnerHTML={{ __html: svg }} />
          )}
          {!svg && error && <div className="AddFormulaFlyout__Preview">{t('editor.createFormulaError')}</div>}
          <PrimaryButton
            className="AddFormulaFlyout__Submit"
            isLoading={isLoading}
            type="button"
            onClick={this.submitFormula}
            disabled={!value || !!error}
          >
            {this.props.t(`common.${formula && elementId ? 'save' : 'add'}`)}
          </PrimaryButton>
        </div>
        <div className="AddFormulaFlyout__Infos">
          <a href={t('editor.latexCheatSheet')} target="_blank" rel="noopener noreferrer">{t('editor.latexFormulaInformation')}</a>
        </div>
      </div>
    )
  }
}

export default Flyout(translate()(AddFormulaFlyout))
