import React from 'react'
import PropTypes from 'prop-types'
import modifyClassName from 'helper/modifyClassName'

import './style.less'

const Switch = ({
  className = '', onChange, value, onLabel, offLabel,
}) => (
  <button
    className={`Switch ${className}`}
    onClick={() => onChange(!value)}
    type="button"
  >
    <div className="Switch__Label">{offLabel}</div>
    <div className="Switch__Slider">
      <div className={modifyClassName('Switch__Handle', { on: value })} />
    </div>
    <div className="Switch__Label">{onLabel}</div>
  </button>
)


Switch.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.bool,
  onLabel: PropTypes.string,
  offLabel: PropTypes.string,
}

export default Switch
