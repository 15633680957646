import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { get } from 'lodash'
import ProfileSelector from 'selectors/profile'
import NoteSelector from 'selectors/notes'
import SubscriptionSelector from 'selectors/subscriptions'
import PaymentSelector from 'selectors/payment'
import { SubmissionError } from 'redux-form'
import moment from 'moment'
import { NavLink } from 'react-router-dom'
import { push } from 'connected-react-router'

import i18n, { propTypes as i18nPropTypes, translate } from 'i18n'
import { Trans } from 'react-i18next'

import PaymentFormContainer from 'containers/subscription/PaymentFormContainer'
import {
  subscribe, unsubscribe, requestSubscriptions, requestPaymentData,
} from 'actions/subscriptions'
import { showModal } from 'actions/modal'
import SubscriptionPlans from 'components/subscription/SubscriptionPlans'
import PaymentSuccess from 'components/subscription/PaymentSuccess'
import Switch from 'components/common/Switch'
import { PremiumButton } from 'components/common/Button'
import modifyClassName from 'helper/modifyClassName'
import EducationVerificationContainer from 'containers/profile/EducationVerificationContainer'
import VoucherInput from 'containers/subscription/VoucherInput'

import ConfirmationModal from 'containers/modal/ConfirmationModal'
import Spinner from 'components/common/Spinner'

import './style.less'

const methodMap = {
  CREDIT: 'Kreditkarte',
  SEPA: 'Lastschrift',
  PAYPAL: 'Paypal',
}

const MODAL_ID = 'CANCEL_SUBSCRIPTION_MODAL'

class SubscriptionContainer extends Component {
  constructor() {
    super()
    this.state = {
      loadingSubscriptions: true,
      loadingPaymentData: true,
      loadingCancel: false,
      loadingPayment: false,
      show: 'default',
      plans: null,
      isYearly: null,
    }
  }

  componentDidMount() {
    this.props.requestSubscriptions().then(() => this.setState({ loadingSubscriptions: false }))
    this.props.requestPaymentData().then(() => this.setState({ loadingPaymentData: false }))
  }

  getSelectedPlan = () => {
    const { plans, isYearly } = this.state
    let selectedPlan = plans[0]
    let isSpecial = true

    if (plans.length === 2 && isYearly !== null) {
      selectedPlan = isYearly ? plans.filter(p => p.duration === 12)[0] : plans.filter(p => p.duration === 1)[0]
      isSpecial = false
    }
    return { ...selectedPlan, isSpecial }
  }

  handlePlansSelect = (plans) => {
    const isYearly = plans.length === 2 && (plans[0].duration === 12 || plans[1].duration === 12) ? true : null
    this.setState({ plans, show: 'checkout', isYearly })
  }

  handleCancelClick = async () => {
    this.props.showModal(MODAL_ID, { content: this.props.t('subscription.cancel.doyoureally', { date: moment(this.props.profile.subscriptions.activeSubscription.end).format('DD.MM.YYYY') }) })
  }

  handleSubscribeSubmit = async (values) => {
    const { voucher, t } = this.props

    this.setState({ loadingPayment: true })
    try {
      const method = values.method || 'FREE'
      const plan = this.getSelectedPlan()
      await this.props.subscribe({
        ...values, method, plan, voucher,
      })
      this.setState({ loadingPayment: false, show: 'success' })
    } catch (err) {
      console.log('failed', err)
      this.setState({ loadingPayment: false })
      throw new SubmissionError({ _error: t('subscription.payments.error') })
    }
  }

  handleCancelSubscription = async () => {
    try {
      this.setState({ loadingCancel: true })
      await this.props.unsubscribe()
      this.setState({ loadingCancel: false })
    } catch (error) {
      this.setState({ loadingCancel: false, loadingCancelError: true })
    }
  }

  handleDoneClick = () => {
    this.setState({ show: 'default' })
  }

  renderPaymentData() {
    const { t } = this.props

    if (this.props.paymentData) {
      let paymentInfo
      if (this.props.paymentData.method === 'PAYPAL') {
        paymentInfo = <span>Paypal</span>
      } else if (this.props.paymentData.method === 'SEPA') {
        paymentInfo = <span>SEPA</span>
      } else {
        paymentInfo = (
          <div>
            <span>
              {methodMap[this.props.paymentData.method]}
              {' '}
            </span>
            {this.props.paymentData.card && <span>{t('subscription.payments.numberendswith', { number: this.props.paymentData.card.number })}</span>}
          </div>
        )
      }
      return (
        <div className="SubscriptionSummary__Detail">
          {t('subscription.payments.method')}
          {' '}
          {paymentInfo}

        </div>
      )
    }
    return <div />
  }

  renderTemporarySubscription(activeTemporarySubscription) {
    const { t } = this.props
    const {
      end, name, duration, label,
    } = activeTemporarySubscription

    return (
      <div className="SubscriptionSummary SubscriptionSummary--referral">
        <div className="SubscriptionSummary__Plan SubscriptionSummary__Plan--special">{i18n.exists(`subscription.plans.${name}`) ? t(`subscription.plans.${name}`) : label}</div>
        <div className="SubscriptionSummary__Amount">{t('subscription.monthWithCount', { count: parseInt(duration, 10) })}</div>
        <div className="SubscriptionSummary__Detail">
          <div>{t('subscription.summary.detail.subscriptionEnd')}</div>
          <div>{moment(end).format('DD.MM.YYYY')}</div>
        </div>
        <div className="SubscriptionSummary__Detail SubscriptionSummary__Detail--section">
          <div>{t('subscription.summary.detail.referralDescription')}</div>
        </div>
        <div className="SubscriptionSummary__Detail SubscriptionSummary__Detail--section">
          <button className="UserSubscription__CancelButton" onClick={() => this.props.push('/profile/referral')}>{t('subscription.summary.detail.referralButton')}</button>
        </div>
      </div>
    )
  }

  renderActiveSubscription(activeSubscription) {
    const { t } = this.props
    const {
      end, begin, name, label, autoRenewal, renewalPrice, price, currency,
    } = activeSubscription

    let duration = null
    if (activeSubscription.duration === 'months') {
      duration = t('subscription.monthWithCount', { count: 1 })
      const diff = moment.duration(moment(end).diff(moment(begin)))
      if (Math.ceil(diff.asMonths()) > 2) {
        duration = t('subscription.monthWithCount', { count: Math.ceil(diff.asMonths()) })
      }
    } else if (activeSubscription.duration === 'years') {
      duration = t('subscription.yearWithCount', { count: 1 })
    }

    let heading = t('subscription.title')
    if (name) heading = i18n.exists(`subscription.plans.${name}`) ? t(`subscription.plans.${name}`) : label

    return (
      <div className="SubscriptionSummary">
        <div className="SubscriptionSummary__Plan">{heading}</div>
        { !duration && <div className="SubscriptionSummary__Amount">{t('subscription.monthWithCount', { count: parseInt(activeSubscription.duration, 10) })}</div> }
        { duration && <div className="SubscriptionSummary__Amount">{duration}</div>}
        <div className="SubscriptionSummary__Detail">
          <div>{t('subscription.summary.detail.renewal')}</div>
          <div>{autoRenewal ? t('common.yes') : t('common.no')}</div>
        </div>
        <div className="SubscriptionSummary__Detail">
          <div>{autoRenewal ? t('subscription.summary.detail.nextPaymentDate') : t('subscription.summary.detail.subscriptionEnd')}</div>
          <div>{moment(end).format('DD.MM.YYYY')}</div>
        </div>
        {autoRenewal && (
        <div className="SubscriptionSummary__Detail">
          <div>{t('subscription.summary.detail.renewalDuration')}</div>
          {!duration && <div>{t('subscription.monthWithCount', { count: activeSubscription.renewalDuration || parseInt(activeSubscription.duration, 10) })}</div>}
          {duration && <div>{duration}</div>}
        </div>
        ) }
        {autoRenewal && (
        <div className="SubscriptionSummary__Detail">
          <div>{t('subscription.summary.detail.renewalPrice')}</div>
          <div>{t(`common.price${currency}`, { price: renewalPrice ? renewalPrice / 100.0 : price / 100.0 })}</div>
        </div>
        )}


        { autoRenewal && this.renderPaymentData() }
        { autoRenewal && (
        <div className="SubscriptionSummary__Detail">
          { !this.state.loadingCancel && <button className="UserSubscription__CancelButton" onClick={this.handleCancelClick}>{t('subscription.cancel.button')}</button> }
          { this.state.loadingCancel && (
          <div>
            <Spinner className="SubscriptionSummary__LoadingIndicator" size="3x" />
            {t('subscription.cancel.canceling')}
          </div>
          )}
          { this.state.loadingCancelError && <div className="UserSubscription__CancelError">{t('subscription.cancel.error')}</div>}
        </div>
        ) }
      </div>
    )
  }

  renderUserAbo() {
    const { profile, t } = this.props
    const activeSubscription = get(profile, 'subscriptions.activeSubscription')
    const activeTemporarySubscription = get(this.props.profile, 'subscriptions.activeTemporarySubscription')

    if (activeSubscription || activeTemporarySubscription) {
      return (
        <div>
          <h1 className="Heading">{t('subscription.heading')}</h1>
          <div className="UserSubscription">
            <div className="left">
              {activeTemporarySubscription && this.renderTemporarySubscription(activeTemporarySubscription)}
              {activeSubscription && this.renderActiveSubscription(activeSubscription)}
            </div>
            <div className="right">
              <div className="PaymentForm PaymentForm--payments">
                <h4>{t('subscription.payments.heading')}</h4>
                { this.props.subscriptions.length > 0 && this.renderSubscriptions() }
              </div>
            </div>

          </div>
        </div>
      )
    }

    return (
      <div>
        <div className="UserSubscription UserSubscription--initial">
          <SubscriptionPlans plans={this.props.subscriptionPlans} voucher={this.props.voucher} onSelect={this.handlePlansSelect} />
          <VoucherInput className="UserSubscription__VoucherInput" />
        </div>
      </div>
    )
  }

  renderSubscription = subscription => (
    <div key={subscription.id} className="SubscriptionHistory__Subscription Subscription">
      <div className="Subscription__Data">{moment(subscription.begin).format('DD.MM.YYYY')}</div>
      <div className="Subscription__Data">
        {subscription.price / 100 }
        {' '}
        {subscription.currency}
      </div>
      <div className="Subscription__Data">
        {moment(subscription.begin).format('DD.MM.YYYY')}
        -
        {moment(subscription.end).format('DD.MM.YYYY')}
      </div>
      <div className="Subscription__Data Subscription__Data--id">{subscription.id}</div>
    </div>
  )

  renderSubscriptions() {
    const { t } = this.props

    if (this.state.loadingSubscriptions) {
      return (
        <div className="SubscriptionHistory">
          <Spinner className="SubscriptionHistory__LoadingIndicator" size="3x" />
          {t('subscription.payments.loading')}
        </div>
      )
    }


    return (
      <div className="SubscriptionHistory">
        <div className="SubscriptionHistory__List">
          <div className="SubscriptionHistory__Header">
            <div className="SubscriptionHistory__Head">{t('common.date')}</div>
            <div className="SubscriptionHistory__Head">{t('subscription.payments.amount')}</div>
            <div className="SubscriptionHistory__Head">{t('subscription.payments.duration')}</div>
            <div className="SubscriptionHistory__Head">{t('subscription.payments.paymentnumber')}</div>
          </div>
          { this.props.subscriptions.map(subscription => this.renderSubscription(subscription)) }
        </div>
      </div>
    )
  }

  renderEducationVerification() {
    const { t } = this.props
    return (
      <div className="EducationVerification">
        <div className="EducationVerification__Title">{t('education.planSelection.title')}</div>
        <Trans i18nKey="education.planSelection.description">
          description
          <NavLink to="/profile">Profil</NavLink>
          description
        </Trans>
        <div className="EducationVerification__Box">
          <EducationVerificationContainer />
        </div>
      </div>
    )
  }

  renderPaymentForm() {
    const { profile, t, voucher } = this.props
    const selectedPlan = this.getSelectedPlan()

    if (profile.educationVerificationStatus !== 'VERIFIED' && selectedPlan.requiresEducationVerification) {
      return this.renderEducationVerification()
    }
    if (selectedPlan.monthlyPrice === 0) {
      return (
        <div className="Payment__ForFreeContainer">
          <div className="Payment__ForFreeText">{t('subscription.freePlanText')}</div>
          <PremiumButton onClick={this.handleSubscribeSubmit} isLoading={this.state.loadingPayment}>{t('subscription.buyButtonCTA') }</PremiumButton>
        </div>
      )
    }

    return (
      <React.Fragment>
        <PaymentFormContainer form="PaymentForm" onSubmit={this.handleSubscribeSubmit} isLoading={this.state.loadingPayment} plan={selectedPlan} voucher={voucher} />
        <div className="Payment__Disclaimer">
          <Trans i18nKey="subscription.adyen.trans">
            index0<a href={t('subscription.adyen.link')} target="_blank" rel="noopener noreferrer">index1</a>index2
          </Trans>
        </div>
      </React.Fragment>
    )
  }

  renderCheckout() {
    const { t, voucher } = this.props
    const { isYearly, plans } = this.state

    const selectedPlan = this.getSelectedPlan()

    const start = moment()
    const end = moment().add(selectedPlan.duration, 'months')

    // Auratikum Premium

    return (
      <div>
        <h1 className="Heading">{t('subscription.summary.heading')}</h1>
        <div className="Subscription__Payment Payment">
          <div className="left">
            <div className="PaymentSummary">
              <div className={modifyClassName('PaymentSummary__Plan', { special: selectedPlan.isSpecial })}>{i18n.exists(`subscription.plans.${selectedPlan.name}`) ? t(`subscription.plans.${selectedPlan.name}`) : selectedPlan.label}</div>
              <div className="PaymentSummary__Amount">{t(`common.price${selectedPlan.currency}`, { price: selectedPlan.price / 100.0 })}</div>
              {(isYearly || isYearly === null) && (
              <div className="PaymentSummary__AmountMonth">
                {t(`common.price${selectedPlan.currency}`, { price: (selectedPlan.monthlyPrice / 100) })}
                <div className="PaymentSummary__AmountMonthDuration">{t('subscription.perMonth')}</div>
              </div>
              )}


              <div className="PaymentSummary__Tax">
                {/* {t('subscription.summary.tax')} */}
              </div>

              {isYearly !== null && plans[0].isValid && plans[1].isValid && (
              <Switch
                className="PaymentSummary__Switch"
                offLabel={t('subscription.monthly')}
                onLabel={t('subscription.yearly')}
                value={isYearly}
                onChange={value => this.setState({ isYearly: value })}
              />
              )}

              <div className="PaymentSummary__Detail">
                <div>{t('subscription.summary.detail.begin')}</div>
                <div>{start.format('DD.MM.YYYY')}</div>
              </div>
              {selectedPlan.autoRenewal && (
              <div className="PaymentSummary__Detail">
                <div>{t('subscription.summary.detail.term')}</div>
                <div>{t('subscription.monthWithCount', { count: selectedPlan.duration })}</div>
              </div>
              )}

              <div className="PaymentSummary__Detail">
                <div>{t('subscription.summary.detail.minimumTerm')}</div>
                <div>{end.subtract(1, 'days').format('DD.MM.YYYY')}</div>
              </div>
              {selectedPlan.autoRenewal && (
              <div className="PaymentSummary__Detail">
                <div>{t('subscription.summary.detail.renewal')}</div>
                <div>{end.format('DD.MM.YYYY')}</div>
              </div>
              )}
              {selectedPlan.renewalDuration && (
              <div className="PaymentSummary__Detail">
                <div>{t('subscription.summary.detail.renewalDuration')}</div>
                <div>{t('subscription.monthWithCount', { count: selectedPlan.renewalDuration })}</div>
              </div>
              )}
              {(selectedPlan.renewalPrice || (voucher && selectedPlan.originalPrice > 0)) && (
              <div className="PaymentSummary__Detail">
                <div>{t('subscription.summary.detail.renewalPrice')}</div>
                <div>{t(`common.price${selectedPlan.currency}`, { price: selectedPlan.renewalPrice / 100 || selectedPlan.originalPrice / 100 })}</div>
              </div>
              )}

              {selectedPlan.autoRenewal && (
              <div className="PaymentSummary__Detail PaymentSummary__Detail--section">
                <div>{t('subscription.summary.detail.cancelationPolicy')}</div>
              </div>
              )}
            </div>
          </div>

          <div className="right">
            <div className="PaymentForm">
              { this.renderPaymentForm()}
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderSuccess = () => <PaymentSuccess onClick={this.handleDoneClick} />

  render() {
    const { t } = this.props
    return (
      <div className="SubscriptionContainer">
        <div className="SubscriptionContainer__Wrapper">
          { this.state.show === 'success' && this.renderSuccess() }
          { this.state.show === 'checkout' && this.renderCheckout() }
          { this.state.show === 'default' && this.renderUserAbo() }
        </div>
        <ConfirmationModal
          id={MODAL_ID}
          title={t('subscription.cancel.title')}
          onConfirm={this.handleCancelSubscription}
          okLabel={t('subscription.delete')}
          cancelLabel={t('subscription.keep')}
        />
      </div>
    )
  }
}

SubscriptionContainer.propTypes = {
  ...i18nPropTypes,
  profile: PropTypes.shape({}),
  subscribe: PropTypes.func,
  subscriptions: PropTypes.array,
}


const mapStateToProps = state => ({
  profile: ProfileSelector.getProfile(state),
  subscriptions: SubscriptionSelector.getSubscriptions(state),
  noteCount: NoteSelector.getCount(state),
  maxNoteCount: ProfileSelector.getMaxNoteCount(state),
  inviteLink: ProfileSelector.getInviteLink(state),
  paymentData: PaymentSelector.getPaymentData(state),
  subscriptionPlans: SubscriptionSelector.getSubscriptionPlans(state),
  voucher: SubscriptionSelector.getVoucher(state),
})


export default connect(
  mapStateToProps,
  {
    subscribe,
    unsubscribe,
    requestSubscriptions,
    requestPaymentData,
    showModal,
    push,
  },
)(translate()(SubscriptionContainer))
