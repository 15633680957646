import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import {
  reduxForm, SubmissionError, Field, propTypes as reduxPropTypes,
} from 'redux-form'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import { get } from 'lodash'
import queryString from 'query-string'

import tracker from 'tracking/tracker'
import { PrimaryButton } from 'components/common/Button'
import { resetPassword } from 'actions/authentication'
import { push } from 'connected-react-router'


class Reset extends Component {
  submit = async (values) => {
    try {
      await this.props.resetPassword(values)
      this.props.push(`/new-password?email=${encodeURIComponent(values.email)}`)
      tracker.logEvent('PASSWORD_RESET_REQUESTED')
    } catch (err) {
      throw new SubmissionError({ _error: err.code })
    }
  }

  render() {
    const {
      handleSubmit, submitting, error, t,
    } = this.props

    return (
      <React.Fragment>
        <div className="AuthForm__Title">{t('auth.reset.title')}</div>
        { error && <div className="AuthForm__Alert">{ this.props.t(`auth.errors.${error}`) || this.props.t('auth.errors.default') }</div> }
        <form className="AuthForm__Form" onSubmit={handleSubmit(this.submit)}>
          <Field className="AuthForm__Input AuthForm__Input--single" name="email" component="input" type="text" placeholder={t('common.email')} />
          <PrimaryButton className="AuthForm__SubmitButton" type="submit" isLoading={submitting}>{t('common.resetPassword')}</PrimaryButton>
        </form>
        <NavLink to="/login" className="AuthForm__Link">{t('auth.reset.hint')}</NavLink>
      </React.Fragment>
    )
  }
}

const form = { form: 'Reset' }

Reset.propTypes = {
  ...reduxPropTypes,
  ...i18nPropTypes,
  resetPassword: PropTypes.func,
}

const mapStateToProps = (state, ownProps) => ({
  initialValues: {
    email: get(queryString.parse(ownProps.location.search), 'email'),
  },
})

export default connect(mapStateToProps, {
  resetPassword,
  push,
})(reduxForm(form)(translate()(Reset)))
