import { handleActions } from 'redux-actions'

import { setExporting } from 'actions/projects'

const reducer = handleActions({
  [setExporting]: (state, action) => ({
    ...state,
    isExporting: action.payload,
  }),
}, {
  isExporting: false,
})

export default reducer
