import React from 'react'
import PropTypes from 'prop-types'
import { AuratikumFontIcons } from 'helper/utils'
import Icon from 'components/common/Icon'
import modifyClassName from 'helper/modifyClassName'

import './style.less'

const NotesOverviewToolbarViewTypeSwitch = ({ viewType, setViewType }) => (
  <div className="NotesOverviewToolbarViewTypeSwitch">
    <button type="button" className={modifyClassName('NotesOverviewToolbarViewTypeSwitch__Button', { active: viewType === 'GRID' })} onClick={() => setViewType('GRID')}>
      <Icon icon={AuratikumFontIcons.GRID} />
    </button>
    <button type="button" className={modifyClassName('NotesOverviewToolbarViewTypeSwitch__Button', { active: viewType === 'LIST' })} onClick={() => setViewType('LIST')}>
      <Icon icon={AuratikumFontIcons.LIST} />
    </button>
  </div>
)

NotesOverviewToolbarViewTypeSwitch.propTypes = {
  viewType: PropTypes.string,
  setViewType: PropTypes.func,
}

export default NotesOverviewToolbarViewTypeSwitch
