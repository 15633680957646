import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { AuratikumFontIcons } from 'helper/utils'
import Icon from 'components/common/Icon'
import { Manager, Reference } from 'react-popper'
import { SmallTag } from 'components/common/Tag'
import TagListFlyout from 'components/common/TagListFlyout'
import { propTypes as i18nPropTypes, translate } from 'i18n'

import './style.less'

const TAG_LIST_FLYOUT_ID = 'TAG_LIST_FLYOUT_ID'

class ToolbarTagFilter extends Component {
  showFlyout = () => this.props.showFlyout(TAG_LIST_FLYOUT_ID)

  render() {
    const {
      tags, selectedTags = [], onSelect, t,
    } = this.props

    return (
      <div className="ToolbarTagFilter">
        <div>
          <Manager>
            <Reference>
              { ({ ref }) => (
                <div ref={ref} className="ToolbarTagFilter__Container">
                  { selectedTags.length === 0 && <button onClick={this.showFlyout} className="ToolbarTagFilter__Button">{t('common.tags')}</button> }
                  { selectedTags.map((tag, index) => {
                    const style = { zIndex: (100 - index) }
                    if (index > 0) {
                      return <button key={tag.tag} onClick={this.showFlyout} style={style} className="ToolbarTagFilter__TagIndication" />
                    }
                    return (
                      <SmallTag
                        style={style}
                        key={tag.tag}
                        className="ToolbarTagFilter__Item"
                        tag={tag.tag}
                        onSelect={this.showFlyout}
                      />
                    )
                  })}
                  <button onClick={this.showFlyout}><Icon className="ToolbarTagFilter__Caret" icon={AuratikumFontIcons.ARROW_DOWN} /></button>
                </div>
              ) }
            </Reference>

            <TagListFlyout
              tags={tags}
              id={TAG_LIST_FLYOUT_ID}
              selectedTags={selectedTags}
              onSelect={onSelect}
            />
          </Manager>
        </div>
      </div>
    )
  }
}

ToolbarTagFilter.propTypes = {
  showFlyout: PropTypes.func,
  tags: PropTypes.array,
  selectedTags: PropTypes.array,
  onSelect: PropTypes.func,
  ...i18nPropTypes,
}

export default translate()(ToolbarTagFilter)
