import React, { Component } from 'react'
import PropTypes from 'prop-types'
import AutoSuggest from 'react-autosuggest'
import { propTypes as i18nPropTypes, translate } from 'i18n'

import HelpSpot from 'containers/HelpSpot'
import Spinner from 'components/common/Spinner'

import FontIcon from 'components/common/FontIcon'

import './style.less'

const CREATE_SUGGESTION_PLACEHOLDER = 'CREATE_SUGGESTION_PLACEHOLDER'

class AutoSuggestButton extends Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      displayAutoSuggest: false,
      value: '',
      isCreating: false,
    }
  }

  componentWillReceiveProps = ({ formValue }) => {
    if (this.props.formValue !== formValue) {
      this.props.onSuggestionsFetchRequested({
        value: this.state.value,
        reason: 'form-update',
      })
    }
  }

  handleDisplayAutoSuggest = (display) => {
    this.setState({
      displayAutoSuggest: display,
    })
  }

  handleOnChange = (e) => {
    this.setState({ value: e.target.value })
  }

  handleKeyPress = async (e) => {
    if (e.charCode === 13 && this.props.onCreateSelected) {
      e.preventDefault()
      this.setState({ isCreating: true, displayAutoSuggest: false })
      await this.props.onCreateSelected(this.state.value)
      this.setState({ isCreating: false })
      this.setState({ value: '' })
    }
  }

  handleSelection = async (event, { suggestion }) => {
    event.preventDefault()
    event.stopPropagation()
    if (suggestion.type && suggestion.type === CREATE_SUGGESTION_PLACEHOLDER) {
      this.setState({ isCreating: true, displayAutoSuggest: false })
      await this.props.onCreateSelected(this.state.value)
      this.setState({ isCreating: false })
    } else {
      this.props.onSuggestionSelected(suggestion)
    }
    this.setState({ value: '' })
  }

  renderButton = () => {
    const { title, hintTranslationKey, t } = this.props

    return (
      <div className="AutoSuggestButton__ButtonContainer">

        <div className="AutoSuggestButton__ButtonWrapper">

          <button type="button" className="AutoSuggestButton__Button" onClick={() => this.handleDisplayAutoSuggest(true)}>
            <Spinner show={this.state.isCreating}>{title}</Spinner>
          </button>
        </div>
        { hintTranslationKey && (
        <div className="AutoSuggestButton__Hint">
          <div className="AutoSuggestButton__HintIcon">
            <FontIcon icon={FontIcon.Icons.faLightbulb} />
          </div>

          {t(hintTranslationKey)}
        </div>
        )}
      </div>
    )
  }

  renderSuggestionsContainer = ({ containerProps, children, query }) => (
    <div {... containerProps}>
      {this.props.renderHeader && this.props.renderHeader({ query })}
      {children}
    </div>
  )

renderSuggestion = (suggestion) => {
  if (suggestion.type && suggestion.type === CREATE_SUGGESTION_PLACEHOLDER) {
    return (
      <span className="suggestion__create">
        {this.props.createLabel}
      </span>
    )
  }
  return this.props.renderSuggestion(suggestion)
}

  renderAutoSuggest = () => {
    const { title } = this.props

    const inputProps = {
      placeholder: title,
      autoFocus: true,
      onBlur: () => this.handleDisplayAutoSuggest(false),
      onChange: this.handleOnChange,
      onKeyPress: this.handleKeyPress,
      value: this.state.value,
    }

    let { suggestions } = this.props
    if (this.props.createLabel) {
      suggestions = [...(suggestions || []), { type: CREATE_SUGGESTION_PLACEHOLDER }]
    }

    return (
      <div className="AutoSuggestButton__Container AutoSuggestButton__Container--displayAutoSuggest">
        <AutoSuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.props.onSuggestionsFetchRequested}
          getSuggestionValue={this.props.getSuggestionValue}
          renderSuggestion={this.renderSuggestion}
          renderSuggestionsContainer={this.renderSuggestionsContainer}
          renderSectionTitle={this.renderSectionTitle}
          onSuggestionSelected={this.handleSelection}
          inputProps={inputProps}
          shouldRenderSuggestions={() => true}
          alwaysRenderSuggestions
        />
      </div>
    )
  }

  render = () => {
    const { displayAutoSuggest } = this.state
    return (
      <div className="AutoSuggestButton">
        { displayAutoSuggest || this.props.displayAutoSuggest ? this.renderAutoSuggest() : this.renderButton() }
        { this.props.helpSpotTranslationKey
        && (
        <HelpSpot
          className="AutoSuggestButton__HelpSpot"
          translationKey={this.props.helpSpotTranslationKey}
        />
        )
        }
      </div>
    )
  }
}

AutoSuggestButton.propTypes = {
  ...i18nPropTypes,
  title: PropTypes.string,
  suggestions: PropTypes.arrayOf(PropTypes.shape()),
  onSuggestionsFetchRequested: PropTypes.func,
  getSuggestionValue: PropTypes.func,
  renderSuggestion: PropTypes.func,
  renderHeader: PropTypes.func,
  onSuggestionSelected: PropTypes.func,
  onCreateSelected: PropTypes.func,
  createLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  helpSpotTranslationKey: PropTypes.string,
  fieldValue: PropTypes.shape({}),
  hintTranslationKey: PropTypes.string,
  displayAutoSuggest: PropTypes.bool,
}

AutoSuggestButton.defaultProps = {
}

export default translate()(AutoSuggestButton)
