import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { isEmpty, get } from 'lodash'

import { translate } from 'i18n'
import modifyClassName from 'helper/modifyClassName'

import Table from 'components/common/Table'
import TableBody from 'components/common/TableBody'

import './style.less'


class ReferralList extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    referrals: PropTypes.arrayOf(PropTypes.shape({})),
    embedded: PropTypes.bool,
    className: PropTypes.string,
  }

  static defaultProps = {
    className: '',
  }

  renderReferral(referral) {
    const { t } = this.props
    return (
      <tr key={get(referral, '_id')} className="ReferralContainer__Referral Referral">
        <td><div className="Referral__Item">{moment(referral.createdAt).format('DD.MM.YYYY')}</div></td>
        <td><div className="Referral__Item Referral__Item--email">{referral.email || t('referral.unknownUser')}</div></td>
        <td className="Referral__Item">
          <div className={modifyClassName('Referral__Status', { [referral.status]: true })}>{t(`referral.status.${referral.status}`)}</div>
        </td>
      </tr>
    )
  }

  render() {
    const {
      t, referrals, embedded, className,
    } = this.props
    return (
      <div className={`ReferralList ${className}`}>
        <div className="ReferralList__Title">{t('referral.sentInvites')}</div>
        {isEmpty(referrals) && !embedded ? null : (
          <Table boxed={!embedded}>
            <thead>
              <tr>
                <th>{t('common.date')}</th>
                <th>{t('common.email')}</th>
                <th>{t('common.status')}</th>
              </tr>
            </thead>
            <TableBody>{referrals.map(referral => this.renderReferral(referral))}</TableBody>
          </Table>
        )}
      </div>
    )
  }
}


export default translate()(ReferralList)
