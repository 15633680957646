import React from 'react'
import PropTypes from 'prop-types'
import modifyClassName from 'helper/modifyClassName'

import './style.less'

const FormTextInput = ({
  className = '',
  label,
  autoFocus,
  placeholder,
  meta: {
    valid, touched, error, form,
  } = {},
  input = {},
}) => (
  <div className={`FormTextInput ${className}`}>
    { (label || (touched && error)) && (
    <label htmlFor={`${form}.${input.name}`} className="FormTextInput__Label">
      {label}
      {touched && error && <span className="FormTextInput__Error">{error}</span>}
    </label>
    ) }
    <div className={modifyClassName('FormTextInput__Field', { valid: touched && valid })}>
      <input id={`${form}.${input.name}`} {...{ autoFocus }} className="FormTextInput__Input" type="text" {...input} placeholder={placeholder} />
    </div>
  </div>
)

FormTextInput.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  meta: PropTypes.shape({}),
  input: PropTypes.shape({}),
  autoFocus: PropTypes.bool,
}

FormTextInput.defaultPropTypes = {
  autoFocus: false,
}

export default FormTextInput
