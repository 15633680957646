
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { propTypes as i18nPropTypes, translate } from 'i18n'

import { checkVoucher } from 'actions/subscriptions'
import { SecondaryButton } from 'components/common/Button'
import Input from 'components/common/Input'
import SubscriptionSelector from 'selectors/subscriptions'

import './style.less'

class VoucherInput extends Component {
  constructor(props) {
    super()
    this.state = {
      isLoading: false,
      value: (props.voucher && props.voucher.code) || '',
      error: null,
    }
  }


  handleSubmitVoucher = async (e) => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    if (this.state.isLoading) return
    this.setState({ isLoading: true, error: null })
    try {
      await this.props.checkVoucher(this.state.value)
    } catch (error) {
      this.setState({ error: error.data.error })
    }
    this.setState({ isLoading: false })
  }

  handleOnChange = async (e) => {
    const { value } = e.target
    await this.setState({ value })
    if (value === '') {
      this.handleSubmitVoucher()
    }
  }

  render() {
    const { className, t } = this.props
    const { value, error, isLoading } = this.state
    return (
      <div className={`VoucherInput ${className}`}>
        <div className="VoucherInput__Heading">{t('subscription.vouchers.heading')}</div>
        <form className="VoucherInput__Form" onSubmit={this.handleSubmitVoucher}>
          <Input className="VoucherInput__Input" placeholder={t('subscription.vouchers.redeemPlaceholder')} value={value} onChange={this.handleOnChange} />
          <SecondaryButton type="submit" className="VoucherInput__SubmitButton" onClick={this.handleSubmitVoucher} isLoading={isLoading}>
            {t('subscription.vouchers.redeem')}
          </SecondaryButton>
        </form>
        { error && <div className="VoucherInput__Error">{t(`subscription.vouchers.errors.${error}`)}</div>}
      </div>
    )
  }
}

VoucherInput.propTypes = {
  ...i18nPropTypes,
  checkVoucher: PropTypes.func,
  voucher: PropTypes.shape({}),
  className: PropTypes.string,
}


const mapStateToProps = state => ({
  voucher: SubscriptionSelector.getVoucher(state),
})

export default connect(
  mapStateToProps,
  {
    checkVoucher,
  },
)(translate()(VoucherInput))
