import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { get } from 'lodash'

import { createProject, updateProject } from 'actions/projects'
import ProjectSelector from 'selectors/projects'
import ProjectSettingsForm, { FORM_NAME } from 'components/projects/ProjectSettingsForm'
import ModalFooter from 'components/common/ModalFooter'
import ModalHeader from 'components/common/ModalHeader'
import { submit, isSubmitting } from 'redux-form'

import Modal from '../Modal'

import './style.less'

export const MODAL_ID = 'PROJECT_SETTINGS_MODAL'

class ProjectSettingsModal extends Component {
  constructor(props) {
    super(props)
  }

  handleSubmit = async (values) => {
    const { project } = this.props
    if (project) {
      // a project has been edited, do an update
      await this.props.updateProject(values)
    } else {
      // create a new project
      await this.props.createProject(values)
    }
    this.props.hide()
  }

  handleConfirm = () => {
    this.props.submit(FORM_NAME)
  }

  handleCancel = () => {
    this.props.hide()
  }

  render() {
    const {
      okLabel, cancelLabel, title, project,
    } = this.props

    return (
      <div className="ProjectSettingsModal">
        <ModalHeader title={title} />
        <ProjectSettingsForm initialValues={project} onSubmit={this.handleSubmit} />
        <ModalFooter
          className=""
          isLoading={this.props.isSubmitting}
          confirmButtonLabel={okLabel}
          confirmButtonType="submit"
          onConfirmButtonClick={this.handleConfirm}
          cancelButtonLabel={cancelLabel}
          onCancelButtonClick={this.handleCancel}
        />
      </div>
    )
  }
}


const mapStateToProps = (state, ownProps) => ({
  project: ProjectSelector.getById(state, get(ownProps, 'data.projectId')),
  selectedProject: ProjectSelector.getSelectedProject(state),
  okLabel: get(ownProps, 'data.okLabel') || ownProps.okLabel,
  title: get(ownProps, 'data.title') || ownProps.title,
  isSubmitting: isSubmitting(FORM_NAME)(state),
})

ProjectSettingsModal.propTypes = {
  okLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  createProject: PropTypes.func,
  title: PropTypes.string,
  hide: PropTypes.func,
  project: PropTypes.shape({}),
  handleSubmit: PropTypes.func, // comes from reduxForm
  submit: PropTypes.func,
  isSubmitting: PropTypes.bool,
}

export default Modal(connect(mapStateToProps, {
  createProject,
  updateProject,
  submit,
})(ProjectSettingsModal))
