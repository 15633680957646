import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import { debounce } from 'lodash'
import modifyClassName from 'helper/modifyClassName'

import { uploadProjectItemImage } from 'actions/project-items'

import FileDrop from 'components/common/FileDrop'
import { OutlinedButton, OutlinedSecondaryButton } from 'components/common/Button'
import Icon from 'components/common/Icon'
import { AuratikumFontIcons } from 'helper/utils'

import { Prompt } from 'react-router'

import WritingMovable from '../WritingMovable'

import './style.less'

const DEBOUNCE_TIME = 1000

class WritingImage extends Component {
  constructor(prop) {
    super(prop)
    this.state = {
      isUploading: false,
      errorMessage: null,
      edit: false,
      title: this.props.item.title,
      isSaving: false,
    }

    this.shouldClose = false
    this.debouncedSave = debounce(this.handleSave, DEBOUNCE_TIME)
  }

  handleFileDrop = async (files) => {
    const { item, uploadImage } = this.props

    this.setState({ errorMessage: null })

    if (files && files.length > 0) {
      const file = files[0]

      if (file.type.includes('image')) {
        this.setState({ isUploading: true })
        try {
          await uploadImage(item, file)
          this.setState({ errorMessage: null })
        } catch (e) {
          this.setState({ errorMessage: e.message })
        }
        this.setState({ isUploading: false })
      } else {
        this.setState({ errorMessage: this.props.t('upload.noImageFile') })
      }
    }
  }

  handleRemove = () => this.props.removeProjectItem(this.props.item)

  handleSize = size => this.props.updateProjectItem({ ...this.props.item, imageSize: size })

  handleSizeSmall = () => this.handleSize('50%')

  handleSizeMedium = () => this.handleSize('75%')

  handleSizeLarge = () => this.handleSize('100%')

  startEdit = async () => await this.setState({ edit: true })

  handleBlur = () => {
    this.shouldClose = true
    this.debouncedSave()
  }


  handleOnChange = (event) => {
    if (this.state.title !== event.target.value) {
      this.setState({ title: event.target.value })
      this.debouncedSave()
    }
  }

  handleSave = async () => {
    if (!this.state.isSaving) {
      this.setState({ isSaving: true })
      await this.props.updateProjectItem({ ...this.props.item, title: this.state.title })
      const s = { isSaving: false }
      if (this.shouldClose) s.edit = false
      this.setState(s)
      if (this.state.title !== this.props.item.title) {
        this.handleSave()
      }
    }
  }

  renderRead = () => {
    if (this.props.item.title && this.props.item.title.length > 0) {
      return (
        <button
          type="button"
          className="WritingImage__Read"
          onClick={this.startEdit}
        >
          {this.props.item.title}

        </button>
      )
    }
    return <button type="button" onClick={this.startEdit} className="WritingImage__ReadEmpty">{this.props.t('writing.writingImage.emptyCaption')}</button>
  }

  renderEdit = () => (
    <React.Fragment>
      <Prompt when={this.props.item.title !== this.state.title} message={this.props.t('common.unsavedChangesPrompt')} />
      <input
        className="WritingImage__Input"
        type="text"
        value={this.state.title || ''}
        onChange={this.handleOnChange}
        autoFocus
        onBlur={this.handleBlur}
      />
    </React.Fragment>
  )

renderFileDrop = () => (
  <React.Fragment>
    <FileDrop
      className="WritingImage__FileDrop"
      text={this.props.t('writing.writingImage.text')}
      textDrop={this.props.t('writing.writingImage.textDrop')}
      onDrop={this.handleFileDrop}
      isLoading={this.state.isUploading}
    />
    {this.state.errorMessage && <div className="WritingImage__Error">{this.state.errorMessage}</div>}
  </React.Fragment>
)

renderImage = () => (
  <img
    role="presentation"
    style={{ width: this.props.item.imageSize || '100%' }}
    className="WritingImage__Image"
    src={this.props.item.imageUrl}
  />
)

renderActions = () => (
  <div className={modifyClassName('WritingImage__Actions', { hidden: this.props.movingModeActive })}>
    <OutlinedSecondaryButton className="WritingImage__Action" onClick={this.props.onSelectForMoveAndDrop} circleModifier="circleSmall"><Icon icon={AuratikumFontIcons.MOVE} /></OutlinedSecondaryButton>
    {this.props.item.imageUrl
        && (
        <div>
          <OutlinedButton className="WritingImage__Action" onClick={this.handleSizeSmall} circleModifier="circleSmall">S</OutlinedButton>
          <OutlinedButton className="WritingImage__Action" onClick={this.handleSizeMedium} circleModifier="circleSmall">M</OutlinedButton>
          <OutlinedButton className="WritingImage__Action" onClick={this.handleSizeLarge} circleModifier="circleSmall">L</OutlinedButton>
        </div>
        )
      }
    <OutlinedButton className="WritingImage__Action" onClick={this.handleRemove} circleModifier="circleSmall"><Icon icon={AuratikumFontIcons.DELETE} /></OutlinedButton>
  </div>
)

render = () => (
  <div id={`WritingItem-${this.props.item.id}`} className="WritingImage">
    {this.props.item.imageUrl ? this.renderImage() : this.renderFileDrop()}
    {this.state.edit ? this.renderEdit() : this.renderRead()}
    {this.renderActions()}
  </div>
)
}


WritingImage.propTypes = {
  item: PropTypes.shape({}),
  uploadImage: PropTypes.func,
  updateProjectItem: PropTypes.func,
  removeProjectItem: PropTypes.func,
  onSelectForMoveAndDrop: PropTypes.func,
  ...i18nPropTypes,
}

const mapDispatchToProps = {
  uploadImage: uploadProjectItemImage,
}

export default connect(null, mapDispatchToProps)(WritingMovable(translate()(WritingImage)))
