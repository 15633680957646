import { handleActions } from 'redux-actions'

import { receiveTags, udpateTags } from 'actions/tags'

import { find } from 'lodash'

const reducer = handleActions({
  [receiveTags]: (state, action) => [...action.payload],
  [udpateTags]: (state, action) => {
    const result = [...state]

    // action.payload is an array of tags (strings)
    action.payload.forEach((tag) => {
      if (!find(result, { tag })) {
        result.push({
          count: 1,
          tag,
        })
      }
    })
    return result
  },
}, [])

export default reducer
