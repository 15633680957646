import React from 'react'

import WritingContainer from 'containers/writing/WritingContainer'
import OutlineContainer, { CONTEXTS } from 'containers/outline/OutlineContainer'

import './style.less'

const WriteWorkSpace = () => (
  <div className="WriteWorkSpace">
    <div className="WriteWorkSpace__OutlineWrapper">
      <OutlineContainer context={CONTEXTS.WRITING} />
    </div>
    <div className="WriteWorkSpace__WritingWrapper">
      <WritingContainer />
    </div>
  </div>
)

WriteWorkSpace.propTypes = {}


export default WriteWorkSpace
