import React from 'react'
import PropTypes from 'prop-types'

import './style.less'

const InviteLink = ({ link, className }) => <textarea rows="1" className={`${className} InviteLink`} defaultValue={link} readOnly />


InviteLink.defaultPropTypes = {
  className: '',
}

InviteLink.propTypes = {
  link: PropTypes.string,
  className: PropTypes.string,
}

export default InviteLink
