import React from 'react'
import PropTypes from 'prop-types'

import { propTypes as i18nPropTypes, translate } from 'i18n'
import Flyout from 'containers/common/Flyout'
import Tag from 'components/common/Tag'

import './style.less'

const TagListFlyout = ({ tags, selectedTags, onSelect }) => (
  <div className="TagListFlyout">
    <div className="TagListFlyout__Content">
      { tags.map((tag) => {
        const props = {}
        if (selectedTags.filter(t => t.tag === tag.tag).length > 0) {
          props.selected = true
        }
        return (
          <Tag
            key={tag.tag}
            tag={tag.tag}
            onSelect={() => onSelect(tag)}
            {...props}
          />
        )
      })}
    </div>
  </div>
)

TagListFlyout.propTypes = {
  data: PropTypes.shape({}),
  selectedTags: PropTypes.array,
  onSelect: PropTypes.func,
  ...i18nPropTypes,
}

export default Flyout(translate()(TagListFlyout))
