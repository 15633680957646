import { get } from 'lodash'
import { createSelector } from 'reselect'

const pathNameSelector = state => get(state, 'router.location.pathname')

const getPathSegment = segment => (path) => {
  if (path) {
    const segments = path.split('/')
    if (segments.length > segment) {
      return segments[segment]
    }
  }
  return undefined
}

export const getSelectedProjectId = createSelector(pathNameSelector, getPathSegment(2))
export const getProfileSubView = createSelector(pathNameSelector, getPathSegment(2))
export const getSelectedNoteId = createSelector(pathNameSelector, getPathSegment(2))
export const getReferenceSubView = createSelector(pathNameSelector, getPathSegment(2))
export const getSelectedReferenceIdLibrary = createSelector(
  pathNameSelector,
  path => ({
    id: getPathSegment(3)(path),
    library: getPathSegment(2)(path),
  }),
)
