import React, { Component } from 'react'
import PropTypes from 'prop-types'

import WritingAddBlockLine from '../WritingAddBlockLine'
import WritingHeading from '../WritingHeading'
import WritingNote from '../WritingNote'
import WritingText from '../WritingText'
import WritingImage from '../WritingImage'

import './style.less'

class WritingItem extends Component {
  static propTypes = {
    item: PropTypes.shape({
      id: PropTypes.string,
    }),
    level: PropTypes.number,
    createProjectItem: PropTypes.func,
    removeProjectItem: PropTypes.func,
    updateProjectItem: PropTypes.func,
    moveProjectItem: PropTypes.func,
    parentHeadingPrefix: PropTypes.string,
    onEditNoteItem: PropTypes.func,
    onAddBlockItem: PropTypes.func,
    onMoveDropClick: PropTypes.func,
    selectedProjectItemForMoveAndDrop: PropTypes.shape({}),
    isLoadingContent: PropTypes.bool,
    containerHeight: PropTypes.number,
    onSetup: PropTypes.func,
    updateProjectCitationCluster: PropTypes.func.isRequired,
  }

  render() {
    const {
      item, level = 0, parentHeadingPrefix = '', selectedProjectItemForMoveAndDrop, isLoadingContent,
    } = this.props
    let headerCount = 1

    return item.children.map((child, childIndex) => {
      const headingPrefix = `${parentHeadingPrefix}${headerCount}.`

      let itemComponent
      let parent = item
      let index = childIndex + 1

      switch (child.type) {
        case 'HEADING':
          itemComponent = (
            <WritingHeading
              item={child}
              level={level}
              headingPrefix={headingPrefix}
              updateProjectItem={this.props.updateProjectItem}
            />
          )
          headerCount += 1
          parent = child
          index = 0
          break
        case 'ROOT':
          parent = child
          index = 0
          break
        case 'TEXT':
          itemComponent = (
            <WritingText
              item={child}
              updateProjectItem={this.props.updateProjectItem}
              removeProjectItem={this.props.removeProjectItem}
              index={index}
              isLoadingContent={isLoadingContent}
              containerHeight={this.props.containerHeight} // is here to rerender item on global height change
              movingModeActive={selectedProjectItemForMoveAndDrop && selectedProjectItemForMoveAndDrop.id}
              onSetupEditor={this.props.onSetup}
              updateProjectCitationCluster={this.props.updateProjectCitationCluster}
            />
          )
          break
        case 'IMAGE':
          itemComponent = (
            <WritingImage
              item={child}
              updateProjectItem={this.props.updateProjectItem}
              removeProjectItem={this.props.removeProjectItem}
              movingModeActive={selectedProjectItemForMoveAndDrop && selectedProjectItemForMoveAndDrop.id}
            />
          )
          break
        default:
          itemComponent = (
            <WritingNote
              item={child}
              onEditClick={this.props.onEditNoteItem}
              removeProjectItem={this.props.removeProjectItem}
              movingModeActive={selectedProjectItemForMoveAndDrop && selectedProjectItemForMoveAndDrop.id}
            />
          )
      }

      const showDropzone = (!selectedProjectItemForMoveAndDrop || (selectedProjectItemForMoveAndDrop
            && selectedProjectItemForMoveAndDrop.id !== child.id
            && !(item.children[index] && item.children[index].id === selectedProjectItemForMoveAndDrop.id)))

      return (
        <div key={`WritingItem-${child.id}`} className="WritingItem">
          { /* we only need this a tag as an anchor for scrolling to it, no content available */}
          { /* eslint-disable */ }
          <a id={child.id} />
          { /* eslint-enable */ }
          { itemComponent }
          <WritingAddBlockLine
            isVisible={showDropzone}
            parent={parent}
            index={index}
            handleAddClick={this.props.onAddBlockItem}
            handleMoveDropClick={this.props.onMoveDropClick}
            moveAndDropActivated={(selectedProjectItemForMoveAndDrop !== null && selectedProjectItemForMoveAndDrop !== undefined)}
          />
          <div className="WritingItem__Items">
            {child.children && (
              <WritingItem
                item={child}
                level={level + 1}
                parentHeadingPrefix={headingPrefix}
                createProjectItem={this.props.createProjectItem}
                removeProjectItem={this.props.removeProjectItem}
                updateProjectItem={this.props.updateProjectItem}
                moveProjectItem={this.props.moveProjectItem}
                onEditNoteItem={this.props.onEditNoteItem}
                onAddBlockItem={this.props.onAddBlockItem}
                onMoveDropClick={this.props.onMoveDropClick}
                selectedProjectItemForMoveAndDrop={selectedProjectItemForMoveAndDrop}
                isLoadingContent={isLoadingContent}
                containerHeight={this.props.containerHeight}
                onSetup={this.props.onSetup}
                updateProjectCitationCluster={this.props.updateProjectCitationCluster}
              />
            )}
          </div>
        </div>
      )
    })
  }
}

export default WritingItem
