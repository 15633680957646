export const parseColor = (color) => {
  if (!color || color === '' || color === 'transparent') {
    return '#e6e6e6'
  }
  return `rgb(${color})`
}

export const isNoColor = color => color === '' || color === undefined || color === 'transparent'

export const isSelected = (color, value) => color === value || (!color && isNoColor(value))

export const noteColors = [
  // yellow
  [252, 229, 130],
  // red
  [255, 98, 92],
  // pink
  [239, 203, 218],
  // blue
  [177, 194, 247],
  // green
  [136, 225, 216],
  // light green
  [211, 241, 234],
  // light brown
  [240, 227, 200],
]
