import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import { push } from 'connected-react-router'

import { createNote } from 'actions/notes'
import { showModal } from 'actions/modal'
import { PAYWALL_MODAL_ID } from 'containers/modal/PaywallModal'

import { OutlinedButton } from 'components/common/Button'
import FontIcon from 'components/common/FontIcon'
import NoteLink from 'components/common/NoteLink'
import ReferenceCitation from 'components/common/ReferenceCitation'
import ReferenceDetailCollections from 'containers/references/ReferenceDetailCollections'
import PageSection from 'components/page/PageSection'

import FlyoutReference from 'containers/common/FlyoutReference'
import FlyoutHeader from 'components/common/FlyoutHeader'

import ProjectsSelector from 'selectors/projects'
import { isNotesCountPremiumRestricted } from 'selectors/subscriptions'
import { getReferenceFromPath, getReferencedNotes } from 'selectors/references'

import './style.less'


class ReferenceDetailSidebar extends Component {
  static propTypes = {
    ...i18nPropTypes,
    reference: PropTypes.shape({}),
    notes: PropTypes.arrayOf(PropTypes.shape({})),
    projects: PropTypes.arrayOf(PropTypes.shape({})),
  }

  constructor(props) {
    super(props)
    this.state = { showProjectFlyout: false }
  }

  handleShowProjectFlyout = () => {
    this.setState(state => ({ showProjectFlyout: !state.showProjectFlyout }))
  }

  handleCreateLinkedNote = project => async () => {
    if (this.props.isNotesCountPremiumRestricted) {
      this.props.showModal(PAYWALL_MODAL_ID)
      return
    }
    this.handleHideFlyout()
    const res = await this.props.createNote({
      title: '',
      content: '',
      color: 'transparent',
      createdInProject: project.id,
      referenceIds: [this.props.reference.id],
    })
    this.props.push(`/notes/${res.payload.result}`)
  }

  handleHideFlyout = () => this.setState({ showProjectFlyout: false })

  renderProjectList = () => {
    const { projects, t } = this.props
    return (
      <React.Fragment>
        <FlyoutHeader title={t('references.createNoteInProject')} />
        {projects.map(project => (
          <button
            key={project.id}
            className="ReferenceDetailSidebar__SelectProjectButton"
            onClick={this.handleCreateLinkedNote(project)}
            type="button"
          >
            {project.title}
          </button>
        ))}
      </React.Fragment>
    )
  }

  renderLinkedNotes = () => {
    const { notes, t, projects } = this.props

    return (
      <div className="ReferenceDetailSidebar__NoteLinkList">
        {notes.map(note => <NoteLink key={note.id} note={note} light />)}
        {notes.length === 0 && <div className="ReferenceDetailSidebar__NoNotes">{t('references.noNotes')}</div>}
        { projects.length > 0
          && (
          <FlyoutReference
            flyoutClassName="ReferenceDetailSidebar__ProjectFlyout"
            showFlyout={this.state.showProjectFlyout}
            placement="bottom"
            component={this.renderProjectList()}
            onOutsideClick={this.handleHideFlyout}
          >
            <OutlinedButton className="ReferenceDetailSidebar__CreateNoteButton" onClick={this.handleShowProjectFlyout}>
              {t('noteDetailView.noteSelectorField.createNote')}
              <FontIcon className="ReferenceDetailSidebar__CreateNoteButtonIcon" icon={FontIcon.Icons.faCaretDown} />
            </OutlinedButton>
          </FlyoutReference>
          )
        }
      </div>
    )
  }

  render = () => {
    const { t, reference } = this.props
    return (
      <div className="ReferenceDetailSidebar">
        <PageSection title={t('references.sidebar.tabs.usedIn')}>
          {this.renderLinkedNotes()}
        </PageSection>
        <PageSection title={t('references.citation')}>
          <ReferenceCitation className="ReferenceDetailSidebar__Citation" reference={reference} />
        </PageSection>
        <PageSection title={t('common.collections')}>
          <ReferenceDetailCollections reference={reference} />
        </PageSection>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  reference: getReferenceFromPath(state),
  notes: getReferencedNotes(state),
  projects: ProjectsSelector.getAll(state),
  isNotesCountPremiumRestricted: isNotesCountPremiumRestricted(state),
})
const mapDispatchToProps = {
  createNote,
  push,
  showModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(ReferenceDetailSidebar))
