import React from 'react'
import PropTypes from 'prop-types'

import modifyClassName from 'helper/modifyClassName'

import './style.less'

const PageContent = ({ children, scrollable, className }) => (
  <div className={`${modifyClassName('PageContent', { scrollable })} ${className || ''}`}>{children}</div>
)

PageContent.defaultProps = {
  scrollable: false,
}

PageContent.propTypes = {
  children: PropTypes.node,
  scrollable: PropTypes.bool,
  className: PropTypes.string,
}

export default PageContent
