import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { DropTarget } from 'react-dnd'
import Icon from 'components/common/Icon'
import PureWrapper from 'components/PureWrapper'
import modifyClassName from 'helper/modifyClassName'

import './style.less'


const DefaultPropsZone = defaultProps => WrappedComponent => ({ ...props }) => <WrappedComponent {...defaultProps} {...props} />

class Dropzone extends PureComponent {
  handleAddClick = event => this.props.onAddClick(event, this.props.parent, this.props.index)

  render() {
    const {
      connectDropTarget, dragOver, level, isFirst, allowHeadings,
    } = this.props

    return connectDropTarget(
      <div className={`${modifyClassName('OutlineDropzone', { dragOver, isFirst })} OutlineDropzone--level${level} `}>
        <div className={`${modifyClassName('OutlineDropzone__Line', { dragOver, allowHeadings, isFirst })} OutlineDropzone__Line--level${level}`}>
          { dragOver && <Icon className="OutlineDropzone__LineIcon" icon="S" /> }
          { !dragOver && allowHeadings && <button onClick={this.handleAddClick} className="OutlineDropzone__LineIcon OutlineDropzone__LineIcon--button"><Icon icon="L" /></button> }
        </div>
      </div>,
    )
  }
}


function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    dragOver: monitor.isOver(),
    item: monitor.getItem() ? monitor.getItem().item : null,
    type: monitor.getItemType(),
  }
}

const target = {
  drop(props, monitor) {
    props.onDrop(monitor.getItemType(), props.parent, monitor.getItem().item, props.index)
  },
}

Dropzone.propTypes = {
  isFirst: PropTypes.bool,
  dragOver: PropTypes.bool,
  connectDropTarget: PropTypes.func,
  level: PropTypes.number,
  allowHeadings: PropTypes.bool,
  onAddClick: PropTypes.func,
  parent: PropTypes.shape({}),
  index: PropTypes.number,
}


export const OutlineDropzone = PureWrapper(DropTarget(['NOTE_LINK', 'NEW_NOTE_LINK', 'HEADING'], target, collect)(DefaultPropsZone({ allowHeadings: true })(Dropzone)))
export const OutlineHeadingDropzone = PureWrapper(DropTarget(['HEADING'], target, collect)(DefaultPropsZone({ allowHeadings: true })(Dropzone)))
export const OutlineNoteLinkDropzone = PureWrapper(DropTarget(['NOTE_LINK', 'NEW_NOTE_LINK'], target, collect)(DefaultPropsZone({ allowHeadings: false })(Dropzone)))
