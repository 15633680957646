import React from 'react'
import PropTypes from 'prop-types'

import './style.less'


const Footnote = ({ className = '', citation, ...props }) => (
  <div {...props} className={`Footnote ${className}`}>
    <div className="Footnote__Prefix"><sup>{citation.index}</sup></div>
    <div dangerouslySetInnerHTML={{ __html: citation.data }} />
  </div>
)

Footnote.propTypes = {
  className: PropTypes.string,
  citation: PropTypes.shape({
    index: PropTypes.number,
    id: PropTypes.string,
    data: PropTypes.string,
  }),
}

export default Footnote
