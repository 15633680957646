import { handleActions } from 'redux-actions'

import { showFlyoutAction, hideFlyoutAction } from 'actions/flyout'

const reducer = handleActions({
  [showFlyoutAction]: (state, action) => ({
    ...state,
    [action.payload.id]: { show: true, ...action.payload },
  }),
  [hideFlyoutAction]: (state, action) => ({
    ...state,
    [action.payload.id]: { show: false },
  }),
}, {})

export default reducer
