import { get, findLastIndex } from 'lodash'
import { createSelector } from 'reselect'

import i18n from 'i18n'

import { AuratikumFontIcons } from 'helper/utils'

import { librariesSelector } from 'selectors/references'
import crossrefIcon from 'assets/icons/crossref.png'
import pubmedIcon from 'assets/icons/pubmed.png'
import googleBooksIcon from 'assets/icons/googlebooks.png'

const collections = {
  projects: {
    icon: AuratikumFontIcons.CLIPBOARD,
    getDetailTitle: project => project.title || i18n.t('statistics.project'),
    listTitle: 'projectsOverview.title',
  },
  notes: {
    icon: AuratikumFontIcons.NOTES,
    getDetailTitle: note => note.title || i18n.t('statistics.note'),
    listTitle: 'common.allNotes',
  },
  references: {
    icon: AuratikumFontIcons.REFERENCE,
    getDetailTitle: reference => reference.title || i18n.t('statistics.reference'),
    listTitle: 'references.title',
    contexts: {
      crossref: {
        icon: crossrefIcon,
      },
      pubmed: {
        icon: pubmedIcon,
      },
      'google-books': {
        icon: googleBooksIcon,
      },
    },
  },
}

const findPathBeginIndex = navStack => findLastIndex(navStack, ({ collection, id }) => !id || collection === 'projects')

export const getNavigationPath = createSelector(
  state => get(state, 'navigation'),
  state => get(state, 'entities'),
  librariesSelector,
  (navigation, entities, libraries) => {
    const { navigationStack } = navigation

    const firstIndex = findPathBeginIndex(navigationStack)

    const displayedNavigationStack = navigationStack
      .slice(Math.max(0, firstIndex))
      .map(({
        url, collection, id, index, context,
      }) => {
        let title
        if (collection && id) {
          const entity = entities[collection]
          let object = entity && id ? entity[id] : null
          if (collection === 'references' && context !== 'lib') {
            object = get(libraries, [context, 'searchResult', 'entities', 'externalReference', id])
          }
          title = object ? collections[collection].getDetailTitle(object) : ''
        } else if (collection) {
          title = i18n.t(collections[collection].listTitle)
        }

        return {
          url,
          collection,
          id,
          title,
          index,
          icon: get(collections, [collection, 'contexts', context, 'icon']) || collections[collection].icon,
        }
      })

    return {
      firstNavigationItem: displayedNavigationStack[0],
      collapsedNavigationItems: displayedNavigationStack.slice(1, -2),
      lastNavigationItems: displayedNavigationStack.slice(Math.max(1, displayedNavigationStack.length - 2)),
    }
  },
)

export const getActiveProjectId = createSelector(
  state => get(state, 'navigation'),
  navigation => navigation.activeProjectId,
)

export const getNavigationContext = createSelector(
  state => get(state, 'navigation'),
  (navigation) => {
    const { navigationStack } = navigation

    const beginIndex = findPathBeginIndex(navigationStack)
    return navigationStack[beginIndex] ? navigationStack[beginIndex].collection : null
  },
)
