import React from 'react'
import PropTypes from 'prop-types'

import './style.less'

const Instruction = ({ title, instruction }) => (
  <div className="Instruction">
    <div className="Instruction__Title">{ title }</div>
    {
      instruction.map((step, i) => (
        <div className="Instruction__Step" key={i}>
          {step.title && <div className="Instruction__StepTitle">{step.title}</div>}
          {step.description && <div className="Instruction__Description">{step.description}</div>}

          { step.src
          && (
          <a href={step.src} rel="noopener noreferrer" target="_blank">
            <img className="Instruction__Image" alt={step.alt} src={step.src} />
          </a>
          )
          }
        </div>
      ))
    }
  </div>
)

Instruction.propTypes = {
  title: PropTypes.string,
  instruction: PropTypes.array,
}

export default Instruction
