import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { propTypes as i18nPropTypes, translate } from 'i18n'

import ReferenceListUser from 'containers/references/ReferenceListUser'
import ReferenceCollectionContainer from 'containers/references/ReferenceCollectionContainer'
import ReferenceSelector from 'selectors/references'
import AddNewReferenceButton from 'containers/references/AddNewReferenceButton'

import arrow from 'assets/sketchy-arrow.png'

import './style.less'

class ReferenceLibUserContainer extends Component {
  static propTypes = {
    ...i18nPropTypes,
    referenceCount: PropTypes.number,
  }

  renderEmptyState() {
    const { t } = this.props
    return (
      <div className="ReferenceLibUserContainer__EmptyContainer">
        <div className="ReferenceLibUserContainer__EmptyTitleSearch">{t('references.emptyHint.titleSearch')}</div>
        <img className="ReferenceLibUserContainer__EmptyArrowSearch" src={arrow} alt="arrow" />

        <div className="ReferenceLibUserContainer__EmptyTitle">{t('references.emptyHint.title')}</div>
        <img className="ReferenceLibUserContainer__EmptyArrow" src={arrow} alt="arrow" />
        <AddNewReferenceButton className="ReferenceLibUserContainer__EmptyAddButton" />
      </div>
    )
  }

  render() {
    const { referenceCount } = this.props
    return (
      <div className="ReferenceLibUserContainer">
        <div className="ReferenceLibUserContainer__Collections">
          <ReferenceCollectionContainer />
        </div>
        <div className="ReferenceLibUserContainer__ReferenceList">
          { referenceCount === 0 ? this.renderEmptyState() : <ReferenceListUser /> }
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  referenceCount: ReferenceSelector.getCount(state),
})

export default connect(mapStateToProps, {})(translate()(ReferenceLibUserContainer))
