import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { parseColor } from 'helper/colorHandler'
import { DragSource } from 'react-dnd'
import { get } from 'lodash'

import { propTypes as i18nPropTypes, translate } from 'i18n'
import { NoteDragImage } from 'helper/DragImages'

import FontIcon from 'components/common/FontIcon'
import Icon from 'components/common/Icon'
import PureWrapper from 'components/PureWrapper'

import './style.less'

class OutlineNoteLink extends PureComponent {
  componentDidMount() {
    const img = new Image()
    img.onload = () => this.props.connectDragPreview && this.props.connectDragPreview(img)
    img.src = NoteDragImage
  }

  handleRemoveClick = () => this.props.onRemoveClick(this.props.item)

  handleMoveClick = () => this.props.onMoveClick(this.props.item)

  handleOpenClick = () => this.props.onOpenClick(this.props.item)

  render() {
    const {
      connectDragSource, item, showHashLink, t,
    } = this.props

    const text = get(item, 'note.id') ? item.note.title || t('notes.noTitle') : t('notes.notAvailable')
    const color = !item.selected ? get(item, 'note.color', '') : undefined

    return connectDragSource(
      <div onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave} className="OutlineItem OutlineItem--note">
        <div className="OutlineNoteLinkItem">
          <div className="OutlineNoteLink" style={{ borderLeftColor: parseColor(color) }}>
            <div className="OutlineNoteLink__Actions" title={text}>
              { /* <button> is not draggable in firefox https://bugzilla.mozilla.org/show_bug.cgi?id=568313 */}
              { /* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
              <div className="OutlineNoteLink__ActionOpen" onClick={this.handleOpenClick}>{t('common.open')}</div>
              <div className="OutlineNoteLink__IconActions">
                { this.props.onRemoveClick && <button type="button" className="OutlineNoteLink__ActionRemove" onClick={this.handleRemoveClick}><Icon icon="i" /></button>}
                { /* eslint-disable-next-line jsx-a11y/anchor-has-content */}
                { showHashLink && <a className="OutlineNoteLinkItem__ActionScroll" href={`#${item.id}`}><FontIcon icon={FontIcon.Icons.faArrowAltCircleRight} aria-hidden="true" /></a> }
              </div>
            </div>
            <div className="OutlineNoteLink__Text">{text}</div>
          </div>
        </div>
      </div>,
    )
  }
}

// DragSource
const noteSource = {
  beginDrag(props) {
    return {
      item: props.item,
    }
  },
}

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  }
}

OutlineNoteLink.propTypes = {
  item: PropTypes.shape({}),
  connectDragSource: PropTypes.func,
  lines: PropTypes.shape({}),
  ...i18nPropTypes,
}

export default PureWrapper(DragSource('NOTE_LINK', noteSource, collect)(translate()(OutlineNoteLink)))
