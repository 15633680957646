import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { propTypes as i18nPropTypes, translate } from 'i18n'
import modifyClassName from 'helper/modifyClassName'

import Flyout from 'containers/common/Flyout'
import FlyoutHeader from 'components/common/FlyoutHeader'

import './style.less'

const options = [
  { type: 'TEXT', coming_soon: false },
  { type: 'IMAGE', coming_soon: false },
  // { type: 'TABLE', coming_soon: true },
]

class WritingAddBlockFlyout extends Component {
  onSelect = async (type) => {
    const { data: { parent, index } } = this.props
    await this.props.onSelect(type, parent, index)
    this.props.hide()
  }

  renderItem = item => (
    <button
      type="button"
      key={item.type}
      className={modifyClassName('WritingAddBlockFlyout__Item', { comingsoon: item.coming_soon })}
      onClick={() => this.onSelect(item.type)}
      disabled={item.coming_soon}
    >
      {this.props.t(`common.${item.type.toLowerCase()}`, { comingsoon: item.coming_soon ? this.props.t('common.comingsoon') : '' })}
    </button>
  )

  render() {
    return (
      <div className="WritingAddBlockFlyout">
        <FlyoutHeader title={this.props.t('writing.addblockflyout.title')} onClose={this.props.hide} />
        <div className="WritingAddBlockFlyout__Content">
          {options.map(item => this.renderItem(item))}
        </div>
      </div>
    )
  }
}

WritingAddBlockFlyout.propTypes = {
  data: PropTypes.shape({}),
  onSelect: PropTypes.func,
  ...i18nPropTypes,
}

export default Flyout(translate()(WritingAddBlockFlyout))
