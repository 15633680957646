
import { createSelector } from 'reselect'

export const selector = state => state.payment

export const getPaymentData = createSelector(
  selector,
  payment => payment.paymentData,
)

export default {
  getPaymentData,
}
