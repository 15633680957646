import React, { Component } from 'react'

import PropTypes from 'prop-types'

import { getDownloadURLForS3 } from 'api'

class S3ImageField extends Component {
  static propTypes = {
    file: PropTypes.shape({
      fileId: PropTypes.string.isRequired,
      fileName: PropTypes.string,
    }),
  }

  constructor(props) {
    super(props)
    this.updateImageSource(props.file)
  }

  state = {
    src: null,
  }

  componentDidUpdate(prevProps) {
    if (prevProps.file && this.props.file && prevProps.file.fileId !== this.props.file.fileId) {
      this.updateImageSource(this.props.file)
    }
  }

  updateImageSource = async (file) => {
    const payload = await getDownloadURLForS3(file)
    this.setState({ src: payload.url })
  }

  render() {
    return <img className="NoteFilesField__Image" src={this.state.src} alt="" />
  }
}

export default S3ImageField
