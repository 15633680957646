import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import {
  values, get, keys, upperFirst,
} from 'lodash'
import ImportSelector from 'selectors/import'
import SubscriptionSelector from 'selectors/subscriptions'
import { resetImport, importData, importFiles } from 'actions/import'
import { showModal, hideModal } from 'actions/modal'
import { SegmentBar } from 'components/common/Bar'
import FileDrop from 'components/common/FileDrop'
import { PrimaryButton, CancelButton } from 'components/common/Button'

import { InfoBigToast } from 'containers/toasts/BigToast'

import bibtexIcon from 'assets/icons/bibtex.png'
import citaviIcon from 'assets/icons/citavi.png'
import jsonIcon from 'assets/icons/json.png'
import endnoteIcon from 'assets/icons/endnote.png'
import evernoteIcon from 'assets/icons/evernote.png'
import mendeleyIcon from 'assets/icons/mendeley.png'
import zkn3Icon from 'assets/icons/zkn3.png'
import zoteroIcon from 'assets/icons/zotero.png'
import FontIcon from 'components/common/FontIcon'

import Instruction from './Instruction'

import NotePreview from './Preview/Note'
import ReferencePreview from './Preview/Reference'
import ProjectPreview from './Preview/Project'

import './style.less'

const TOAST_ID = 'IMPORT_RESULT_TOAST_ID'

class ImportContainer extends Component {
  constructor() {
    super()

    this.state = {
      softwareVisible: null,
      isUploading: false,
      isFinished: false,
      error: null,
      dataUploadLoading: false,
      fileSize: 0,
    }

    this.entityMap = {
      notes: NotePreview,
      references: ReferencePreview,
      projects: ProjectPreview,
    }

    this.navItems = {
      bibtex: { title: 'Bibtex', image: bibtexIcon },
      citavi: { title: 'Citavi', image: citaviIcon },
      csl: { title: 'CSL JSON', image: jsonIcon },
      endnote: { title: 'Endnote', image: endnoteIcon },
      evernote: { title: 'Evernote', image: evernoteIcon },
      mendeley: { title: 'Mendeley', image: mendeleyIcon },
      zkn: { title: 'Zkn3 Zettelkasten (Daniel Luedecke)', image: zkn3Icon },
      zotero: { title: 'Zotero', image: zoteroIcon },
    }
  }

  showSoftware = value => this.setState({ softwareVisible: value })

  saveImportData = async () => {
    this.setState({ dataUploadLoading: true })
    const { t } = this.props
    try {
      await this.props.importData({ ...this.props.importResult })
      this.props.showModal(TOAST_ID, { content: t('import.success'), data: { level: 'success' } })
    } catch (error) {
      this.props.showModal(TOAST_ID, { content: t('import.errorOccurredDataImport'), data: { level: 'danger' } })
    }

    this.setState({ dataUploadLoading: false, isFinished: false })
    this.reset()
  }

  handleFileDrop = async (files) => {
    try {
      this.props.hideModal(TOAST_ID)
      this.setState({
        fileSize: files.reduce((accumulator, file) => accumulator + file.size, 0) / 1048576,
        isUploading: true,
        isFinished: false,
        error: null,
      })
      await this.props.importFiles(files)
    } catch (error) {
      this.props.showModal(TOAST_ID, { content: this.props.t('import.errorOccurred'), data: { level: 'danger' } })
      this.setState({ error: true })
    }
    this.setState({ fileSize: 0, isUploading: false, isFinished: true })
  }

  reset = async () => this.props.resetImport()

  renderEntities = (entityName, entities, importEntities) => {
    const count = entities.length
    const PreviewComponent = this.entityMap[entityName]
    const { t } = this.props
    return (
      <div className="ImportContainer__Entities">
        <h2>
          {upperFirst(t(`common.${entityName}`))} ({count})
        </h2>
        { count > 1000 && <span>{this.props.t('import.impact')}</span> }
        { count > 0 && this.props.remainingCounts[entityName] - count <= 0 && <div className="ImportContainer__ImportQuotaHint">{this.props.t('import.quotaReached', { remaining: this.props.remainingCounts[entityName], count })}</div> }
        { entities.map((obj, index) => <div key={`data-${entityName}-${index}`}><PreviewComponent className="ImportContainer__Entity" entity={obj} entities={importEntities} /></div>)}
      </div>
    )
  }

  renderIssues = (entityName, entities) => {
    const count = entities.length
    if (count === 0) return <div />
    return (
      <div className="ImportContainer__Entities--issues">
        <h2>
          {count}
          {this.props.t('import.errorWhile')}
          {upperFirst(this.props.t(`common.${entityName}`))}
        </h2>
      </div>
    )
  }

  renderResult = () => {
    const hasIssues = Object.keys(get(this.props, 'importResult.issues.notes', {})).length + Object.keys(get(this.props, 'importResult.issues.projects', {})).length + Object.keys(get(this.props, 'importResult.issues.references', {})).length > 0

    return (
      <div className="ImportContainer__Result">
        <div className="ImportContainer__Actions">
          <PrimaryButton onClick={this.saveImportData} isLoading={this.state.dataUploadLoading}>{this.props.t('import.importData')}</PrimaryButton>
          { !this.state.dataUploadLoading && <CancelButton onClick={this.reset}>{this.props.t('import.importAbort')}</CancelButton> }
        </div>
        <div className="ImportContainer__Text">{this.props.t('import.importText')}</div>
        <div>
          <FontIcon className="ImportContainer__OverrideIcon" icon={FontIcon.Icons.faPencil} />
          {this.props.t('import.createOverwrite')}
        </div>
        <div>
          <FontIcon className="ImportContainer__AddIcon" icon={FontIcon.Icons.faPlus} />
          {this.props.t('import.createNew')}
        </div>

        { Object.keys(this.entityMap).map(attr => (
          <div key={`data-${attr}`}>
            {get(this.props, 'importResult.data') && this.renderEntities(attr, values(this.props.importResult.data[attr]), this.props.importResult.data) }
          </div>
        )) }

        { hasIssues && (
        <div className="ImportContainer__Text">
          {this.props.t('import.error')}
        </div>
        ) }
        { Object.keys(this.entityMap).map(attr => (
          <div key={`issue-${attr}`}>
            {get(this.props, 'importResult.issues') && this.renderIssues(attr, values(this.props.importResult.issues[attr]))}
          </div>
        )) }
      </div>
    )
  }

  renderHelp = () => (
    <div className="ImportContainer__Help">
      <div className="ImportContainer__Heading">{this.props.t('import.howtoHead')}</div>
      <div className="ImportContainer__Text">{this.props.t('import.howtoText')}</div>
      <div className="ImportContainer__Instructions">
        <SegmentBar
          className="ImportContainer__SegmentBar"
          onSelect={this.showSoftware}
          selectedValue={this.state.softwareVisible}
          values={keys(this.navItems)}
          icons={values(this.navItems).map(item => <img className="ImportContainer__Icon" src={item.image} alt={item.title} />)}
        />
      </div>
      { this.state.softwareVisible && (
      <Instruction
        title={get(this.navItems, `['${this.state.softwareVisible}'].title`)}
        instruction={this.props.t(`import.${this.state.softwareVisible}`, { returnObjects: true })}
      />
      )}
    </div>
  )

render = () => {
  const { t } = this.props
  const { isUploading, isFinished, error } = this.state

  return (
    <div className="ImportContainer">
      <div className="ImportContainer__Dropzone">
        { this.state.fileSize > 8 && <div className="ImportContainer__FileSizeHint">{t('import.sizeHint', { fileSize: Number.parseFloat(this.state.fileSize).toFixed(0) })}</div>}
        <FileDrop
          onDrop={this.handleFileDrop}
          isLoading={isUploading}
          text={t('import.fileDropText')}
          textDrop={t('import.fileDroppingText')}
        />
      </div>
      <div className="ImportContainer__Content">
        { isFinished && !error && this.renderResult() }
        { (!isFinished || (isFinished && error)) && this.renderHelp() }
      </div>
      <InfoBigToast
        id={TOAST_ID}
        timeout={3000}
      />
    </div>
  )
}
}

const mapStateToProps = state => ({
  importResult: ImportSelector.getImportResult(state),
  remainingCounts: SubscriptionSelector.getRemainingQuotaCounts(state),
})

ImportContainer.propTypes = {
  importResult: PropTypes.shape({}),
  resetImport: PropTypes.func,
  importFiles: PropTypes.func,
  importData: PropTypes.func,
  ...i18nPropTypes,
}

export default connect(mapStateToProps, {
  resetImport,
  importData,
  importFiles,
  showModal,
  hideModal,
})(translate()(ImportContainer))
