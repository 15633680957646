import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Measure from 'react-measure'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'

import { propTypes as i18nPropTypes, translate } from 'i18n'
import modifyClassName from 'helper/modifyClassName'
import { showFlyout } from 'actions/flyout'
import { writingSidebarVisibilityChanged } from 'actions/writing-sidebar'
import {
  transformProjectItemToText,
  requestProjectContent,
  updateProjectItem,
  createProjectItem,
  removeProjectItem,
  selectProjectItemForMoveAndDrop,
  moveProjectItem,
} from 'actions/project-items'
import { showModal } from 'actions/modal'
import { updateProjectCitationCluster } from 'actions/citation'
import { getSelectedProjectId } from 'selectors/path'
import ProjectSelector from 'selectors/projects'
import { getCitationClusterForSelectedProject } from 'selectors/citation'
import ProjectItemsSelector from 'selectors/project-items'
import WritingSideBarSelector from 'selectors/writing-sidebar'

import WritingItem from 'components/writing/WritingItem'
import WritingBibliography from 'components/writing/WritingBibliography'
import WritingAddBlockFlyout from 'components/writing/WritingAddBlockFlyout'
import WritingSidebar from 'components/writing/WritingSidebar'
import ScrollRestoration from 'containers/common/ScrollRestoration'
import DeleteProjectItemModal from 'containers/modal/DeleteProjectItemModal'

import './style.less'


const DELETE_MODAL_ID = 'WRITING_ITEM_DELETION_MODAL_ID'
const FLYOUT_ID = 'WRITING_ADD_BLOCK_FLYOUT'

class WritingContainer extends PureComponent {
  static propTypes = {
    showModal: PropTypes.func.isRequired,
    rootProjectItem: PropTypes.shape({}),
    projectId: PropTypes.string,
    createProjectItem: PropTypes.func,
    changeProjectItemText: PropTypes.func,
    removeProjectItem: PropTypes.func,
    moveProjectItem: PropTypes.func,
    transformProjectItemToText: PropTypes.func,
    requestProjectContent: PropTypes.func,
    updateProjectItem: PropTypes.func,
    showFlyout: PropTypes.func,
    selectedProjectItemForMoveAndDrop: PropTypes.shape({}),
    writingSidebarVisibilityChanged: PropTypes.func,
    sidebarIsVisible: PropTypes.bool,
    updateProjectCitationCluster: PropTypes.func.isRequired,
    ...i18nPropTypes,
  }

  state = { isLoadingContent: false }

  componentDidMount = async () => {
    this.setState({ isLoadingContent: true })
    await this.props.requestProjectContent(this.props.projectId)
    this.setState({ isLoadingContent: false })

    this.props.updateProjectCitationCluster()
  }

  componentDidUpdate() {
  }

  onResize = (contentRect) => {
    if (this.state.height !== contentRect.bounds.height) {
      this.setState({ height: contentRect.bounds.height })
    }
  }

  handleEditNoteItem = (item) => {
    this.props.transformProjectItemToText(item.id)
  }

  handleAddBlockItem = (referenceElement, parent, index) => {
    this.props.showFlyout(FLYOUT_ID, { referenceElement, parent, index })
  }

  handleAddBlockItemSelect = async (type, parent, index) => {
    await this.props.createProjectItem(type, { content: '' }, parent, index)
  }

  handleDeleteItem = item => this.props.showModal(DELETE_MODAL_ID, { data: { ...item } })

  toggleSidebar = () => this.props.writingSidebarVisibilityChanged()

  handleMoveDropClick = async (parent, index) => {
    const { selectedProjectItemForMoveAndDrop } = this.props
    if (selectedProjectItemForMoveAndDrop) {
      await this.props.moveProjectItem(selectedProjectItemForMoveAndDrop.id, index, parent.id, selectedProjectItemForMoveAndDrop.parentId)
      await this.props.selectProjectItemForMoveAndDrop(null)
    }
  }

  onDeleteProjectItem = async (headingItem) => {
    this.setState({ isLoadingContent: true })
    await this.props.removeProjectItem(headingItem)
    this.setState({ isLoadingContent: false })
  }

  render() {
    const {
      rootProjectItem, t, sidebarIsVisible, projectId, citationCluster,
    } = this.props
    if (!rootProjectItem) return null

    if (rootProjectItem.children.length === 0) {
      return (
        <div className="WritingContainer">
          <div className="WritingContainer__NoHeading">
            <div>{t('writing.emptyTitle')}</div>
            <div className="WritingContainer__NoDescription">{t('writing.emptyDescription')}</div>
          </div>
        </div>
      )
    }

    return (
      <div className="WritingContainer">
        <div className="WritingContainer__ToolbarContainer">
          <div className="WritingContainer__EditorToolbar" />
          <div className={modifyClassName('WritingContainer__SidebarToolbar', { sidebarIsVisible })} />
        </div>
        <ScrollRestoration id={projectId} className="WritingContainer__ScrollContainer">
          <div className="WritingContainer__ContentContainer">
            <Measure
              bounds
              onResize={this.onResize}
            >
              {({ measureRef }) => (
                <div ref={measureRef} className="WritingContainer__Items">
                  <WritingItem
                    item={rootProjectItem}
                    createProjectItem={this.props.createProjectItem}
                    changeProjectItemText={this.props.changeProjectItemText}
                    removeProjectItem={this.handleDeleteItem}
                    updateProjectItem={this.props.updateProjectItem}
                    onEditNoteItem={this.handleEditNoteItem}
                    onAddBlockItem={this.handleAddBlockItem}
                    onMoveDropClick={this.handleMoveDropClick}
                    selectedProjectItemForMoveAndDrop={this.props.selectedProjectItemForMoveAndDrop}
                    isLoadingContent={this.state.isLoadingContent}
                    containerHeight={this.state.height}
                    updateProjectCitationCluster={this.props.updateProjectCitationCluster}
                  />
                  <WritingBibliography citationCluster={citationCluster} />
                  <WritingAddBlockFlyout
                    id={FLYOUT_ID}
                    onSelect={this.handleAddBlockItemSelect}
                  />
                </div>
              )}
            </Measure>


            <div className={modifyClassName('WritingContainer__SidebarContainer', { sidebarIsVisible })}>
              <WritingSidebar
                visible={sidebarIsVisible}
                toggleSidebar={this.toggleSidebar}
              />
            </div>
          </div>
        </ScrollRestoration>
        <DeleteProjectItemModal id={DELETE_MODAL_ID} onConfirm={this.onDeleteProjectItem} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  rootProjectItem: ProjectSelector.getResolvedProjectItemTreeForSelectedProject(state),
  references: ProjectSelector.getResolvedProjectItemReferencesForSelectedProject(state),
  isExporting: ProjectSelector.isExporting(state),
  projectId: getSelectedProjectId(state),
  selectedProjectItemForMoveAndDrop: ProjectItemsSelector.getSelectedProjectItemForMoveAndDrop(state),
  sidebarIsVisible: WritingSideBarSelector.isVisible(state),
  citationCluster: getCitationClusterForSelectedProject(state),
})

export default connect(mapStateToProps, {
  selectProjectItemForMoveAndDrop,
  createProjectItem,
  removeProjectItem,
  moveProjectItem,
  push,
  transformProjectItemToText,
  requestProjectContent,
  updateProjectItem,
  showFlyout,
  writingSidebarVisibilityChanged,
  showModal,
  updateProjectCitationCluster,
})(translate()(WritingContainer))
