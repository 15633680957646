import _ from 'lodash'
import { handleActions } from 'redux-actions'

import { receiveNotePage, addNoteToPage, removeNoteFromPage } from 'actions/notes'

const reducer = handleActions({
  [receiveNotePage]: (state, action) => ({
    noteIds: [...action.payload.noteIds],
  }),
  [addNoteToPage]: (state, action) => ({
    noteIds: [action.payload.noteId, ...state.noteIds],
  }),
  [removeNoteFromPage]: (state, action) => ({
    noteIds: _.without(state.noteIds, action.payload.noteId),
  }),
}, { noteIds: [] })

export default reducer
