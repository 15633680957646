import _ from 'lodash'
import { createSelector } from 'reselect'

import { isItemInTraversedChildren } from 'helper/hierarchyUtils'


const getProjectItemEntity = state => _.get(state, 'entities.projectItems', {})
const selectedProjectItemIdForMoveAndDropSelector = state => _.get(state, 'projectItem.selectedProjectItemIdForMoveAndDrop')

const getSelectedProjectItemForMoveAndDrop = createSelector(
  getProjectItemEntity,
  selectedProjectItemIdForMoveAndDropSelector,
  (items, id) => _.get(items, `['${id}']`),
)


export const getCount = createSelector(
  getProjectItemEntity,
  projectItems => _.values(projectItems).filter(item => item.type !== 'ROOT').length,
)

export const getProjectItem = (state, id) => _.get(state, ['entities', 'projectItems', id])

export const isProjectItemInChildren = (itemId, newParentId) => createSelector(
  getProjectItemEntity,
  entity => isItemInTraversedChildren(entity, newParentId, itemId),
)

export default {
  getSelectedProjectItemForMoveAndDrop,
  getCount,
}
