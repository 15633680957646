import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ProjectItemsSelector from 'selectors/project-items'
import { selectProjectItemForMoveAndDrop } from 'actions/project-items'
import modifyClassName from 'helper/modifyClassName'

import './style.less'

const WritingMovable = (WrappedComponent) => {
  class Movable extends Component {
    static propTypes = {
      item: PropTypes.shape({
        id: PropTypes.string,
      }),
      selectProjectItemForMoveAndDrop: PropTypes.func,
      selectedProjectItemForMoveAndDrop: PropTypes.shape({
        id: PropTypes.string,
      }),
    }

    static defaultProps = {
      selectedProjectItemForMoveAndDrop: {},
    }

    componentWillReceiveProps = (nextProps) => {
      const selected = nextProps.item.id === nextProps.selectedProjectItemForMoveAndDrop.id

      if (selected) {
        document.addEventListener('mousedown', this.handleClick, false)
      } else {
        document.removeEventListener('mousedown', this.handleClick, false)
      }
    }

    componentWillUnmount() {
      document.removeEventListener('mousedown', this.handleClick, false)
    }

    handleClick = (e) => {
      if (!this.node.contains(e.target) && !e.target.className.includes('WritingAddBlockLine')) {
        this.handleSelectForMoveAndDrop(null)
        e.preventDefault()
        e.stopPropagation()
      }
    }

    handleSelectForMoveAndDrop = () => {
      const id = this.props.item.id === this.props.selectedProjectItemForMoveAndDrop.id ? null : this.props.item.id
      this.props.selectProjectItemForMoveAndDrop(id)
    }

    render() {
      const { item, selectedProjectItemForMoveAndDrop } = this.props
      const selected = item.id === selectedProjectItemForMoveAndDrop.id
      return (
        <div ref={node => (this.node = node)} className={modifyClassName('WritingMovable', { selected, noHover: !!this.props.selectedProjectItemForMoveAndDrop.id })}>
          <WrappedComponent
            onMoveDropClick={this.handleMoveDropClick}
            onSelectForMoveAndDrop={this.handleSelectForMoveAndDrop}
            selectedProjectIdForMoveAndDrop={selectedProjectItemForMoveAndDrop.id}
            {...this.props}
          />
        </div>
      )
    }
  }

  const mapStateToProps = state => ({
    selectedProjectItemForMoveAndDrop: ProjectItemsSelector.getSelectedProjectItemForMoveAndDrop(state),
  })

  return connect(mapStateToProps, { selectProjectItemForMoveAndDrop })(Movable)
}

export default WritingMovable
