import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { translate } from 'i18n'
import { isEmpty } from 'lodash'

import { KEY_CODE_ENTER } from 'helper/keyboard'

// import FontIcon from 'components/common/FontIcon'
import { OutlinedAddButton } from 'components/common/Button'

import './style.less'


const withButton = ButtonComponent => class InputButton extends Component {
  static propTypes = {
    onSubmit: PropTypes.func,
    children: PropTypes.node,
    inputPlaceholder: PropTypes.string,
    className: PropTypes.string,
  }

  static defaultProps = {
    onSubmit: undefined,
    children: undefined,
    inputPlaceholder: undefined,
    className: '',
  }

  state = { shouldDisplayInput: false, isLoading: false }

  handleDisplayMode = isInputVisible => () => this.setState({
    shouldDisplayInput: isInputVisible,
    value: isInputVisible ? this.state.value : null,
  })

  handleOnChange = e => this.setState({ value: e.target.value })

  handleSubmit = async () => {
    const { value } = this.state
    const { onSubmit } = this.props
    if (!onSubmit || isEmpty(value)) {
      this.setState({ shouldDisplayInput: false })
      return
    }
    try {
      this.setState({ isLoading: true, shouldDisplayInput: false })
      await onSubmit(value)
    } catch (err) {
      // TODO: handle submission error
    } finally {
      this.setState({ isLoading: false, value: null })
    }
  }

  handleKeyPress = async (e) => {
    if (e.charCode === KEY_CODE_ENTER) {
      await this.handleSubmit()
    }
  }

  renderButton = () => {
    const { children } = this.props
    const { isLoading } = this.state

    return (
      <ButtonComponent isLoading={isLoading} onClick={this.handleDisplayMode(true)}>
        {children}
      </ButtonComponent>
    )
  }

  renderInput = () => {
    const { inputPlaceholder } = this.props
    return (
      <div className="InputButton__Input">
        <input
          type="text"
          placeholder={inputPlaceholder}
          onBlur={this.handleDisplayMode(false)}
          onChange={this.handleOnChange}
          onKeyPress={this.handleKeyPress}
          value={this.state.value || ''}
          autoFocus
        />
        {/* <button onClick={this.handleSubmit}><FontIcon icon={FontIcon.Icons.faPlus} /></button> */}
      </div>
    )
  }

  render = () => {
    const { shouldDisplayInput } = this.state
    const { className } = this.props
    return (
      <div className={className}>
        { shouldDisplayInput ? this.renderInput() : this.renderButton() }
      </div>
    )
  }
}


export default withButton

export const OutlinedAddInputButton = translate()(withButton(OutlinedAddButton))
