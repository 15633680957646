import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import FontIcon from 'components/common/FontIcon'
import { downloadFileFromS3 } from 'api'
import Spinner from 'components/common/Spinner'

import modifyClassName from 'helper/modifyClassName'

import './style.less'

// Component displays a file icon and downloads the attachment on click
// TODO: once we use multiple attachments again, a flyout should appear on hover

class ReferenceFileDownload extends Component {
  static propTypes = {
    ...i18nPropTypes,
    reference: PropTypes.shape({}),
  }

  state = {
    isLoading: false,
    error: null,
  }

  handleClick = async (e) => {
    e.preventDefault()
    e.stopPropagation()
    try {
      this.setState({ isLoading: true })
      await downloadFileFromS3(this.props.reference.files[0])
    } catch (error) {
      this.setState({ error })
    }
    this.setState({ isLoading: false })
  }

  render() {
    const { reference, t } = this.props
    const { isLoading, error } = this.state
    if (!reference || !reference.files || reference.files.length === 0) return null

    return (
      <Spinner show={isLoading}>
        <button className={modifyClassName('ReferenceFileDownload', { error })} onClick={this.handleClick} title={t('common.downloadFile')}>
          <FontIcon icon={error ? FontIcon.Icons.faExclamationCircle : FontIcon.Icons.faFileDownload} />
        </button>
      </Spinner>
    )
  }
}

export default translate()(ReferenceFileDownload)
