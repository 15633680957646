import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import OnboardingSelector from 'selectors/onboarding'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import { CircularProgressbar } from 'react-circular-progressbar'
import modifyClassName from 'helper/modifyClassName'
import { showChecklist } from 'actions/onboarding'
import tracker from 'tracking/tracker'
import withTooltip from 'containers/common/Tooltip'

import './style.less'

class ChecklistProgressNavIcon extends Component {
  handleClick = () => {
    const { checklistIsVisible } = this.props
    this.props.showChecklist(!checklistIsVisible)
    tracker.logEvent('CHECKLIST_NAV_CLICK', { show: !checklistIsVisible })
  }

  render() {
    const {
      expanded, checklistPercentage, checklistTodoCount, t,
    } = this.props
    if (checklistPercentage >= 100) return null
    return (
      <button type="button" onClick={this.handleClick} className="ChecklistProgressNavIcon">
        <div className="ChecklistProgressNavIcon__Badge">{checklistTodoCount}</div>
        <div className="ChecklistProgressNavIcon__Bar">
          <CircularProgressbar
            value={checklistPercentage}
            strokeWidth="15"
          />
        </div>

        <div className={modifyClassName('ChecklistProgressNavIcon__Text', { expanded })}>{t('checklist.navButton')}</div>
      </button>
    )
  }
}

const mapStateToProps = (state) => {
  const checklistPercentage = OnboardingSelector.getChecklistPercentage(state)
  const checklistTodoCount = OnboardingSelector.getChecklistTodoCount(state)
  const checklistIsVisible = OnboardingSelector.getChecklistIsVisible(state)
  return {
    checklistIsVisible,
    checklistPercentage,
    checklistTodoCount,
  }
}

ChecklistProgressNavIcon.propTypes = {
  checklistIsVisible: PropTypes.bool,
  expanded: PropTypes.bool,
  ...i18nPropTypes,
}

export default connect(
  mapStateToProps,
  {
    showChecklist,
  },
)(translate()(withTooltip(ChecklistProgressNavIcon)))
