import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { get } from 'lodash'

import { propTypes as i18nPropTypes, translate } from 'i18n'
import { reduxForm, Field, propTypes as reduxFormPropTypes } from 'redux-form'
import FormTextInput from 'components/common/FormTextInput'

import { createProjectItem, updateProjectItem } from 'actions/project-items'
import Flyout from 'containers/common/Flyout'
import FlyoutHeader from 'components/common/FlyoutHeader'
import { SecondaryButton } from 'components/common/Button'

import './style.less'

class OutlineAddHeadingFlyout extends Component {
  handleSubmit = async (values) => {
    if (this.props.data.headingItem) {
      // update existing item
      await this.props.updateProjectItem({ ...this.props.data.headingItem, title: values.title })
    } else {
      // add new item
      await this.props.createProjectItem('HEADING', {
        title: values.title,
      },
      this.props.data.parent,
      this.props.data.index)
    }
    this.props.onClose(get(this.props, 'data.parent'))
    this.props.hide()
  }

  render() {
    const {
      handleSubmit, data, t, submitting,
    } = this.props
    return (
      <div className="OutlineAddHeadingFlyout">
        <FlyoutHeader title={data.headingItem ? t('projectStructure.editHeadingFlyout.title') : t('projectStructure.addHeadingFlyout.title')} onClose={this.props.hide} />
        <div className="OutlineAddHeadingFlyout__Content">
          <div>{data.headingItem ? t('projectStructure.editHeadingFlyout.text') : t('projectStructure.addHeadingFlyout.text')}</div>
          <form className="OutlineAddHeadingFlyout__Form" onSubmit={handleSubmit(this.handleSubmit)}>
            <Field autoFocus name="title" component={FormTextInput} placeholder={data.headingItem ? t('projectStructure.editHeadingFlyout.placeholder') : t('projectStructure.addHeadingFlyout.placeholder')} />
            <SecondaryButton
              className="OutlineAddHeadingFlyout__SubmitButton"
              type="submit"
              isLoading={submitting}
            >
              { data.headingItem ? t('common.update') : t('common.add') }
            </SecondaryButton>
          </form>
        </div>

      </div>
    )
  }
}

OutlineAddHeadingFlyout.propTypes = {
  data: PropTypes.shape({}),
  onClose: PropTypes.func,
  ...reduxFormPropTypes,
  ...i18nPropTypes,
}

const mapStateToProps = (state, ownProps) => ({
  initialValues: get(ownProps, 'data.headingItem'),
})

const form = { form: 'ADD_HEADING_FLYOUT_FORM', enableReinitialize: true }

const FormComponent = reduxForm(form)(OutlineAddHeadingFlyout)
const translatedComponent = translate()(FormComponent)

export default Flyout(connect(mapStateToProps, {
  createProjectItem,
  updateProjectItem,
})(translatedComponent))
