import React, { Component } from 'react'
import PropTypes from 'prop-types'

import FontIcon from 'components/common/FontIcon'
import Row from './row'
import './style.less'

const excludes = ['_id', 'isNew', 'shouldOverride', 'externalId']

class ReferencePreview extends Component {
  constructor() {
    super()
    this.state = {
      expanded: false,
    }
  }

  toggleExpand = () => {
    this.setState({ expanded: !this.state.expanded })
  }

  render() {
    const { entity, className } = this.props

    return (
      <div className={`${className} ReferencePreview`}>
        <div className="ReferencePreview__Title" onClick={this.toggleExpand}>
          { entity.shouldOverride && <FontIcon className="ImportContainer__OverrideIcon" icon={FontIcon.Icons.faPencil} /> }
          { entity.isNew && <FontIcon className="ImportContainer__AddIcon" icon={FontIcon.Icons.faPlus} /> }
          { <FontIcon icon={this.state.expanded ? FontIcon.Icons.faCaretDown : FontIcon.Icons.faCaretRight} /> }
          {entity.title || entity.importReference}
        </div>
        { this.state.expanded && (
        <div className="ReferencePreview__Details">
          <div>
ID:
            {entity.id}
          </div>
          <h3>Attribute</h3>
          <table className="ReferencePreview__Attributes">
            <tbody>
              { Object.keys(entity).filter(k => excludes.indexOf(k) < 0).map(attribute => <Row key={attribute} attribute={attribute} entity={entity} />) }
            </tbody>
          </table>
        </div>
        )
        }
      </div>
    )
  }
}


ReferencePreview.propTypes = {
  entity: PropTypes.shape({}),
  className: PropTypes.string,
}

export default ReferencePreview
