import React from 'react'
import PropTypes from 'prop-types'

import modifyClassName from 'helper/modifyClassName'

import './style.less'


const Input = ({ className = '', underlined, ...props }) => <input className={`${modifyClassName('Input', { underlined })} ${className}`} {...props} />

Input.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  autoFocus: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string,
  underlined: PropTypes.bool,
}

Input.defaultPropTypes = {
  autoFocus: false,
  underlined: false,
}

export default Input
