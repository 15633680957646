import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { Manager, Reference } from 'react-popper'

import { showFlyout } from 'actions/flyout'
import { navigateToNavigationPathItem } from 'actions/navigation'

import modifyClassName from 'helper/modifyClassName'
import { AuratikumFontIcons } from 'helper/utils'

import { getNavigationPath } from 'selectors/navigation'

import NavigationPathFlyout from 'containers/common/NavigationPathFlyout'

import './style.less'

const FLYOUT_ID = 'NAVIGATION_PATH_FLYOUT'


class NavigationPath extends Component {
  static propTypes = {
    navigateTo: PropTypes.func.isRequired,
    showFlyout: PropTypes.func.isRequired,
    navigationStack: PropTypes.shape({
      firstNavigationItem: PropTypes.shape({}),
      collapsedNavigationItems: PropTypes.arrayOf(PropTypes.shape({})),
      lastNavigationItems: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }

  handleOnNavigate = navigationItem => () => this.props.navigateTo(navigationItem)

  handleShowFlyout = () => this.props.showFlyout(FLYOUT_ID)

  renderPathItem = ({
    url, title, isFirst, icon, index,
  }) => {
    const iconContent = icon.startsWith('/') ? <img src={icon} className="NavigationPath__IconImage" alt="Icon" /> : icon
    return (
      <button
        type="button"
        key={index}
        className="NavigationPath__Item"
        to={`${url}?nav=path`}
        onClick={this.handleOnNavigate({ url, index })}
      >
        { !isFirst && <div className="NavigationPath__Arrow">{AuratikumFontIcons.ARROW_RIGHT}</div>}
        <div className="NavigationPath__Icon">{iconContent}</div>
        <div className={modifyClassName('NavigationPath__Title', { noWrap: true })}>{title}</div>
      </button>
    )
  }

  renderPointPointPoint = collapsedNavigationItems => (
    <Manager>
      <Reference>
        { ({ ref }) => (
          <button
            type="button"
            ref={ref}
            className="NavigationPath__Item"
            onClick={this.handleShowFlyout}
          >
            <div className="NavigationPath__Arrow">{AuratikumFontIcons.ARROW_RIGHT}</div>
            <div className="NavigationPath__Title">...</div>
          </button>
        )}
      </Reference>
      <NavigationPathFlyout
        id={FLYOUT_ID}
        onNavigate={this.handleOnNavigate}
        navigationItems={collapsedNavigationItems}
        placement="bottom"
      />
    </Manager>
  )

  render= () => {
    const { navigationStack: { firstNavigationItem, collapsedNavigationItems, lastNavigationItems } } = this.props
    return (
      <div className="NavigationPath">
        { this.renderPathItem({ ...firstNavigationItem, isFirst: true })}
        { collapsedNavigationItems.length > 0 && this.renderPointPointPoint(collapsedNavigationItems)}
        { lastNavigationItems.map(navigationItem => this.renderPathItem({ ...navigationItem, isFirst: false }))}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  navigationStack: getNavigationPath(state),
})

const mapDispatchToProps = {
  navigateTo: navigateToNavigationPathItem,
  showFlyout,
}

export default connect(mapStateToProps, mapDispatchToProps)(NavigationPath)
