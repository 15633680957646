import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import OnboardingSelector from 'selectors/onboarding'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import { showChecklist } from 'actions/onboarding'
import { AuratikumFontIcons } from 'helper/utils'
import Icon from 'components/common/Icon'
import { push } from 'connected-react-router'
import modifyClassName from 'helper/modifyClassName'
import tracker from 'tracking/tracker'
import FontIcon from 'components/common/FontIcon'

import './style.less'

class ChecklistPanel extends Component {
  handleClose = () => {
    this.props.showChecklist(false)
  }

  handleItemClick = (item) => {
    this.props.push(item.linkPath)
    tracker.logEvent('CHECKLIST_ITEM_CLICK', { item: item.key })
    setTimeout(() => this.props.showChecklist(false), 1000)
  }

  renderItemContent = item => (
    <React.Fragment>
      <div className={modifyClassName('ChecklistPanel__ItemCheckbox', { completed: item.completed })}>
        {item.completed && <FontIcon icon={FontIcon.Icons.faCheck} />}
        { !item.completed && item.linkPath && <FontIcon icon={FontIcon.Icons.faArrowRight} /> }
      </div>
      <div className="ChecklistPanel__ItemContent">
        <div className="ChecklistPanel__ItemHeading">{this.props.t(`checklist.${item.key}.heading`)}</div>
        <div className="ChecklistPanel__ItemDescription">{this.props.t(`checklist.${item.key}.description`)}</div>
      </div>
    </React.Fragment>
  )

  renderItem = (item) => {
    if (item.linkPath) {
      return <button onClick={() => this.handleItemClick(item)} key={item.key} to={item.linkPath} className="ChecklistPanel__Item">{this.renderItemContent(item)}</button>
    }
    return <div key={item.key} className="ChecklistPanel__Item">{this.renderItemContent(item)}</div>
  }

  render() {
    const {
      checklist, checklistIsVisible, className, t,
    } = this.props
    if (checklistIsVisible) {
      return (
        <div className={`${className} ChecklistPanel`}>
          <div className="ChecklistPanel__Header">
            <div className="ChecklistPanel__Heading">{t('checklist.title')}</div>
            <button className="ChecklistPanel__CloseButton" onClick={this.handleClose}>
              <Icon icon={AuratikumFontIcons.CLOSE} />
            </button>
          </div>
          <div className="ChecklistPanel__Items">
            {checklist.map(item => this.renderItem(item))}
          </div>
        </div>
      )
    }

    return null
  }
}

const mapStateToProps = (state) => {
  const checklist = OnboardingSelector.getChecklist(state)
  const checklistIsVisible = OnboardingSelector.getChecklistIsVisible(state)
  return {
    checklist,
    checklistIsVisible,
  }
}

ChecklistPanel.propTypes = {
  className: PropTypes.string,
  checklist: PropTypes.array,
  ...i18nPropTypes,
}

export default connect(
  mapStateToProps,
  {
    showChecklist,
    push,
  },
)(translate()(ChecklistPanel))
