import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { propTypes as i18nPropTypes, translate } from 'i18n'

import CollectionItem from 'containers/references/ReferenceDetailCollectionItem'

import './style.less'

class ReferenceDetailCollections extends Component {
  static propTypes = {
    ...i18nPropTypes,
    reference: PropTypes.shape({
      collections: PropTypes.array,
    }),
  }

  renderCollections() {
    return this.props.reference.collections.map(collection => (
      <CollectionItem
        key={collection.id}
        collection={collection}
        reference={this.props.reference}
      />
    ))
  }

  render() {
    const { reference, t } = this.props

    return (
      <div className="ReferenceDetailCollections">
        { reference && reference.collections && reference.collections.length > 0 ? this.renderCollections() : t('references.noCollections')}
      </div>
    )
  }
}

const mapStateToProps = () => ({})

export default connect(mapStateToProps, {})(translate()(ReferenceDetailCollections))
