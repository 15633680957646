import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { get } from 'lodash'

import { showModalAction, showModal } from 'actions/modal'

import { initialize } from 'redux-form'

import { NoteCollectionList, NoteCollectionGrid } from 'components/notes-overview/NoteCollection'

import NotesOverviewToolbar from 'components/notes-overview/toolbar/NotesOverviewToolbar'

import {
  createNote, requestNotes, selectNote, deleteNote, searchNotes, duplicateNote,
} from 'actions/notes'
import { requestTags } from 'actions/tags'

import NotesSelector from 'selectors/notes'
import SearchSelector from 'selectors/search'
import SubscriptionSelector from 'selectors/subscriptions'

import { handleHotkey } from 'helper/eventHandler'

import { HotKeys } from 'react-hotkeys'

import DeleteNoteModal from 'containers/modal/DeleteNoteModal'

import './style.less'

const DELETE_MODAL_ID = 'DELETE_NOTE_CONFIRMATION_MODAL'

class NotesOverview extends Component {
  handleAddNote = async () => {
    const { project } = this.props

    const emptyNote = { title: '', content: '', color: 'transparent' }
    if (project) emptyNote.createdInProject = project.id
    const res = await this.props.createNote(emptyNote)
    const newNoteId = Object.keys(res.payload.entities.notes)[0]
    this.props.selectNote(newNoteId)
    this.props.push(`/notes/${newNoteId}`)
  }

  handleDeleteNote = async (note) => {
    try {
      await this.props.deleteNote(note, { dryRun: true })
      this.props.showModal(DELETE_MODAL_ID, { data: { ...note, usedIn: undefined } })
    } catch (err) {
      this.props.showModal(DELETE_MODAL_ID, { data: { ...note, usedIn: err.data } })
    }
  }

  handleDuplicateNote = async (note) => {
    await this.props.duplicateNote(note)
  }

  handleSelectNote = (note) => {
    this.props.push(`/notes/${note.id}`)
  }

  deleteNote = async (note) => {
    await this.props.deleteNote(note, { force: true })
  }

  render() {
    const {
      noteOverviewType, notes, isPremiumRestricted, project, push: dispatchedPush,
    } = this.props
    const id = `notes-${get(project, 'id', 'GLOBAL')}`
    const noteCollection = noteOverviewType === 'GRID'
      ? (
        <NoteCollectionGrid
          id={id}
          notes={notes}
          push={dispatchedPush}
          onAddNote={this.handleAddNote}
          onDeleteNote={this.handleDeleteNote}
          onDuplicateNote={this.handleDuplicateNote}
          isPremiumRestricted={isPremiumRestricted}
          onOpenNote={this.handleSelectNote}
        />
      )
      : (
        <NoteCollectionList
          id={id}
          notes={notes}
          push={dispatchedPush}
          onAddNote={this.handleAddNote}
          onDeleteNote={this.handleDeleteNote}
          onDuplicateNote={this.handleDuplicateNote}
          isPremiumRestricted={isPremiumRestricted}
          onOpenNote={this.handleSelectNote}
          showModal={this.props.showModal}
        />
      )

    const handlers = {
      'ctrl+=': handleHotkey(this.handleAddNote),
      'command+=': handleHotkey(this.handleAddNote),
    }

    return (
      <HotKeys handlers={handlers} className="NotesOverviewContainer">
        <NotesOverviewToolbar />
        {noteCollection}
        <DeleteNoteModal
          id={DELETE_MODAL_ID}
          onConfirm={this.deleteNote}
        />
      </HotKeys>
    )
  }
}

NotesOverview.propTypes = {
  noteOverviewType: PropTypes.string,
  notes: PropTypes.array,
  push: PropTypes.func,
  isPremiumRestricted: PropTypes.bool,
  // eslint-disable-next-line
  project: PropTypes.shape({}), // false positive linting issue
  deleteNote: PropTypes.func,
  // eslint-disable-next-line
  duplicateNote: PropTypes.func, // false positive linting issue
  selectNote: PropTypes.func,
  showModal: PropTypes.func,
  createNote: PropTypes.func,
}

const mapStateToProps = state => ({
  notes: NotesSelector.getPagedNotes(state),
  noteOverviewType: NotesSelector.getOverviewType(state),
  query: SearchSelector.getSearchQuery(state),
  isPremiumRestricted: SubscriptionSelector.isNotesCountPremiumRestricted(state),
})

export default connect(mapStateToProps, {
  push,
  createNote,
  selectNote,
  requestNotes,
  deleteNote,
  duplicateNote,
  requestTags,
  searchNotes,
  showModalAction,
  showModal,
  initialize,
})(NotesOverview)
