import React from 'react'
import PropTypes from 'prop-types'

import './style.less'


const ChallengeSectionDetails = ({ title, subTitle, children }) => (
  <div className="ChallengeSectionDetails">
    <div className="ChallengeSectionDetails__Title">{title}</div>
    {subTitle && <div className="ChallengeSectionDetails__SubTitle">{subTitle}</div>}
    <div className="ChallengeSectionDetails__Content">
      {children}
    </div>
  </div>
)

ChallengeSectionDetails.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  children: PropTypes.node,
}

export default ChallengeSectionDetails
