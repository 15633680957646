import React from 'react'
import { connect } from 'react-redux'

import PropTypes from 'prop-types'

import ToolbarSearchField from 'components/common/ToolbarSearchField'
import ToolbarSortSwitch from 'components/common/ToolbarSortSwitch'
import NotesOverviewToolbarViewTypeSwitch from 'components/notes-overview/toolbar/NotesOverviewToolbarViewTypeSwitch'
import NotesOverviewToolbarFilter from 'components/notes-overview/toolbar/filter/NotesOverviewToolbarFilter'
import SortFlyout from 'components/common/SortFlyout'

import NotesSelector from 'selectors/notes'
import SearchSelector from 'selectors/search'
import { getSelectedProjectId } from 'selectors/path'
import ProjectSelector from 'selectors/projects'
import TagSelector from 'selectors/tags'

import {
  setOverviewType,
  sortNotes,
  searchNotes,
  showSuggestions,
  setFilter,
  activateFilter,
} from 'actions/notes'

import { showFlyout } from 'actions/flyout'

import { toggleTagsInFilter, toggleColorsInFilter } from 'helper/utils'

import './style.less'

const FLYOUT_ID = 'NOTES_SORT_FLYOUT'
const SORT_ATTRIBUTES = [
  { name: 'createdAt', translationKey: 'common.toolbar.sortFlyoutCreated' },
  { name: 'updatedAt', translationKey: 'common.toolbar.sortFlyoutUpdated' },
]

const NotesOverviewToolbar = ({
  setOverviewViewType,
  notesOverviewViewType,
  notesOrder,
  notesOrderedBy,
  searchQuery,
  isSearching,
  filter,
  projects,
  tags,
  selectedProjectId,
  ...props
}) => (
  <div className="NotesOverviewToolbar">
    <ToolbarSearchField
      onSearch={props.searchNotes}
      query={searchQuery}
      isSearching={isSearching}
      showSuggestions={props.showSuggestions}
    />
    <NotesOverviewToolbarViewTypeSwitch
      viewType={notesOverviewViewType}
      setViewType={setOverviewViewType}
    />
    <ToolbarSortSwitch
      showFlyout={props.showFlyout}
      flyoutId={FLYOUT_ID}
    >
      <SortFlyout
        id={FLYOUT_ID}
        onSelect={props.sortNotes}
        orderBy={notesOrderedBy}
        order={notesOrder}
        attributes={SORT_ATTRIBUTES}
      />
    </ToolbarSortSwitch>
    <div className="NotesOverviewToolbar__Separator" />
    <NotesOverviewToolbarFilter
      activateFilter={props.activateFilter}
      isFilterActive={props.isFilterActive}
      showFlyout={props.showFlyout}
      onFilterProject={project => props.setFilter({
        projectId: project ? project.id : '',
        setForProject: selectedProjectId,
      })}
      projects={projects}
      currentProjectId={selectedProjectId}
      selectedProjectId={filter ? filter.projectId : null}
      onFilterTags={tag => props.setFilter({
        ...filter,
        setForProject: selectedProjectId,
        tags: toggleTagsInFilter(filter, tag),
      })}
      tags={tags}
      selectedTags={filter ? filter.tags : null}
      selectedColors={filter ? filter.colors : null}
      onFilterColors={color => props.setFilter({
        ...filter,
        setForProject: selectedProjectId,
        colors: toggleColorsInFilter(filter, color),
      })}
    />
  </div>
)

NotesOverviewToolbar.propTypes = {
  setOverviewViewType: PropTypes.func,
  sortNotes: PropTypes.func,
  notesOrder: PropTypes.string,
  notesOrderedBy: PropTypes.string,
  notesOverviewViewType: PropTypes.string,
  searchQuery: PropTypes.shape({
    q: PropTypes.string,
    tags: PropTypes.array,
  }),
  isSearching: PropTypes.bool,
  searchNotes: PropTypes.func,
  showSuggestions: PropTypes.func,
  showFlyout: PropTypes.func,
  setFilter: PropTypes.func,
  filter: PropTypes.shape({
    projectId: PropTypes.string,
  }),
  selectedProjectId: PropTypes.string,
  isFilterActive: PropTypes.bool,
  activateFilter: PropTypes.func,
  projects: PropTypes.array,
  tags: PropTypes.array,
}

const mapStateToProps = state => ({
  notesOverviewViewType: NotesSelector.getOverviewType(state),
  notesOrderedBy: NotesSelector.getOrderBy(state),
  notesOrder: NotesSelector.getOrder(state),
  searchQuery: SearchSelector.getSearchQuery(state),
  isSearching: SearchSelector.isLoading(state),
  projects: ProjectSelector.getAll(state),
  filter: NotesSelector.getFilter(state),
  isFilterActive: NotesSelector.isFilterActive(state),
  tags: TagSelector.getAll(state),
  selectedProjectId: getSelectedProjectId(state),
})

export default connect(mapStateToProps, {
  setOverviewViewType: setOverviewType,
  sortNotes,
  searchNotes,
  showSuggestions,
  showFlyout,
  setFilter,
  activateFilter,
})(NotesOverviewToolbar)
