import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import { HotKeys } from 'react-hotkeys'
import { Popper } from 'react-popper'

import FlyoutSelectors from 'selectors/flyout'
import { hideFlyout } from 'actions/flyout'

import './style.less'

const flyoutRoot = document.getElementById('root-flyout')
/**
 * FLYOUT USAGE
 *
 * import { Manager, Reference } from 'react-popper'
 *
 * const MyFlyout = Flyout(FlyoutContentComponent)
 *
 * <Manager>
 *   <Reference>
 *     { ({ ref }) => <div ref={ref}>The Flyout will be positioned around this div.</div>}
 *   </Reference>
 *   <MyFlyout id="UNIQUE_ID" placement="bottom" />
 * </Manager>
 *
 * Placement reference: https://popper.js.org/popper-documentation.html#Popper.placements
 */

const referenceElementType = PropTypes.oneOfType([
  PropTypes.instanceOf(HTMLInputElement),
  PropTypes.instanceOf(HTMLButtonElement),
  PropTypes.instanceOf(Element),
])

export default (WrappedComponent) => {
  class Flyout extends Component {
    static propTypes = {
      id: PropTypes.string.isRequired,
      isVisible: PropTypes.bool,
      hideFlyout: PropTypes.func,
      onHideFlyout: PropTypes.func,
      placement: PropTypes.string,
      referenceElement: referenceElementType,
      data: PropTypes.shape({
        referenceElement: referenceElementType,
      }),
    }

    constructor(props) {
      super(props)

      this.containerRef = null
      this.handlers = {
        escape: () => this.hide(),
      }
    }

    componentDidMount() {
      document.addEventListener('mousedown', this.handleClickOutside)
    }

    componentWillUnmount() {
      document.removeEventListener('mousedown', this.handleClickOutside)
    }

    setContainerRef = (node) => {
      this.containerRef = node
    }

    handleClickOutside = (event) => {
      if (this.containerRef && !this.containerRef.contains(event.target)) {
        this.hide()
      }
    }

    hide = () => {
      const { onHideFlyout } = this.props
      this.props.hideFlyout(this.props.id)
      if (onHideFlyout) onHideFlyout(this.props.id)
    }

    renderPortalContent() {
      const referenceElement = this.props.referenceElement || this.props.data.referenceElement
      const attributes = referenceElement ? { referenceElement } : {}
      return (
        <Popper placement={this.props.placement} {...attributes}>
          {({ ref, style }) => (
            <div className="Flyout" ref={ref} style={style}>
              <div ref={this.setContainerRef}>
                <HotKeys focused attach={window} handlers={this.handlers}>
                  <WrappedComponent {...this.props} hide={this.hide} />
                </HotKeys>
              </div>
            </div>
          )}
        </Popper>
      )
    }

    render() {
      const { isVisible } = this.props
      return isVisible ? ReactDOM.createPortal(this.renderPortalContent(), flyoutRoot) : null
    }
  }
  const mapStateToProps = (state, ownProps) => ({
    isVisible: FlyoutSelectors.isVisible(state, ownProps.id),
    data: FlyoutSelectors.getData(state, ownProps.id),
  })

  const mapDispatchToProps = dispatch => ({
    hideFlyout: name => dispatch(hideFlyout(name)),
  })

  return connect(mapStateToProps, mapDispatchToProps)(Flyout)
}
