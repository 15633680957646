import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { AuratikumFontIcons } from 'helper/utils'
import Icon from 'components/common/Icon'
import modifyClassName from 'helper/modifyClassName'
import ToolbarProjectFilter from 'components/notes-overview/toolbar/filter/ToolbarProjectFilter'
import ToolbarTagFilter from 'components/notes-overview/toolbar/filter/ToolbarTagFilter'
import ToolbarColorFilter from 'components/notes-overview/toolbar/filter/ToolbarColorFilter'
import HelpSpot from 'containers/HelpSpot'
import { noteColors } from 'helper/colorHandler'

import './style.less'

class NotesOverviewToolbarFilter extends Component {
  static propTypes = {
    activateFilter: PropTypes.func,
    isFilterActive: PropTypes.bool,
    showFlyout: PropTypes.func,
    onFilterProject: PropTypes.func,
    projects: PropTypes.array,
    selectedProjectId: PropTypes.string, // selected filter project
    currentProjectId: PropTypes.string, // currently open project
    onFilterTags: PropTypes.func,
    onFilterColors: PropTypes.func,
    tags: PropTypes.array,
    selectedTags: PropTypes.array,
    selectedColors: PropTypes.array,
  }

  toggleExpansion = () => this.props.activateFilter({
    active: !this.props.isFilterActive,
    setForProject: this.props.currentProjectId,
  })

  renderProjectFilter = () => (
    <ToolbarProjectFilter
      showFlyout={this.props.showFlyout}
      projects={this.props.projects}
      selectedProjectId={this.props.selectedProjectId}
      onSelect={this.props.onFilterProject}
    />
  )

  renderColorFilter = () => (
    <ToolbarColorFilter
      showFlyout={this.props.showFlyout}
      colors={noteColors}
      selectedColors={this.props.selectedColors}
      onSelect={this.props.onFilterColors}
    />
  )

  renderTagFilter = () => (
    <ToolbarTagFilter
      showFlyout={this.props.showFlyout}
      tags={this.props.tags}
      selectedTags={this.props.selectedTags}
      onSelect={this.props.onFilterTags}
    />
  )

  renderFilter = () => (
    <div className="NotesOverviewToolbarFilter">
      { this.renderProjectFilter() }
      { this.renderColorFilter() }
      { this.renderTagFilter() }
    </div>
  )

  render() {
    return (
      <div className="NotesOverviewToolbarFilter">
        <HelpSpot
          className="NotesOverviewToolbarFilter__HelpSpot"
          translationKey="helpSpot.filter"
        />
        <button type="button" className={modifyClassName('NotesOverviewToolbarFilter__Button', { expanded: this.props.isFilterActive })} onClick={this.toggleExpansion}>
          <Icon icon={AuratikumFontIcons.FILTER} />
        </button>
        { this.props.isFilterActive && this.renderFilter() }

      </div>
    )
  }
}


export default NotesOverviewToolbarFilter
