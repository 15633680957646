import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import { push } from 'connected-react-router'
import { propTypes as i18nPropTypes, translate } from 'i18n'

import ProfileContainer from 'containers/profile/ProfileContainer'
import ImportContainer from 'containers/profile/ImportContainer'
import ReferralContainer from 'containers/profile/ReferralContainer'

import StatsContainer from 'containers/profile/StatsContainer'
import SubscriptionContainer from 'containers/subscription/SubscriptionContainer'
import ChallengeContainer from 'containers/profile/ChallengeContainer'

import Page from 'components/page/Page'
import PageHeader from 'components/page/PageHeader'
import PageContent from 'components/page/PageContent'
import { FlatTabBar } from 'components/common/Bar'
import FeatureSelector from 'selectors/features'

import FontIcon from 'components/common/FontIcon'
import { getProfileSubView } from 'selectors/path'

import './style.less'

class ProfilePage extends Component {
  navigation = [
    { key: 'profile', url: '/profile' },
    { key: 'stats', url: '/profile/stats' },
    { key: 'premium', url: '/profile/premium' },
    { key: 'import', url: '/profile/import' },
    { key: 'referral', url: '/profile/referral' },
  ]

  selectTab = tab => this.navigation.filter(n => n.key === tab).forEach(n => this.props.push(n.url))

  render() {
    const {
      match, subView, t, paymentEnabled,
    } = this.props
    let title = t('profile.profile')
    if (subView && subView !== 'profile') title = `${title} > ${t(`profile.${subView}`)}`

    return (
      <Page className="ProfilePage">
        <PageHeader icon={<FontIcon className="ProfilePage__HeaderIcon" icon={FontIcon.Icons.faUser} />} title={title}>
          <FlatTabBar
            className="ProfilePage__HeaderTabBar"
            onSelect={this.selectTab}
            selectedValue={subView}
            values={this.navigation.filter(n => paymentEnabled || (!paymentEnabled && n.key !== 'premium')).map(n => n.key)}
            translationKeys={this.navigation.filter(n => paymentEnabled || (!paymentEnabled && n.key !== 'premium')).map(n => `profile.${n.key}`)}
            usedForPremium={subView === 'premium'}
          />
        </PageHeader>
        <PageContent scrollable>
          <div className="SettingsPage__Main">
            <div className="SettingsPage__Content">
              <Switch>
                <Route path={`${match.path}/import`} component={ImportContainer} />
                <Route path={`${match.path}/stats`} component={StatsContainer} />
                <Route path={`${match.path}/premium`} component={SubscriptionContainer} />
                <Route path={`${match.path}/referral`} component={ReferralContainer} />
                <Route path={`${match.path}/challenge`} component={ChallengeContainer} />
                <Route exact path="" component={ProfileContainer} />
              </Switch>
            </div>
          </div>
        </PageContent>
      </Page>
    )
  }
}

ProfilePage.propTypes = {
  ...i18nPropTypes,
  subView: PropTypes.string,
  match: PropTypes.shape({ path: PropTypes.string }),
  push: PropTypes.func,
}

const mapStateToProps = state => ({
  subView: getProfileSubView(state) || 'profile',
  paymentEnabled: FeatureSelector.featureEnabled(state, 'payment'),
})

export default connect(mapStateToProps, {
  push,
})(translate()(ProfilePage))
