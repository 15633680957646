import { handleActions } from 'redux-actions'

import { cleanNavigationStack, popNavigationItem } from 'actions/navigation'

const supportedCollections = ['projects', 'notes', 'references']

const parseUrl = (url) => {
  const segments = url.split('/').filter(segment => segment) // filter empty segments
  return {
    url,
    collection: segments[0],
    id: segments[0] === 'references' ? segments[2] : segments[1],
    context: segments[0] === 'references' ? segments[1] : undefined,
  }
}

const initialNavItem = { ...parseUrl(window.location.pathname), index: 0 }

const initialState = {
  activeProjectId: initialNavItem.collection === 'projects' && initialNavItem.id ? initialNavItem.id : null,
  navigationStack: [initialNavItem],
}

const reducer = handleActions({
  [cleanNavigationStack]: (state, action) => ({
    ...state,
    navigationStack: state.navigationStack.slice(0, action.payload),
  }),
  [popNavigationItem]: (state, action) => ({
    ...state,
    navigationStack: state.navigationStack.slice(0, -(action.payload || 1)),
  }),
  '@@router/LOCATION_CHANGE': (state, action) => {
    let { activeProjectId = null, navigationStack = [] } = state
    const { payload: { location: { pathname: url }, action: navigationAction } } = action
    const navigationItem = parseUrl(url)

    if (navigationItem.collection && supportedCollections.includes(navigationItem.collection)) {
      if (navigationAction === 'POP'
        && navigationStack.length > 1
        && navigationStack[navigationStack.length - 2].url === url
      ) {
        navigationStack.pop()
        navigationStack = [...navigationStack]
      } if (navigationAction === 'REPLACE'
        && navigationStack.length > 1
      ) {
        navigationStack = [...navigationStack.slice(0, -1), {
          ...navigationItem,
          index: navigationStack.length,
        }]
      } else if (navigationStack.length === 0
        || (navigationStack.length > 0 && navigationStack[navigationStack.length - 1].url !== url)) {
        navigationStack = [...navigationStack, {
          ...navigationItem,
          index: navigationStack.length,
        }]
      }

      if (navigationItem.collection === 'projects' && navigationItem.id) {
        activeProjectId = navigationItem.id
      }
    }

    return {
      activeProjectId,
      navigationStack,
    }
  },

}, initialState)

export default reducer
