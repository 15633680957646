import CSL from 'citeproc'
import {
  values, get, isEmpty, uniqBy, intersection, keys, find, isArray, difference,
} from 'lodash'
import i18n from 'i18n'

import localeEN from 'assets/locales-en-US.xml'
import localeDE from 'assets/locales-de-DE.xml'

import typeMap from 'helper/referenceTypeMap.json'

export const Locators = {
  BOOK: 'book',
  CHAPTER: 'chapter',
  COLUMN: 'column',
  FIGURE: 'figure',
  FOLIO: 'folio',
  ISSUE: 'issue',
  LINE: 'line',
  NOTE: 'note',
  OPUS: 'opus',
  PAGE: 'page',
  PARAGRAPH: 'paragraph',
  PART: 'part',
  SECTION: 'section',
  SUB_VERBO: 'sub verbo',
  VERSE: 'verse',
  VOLUME: 'volume',
}
export const CITATION_ID_ATTR = 'data-citation-id'
export const CITATION_META_ATTR = 'data-citation-meta'
export const CITATION_PLACEHOLDER = '[…]'

export const CitationStyleType = {
  FOOTNOTE: 'footnote',
  OTHER: 'other',
}

const CITATION_FORMAT_ATTR = 'citation-format'
const CATEGORY_ATTR = 'category'

const DEFAULT_LANGUAGE = 'en'

const locales = {
  'de-DE': localeDE,
  'en-US': localeEN,
}


const isImportedReference = reference => reference && !isEmpty(reference.importReference) && !reference.title && get(reference, 'author.length') === 0

const updateImportedReference = reference => (reference && { ...reference, citation: reference.importReference, imported: true })

// same code is used in backend, remember to update it there as well if improved here
const cleanReferenceForCiteproc = (reference) => {
  const ref = { ...reference }
  Object.keys(ref).forEach((key) => {
    if (isArray(ref[key]) && ref[key].length === 0) ref[key] = undefined
    if (ref[key] === null) ref[key] = undefined
  })
  return ref
}

const createCslSys = ({ entities, item, itemId }) => ({
  retrieveLocale: language => locales[language] || localeEN,
  retrieveItem: entities
    ? id => (entities[id] ? { ...cleanReferenceForCiteproc(entities[id]), type: typeMap[entities[id].type] } : {})
    : () => (item ? { ...item, type: typeMap[item.type], id: itemId } : {}),
})

export const updateReferenceCitation = (reference, cslStyle, citationLanguage = DEFAULT_LANGUAGE) => {
  const id = 'id'

  if (isImportedReference(reference)) {
    return updateImportedReference(reference)
  }

  if (!cslStyle) {
    return reference
  }

  try {
    const citeproc = new CSL.Engine(createCslSys({ item: reference, itemId: id }), cslStyle, citationLanguage, true)
    citeproc.updateItems([id])
    return { ...reference, citation: citeproc.makeBibliography()[1][0] }
  } catch (error) {
    console.error(error)
    // TODO: translated error message
    return { ...reference, citation: '<div style="color: red">Fehler bei der Erstellung der Bibliografie. Bitte Referenzattribute anpassen.</div>' }
  }
}

export const getStyleType = (engine) => {
  if (!engine || !engine.cslXml) {
    return null
  }
  let format
  const categoryNodes = engine.cslXml.getNodesByName(engine.cslXml.dataObj, CATEGORY_ATTR)
  if (!categoryNodes) {
    return CitationStyleType.OTHER
  }
  categoryNodes.forEach((node) => {
    format = engine.cslXml.getAttributeValue(node, CITATION_FORMAT_ATTR) || format
  })
  return format && format.toLowerCase() === 'note' ? CitationStyleType.FOOTNOTE : CitationStyleType.OTHER
}

/**
 * creates bibliography entries for given references
 * @param {*} referenceEntities reference objects { <id> : {}, ...}
 * @param {*} cslStyle csl xml
 * @param {*} citationLanguage en or de
 */
export const generateReferenceCitations = (referenceEntities, cslStyle, citationLanguage = DEFAULT_LANGUAGE) => {
  const references = { ...referenceEntities }
  // imported references are not processed with CSL
  const importedReferences = values(references).filter(isImportedReference)
  importedReferences.forEach((reference) => {
    references[reference.id] = updateImportedReference(reference)
  })
  const importedIds = importedReferences.map(ref => ref.id)

  if (!cslStyle) {
    return references
  }

  const ids = Object.keys(references).filter(id => !importedIds.includes(id))
  const citeproc = new CSL.Engine(createCslSys({ entities: references }), cslStyle, citationLanguage, true)
  citeproc.updateItems(ids)
  const bibliography = citeproc.makeBibliography()
  if (bibliography[0]) {
    bibliography[0].entry_ids.forEach((id, index) => {
      references[id] = { ...references[id], citation: bibliography[1][index] }
    })
  } else {
    throw new Error('Citeproc CSL Enginge Error: makeBibliography returned no value')
  }
  return references
}

/**
 *
 * @param {*} references
 * @param {*} cslStyle
 * @param {*} citationLanguage
 * @param {*} items ordered citation-items w/ options { locator, label: Locators, suppress-author, author-only, prefix, suffix, noteIndex }
 */
export const citationGenerator = (references, cslStyle, citationLanguage = DEFAULT_LANGUAGE) => (items = []) => {
  const citeproc = new CSL.Engine(createCslSys({ entities: references }), cslStyle, citationLanguage, true)
  const idsToUpdate = uniqBy(items, 'id').map(item => item.id)
  citeproc.updateItems(idsToUpdate)

  const result = {}
  items.forEach((item, idx) => {
    const citation = citeproc.processCitationCluster({
      properties: { noteIndex: idx },
      citationItems: [item],
    }, values(result).map(resultItem => [resultItem.citationId, resultItem.index]), [])

    const citationData = get(citation, [1])
    if (isEmpty(citationData)) { return }
    citationData.forEach((citationItem) => {
      const dataItem = { data: citationItem[1], index: citationItem[0], citationId: citationItem[2] }
      result[dataItem.index] = dataItem
    })
  })

  return { data: result, type: getStyleType(citeproc) }
}


// const cache = {}

/**
 * @param {*} id unique identifier for csl engine caching
 * @param {*} references entities, { <id>: {}, ...}
 * @param {*} citedReferenceIds references that are cited in content [ <id>, <id> ...]
 * @param {*} uncitedReferenceIds references that are not cited in content [ <id>, <id> ...]
 * @param {*} cslStyle xml of csl style
 * @param {*} citationLanguage en or de
 * @param {*} items ordered citations w/ options { locator, label: Locators, suppress-author, author-only, prefix, suffix, noteIndex }
 */
export const generateCitations = async (
  id, references, citedReferenceIds, uncitedReferenceIds, cslStyle, citationLanguage = DEFAULT_LANGUAGE, citations,
) => {
  const availableCitedIds = intersection(citedReferenceIds, keys(references))
  const availableUncitedIds = difference(intersection(uncitedReferenceIds, keys(references)), availableCitedIds)

  const citeproc = new CSL.Engine(
    createCslSys({ entities: references }), cslStyle, citationLanguage, true,
  )
  citeproc.updateItems(availableCitedIds)
  citeproc.updateUncitedItems(availableUncitedIds)
  const bibliography = citeproc.makeBibliography()

  const result = {}
  citations.forEach((citation, idx) => {
    const position = values(result).map(item => [item.citationId, item.index])

    const citationItems = citation.items.filter(item => availableCitedIds.includes(item.referenceId)).map(item => ({
      id: item.referenceId,
      ...item,
      'suppress-author': item.suppressAuthor,
      'author-only': item.authorOnly,
    }))
    if (citationItems.length === 0) return
    const cite = citeproc.processCitationCluster({
      properties: { noteIndex: idx },
      citationID: citation.id,
      citationItems,
    }, position, [])

    const citationCluster = get(cite, [1])
    if (isEmpty(citationCluster)) { return }

    citationCluster.forEach((citationItem) => {
      const data = {
        data: citationItem[1],
        index: citationItem[0] + 1,
        citationId: citationItem[2],
        referenceIds: citation.items.filter(item => availableCitedIds.includes(item.referenceId)).map(item => item.referenceId),
      }
      result[data.index] = data
    })
  })
  const entries = {}
  const referenceIds = []
  bibliography[0].entry_ids.forEach((entry, idx) => {
    referenceIds.push(entry[0])
    entries[entry[0]] = bibliography[1][idx]
  })

  const citationEntries = {}

  const citationIds = values(result).sort((el1, el2) => el1.index > el2.index).map((citation) => {
    citationEntries[citation.citationId] = citation
    return citation.citationId
  })
  return {
    citations: citationEntries,
    citationIds,
    type: getStyleType(citeproc),
    bibliography: {
      meta: {
        ...bibliography[0],
        referenceIds,
        uncitedReferenceIds,
      },
      entries,
    },
  }
}

export const updateDomCitations = (citationElements, citations, type) => {
  if (citationElements) {
    citationElements.forEach((citationEl) => {
      const citation = find(citations, (clusterCitation) => {
        const cleanedId = clusterCitation.citationId.substring(0, clusterCitation.citationId.indexOf('-'))
        return citationEl.dataset.citationId === clusterCitation.citationId || citationEl.dataset.citationId === cleanedId
      })
      if (citation) {
        // eslint-disable-next-line no-param-reassign
        citationEl.innerHTML = type === CitationStyleType.FOOTNOTE
          ? `<sup>${citation.index}</sup>` : citation.data
      } else {
        // eslint-disable-next-line no-param-reassign
        citationEl.innerHTML = `<span style="color: #e03e2d;">(${i18n.t('citation.broken')})</span>`
      }
    })
  }
}
