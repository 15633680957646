import { handleActions } from 'redux-actions'
import { omit } from 'lodash'

import { setScrollPosition, removeScrollPosition } from 'actions/scroll-restoration'

const reducer = handleActions({
  LOAD_LOCALSTORAGE: (state, action) => ({
    ...state,
    ...action.payload.scrollRestoration,
  }),
  [setScrollPosition]: (state, action) => ({
    ...state,
    [action.payload.id]: action.payload.position,
  }),
  [removeScrollPosition]: (state, action) => (action.payload.id ? omit(state, action.payload.id) : state),
}, {})

export default reducer
