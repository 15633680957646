import { createSelector } from 'reselect'
import { values } from 'lodash'


export const getEntities = state => state.entities.referenceCollections
const selectedCollectionSelector = state => state.reference.selectedCollection

export const getReferenceCollections = createSelector(
  getEntities,
  references => values(references),
)

export const getSelectedCollection = createSelector(
  getEntities,
  selectedCollectionSelector,
  (collections, selectedCollectionId) => (selectedCollectionId ? collections[selectedCollectionId] : null),
)
