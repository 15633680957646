import React from 'react'
import PropTypes from 'prop-types'

import './style.less'


const ChallengeSection = ({ className, children }) => (
  <div className={`ChallengeSection ${className}`}>
    {children}
  </div>
)

ChallengeSection.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}

ChallengeSection.defaultProps = {
  className: '',
}

export default ChallengeSection
