import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Trans } from 'react-i18next'

import { propTypes as i18nPropTypes, translate } from 'i18n'
import { fetchScores, getEarlyBirdStatus } from 'actions/challenge'
import { getPartitionedScores, getIsEarlyBird } from 'selectors/challenge'
import ProfileSelector from 'selectors/profile'
import { getPercentage } from 'helper/utils'

import ScoreBoard from 'components/challenge/ScoreBoard'
import ReferralList from 'components/profile/ReferralList'
import ReferralInviteForm from 'containers/profile/ReferralInviteForm'
import ProgressBar from 'components/common/ProgressBar'
import ChallengeHeader from 'components/challenge/ChallengeHeader'
import EarlyBirdStatus from 'components/challenge/EarlyBirdStatus'
import ChallengeSection from 'components/challenge/ChallengeSection'
import ChallengeSectionDetails from 'components/challenge/ChallengeSectionDetails'
import EducationVerificationContainer from 'containers/profile/EducationVerificationContainer'

import './style.less'


const SCORE_LIMIT = 50
const TERMS_URL = 'https://auratikum.de/teilnahmebedingungen-uni-challenge'

class ChallengeContainer extends Component {
  static propTypes = {
    scores: PropTypes.shape({}),
    isEarlyBird: PropTypes.bool,
    ...i18nPropTypes,
  }

  componentDidMount() {
    const { fetchScores: fetchScoresAction, getEarlyBirdStatus: getEarlyBirdStatusAction } = this.props
    fetchScoresAction()
    getEarlyBirdStatusAction()
  }

  getInvitationSubTitle = () => {
    const { t, isEarlyBird, scores } = this.props
    const isFirst = scores && scores.myInstitute && scores.myInstitute.order > 0
    const hasReachedGoal = scores && scores.myInstitute && scores.myInstitute.count >= SCORE_LIMIT
    if (isFirst) { return t('challenge.inviteFurtherStudentsLeading') }
    if (isEarlyBird && !hasReachedGoal) { return t('challenge.inviteFurtherStudents') }
    if (hasReachedGoal) { return t('challenge.inviteFurtherStudentsGoalReached') }
    return null
  }

  renderInstituteStanding() {
    const { t, scores } = this.props
    const { myInstitute } = scores
    let standingFragment
    if (myInstitute.order === 0) {
      const count = scores.top[1] ? myInstitute.count - scores.top[1].count : myInstitute.count
      standingFragment = <div className="ChallengeContainer__Standing">{t('challenge.instituteInTheLead', { count })}</div>
    } else if (myInstitute.count >= SCORE_LIMIT) {
      const count = scores.top[0].count - myInstitute.count
      standingFragment = <div className="ChallengeContainer__Standing">{t('challenge.distanceFromFirst', { count })}</div>
    }
    return (
      <ChallengeSectionDetails title={t('challenge.progress', { name: myInstitute.educationalInstitute })}>
        { standingFragment }
        { myInstitute.count < SCORE_LIMIT && (
        <React.Fragment>
          <div className="ChallengeContainer__Info">{`${myInstitute.count}/${SCORE_LIMIT}`}</div>
          <ProgressBar className="ChallengeContainer__ProgressBar" percent={getPercentage(myInstitute.count, SCORE_LIMIT)} />
          <div className="ChallengeContainer__Info">{t('challenge.progressInfo', { score: SCORE_LIMIT })}</div>
        </React.Fragment>
        )}
      </ChallengeSectionDetails>
    )
  }

  renderProgress() {
    const { t, scores } = this.props
    return scores && scores.myInstitute
      ? this.renderInstituteStanding()
      : (
        <ChallengeSectionDetails title={t('challenge.emailVerification')}>
          <EducationVerificationContainer />
        </ChallengeSectionDetails>
      )
  }

  render() {
    const {
      t,
      scores,
      referrals,
      isEarlyBird,
    } = this.props
    const invitationSubTitle = isEarlyBird
      ? t(`challenge.${scores && scores.myInstitute && scores.myInstitute.order > 0 ? 'inviteFurtherStudents' : 'inviteFurtherStudentsLeading'}`)
      : undefined
    return (
      <div className="ChallengeContainer">
        <ChallengeHeader scoreLimit={SCORE_LIMIT} />
        <div className="ChallengeContainer__Content">
          <ChallengeSection className="ChallengeContainer__Section">
            {this.renderProgress()}
            <EarlyBirdStatus
              institute={scores && scores.myInstitute}
              limit={SCORE_LIMIT}
              isEarlyBird={isEarlyBird}
            />
          </ChallengeSection>
          <ChallengeSection className="ChallengeContainer__Section">
            <ChallengeSectionDetails
              title={t('challenge.inviteFellows')}
              subTitle={invitationSubTitle}
            >
              <ReferralInviteForm
                inviteText={t('challenge.referralLinkDescription')}
                invitePlaceholder={t('challenge.referralEnterEmails')}
                embedded
              />
            </ChallengeSectionDetails>
          </ChallengeSection>
        </div>
        <div className="ChallengeContainer__Content">
          <ChallengeSection className="ChallengeContainer__Section">
            <div className="ChallengeContainer__SubTitle">{t('challenge.leaderboard')}</div>
            <ScoreBoard scores={scores} />
          </ChallengeSection>
          <ChallengeSection className="ChallengeContainer__Section">
            <ReferralList referrals={referrals} embedded />
          </ChallengeSection>
        </div>
        <div className="ChallengeContainer__Terms">
          <Trans i18nKey="challenge.termsOfParticipation">
            <span>index0</span>
            <a href={TERMS_URL} target="_blank" rel="noopener noreferrer">index1</a>
            <span>index2</span>
          </Trans>
        </div>
      </div>
    )
  }
}


const mapStateToProps = state => ({
  scores: getPartitionedScores(state),
  referrals: ProfileSelector.getReferrals(state),
  isEarlyBird: getIsEarlyBird(state),
})


export default connect(mapStateToProps, {
  fetchScores,
  getEarlyBirdStatus,
})(translate()(ChallengeContainer))
