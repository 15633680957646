import { handleActions } from 'redux-actions'

import { showChecklist } from 'actions/onboarding'

const reducer = handleActions({
  [showChecklist]: (state, action) => ({
    ...state,
    checklistIsVisible: action.payload,
  }),
}, {
  checklistIsVisible: false,
})

export default reducer
