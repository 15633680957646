import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { propTypes as i18nPropTypes, translate } from 'i18n'

import { push } from 'connected-react-router'

import Page from 'components/page/Page'
import PageHeader from 'components/page/PageHeader'
import ProjectsGrid from 'components/projects-overview/ProjectsGrid'
import PageContent from 'components/page/PageContent'
import ExtensionHint from 'components/common/ExtensionHint'

import { showModal } from 'actions/modal'
import { deleteProject } from 'actions/projects'

import ProjectSelector from 'selectors/projects'
import SubscriptionSelector from 'selectors/subscriptions'
import { OutlinedButton } from 'components/common/Button'

import ProjectSettingsModal, { MODAL_ID as PROJECT_SETTINGS_MODAL } from 'containers/modal/ProjectSettingsModal'
import { PAYWALL_MODAL_ID } from 'containers/modal/PaywallModal'

import { AuratikumFontIcons } from 'helper/utils'
import ConfirmationModal from 'containers/modal/ConfirmationModal'
import Icon from 'components/common/Icon'

import './style.less'

const DELETE_MODAL_ID = 'DELETE_PROJECT_CONFIRMATION_MODAL'
const SAMPLE_PROJECT_MODAL_ID = 'SAMPLE_PROJECT_MODAL'

class ProjectsOverviewPage extends Component {
  handleNewProject = () => {
    if (this.props.isPremiumRestricted) {
      this.props.showModal(PAYWALL_MODAL_ID)
    } else {
      this.props.showModal(PROJECT_SETTINGS_MODAL)
    }
  }

  handleNewSampleProject = () => this.props.showModal(SAMPLE_PROJECT_MODAL_ID)

  handleOpenProject = async (project) => {
    this.props.push(`/projects/${project.id}/structure`)
  }

  handleDeleteProject = (project) => {
    this.props.showModal(DELETE_MODAL_ID, { data: { ...project } })
  }

  deleteProject = async (project) => {
    await this.props.deleteProject(project)
  }

  handleEditProject = (project) => {
    this.props.showModal(PROJECT_SETTINGS_MODAL, { data: { projectId: project.id, okLabel: this.props.t('common.save'), title: this.props.t('projectSettings.editTitle') } })
  }

  navigateToNotes = () => {
    this.props.push('/notes')
  }

  renderDeleteModalContent = project => (
    <React.Fragment>
      <Icon key="icon" className="ProjectsOverviewPage__DeleteModalIcon" icon={AuratikumFontIcons.DELETE} />
      <div key="title" className="ProjectsOverviewPage__DeleteModalTitle">{this.props.t('deleteProjectModal.contentTitle', { project: project.title })}</div>
      <div key="subtitle" className="ProjectsOverviewPage__DeleteModalSubTitle">{this.props.t('modal.cannotBeUndone')}</div>
    </React.Fragment>
  )

  render() {
    const {
      myProjects, sampleProjects, t, isPremiumRestricted,
    } = this.props
    return (
      <Page>
        <PageHeader>
          <ExtensionHint className="ProjectsOverviewPage__HeaderHint" shouldDelayRendering />
          <div className="ProjectsOverviewPage__HeaderActions">
            <OutlinedButton className="ProjectsOverviewPage__HeaderButton" onClick={this.navigateToNotes}>{t('common.allNotes')}</OutlinedButton>
          </div>
        </PageHeader>
        <PageContent scrollable>
          <ProjectsGrid
            title={t('projectsOverview.myProjects')}
            projects={myProjects}
            onOpenProject={this.handleOpenProject}
            onEditProject={this.handleEditProject}
            onDeleteProject={this.handleDeleteProject}
            onNewProject={this.handleNewProject}
            isPremiumRestricted={isPremiumRestricted}
          />
          { sampleProjects.length > 0 && (
          <ProjectsGrid
            title={t('projectsOverview.sampleProjects')}
            projects={sampleProjects}
            onOpenProject={this.handleOpenProject}
            onEditProject={this.handleEditProject}
            onDeleteProject={this.handleDeleteProject}
          />
          )}
        </PageContent>
        <ProjectSettingsModal
          id={PROJECT_SETTINGS_MODAL}
          title={t('projectSettings.createNewTitle')}
          okLabel={t('common.create')}
          cancelLabel={t('common.cancel')}
        />

        <ConfirmationModal
          id={DELETE_MODAL_ID}
          title={t('deleteProjectModal.title')}
          onConfirm={this.deleteProject}
          contentFunc={this.renderDeleteModalContent}
          okLabel={t('common.delete')}
          cancelLabel={t('common.cancel')}
        />
      </Page>
    )
  }
}


const mapStateToProps = state => ({
  myProjects: ProjectSelector.getMyProjects(state),
  sampleProjects: ProjectSelector.getInitialProjects(state),
  isPremiumRestricted: SubscriptionSelector.isProjectCountPremiumRestricted(state),
})

ProjectsOverviewPage.propTypes = {
  myProjects: PropTypes.array,
  sampleProjects: PropTypes.array,
  showModal: PropTypes.func,
  isPremiumRestricted: PropTypes.bool,
  ...i18nPropTypes,
}

export default connect(mapStateToProps, {
  push,
  deleteProject,
  showModal,
})(translate()(ProjectsOverviewPage))
