import { createAction } from 'redux-actions'

import * as api from 'api'

export const receiveTags = createAction('RECEIVE_TAGS')
export const udpateTags = createAction('UPDATE_TAGS')

export const requestTags = () => async (dispatch) => {
  const payload = await api.requestTags()
  return dispatch(receiveTags(payload))
}
