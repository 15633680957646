import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { requestPaymentInfo } from 'api'
import checkoutPaypalButton from 'assets/payment/checkoutPaypal.png'
import { propTypes as i18nPropTypes, translate } from 'i18n'

import Spinner from 'components/common/Spinner'
import './style.less'

class PaypalForm extends Component {
  constructor() {
    super()
    this.state = {
      isLoading: true,
      isWaiting: false,
      paymentInfo: null,
    }
  }

  componentDidMount() {
    requestPaymentInfo(this.props.plan, this.props.voucher)
      .then(payload => this.setState({ isLoading: false, paymentInfo: payload }),
        () => alert(this.props.t('paymentform.paypal.prepareError')))
  }

  checkSubmit = () => {
    if (!this.props.canSubmit) {
      alert(this.props.t('paymentform.paypal.tosError'))
    } else {
      this.setState({ isWaiting: true })
    }
    return this.props.canSubmit
  }

  render() {
    let paypalButton = (
      <button className="PaypalForm__Button" type="submit">
        <img className="PaypalForm__ButtonImage" src={checkoutPaypalButton} alt="Checkout Paypal" />
      </button>
    )

    if (this.state.isLoading) return <div className="PaypalForm"><Spinner size="2x" /></div>
    if (this.state.isWaiting) {
      paypalButton = (
        <div className="PaypalForm">
          <Spinner />
          {this.props.t('paymentform.paypal.pleaseWait')}
        </div>
      )
    }
    return (
      <div className="PaypalForm">
        <form method="post" onSubmit={this.checkSubmit} action={process.env.ADYEN_HPP_URL} id="adyenForm" name="adyenForm" target="_parent">
          { Object.keys(this.state.paymentInfo).map(key => <input key={key} type="hidden" name={key} value={`${this.state.paymentInfo[key]}`} />)}

          { paypalButton }
        </form>
      </div>
    )
  }
}

PaypalForm.propTypes = {
  canSubmit: PropTypes.bool,
  plan: PropTypes.shape({}),
  voucher: PropTypes.shape({}),
  ...i18nPropTypes,
}

export default translate()(PaypalForm)
