import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { propTypes as i18nPropTypes, translate } from 'i18n'

import { PremiumButton } from 'components/common/Button'
import FontIcon from 'components/common/FontIcon'

import FlyoutReference from 'containers/common/FlyoutReference'

import modifyClassName from 'helper/modifyClassName'


import './style.less'

class AutoSaveButton extends Component {
  static propTypes = {
    ...i18nPropTypes,
    className: PropTypes.string,
    status: PropTypes.string.isRequired,
    onRetry: PropTypes.func.isRequired,
  }

  static status = {
    SYNCED: {
      icon: FontIcon.Icons.faCheck,
      render: ({ t }) => null, // (<div className="AutoSaveButton__Flyout">{t('autoSave.synced')}</div>),
    },
    SYNCING: {
      icon: FontIcon.Icons.faSpinner,
      pulse: true,
      render: () => null,
    },
    ERROR: {
      icon: FontIcon.Icons.faExclamationTriangle,
      render: ({ t, onRetry }) => (
        <div className="AutoSaveButton__Flyout">
          <FontIcon className="AutoSaveButton__FlyoutIcon" icon={FontIcon.Icons.faExclamationTriangle} />
          <div className="AutoSaveButton__FlyoutText">{t('autoSave.error')}</div>
          <PremiumButton className="AutoSaveButton__FlyoutButton" onClick={onRetry}>{t('autoSave.retry')}</PremiumButton>
        </div>
      ),
    },
  }

  render = () => {
    const {
      className, status, t, onRetry,
    } = this.props
    const { icon, pulse, render } = AutoSaveButton.status[status]
    return (
      <div className={`AutoSaveButton ${className || ''}`}>
        <FlyoutReference
          flyoutClassName="AutoSaveButton__FlyoutContainer"
          showFlyout={status === 'ERROR'}
          placement="left-end"
          component={render({ t, onRetry })}
        >
          <FontIcon
            className={modifyClassName('AutoSaveButton__Icon', { [status.toLowerCase()]: true })}
            icon={icon}
            pulse={pulse}
          />
        </FlyoutReference>
      </div>
    )
  }
}

export default translate()(AutoSaveButton)
