import React, { Component } from 'react'
import PropTypes from 'prop-types'

import modifyClassName from 'helper/modifyClassName'
import FontIcon from 'components/common/FontIcon'
import Toast from '../Toast'

import './style.less'

const ModifiedSimpleToast = modifier => WrappedComponent => ({ ...props }) => <WrappedComponent modifier={modifier} {...props} />


const modifierIconMap = {
  success: FontIcon.Icons.faCheck,
  warning: FontIcon.Icons.faExclamationTriangle,
  danger: FontIcon.Icons.faExclamationCircle,
}

class SimpleToast extends Component {
  handleCancel() {
    this.props.onClose(this.props.data)
  }

  render() {
    const {
      content, contentFunc, modifier, icon, data,
    } = this.props
    const mod = modifier || data.level
    const modifiers = { [mod]: mod !== undefined }

    const iconComponent = icon || (data.level && <FontIcon icon={modifierIconMap[data.level]} />)
    return (
      <div className={modifyClassName('SimpleToast', modifiers)}>
        {iconComponent && <div className={modifyClassName('SimpleToast__Icon', modifiers)}>{iconComponent}</div> }
        <div className="SimpleToast__Content">{content || (contentFunc && contentFunc(this.props.data))}</div>
      </div>
    )
  }
}

SimpleToast.propTypes = {
  onClose: PropTypes.func,
  data: PropTypes.shape({}),
  content: PropTypes.any,
  contentFunc: PropTypes.func,
  modifier: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

SimpleToast.defaultPropTypes = {}

export const InfoSimpleToast = Toast(SimpleToast)
export const SuccessBigToast = Toast(ModifiedSimpleToast('success')(SimpleToast))
export const WarningSimpleToast = Toast(ModifiedSimpleToast('warning')(SimpleToast))
export const DangerSimpleToast = Toast(ModifiedSimpleToast('danger')(SimpleToast))
