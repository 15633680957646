import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { propTypes as i18nPropTypes, translate } from 'i18n'

import modifyClassName from '../../../helper/modifyClassName'
import { mapProjectTypeToIcon } from '../../../helper/utils'
import { GreyRoundLabel } from '../../common/RoundLabel'
import Icon from '../../common/Icon'
import { OutlinedButton } from '../../common/Button'
import ThreeDotsButton from '../../common/ThreeDotsButton'

import ProjectCardMenu from '../ProjectCardMenu'

import './style.less'

class ProjectCard extends Component {
  static propTypes = {
    onOpenProject: PropTypes.func,
    onEditProject: PropTypes.func,
    onDeleteProject: PropTypes.func,
    project: PropTypes.shape({}),
    ...i18nPropTypes,
  }

  state = {
    cardHoverState: false,
    showMenu: false,
  }

  onMouseEnter = () => {
    this.setState({
      cardHoverState: true,
    })
  }

  onMouseLeave = () => {
    this.setState({
      cardHoverState: false,
      showMenu: false,
    })
  }

  onMenuClick = (event) => {
    event.stopPropagation()
    this.setState({
      showMenu: !this.state.showMenu,
    })
  }

  onMenuEditClick = (event) => {
    event.stopPropagation()
    this.props.onEditProject(this.props.project)
  }

  onMenuDeleteClick = (event) => {
    event.stopPropagation()
    this.props.onDeleteProject(this.props.project)
  }

  openProject = () => {
    const { project, onOpenProject } = this.props
    onOpenProject(project)
  }

  render() {
    const { project, t } = this.props
    const { cardHoverState, showMenu } = this.state
    return (
      <div className="ProjectCard" onClick={this.openProject}>
        <div className="ProjectCard__CardStack">
          <div className={modifyClassName('ProjectCard__Card', { rotateLeft: true, bottomBorder: true })} />
          <div className={modifyClassName('ProjectCard__Card', { rotateRight: true })} />
          <div
            className={modifyClassName('ProjectCard__Card', { main: true })}
            onMouseEnter={this.onMouseEnter}
            onMouseLeave={this.onMouseLeave}
          >
            {!showMenu && <ThreeDotsButton onClick={this.onMenuClick} className={modifyClassName('ProjectCard__ThreeDotsButton', { cardHoverState })} />}
            <div className="ProjectCard__TopContainer">
              <Icon icon={mapProjectTypeToIcon(project.projectType || '')} className={modifyClassName('ProjectCard__Icon', { cardHoverState })} />
              {!cardHoverState && <div className="ProjectCard__ProjectType">{project.projectType || ''}</div>}
              {cardHoverState
                && (
                <OutlinedButton>
                  {t('common.openProject')}
                </OutlinedButton>
                )}
            </div>
            {project.subject && <GreyRoundLabel title={project.subject} className={modifyClassName('ProjectCard__Subject', { cardHoverState })} />}

            <ProjectCardMenu
              visible={showMenu}
              onEditClick={this.onMenuEditClick}
              onDeleteClick={this.onMenuDeleteClick}
            />
          </div>
        </div>
        <div className="ProjectCard__Title">
          {project.title}
        </div>
      </div>
    )
  }
}
export default translate()(ProjectCard)
