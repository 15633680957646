
import { get } from 'lodash'


export const profileSelector = state => get(state, 'entities.profiles', {})


const featureEnabled = (state, featureName) => {
  const id = Object.keys(profileSelector(state))[0]
  const hasFeature = get(state, `entities.profiles['${id}'].features['${featureName}']`, false)
  return hasFeature
}


export default {
  featureEnabled,
}
