import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { TabBar } from 'components/common/Bar'

import { fieldPropTypes } from 'redux-form'
import { without } from 'lodash'

import './style.less'

const tabs = {
  CREDIT: 'CREDIT',
  SEPA: 'SEPA',
  PAYPAL: 'PAYPAL',
}

const tabsList = [tabs.CREDIT, tabs.SEPA] //  , tabs.PAYPAL]
const tabTranslationKeys = tabsList.map(tab => `paymentform.tabs.${tab}`)

class SidebarSelectorField extends Component {
  handleSelect = (key) => {
    this.props.input.onChange(key)
  }

  render() {
    const values = without(tabsList, ...this.props.inactiveMethods)
    return (
      <div className="MethodSelectorField">
        <TabBar
          className="MethodSelectorField__TabBar"
          onSelect={this.handleSelect}
          selectedValue={this.props.input.value}
          values={values}
          translationKeys={tabTranslationKeys}
          usedForPremium
          borderless
        />
      </div>
    )
  }
}

SidebarSelectorField.propTypes = {
  ...fieldPropTypes,
  inactiveMethods: PropTypes.array,
}

export default SidebarSelectorField
