import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { AuratikumFontIcons } from 'helper/utils'
import { Manager, Reference } from 'react-popper'

import Icon from 'components/common/Icon'

import './style.less'

class ToolbarSortSwitch extends Component {
  static propTypes = {
    children: PropTypes.node,
    showFlyout: PropTypes.func,
    flyoutId: PropTypes.string,
    disabled: PropTypes.bool,
    tooltip: PropTypes.string,
  }

  showFlyout = () => {
    this.props.showFlyout(this.props.flyoutId)
  }

  render() {
    const { disabled, tooltip, children } = this.props
    return (
      <div className="ToolbarSortSwitch" title={tooltip}>
        <div>
          <Manager>
            <Reference>
              { ({ ref }) => (
                <button ref={ref} type="button" onClick={this.showFlyout} className="ToolbarSortSwitch__Button" disabled={disabled}>
                  <Icon icon={AuratikumFontIcons.ORDER} />
                </button>
              )}
            </Reference>
            { children }
          </Manager>
        </div>
      </div>
    )
  }
}

export default ToolbarSortSwitch
