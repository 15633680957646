import { logEvent } from 'api'

export default class LogstashAnalyticsTracker {
  async logEvent(appEvent, eventData) {
    try {
      await logEvent({
        event: appEvent,
        data: eventData,
      })
    } catch (e) {
      // ignored
    }
  }
}
