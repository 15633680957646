import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'

import { translate } from 'i18n'

import './style.less'


const TableBody = ({ t, children }) => (
  <tbody>{isEmpty(children) ? <tr><td colSpan="100"><div className="TableBody__Empty">{t('common.emptyTable')}</div></td></tr> : children}</tbody>
)

TableBody.propTypes = {
  t: PropTypes.func.isRequired,
  children: PropTypes.node,
}

export default translate()(TableBody)
