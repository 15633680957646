import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { AuratikumFontIcons } from 'helper/utils'

import Icon from 'components/common/Icon'
import Tag from 'components/common/Tag'
import Spinner from 'components/common/Spinner'

import './style.less'

class ToolbarSearchField extends Component {
  static propTypes = {
    onSearch: PropTypes.func,
    showSuggestions: PropTypes.func,
    query: PropTypes.shape({
      q: PropTypes.string,
      tags: PropTypes.array,
    }),
    isSearching: PropTypes.bool,
    onKeyPress: PropTypes.func,
  }

  static defaultProps = {
    showSuggestions: () => {},
  }

  constructor(props, context) {
    super(props, context)
    this.debouncedUpdate = _.debounce(this.props.onSearch, 500)
  }

  onChange = (e) => {
    const { value } = e.target

    const query = {
      ...this.props.query,
      q: value,
    }
    this.props.onSearch(query)
  }

  handleKeyPress = (e) => {
    if (this.props.query.q === '' && e.keyCode === 8) {
      this.handleTagRemove()
    }

    if (this.props.onKeyPress) {
      this.props.onKeyPress(e)
    }
  }

  handleTagRemove = () => {
    const tags = [...this.props.query.tags]
    tags.pop()
    const query = {
      ...this.props.query,
      tags,
    }
    this.props.onSearch(query)
  }

  handleShowSuggestions = () => {
    this.props.showSuggestions(true)
  }

  handleHideSuggestions = () => {
    this.props.showSuggestions(false)
  }

  render() {
    const { query } = this.props

    return (
      <div className="ToolbarSearchField">
        <div className="ToolbarSearchField__InputGroup">
          <div className="ToolbarSearchField__Addon">
            {this.props.isSearching ? <Spinner /> : <Icon className="ToolbarSearchField__Icon" icon={AuratikumFontIcons.SEARCH} />}
          </div>
          {query && query.tags && query.tags.map(tag => <Tag className="ToolbarSearchField__Tag" key={tag} tag={tag} />)}
          <input onKeyDown={this.handleKeyPress} className="ToolbarSearchField__Input" type="text" onBlur={this.handleHideSuggestions} onFocus={this.handleShowSuggestions} onChange={this.onChange} value={query.q} />
        </div>
      </div>
    )
  }
}

export default ToolbarSearchField
