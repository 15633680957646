import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  Form,
  reduxForm,
  getFormValues,
  propTypes as formPropTypes,
  initialize,
  isDirty as formIsDirty,
} from 'redux-form'
import { debounce, isEqual } from 'lodash'

import EntitySyncQueue from 'api/EntitySyncQueue'

import './style.less'

const identity = x => x

const DEBOUNCE_TIME = 1000

const AutoSaveForm = ({
  form,
  entitySelector,
  upsertEntityAction,
  entityToFormValues = identity,
  formValuesToEntity = identity,
  skipDebounceAttributes = [],
  enableAutoSaveSelector = () => true,
}) => (WrappedComponent) => {
  const entitySyncQueue = new EntitySyncQueue()

  const handleSubmit = async (dispatch, formValues, props) => {
    const { enableAutoSave } = props

    if (enableAutoSave) {
      const entity = formValuesToEntity(formValues)
      const beforeFormValues = entityToFormValues(entity)
      dispatch(initialize(form, beforeFormValues, false))
      const newEntity = await entitySyncQueue.push(entity)
      if (newEntity) {
        const newFormValues = entityToFormValues(newEntity)
        dispatch(initialize(form, newFormValues, true))
      }
    }
  }


  const handleFormChangeDebounced = debounce((dispatch, formValues, props) => handleSubmit(dispatch, formValues, props), DEBOUNCE_TIME)

  const handleFormChange = (formValues, dispatch, props, previousFormValues) => {
    if (!skipDebounceAttributes.every(key => isEqual(formValues[key], previousFormValues[key]))) {
      const processedFormValues = entityToFormValues(formValuesToEntity(formValues))
      dispatch(initialize(form, processedFormValues, false))
    }
    if (formValues.id === previousFormValues.id) {
      handleFormChangeDebounced(dispatch, formValues, props)
    }
  }

  const formConfig = {
    form,
    onChange: handleFormChange,
  }

  class AutoSaveFormHOC extends Component {
    static propTypes = {
      ...formPropTypes,
      entity: PropTypes.shape({}),
      isDirty: PropTypes.bool,
      enableAutoSave: PropTypes.bool,
    }

    constructor(props) {
      super(props)
      entitySyncQueue.action = props.upsertEntity
      entitySyncQueue.subscribe(status => this.setState({ status }))
      this.state = { status: entitySyncQueue.status }
    }

    componentDidUpdate(prevProps) {
      const { entity, reinitialize } = this.props
      if (entity && prevProps && prevProps.entity && entity.id !== prevProps.entity.id) {
        reinitialize(form, entityToFormValues(entity), false)
      }
    }

    componentWillUnmount = () => {
      entitySyncQueue.subscribe(null)
    }

    onSubmit = () => {}

    render = () => {
      const { formValues, isDirty, change } = this.props
      const { status } = this.state
      return (
        <Form className="AutoSaveForm" onSubmit={this.onSubmit}>
          <WrappedComponent
            status={status}
            onRetry={entitySyncQueue.retry}
            formValues={formValues}
            isDirty={isDirty}
            change={change}
          />
        </Form>
      )
    }
  }

  const mapStateToProps = state => ({
    formValues: getFormValues(form)(state),
    initialValues: entityToFormValues(entitySelector(state)),
    entity: entitySelector(state),
    isDirty: formIsDirty(form)(state),
    enableAutoSave: enableAutoSaveSelector(state),
  })
  const mapDispatchToProps = {
    upsertEntity: upsertEntityAction,
    reinitialize: initialize,
  }

  return connect(mapStateToProps, mapDispatchToProps)(reduxForm(formConfig)(AutoSaveFormHOC))
}

export default AutoSaveForm
