import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  reduxForm, Field, propTypes as reduxFormPropTypes, formValueSelector,
} from 'redux-form'
import { required } from 'helper/validators'

import FormTextInput from 'components/common/FormTextInput'
import FormSelectInput from 'components/common/FormSelectInput'

import { propTypes as i18nPropTypes, translate } from 'i18n'

import './style.less'

class ProjectSettingsForm extends Component {
  getSelectOptions = (isInitial) => {
    const initialValue = [{ label: this.props.t('projectSettings.selectForm.select'), value: '' }]
    const options = [
      { value: this.props.t('projectSettings.selectForm.paper') },
      { value: this.props.t('projectSettings.selectForm.article') },
      { value: this.props.t('projectSettings.selectForm.seminarpaper') },
      { value: this.props.t('projectSettings.selectForm.bachelorthesis') },
      { value: this.props.t('projectSettings.selectForm.masterthesis') },
      { value: this.props.t('projectSettings.selectForm.dissertation') },
      { value: this.props.t('projectSettings.selectForm.book') },
      { value: this.props.t('projectSettings.selectForm.habilitation') },
      { value: this.props.t('projectSettings.selectForm.course') },
      { value: this.props.t('projectSettings.selectForm.presentation') },
      { value: this.props.t('projectSettings.selectForm.defense') },
      { value: this.props.t('projectSettings.selectForm.projectplan') },
      { value: this.props.t('projectSettings.selectForm.other') },
    ]

    return isInitial ? initialValue.concat(options) : options
  }

  render() {
    const { handleSubmit, t, projectType } = this.props
    const options = this.getSelectOptions(projectType === undefined)

    return (
      <div className="ProjectSettingsForm">
        <form className="ProjectSettingsForm__Form" onSubmit={handleSubmit}>

          <Field name="projectType" label={t('projectSettings.typeLabel')} options={options} validate={required} component={FormSelectInput} />

          <Field name="title" label={t('projectSettings.titleLabel')} placeholder={t('projectSettings.titlePlaceholder')} validate={required} component={FormTextInput} />
          <Field name="subject" label={t('projectSettings.subjectLabel')} placeholder={t('projectSettings.subjectPlaceholder')} component={FormTextInput} />
        </form>
      </div>
    )
  }
}

ProjectSettingsForm.propTypes = {
  projectType: PropTypes.string,
  ...reduxFormPropTypes,
  ...i18nPropTypes,
}

export const FORM_NAME = 'ProjectSettingsForm'
const form = { form: FORM_NAME, enableReinitialize: true }

const selector = formValueSelector(FORM_NAME)

export default translate()(reduxForm(form)(connect(state => ({ projectType: selector(state, 'projectType') }))(ProjectSettingsForm)))
