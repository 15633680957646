import React from 'react'
import PropTypes from 'prop-types'

import FontIcon from 'components/common/FontIcon'


const Spinner = ({ children, show, ...props }) => (
  show || !children ? <FontIcon icon={FontIcon.Icons.faSpinner} pulse fixedWidth {...props} /> : children
)

Spinner.propTypes = {
  children: PropTypes.node,
  show: PropTypes.bool,
}

export default Spinner
