import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import { get } from 'lodash'
import { push } from 'connected-react-router'
import { Route, Switch, Redirect } from 'react-router-dom'

import { requestExportBib } from 'api'
import { showFlyout } from 'actions/flyout'
import { getReferenceSubView } from 'selectors/path'
import { sortReferences } from 'actions/references'
import { getOrder, getOrderBy } from 'selectors/references'
import { ReferenceSortAttribute } from 'helper/constants'

import Page from 'components/page/Page'
import PageHeader from 'components/page/PageHeader'
import PageContent from 'components/page/PageContent'
import ExtensionHint from 'components/common/ExtensionHint'

import FontIcon from 'components/common/FontIcon'

import { OutlinedButton } from 'components/common/Button'

import ReferenceSearchField from 'containers/references/ReferenceSearchField'
import LibrarySelector from 'containers/references/LibrarySelector'

import { ReferenceLibCrossrefContainer, ReferenceLibPubmedContainer, ReferenceLibGoogleBooksContainer } from 'containers/references/ReferenceLibContainer'
import ReferenceLibUserContainer from 'containers/references/ReferenceLibUserContainer'

import FlyoutReference from 'containers/common/FlyoutReference'
import FlyoutHeader from 'components/common/FlyoutHeader'
import { getSelectedCollection } from 'selectors/reference-collections'
import { ExportButtonWord, ExportButtonBibtex } from 'components/common/ImageButton'
import ToolbarSortSwitch from 'components/common/ToolbarSortSwitch'
import SortFlyout from 'components/common/SortFlyout'

import './style.less'


const SORT_FLYOUT_ID = 'referenceSortFlyout'
const SORT_ATTRIBUTES = [
  { name: ReferenceSortAttribute.CREATED, translationKey: 'common.toolbar.sortFlyoutCreated' },
  { name: ReferenceSortAttribute.UPDATED, translationKey: 'common.toolbar.sortFlyoutUpdated' },
  { name: ReferenceSortAttribute.PERSON, translationKey: 'common.toolbar.sortFlyoutLastname' },
]

class ReferencesPage extends Component {
  static propTypes = {
    push: PropTypes.func,
    match: PropTypes.shape({ path: PropTypes.string }),
    selectedReferenceCollection: PropTypes.shape({}),
    ...i18nPropTypes,
  }

  static defaultProps = {
    selectedReferenceCollection: {},
  }

  state = {
    loadingExport: false,
    showBibExportFlyout: false,
  }

  navigation = [
    { key: 'profile', url: '/profile' },
    { key: 'stats', url: '/profile/stats' },
  ]

  selectTab = tab => this.navigation.filter(n => n.key === tab).forEach(n => this.props.push(n.url))

  exportInFormat = async (format) => {
    const id = get(this.props, 'selectedReferenceCollection.id')
    try {
      this.setState({ loadingExport: true })
      await requestExportBib(format, id)
      this.setState({ loadingExport: false })
    } catch (error) {
      this.setState({ loadingExport: false })
      alert('Fehler beim Bibtext Export!')
    }
  }

  exportBibtex = (e) => {
    e.preventDefault()
    this.exportInFormat('tex')
  }

  exportMSWord = (e) => {
    e.preventDefault()
    this.exportInFormat('docx')
  }

  navigateToImport = () => this.props.push('/profile/import')

  handleShowBibExportFlyout = () => this.setState(state => ({ showBibExportFlyout: !state.showBibExportFlyout }))

  handleHideFlyout = () => this.setState({ showBibExportFlyout: false })

  handleTabSelect = value => this.props.push(`/references/${value}`)

  renderFlyoutContent = () => {
    const { t, selectedReferenceCollection } = this.props
    return (
      <React.Fragment>
        <FlyoutHeader title={t('references.exportBib')} />
        <div className="ReferencesPage__FlyoutTitle">{selectedReferenceCollection ? t('references.exportBibTitle', { name: selectedReferenceCollection.name }) : t('references.exportBibTitleAll')}</div>
        <div className="ReferencesPage__FlyoutButtonContainer">
          <ExportButtonWord onClick={this.exportMSWord} />
          <ExportButtonBibtex onClick={this.exportBibtex} />
        </div>
      </React.Fragment>
    )
  }

  renderSortSwitch = () => {
    const {
      showFlyout: showFlyoutAction, sortReferences: sortReferencesAction, referenceOrderBy, referenceOrder,
    } = this.props
    return (
      <ToolbarSortSwitch
        showFlyout={showFlyoutAction}
        flyoutId={SORT_FLYOUT_ID}
      >
        <SortFlyout
          id={SORT_FLYOUT_ID}
          attributes={SORT_ATTRIBUTES}
          onSelect={sortReferencesAction}
          orderBy={referenceOrderBy}
          order={referenceOrder}
        />
      </ToolbarSortSwitch>
    )
  }

  render() {
    const { match, subView, t } = this.props
    return (
      <Page className="ReferencesPage">
        <PageHeader className="ReferencesPage__Header">
          <ExtensionHint shouldDelayRendering />
          <div className="ReferencesPage__HeaderActions">
            <OutlinedButton
              circleModifier="circleMedium"
              className="ReferencesPage__ActionButton"
              onClick={this.navigateToImport}
              title={t('common.import')}
            >
              <FontIcon icon={FontIcon.Icons.faUpload} />
            </OutlinedButton>
            <FlyoutReference
              flyoutClassName="ReferencesPage__BibExportFlyout"
              showFlyout={this.state.showBibExportFlyout}
              placement="bottom"
              component={this.renderFlyoutContent()}
              onOutsideClick={this.handleHideFlyout}
            >
              <OutlinedButton
                circleModifier="circleMedium"
                className="ReferencesPage__ActionButton"
                onClick={this.handleShowBibExportFlyout}
                isLoading={this.state.loadingExport}
                title="BibTex Download"
              >
                <FontIcon icon={FontIcon.Icons.faDownload} />
              </OutlinedButton>
            </FlyoutReference>
          </div>
        </PageHeader>
        <PageContent className="ReferencesPage__Container">
          <div className="ReferencesPage__Searchbar">
            <ReferenceSearchField shouldGrow />
            <Switch>
              <Route path={`${match.path}/lib`} component={this.renderSortSwitch} />
            </Switch>
          </div>
          <LibrarySelector
            className="ReferencesPage__LibrarySelector"
            selectedValue={subView}
            onSelect={this.handleTabSelect}
          />

          <Switch>
            <Route path={`${match.path}/lib`} component={ReferenceLibUserContainer} />
            <Route path={`${match.path}/crossref`} component={ReferenceLibCrossrefContainer} />
            <Route path={`${match.path}/google-books`} component={ReferenceLibGoogleBooksContainer} />
            <Route path={`${match.path}/pubmed`} component={ReferenceLibPubmedContainer} />

            <Redirect to={`${match.path}/lib`} />
          </Switch>
        </PageContent>
      </Page>
    )
  }
}

const mapStateToProps = state => ({
  subView: getReferenceSubView(state) || 'lib',
  selectedReferenceCollection: getSelectedCollection(state),
  referenceOrderBy: getOrderBy(state),
  referenceOrder: getOrder(state),
})

export default connect(mapStateToProps, {
  push,
  showFlyout,
  sortReferences,
})(translate()(ReferencesPage))
