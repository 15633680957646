import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { propTypes as i18nPropTypes, translate } from 'i18n'

import Tag from 'components/common/Tag'
import TagSelector from 'selectors/tags'
import NoteSelector from 'selectors/notes'
import ProjectSelector from 'selectors/projects'
import ReferenceSelector from 'selectors/references'

import './style.less'

const StatsContainer = ({
  tags, projectCount, noteCount, referenceCount, t,
}) => (
  <div className="StatsContainer">
    <div className="StatsContainer__Wrapper">
      <div className="StatsContainer__BoxTitle">
        {t('statistics.numbers')}
      </div>
      <div className="StatsContainer__Box">
        <div className="StatsContainer__BoxRow">
          <div className="StatsContainer__MetricStat">
            <div className="StatsContainer__Number">{noteCount}</div>
            <div className="StatsContainer__Text">{t('statistics.note', { count: noteCount })}</div>
          </div>

          <div className="StatsContainer__MetricStat">
            <div className="StatsContainer__Number">{projectCount}</div>
            <div className="StatsContainer__Text">{t('statistics.project', { count: projectCount })}</div>
          </div>

          <div className="StatsContainer__MetricStat">
            <div className="StatsContainer__Number">{referenceCount}</div>
            <div className="StatsContainer__Text">{t('statistics.reference', { count: referenceCount })}</div>
          </div>

          <div className="StatsContainer__MetricStat">
            <div className="StatsContainer__Number">{tags.length}</div>
            <div className="StatsContainer__Text">{t('statistics.tag', { count: tags.length })}</div>
          </div>
        </div>
      </div>
      <div className="StatsContainer__BoxTitle">
        {t('statistics.allusedtags')}
      </div>
      <div className="StatsContainer__Box">
        <div className="StatsContainer__BoxRow">
          { tags.map(tag => <Tag className="StatsContainer__Tag" key={tag.tag} tag={tag.tag} />) }
        </div>
      </div>
    </div>

  </div>
)

StatsContainer.propTypes = {
  tags: PropTypes.array,
  projectCount: PropTypes.number,
  noteCount: PropTypes.number,
  referenceCount: PropTypes.number,
  ...i18nPropTypes,
}


const mapStateToProps = state => ({
  tags: TagSelector.getAll(state),
  noteCount: NoteSelector.getCount(state),
  projectCount: ProjectSelector.getCount(state),
  referenceCount: ReferenceSelector.getCount(state),
})


export default connect(mapStateToProps)(translate()(StatsContainer))
