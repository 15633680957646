import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import { Field } from 'redux-form'
import { get } from 'lodash'


import { OutlinedButton } from 'components/common/Button'
import FontIcon from 'components/common/FontIcon'
import Icon from 'components/common/Icon'
import ReferenceAttributeInput, { ReferenceAttributeSelect } from 'components/reference-detail/ReferenceAttributeInput'
import ReferenceTypeSwitch from 'components/reference-detail/ReferenceTypeSwitch'

import { AuratikumFontIcons } from 'helper/utils'


import './style.less'


class RolesFieldArray extends Component {
  types = ['person', 'organization']

  i18n = 'references'

  addRole = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.props.fields.push({
      family: '', given: '', type: 'person', role: this.props.validRoles[0],
    })
  }

  removeRole = (e, index) => {
    e.preventDefault()
    e.stopPropagation()
    this.props.fields.remove(index)
  }

  handleTypeChange = (event, newValue, previousValue, name) => {
    // get field index to clean literal if value is 'person'
    const index = get(name.match(/\d+/g), [0], -1)
    if (index >= 0) {
      if (newValue === 'person') this.props.change(`roles[${index}].literal`, '')
      if (newValue === 'organization') {
        this.props.change(`roles[${index}].family`, '')
        this.props.change(`roles[${index}].given`, '')
      }
    }
  }

  renderNameField = (path, name, translationKey) => (
    <Field
      className="RolesFieldArray__NameField"
      name={`${path}.${name}`}
      placeholder={this.props.t(`${this.i18n}.${translationKey}`)}
      component={ReferenceAttributeInput}
      readOnly={this.props.readOnly}
    />
  )

renderRoleField = (path, key, role) => (
  <div key={key} className="RolesFieldArray__RoleField">
    <Field
      className="RolesFieldArray__RoleSelect"
      name={`${path}.role`}
      options={this.props.validRoles.map(validRole => (
        {
          value: validRole,
          label: this.props.t(`references:types.${this.props.formValues.type}.${validRole}`),
        }))}
      component={ReferenceAttributeSelect}
      readOnly={this.props.readOnly}
    />
    <Field
      name={`${path}.type`}
      options={this.types.map(t => ({ value: t, label: this.props.t(`${this.i18n}.${t}`) }))}
      component={ReferenceTypeSwitch}
      readOnly={this.props.readOnly}
      onChange={this.handleTypeChange}
    />
    { role && role.type && role.type.indexOf('organization') < 0
      ? (
        <React.Fragment>
          {this.renderNameField(path, 'given', 'givenName')}
          {this.renderNameField(path, 'family', 'familyName')}
        </React.Fragment>
      )
      : this.renderNameField(path, 'literal', 'organization')
      }
    { !this.props.readOnly && (
    <button type="button" className="RolesFieldArray__RoleFieldRemoveButton" onClick={e => this.removeRole(e, key)}>
      <FontIcon icon={FontIcon.Icons.faTimes} />
    </button>
    ) }
  </div>
)

render = () => {
  const {
    fields, formValues, readOnly, t,
  } = this.props
  if (!formValues) return null

  return (
    <div className="RolesFieldArray">
      <div className="RolesFieldArray__Header">
        <div className="RolesFieldArray__RoleSelect">{t('references.role')}</div>
        <div className="RolesFieldArray__HeaderType">{t('common.type')}</div>
        <div className="RolesFieldArray__HeaderName">{t('common.name')}</div>
      </div>
      { fields.map((field, index) => this.renderRoleField(field, index, get(formValues, field)))}
      { !readOnly && (
        <OutlinedButton className="RolesFieldArray__CreateButton" onClick={this.addRole}>
          <Icon className="RolesFieldArray__CreateButtonIcon" icon={AuratikumFontIcons.ADD} />
          {t('references.createRole')}
        </OutlinedButton>
      )}
    </div>
  )
}
}

RolesFieldArray.propTypes = {
  ...i18nPropTypes,
  fields: PropTypes.shape({}),
  formValues: PropTypes.shape({}),
  readOnly: PropTypes.bool,
}

export default translate()(RolesFieldArray)
