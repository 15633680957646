import { createAction } from 'redux-actions'
import * as api from 'api'
import { get } from 'lodash'

import { requestNotes } from 'actions/notes'
import { requestReferences } from 'actions/references'
import { requestTags } from 'actions/tags'
import { requestProjects } from 'actions/projects'
import { requestProjectItems } from 'actions/project-items'

export const receivedImportResponse = createAction('RECEIVED_IMPORT_RESPONSE')
export const resetImport = createAction('RESET_IMPORT')

const IMPORT_MAX_FILE_SIZE = 15 * 1024 * 1024

export const importFiles = files => async (dispatch) => {
  const { fileId } = await api.uploadFileToS3(files[0], { maxSize: IMPORT_MAX_FILE_SIZE, documentType: 'import' })
  const payload = await api.getImportPreview(fileId)
  await dispatch(receivedImportResponse(payload))
}

export const importData = data => async (dispatch) => {
  await api.importData(data)
  await dispatch(requestNotes())
  await dispatch(requestReferences())
  await dispatch(requestTags())


  const payload = await dispatch(requestProjects())
  const ids = Object.keys(get(payload, 'payload.entities.projects', []))
  await Promise.all(ids.map(id => dispatch(requestProjectItems(id))))
}
