import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Tag from 'components/common/Tag'
import FontIcon from 'components/common/FontIcon'

import './style.less'

class NotePreview extends Component {
  constructor() {
    super()
    this.state = {
      expanded: false,
    }
  }

  toggleExpand = () => {
    this.setState({ expanded: !this.state.expanded })
  }

  renderReference() {
    const { entity } = this.props
    if (!entity.referenceId) return <div />
    return (
      <div className="NotePreview__Reference">
Referenz-ID:
        {entity.referenceId}
      </div>
    )
  }

  renderTags() {
    const { entity } = this.props
    if (entity.tags && entity.tags.length > 0) {
      return entity.tags.map(tag => <Tag key={tag} tag={tag} />)
    }
    return <div />
  }

  render() {
    const { entity, className } = this.props

    return (
      <div className={`${className} NotePreview`}>
        <div className="NotePreview__Title" onClick={this.toggleExpand}>
          { entity.shouldOverride && <FontIcon className="ImportContainer__OverrideIcon" icon={FontIcon.Icons.faPencil} /> }
          { entity.isNew && <FontIcon className="ImportContainer__AddIcon" icon={FontIcon.Icons.faPlus} /> }
          { <FontIcon icon={this.state.expanded ? FontIcon.Icons.faCaretDown : FontIcon.Icons.faCaretRight} /> }
          {entity.title}
        </div>
        { this.state.expanded && (
        <div className="NotePreview__Details">
          <div className="NotePreview__Tags">{ this.renderTags() }</div>
          <div dangerouslySetInnerHTML={{ __html: entity.content }} />
          {this.renderReference()}
        </div>
        )
        }
      </div>
    )
  }
}


NotePreview.propTypes = {
  className: PropTypes.string,
  entity: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.string,
    referenceId: PropTypes.string,
    reference: PropTypes.shape({}),
    color: PropTypes.string,
  }),

}

export default NotePreview
