import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { HotKeys } from 'react-hotkeys'

import { hideModal } from 'actions/modal'
import ModalSelector from 'selectors/modal'
import { handleHotkey } from 'helper/eventHandler'
import { AuratikumFontIcons } from 'helper/utils'

import Icon from 'components/common/Icon'
import './style.less'

const modalRoot = document.getElementById('root-modal')

export default (WrappedComponent) => {
  const Modal = class Modal extends Component {
    static propTypes = {
      id: PropTypes.string.isRequired,
      className: PropTypes.string,
      onCancel: PropTypes.func,
      hide: PropTypes.func,
      isVisible: PropTypes.bool,
      disableCloseButton: PropTypes.bool,
    }

    static defaultProps = {
      className: '',
      title: '',
      children: null,
      disableCloseButton: false,
      hotKeyHandlers: {},
    }

    constructor(props) {
      super(props)
      this.state = {}
      this.el = document.createElement('div')
      modalRoot.className = 'FullScreenModal'
      this.handlers = {
        escape: (event) => {
          if (!this.props.disableCloseButton) { handleHotkey(this.handleClose)(event) }
        },
      }
    }

    componentDidMount() {
    // The portal element is inserted in the DOM tree after
    // the Modal's children are mounted, meaning that children
    // will be mounted on a detached DOM node. If a child
    // component requires to be attached to the DOM tree
    // immediately when mounted, for example to measure a
    // DOM node, or uses 'autoFocus' in a descendant, add
    // state to Modal and only render the children when Modal
    // is inserted in the DOM tree.
      modalRoot.appendChild(this.el)
    }

    componentWillUnmount() {
      modalRoot.removeChild(this.el)
    }

    attachHotKeyHandlers = (newHandlers) => {
      this.handlers = {
        ...this.handlers,
        ...newHandlers,
      }
      this.setState({}) // dummy update to refresh the hotkey handlers
    }

    handleClose = () => {
      const { onCancel, hide, id } = this.props
      if (onCancel) onCancel()
      else hide(id)
    }

    hide = () => {
      this.props.hide(this.props.id)
    }

    render() {
      const { isVisible, className, disableCloseButton } = this.props
      if (!isVisible) return null

      return ReactDOM.createPortal(
        <div className={`Modal ${className}`}>
          <HotKeys focused attach={window} handlers={this.handlers} className="Modal__HotKeys">
            { !disableCloseButton && (
            <button className="Modal__CloseButton" onClick={this.handleClose}>
              <Icon icon={AuratikumFontIcons.CLOSE} className="Modal__CloseButtonIcon" />
            </button>
            )}
            <div className="Modal__Content">
              <WrappedComponent
                {...this.props}
                attachHotKeyHandlers={this.attachHotKeyHandlers}
                onCancel={this.handleClose}
                hide={this.hide}
              />
            </div>
          </HotKeys>
        </div>,
        this.el,
      )
    }
  }

  const mapStateToProps = (state, ownProps) => ({
    isVisible: ModalSelector.isVisible(state, ownProps.id) || (ownProps.isVisible !== undefined),
    content: ModalSelector.getContent(state, ownProps.id) || ownProps.text,
    data: ModalSelector.getData(state, ownProps.id) || {},
  })

  return connect(mapStateToProps, {
    hide: hideModal,
  })(Modal)
}
