import { handleActions } from 'redux-actions'

import { receivePaymentData } from 'actions/subscriptions'

const reducer = handleActions({
  [receivePaymentData]: (state, action) => ({
    paymentData: action.payload,
  }),
}, {})

export default reducer
