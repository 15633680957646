import { values, sortBy } from 'lodash'
import { createSelector } from 'reselect'

export const selector = state => state.tag

export const getAll = createSelector(
  selector,
  tags => sortBy(tags, ['tag']),
)

export default {
  getAll,
}
