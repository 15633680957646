import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import {
  reduxForm, SubmissionError, Field, propTypes as reduxPropTypes, getFormValues,
} from 'redux-form'
import sbjs from 'sourcebuster'
import i18n, { propTypes as i18nPropTypes, translate } from 'i18n'
import { Trans } from 'react-i18next'
import { PrimaryButton } from 'components/common/Button'
import { register } from 'actions/authentication'
import queryString from 'query-string'

const b64DecodeUnicode = str => decodeURIComponent(atob(str).split('').map(c => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''))


class Register extends Component {
  submit = async (values) => {
    try {
      let trafficSource

      try {
        trafficSource = {
          sourcebuster: {
            ...sbjs.get.first,
            ...sbjs.get.first_add,
            ...sbjs.get.udata,
          },
        }
      } catch (e) {
        // ignore possible sourcebuster errors and ensure registration goes through
      }
      const search = queryString.parse(this.props.location.search)
      await this.props.register(values, search.token, trafficSource)
    } catch (err) {
      throw new SubmissionError({ _error: err.code })
    }
  }

  render() {
    const {
      handleSubmit, submitting, error, t,
    } = this.props

    return (
      <React.Fragment>
        <div className="AuthForm__Title">{t('auth.register.title')}</div>
        <div className="AuthForm__Description AuthForm__Description--unimportant">{t('auth.register.intro')}</div>
        { error && <div className="AuthForm__Alert">{ this.props.t(`auth.errors.${error}`) || this.props.t('auth.errors.default') }</div> }
        <form className="AuthForm__Form" onSubmit={handleSubmit(this.submit)}>
          <Field className="AuthForm__Input" name="email" component="input" type="text" placeholder={t('common.email')} />
          <Field className="AuthForm__Input AuthForm__Input--single" name="password" component="input" type="password" placeholder={t('common.password')} />
          {/* <Field name="language" component={FormLanguageSelectorInput} /> */}
          <PrimaryButton className="AuthForm__SubmitButton" type="submit" isLoading={submitting}>{t('common.register')}</PrimaryButton>
        </form>
        <div className="AuthForm__Description">
          {/*eslint-disable */}
          <Trans i18nKey="auth.register.check">
            With your registration you agree to <a className="AuthForm__Check" href="https://auratikum.de/datenschutz">Data Protection</a> and <a className="AuthForm__Check" href="https://auratikum.de/allgemeine-geschaeftsbedingungen">AGB</a> of Auratikum. Registration and use is free and without obligation.
          </Trans>
          {/* eslint-enable */}
        </div>
        <div className="AuthForm__Description AuthForm__Description--unimportant">{t('auth.register.help')}</div>
        <NavLink to="/login" className="AuthForm__Link">{t('auth.register.hint')}</NavLink>
      </React.Fragment>
    )
  }
}

const form = {
  form: 'Register',
  enableReinitialize: true,
}

Register.propTypes = {
  ...reduxPropTypes,
  ...i18nPropTypes,
  register: PropTypes.func,
}

const mapStateToProps = (state, ownProps) => {
  const query = queryString.parse(ownProps.location.search)

  const formValues = getFormValues(form.form)(state) || {}
  let email = formValues.email || query.email
  let password = formValues.password || ''
  let shouldSubmit = false
  if (query.data) {
    const decoded = b64DecodeUnicode(query.data)
    const values = decoded.split(',')
    email = values[0]
    password = values[1]
    shouldSubmit = true
  }
  let { language } = formValues
  if (!language && i18n.language) {
    language = i18n.language.includes('de') ? 'de' : 'en'
  }
  return {
    initialValues: {
      email,
      password,
      language,
    },
    formValues,
    shouldSubmit,
  }
}

export default connect(mapStateToProps, {
  register,
})(reduxForm(form)(translate()(Register)))
