import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Trans } from 'react-i18next'
import { translate } from 'i18n'
import { connect } from 'react-redux'
import { debounce, values } from 'lodash'

import { handleHotkey } from 'helper/eventHandler'
import { createReferenceFromFile, createModalReference, searchReferences } from 'actions/references'
import { updateNote } from 'actions/notes'
import { push } from 'connected-react-router'
import Spinner from 'components/common/Spinner'
import ExtensionHint from 'components/common/ExtensionHint'

import ModalHeader from 'components/common/ModalHeader'
import Modal from 'containers/modal/Modal'
import { PrimaryButton } from 'components/common/Button'
import FileDrop from 'components/common/FileDrop'
import { createReferenceFromData } from 'api'
import tracker from 'tracking/tracker'

import './style.less'

const resetState = {
  isLoadingInputCheck: false,
  isLoadingInputCreate: false,
  inputError: null,
  resultReference: null,
  resultType: null,
}

const i18nButtonMap = {
  WEBPAGE: 'WEBPAGE',
}

export const ADD_REFERENCE_MODAL_ID = 'ADD_REFERENCE_MODAL_ID'

class AddReferenceModal extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    hide: PropTypes.func,
    attachHotKeyHandlers: PropTypes.func,
    push: PropTypes.func,
    createReferenceFromFile: PropTypes.func,
    createModalReference: PropTypes.func,
    searchReferences: PropTypes.func,
  }

  state = {
    ...resetState,
    referenceText: '',
    isLoadingManually: false,
    isUploadingFile: false,
    uploadError: null,
  }

  checkReferenceText = debounce(async (value) => {
    if (!value || value.length === 0) {
      this.setState({ ...resetState, isLoadingInputCheck: false })
      return
    }
    this.setState({ ...resetState, isLoadingInputCheck: true })
    try {
      const { result, type } = await createReferenceFromData({ value })
      const ref = values(result.entities.references)[0]
      tracker.logEvent('ADD_REFERENCE_LOOKUP', { value, id: ref.id })
      this.setState({ resultReference: ref, resultType: type })
    } catch (error) {
      if (this.state.referenceText.length > 0) {
        tracker.logEvent('ADD_REFERENCE_LOOKUP_ERROR', { value: this.state.referenceText })
        this.setState({ inputError: error.message })
      }
    }
    this.setState({ isLoadingInputCheck: false })
  }, 400)

  constructor(props) {
    super(props)
    this.props.attachHotKeyHandlers({
      escape: handleHotkey(this.handleCancel),
    })
  }


  onChangeReferenceText = (event) => {
    const { value } = event.target
    this.setState({ ...resetState, referenceText: value })
    this.checkReferenceText(value)
  }

  onCreateManually = async () => {
    try {
      this.setState({ isLoadingManually: true })
      const reference = await this.props.createModalReference({ type: 'article' })
      tracker.logEvent('CREATE_REFERENCE', { creation: 'MANUAL', id: reference.id })
      this.props.push(`/references/lib/${reference.id}`)
      this.props.hide()
    } catch (err) {
      this.setState({ isLoadingManually: false })
    }
  }


  handleDrop = async (files) => {
    this.setState({ isUploadingFile: true, uploadError: null })
    try {
      const reference = await this.props.createReferenceFromFile(files[0])
      tracker.logEvent('CREATE_REFERENCE', { creation: 'FILE', id: reference.id })
      this.props.push(`/references/lib/${reference.id}`)
      this.props.hide()
    } catch (error) {
      if (!(error instanceof TypeError)) {
        this.setState({ uploadError: error.message, isUploadingFile: false })
      } else {
        this.setState({ uploadError: 'UNKNOWN', isUploadingFile: false })
      }
    }
  }

  handleCreateReference = async () => {
    const { resultReference, resultType } = this.state
    try {
      this.setState({ isLoadingInputCreate: true })
      const reference = await this.props.createModalReference({
        ...resultReference,
      })
      tracker.logEvent('CREATE_REFERENCE', { creation: `INPUT_${resultType}`, id: reference.id })
      this.props.push(`/references/lib/${reference.id}`)
      this.props.hide()
    } catch (err) {
      this.setState({ inputError: err.message, isLoadingInputCreate: false })
    }
  }

  handleOpenReference = async () => {
    const { resultReference } = this.state
    this.props.push(`/references/lib/${resultReference.id}`)
    this.props.hide()
  }

  handleCancel = () => {
    this.props.hide()
  }

  handleSearchClick = () => {
    tracker.logEvent('ADD_REFERENCE_SEARCH', { value: this.state.referenceText })
    this.props.searchReferences(this.state.referenceText)
    this.props.push('/references/crossref')
    this.props.hide()
  }

  renderResultContent() {
    const { t } = this.props
    const {
      isLoadingInputCheck, isLoadingInputCreate, inputError, resultType, resultReference,
    } = this.state
    let content = <PrimaryButton disabled>{t('references.modal.insertButton.default')}</PrimaryButton>
    if (isLoadingInputCheck) content = <Spinner className="AddReferenceModal__TextAreaLoading" />
    if (inputError) {
      content = (
        <div className="AddReferenceModal__Error">
          <Trans i18nKey="references.modal.errorExtractingReference">
            <div> No information could be extracted or found from the given information.</div>
            <button type="button" onClick={this.handleSearchClick} className="AddReferenceModal__ErrorSearch">Click to search for it</button>
          </Trans>
        </div>
      )
    }
    if (resultType) {
      const i18nKey = i18nButtonMap[resultType] || 'default'
      content = (
        <PrimaryButton
          isLoading={isLoadingInputCreate}
          onClick={resultReference.id ? this.handleOpenReference : this.handleCreateReference}
        >
          {t(`references.modal.insertButton.${resultReference.id ? 'open' : i18nKey}`)}
        </PrimaryButton>
      )
    }
    return (
      <React.Fragment>
        {resultReference && resultReference.id && <div className="AddReferenceModal__Error">{t('references.modal.referenceAlreadyExists')}</div>}
        <div className="AddReferenceModal__TextAreaResult">{content}</div>
      </React.Fragment>
    )
  }

  render() {
    const { t } = this.props
    const {
      isLoadingManually, referenceText,
      isUploadingFile, uploadError,
    } = this.state

    return (
      <div className="AddReferenceModal">
        <ModalHeader title={t('editor.addReference')} />
        <div className="AddReferenceModal__ContentWrapper">
          <div className="AddReferenceModal__Content">
            <div className="AddReferenceModal__Section">
              <div className="AddReferenceModal__SectionTitle">{t('references.modal.insertTitle')}</div>
              <ExtensionHint i18nKey="browserExtension.referenceHintDescription" className="AddReferenceModal__ExtensionHint" />
              <textarea className="AddReferenceModal__TextArea" value={referenceText} onChange={this.onChangeReferenceText} />
              { this.renderResultContent() }
            </div>
            <div className="AddReferenceModal__Separator">{t('references.modal.or')}</div>
            <div className="AddReferenceModal__Section">
              <div className="AddReferenceModal__SectionTitle">{t('references.modal.insertDocument')}</div>
              <FileDrop
                className="AddReferenceModal__FileDrop"
                onDrop={this.handleDrop}
                isLoading={isUploadingFile}
                text={t('references.files.fileFieldText')}
                textDrop={t('references.files.fileFieldTextDrop')}
              />
              { uploadError && <div className="AddReferenceModal__Error">{uploadError !== 'UNKNOWN' ? uploadError : t('references.modal.errorUploadDocument')}</div> }
            </div>
            <div className="AddReferenceModal__Separator">{t('references.modal.or')}</div>
            <PrimaryButton isLoading={isLoadingManually} onClick={this.onCreateManually}>{t('references.modal.add')}</PrimaryButton>
          </div>
        </div>
      </div>
    )
  }
}

export default Modal(connect(null, {
  createReferenceFromFile,
  createModalReference,
  searchReferences,
  push,
  updateNote,
})(translate()(AddReferenceModal)))
