import i18n from 'i18n'
import { get } from 'lodash'

const PLUGIN_NAME = 'aur-citations'
const CITATION_ID_ATTRIBUTE_PATH = 'attributes.data-citation-id.value'

const CitationPlugin = (editor) => {
  const showInsertCitationFlyout = (options) => {
    editor.execCallback('citation_showInsertCitationFlyout', editor, options)
  }

  editor.ui.registry.addIcon(PLUGIN_NAME, '<svg xmlns="http://www.w3.org/2000/svg" width="17" height="27" viewBox="0 0 24 27"><g fill="none" fill-rule="evenodd"><path fill="none" d="M-1279-316h5502v3900h-5502z"/><path stroke="#05ce64" d="M-216.5-115.5h862v638h-862z"/><path fill="#05ce64" fill-rule="nonzero" d="M17.352 26.13l-8.68-5.788L0 26.13V2.089C0 .82.745 0 2.148 0h15.993c-.4.64-.497.87-.615 1.22-.119.348-.143.691-.153.812-.016.121-.021.247-.021.373v23.726zm5.787-15.61l-4.244-.115V2.518c0-.178.021-.352.053-.52.162-.809.464-1.366 1.189-1.718.032-.016.063-.037.1-.052.1-.042.164-.07.264-.102a2.45 2.45 0 0 1 .739-.108c1.177.01 1.9.896 1.9 2.163v8.339z"/></g></svg>')

  editor.ui.registry.addButton('addCitation', {
    icon: PLUGIN_NAME,
    tooltip: i18n.t('editor.addReference'),
    onAction: showInsertCitationFlyout,
  })


  editor.on('keypress', (e) => {
    if (e.which === 64) {
      e.stopPropagation()
      e.preventDefault()
      showInsertCitationFlyout()
    }
  })

  editor.on('Click', (event) => {
    const { target } = event

    let citationId = get(target, CITATION_ID_ATTRIBUTE_PATH) || get(target, `parentElement.${CITATION_ID_ATTRIBUTE_PATH}`)

    if (!citationId) {
      return
    }
    if (editor.settings.projectItemId) citationId = `${citationId}-${editor.settings.projectItemId}`
    showInsertCitationFlyout({ citationId })
  })
}

export default () => {
  window.tinymce.PluginManager.add(PLUGIN_NAME, editor => CitationPlugin(editor))
}
