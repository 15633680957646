import { handleActions } from 'redux-actions'
import { without } from 'lodash'

import {
  expandCollapseProjectItem,
  expandProjectItems,
  collapseProjectItems,
  selectProjectItemForMoveAndDropAction,
} from 'actions/project-items'


const reducer = handleActions({
  LOAD_LOCALSTORAGE: (state, action) => ({
    ...state,
    expandedProjectItems: action.payload.expandedProjectItems || [],
  }),
  [expandCollapseProjectItem]: (state, action) => {
    let expandedProjectItems = [...state.expandedProjectItems]

    if (expandedProjectItems.indexOf(action.payload) >= 0) {
      expandedProjectItems = without(expandedProjectItems, action.payload)
    } else {
      expandedProjectItems.push(action.payload)
    }
    return {
      ...state,
      expandedProjectItems,
    }
  },
  [expandProjectItems]: (state, action) => {
    const expandedProjectItems = [...state.expandedProjectItems]
    action.payload.forEach((itemId) => {
      if (expandedProjectItems.indexOf(itemId) < 0) {
        expandedProjectItems.push(itemId)
      }
    })
    return { ...state, expandedProjectItems }
  },
  [collapseProjectItems]: (state, action) => {
    let expandedProjectItems = [...state.expandedProjectItems]
    action.payload.forEach((itemId) => {
      if (expandedProjectItems.indexOf(itemId) >= 0) {
        expandedProjectItems = without(expandedProjectItems, itemId)
      }
    })
    return { ...state, expandedProjectItems }
  },
  [selectProjectItemForMoveAndDropAction]: (state, action) => ({ ...state, selectedProjectItemIdForMoveAndDrop: action.payload }),
}, {
  expandedProjectItems: [],
})

export default reducer
