import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'

import { translate } from 'i18n'
import { sendInvitationEmails } from 'actions/profiles'
import ProfileSelector from 'selectors/profile'
import modifyClassName from 'helper/modifyClassName'
import { splitEmails } from 'helper/utils'

import InviteLink from 'components/common/InviteLink'
import Input from 'components/common/Input'
import { PrimaryButton } from 'components/common/Button'

import './style.less'


class ReferralInviteForm extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    sendInvitationEmails: PropTypes.func.isRequired,
    inviteLink: PropTypes.string,
    className: PropTypes.string,
    embedded: PropTypes.bool,
    inviteText: PropTypes.string,
    invitePlaceholder: PropTypes.string,
  }

  static defaultProps = {
    className: '',
    embedded: false,
  }

  state = {
    inputValue: '',
    isLoading: false,
  }

  submitInvitations = async () => {
    const emails = splitEmails(this.state.inputValue)
    if (isEmpty(emails)) {
      return
    }
    const { sendInvitationEmails: sendInvitations } = this.props
    this.setState({ isLoading: true })
    try {
      await sendInvitations(emails)
      this.setState({ isLoading: false, inputValue: '' })
    } catch (err) {
      this.setState({ isLoading: false })
    }
  }

  render() {
    const {
      t, inviteLink, className, embedded, inviteText, invitePlaceholder,
    } = this.props
    return (
      <div className={className}>
        <div className="ReferralInviteForm">
          <div className="ReferralInviteForm__Controls">
            <Input
              className="ReferralInviteForm__Input"
              placeholder={invitePlaceholder || t('referral.enterEmails')}
              value={this.state.inputValue}
              onChange={e => this.setState({ inputValue: e.target.value })}
              underlined={embedded}
              autoFocus
            />
            <PrimaryButton
              className={modifyClassName('ReferralInviteForm__Button', { embedded })}
              onClick={this.submitInvitations}
              isLoading={this.state.isLoading}
            >
              {t('common.invite')}
            </PrimaryButton>
          </div>
        </div>
        <div className="ReferralInviteForm__Text">
          {inviteText || t('referral.inviteText')}
          <InviteLink className="ReferralInviteForm__Link" link={inviteLink} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  inviteLink: ProfileSelector.getInviteLink(state),
})

export default connect(mapStateToProps, {
  sendInvitationEmails,
})(translate()(ReferralInviteForm))
