import React from 'react'
import PropTypes from 'prop-types'

import FontIcon from 'components/common/FontIcon'
import './style.less'

const ProjectPreview = ({ entity, className }) => (
  <div className={`${className} ProjectPreview`}>
    { entity.shouldOverride && <FontIcon className="ImportContainer__OverrideIcon" icon={FontIcon.Icons.faPencil} /> }
    { entity.isNew && <FontIcon className="ImportContainer__AddIcon" icon={FontIcon.Icons.faPlus} /> }
    {entity.title}
  </div>
)


ProjectPreview.propTypes = {
  entity: PropTypes.shape({}),
  className: PropTypes.string,
}

export default ProjectPreview
