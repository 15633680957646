import { createSelector } from 'reselect'
import { get, values } from 'lodash'
import { getProfile } from 'selectors/profile'
import { getNoneInitialCount as getProjectCount, getMyProjects } from 'selectors/projects'
import { getNoneInitialCount as getNoteCount } from 'selectors/notes'
import { getNoneInitialCount as ReferenceCount } from 'selectors/references'

const getChecklistIsVisible = state => state.onboarding.checklistIsVisible

const projectItemsSelector = state => get(state, 'entities.projectItems', {})

const getTourStep = createSelector(
  getProfile,
  profile => get(profile, 'tourStep', 'FINISHED'),
)


const checklistItemRegister = createSelector(
  getProjectCount,
  () => ({ key: 'REGISTER_USER', completed: true }),
)


const checklistItemCreateProject = createSelector(
  getProjectCount,
  count => ({
    key: 'CREATE_PROJECT',
    completed: count > 0,
    linkPath: '/projects',
  }),
)

const checklistItemCreateNote = createSelector(
  getNoteCount,
  getMyProjects,
  (count, projects) => ({
    key: 'CREATE_NOTES',
    completed: count > 1,
    linkPath: projects.length > 0 ? `/projects/${projects[0].id}/structure` : null,
  }),
)

const checklistItemCreateReference = createSelector(
  ReferenceCount,
  count => ({
    key: 'CREATE_REFERENCE',
    completed: count > 0,
    linkPath: '/references',
  }),
)

const checklistItemCreateHeading = createSelector(
  projectItemsSelector,
  getMyProjects,
  (projectItems, projects) => ({
    key: 'CREATE_HEADING',
    completed: values(projectItems).filter(item => !item.isInitial && item.type === 'HEADING').length > 0,
    linkPath: projects.length > 0 ? `/projects/${projects[0].id}/structure` : null,
  }),
)

const checklistItemLinkNote = createSelector(
  projectItemsSelector,
  getMyProjects,
  (projectItems, projects) => ({
    key: 'LINK_NOTE',
    completed: values(projectItems).filter(item => !item.isInitial && item.noteId).length > 0,
    linkPath: projects.length > 0 ? `/projects/${projects[0].id}/structure` : null,
  }),
)

const checklistItemTransformNote = createSelector(
  projectItemsSelector,
  getMyProjects,
  (projectItems, projects) => ({
    key: 'TRANSFORM_NOTE',
    completed: values(projectItems).filter(item => !item.isInitial && item.type === 'TEXT' && item.noteId).length > 0,
    linkPath: projects.length > 0 ? `/projects/${projects[0].id}/write` : null,
  }),
)

// the checklist order is defined by the order of the selector functions
const getChecklist = createSelector(
  checklistItemRegister,
  checklistItemCreateProject,
  checklistItemCreateNote,
  checklistItemCreateReference,
  checklistItemCreateHeading,
  checklistItemLinkNote,
  checklistItemTransformNote,
  (...args) => args,
)

const getChecklistPercentage = createSelector(
  getChecklist,
  list => list.reduce((percentage, item) => percentage + (item.completed ? 100 / list.length / 100 : 0), 0) * 100,
)

const getChecklistTodoCount = createSelector(
  getChecklist,
  list => list.reduce((count, item) => count + (item.completed ? 0 : 1), 0),
)

export default {
  getTourStep,
  getChecklist,
  getChecklistIsVisible,
  getChecklistPercentage,
  getChecklistTodoCount,
}
