import React from 'react'
import PropTypes from 'prop-types'

import './style.less'

const Page = ({ children, className = '' }) => <div className={`Page ${className}`}>{children}</div>


Page.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

export default Page
