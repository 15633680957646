import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { propTypes as i18nPropTypes, translate } from 'i18n'

import { connect } from 'react-redux'
import { without } from 'lodash'

import ProjectSelector from 'selectors/projects'
import { expandCollapseProjectItem, createProjectItem } from 'actions/project-items'

import { requestNote } from 'actions/notes'

import { AuratikumFontIcons } from 'helper/utils'
import Icon from 'components/common/Icon'

import OutlineHeadingItem from 'containers/note-link/OutlineHeadingItem'

import './style.less'

class FlyoutOutlineContainer extends Component {
  state = {
    expandedProjectItems: [],
    expandedProject: null,
    isLinking: false,
    linkingSuccess: false,
  }

  handleExpandCollapseProjectItem = (itemId) => {
    const { expandedProjectItems } = this.state
    let newExpandedProjectItems = expandedProjectItems.slice()
    if (expandedProjectItems.includes(itemId)) {
      newExpandedProjectItems = without(newExpandedProjectItems, itemId)
    } else {
      newExpandedProjectItems.push(itemId)
    }
    this.setState({
      expandedProjectItems: newExpandedProjectItems,
    })
  }

  handleExpandCollapseProject = (project) => {
    const { expandedProject } = this.state
    if (expandedProject === project.id) {
      this.setState({
        expandedProject: null,
      })
    } else {
      this.setState({
        expandedProject: project.id,
      })
    }
  }

  handleLinkItem = async (item) => {
    const { note, onNoteLinked } = this.props
    this.setState({
      isLinking: true,
    })
    await this.props.createProjectItem('NOTE_LINK', { noteId: note.id }, item, 0)
    await this.props.requestNote(note.id)
    this.setState({
      linkingSuccess: true,
    }, () => {
      window.setTimeout(onNoteLinked, 500)
    })
  }

  render() {
    const { resolvedProjectItemTrees, t } = this.props
    const {
      expandedProjectItems,
      expandedProject,
      isLinking,
      linkingSuccess,
    } = this.state
    if (!resolvedProjectItemTrees) return <div />
    if (resolvedProjectItemTrees.length === 0) return <div className="FlyoutOutlineContainer__NoProjects">{t('linkNoteFlyout.noProjects')}</div>
    return (
      <div className="FlyoutOutlineContainer">
        { resolvedProjectItemTrees.map((resolvedProject, index) => (
          <div key={resolvedProject.project.id} className="FlyoutOutlineContainer__Items">
            { index !== 0 && <div className="FlyoutOutlineContainer__ProjectDivider" /> }
            <button className="FlyoutOutlineContainer__ProjectTitle" type="button" onClick={() => this.handleExpandCollapseProject(resolvedProject.project)}>
              {resolvedProject.project.title}
              <Icon className="FlyoutOutlineContainer__Caret" icon={AuratikumFontIcons.ARROW_DOWN} />
            </button>
            {expandedProject === resolvedProject.project.id && (
              <OutlineHeadingItem
                item={resolvedProject.itemTree}
                expandCollapseProjectItem={this.handleExpandCollapseProjectItem}
                expandedProjectItems={expandedProjectItems}
                handleLinkItem={this.handleLinkItem}
                isLinking={isLinking}
                linkingSuccess={linkingSuccess}
              />
            )}
          </div>
        ))}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  resolvedProjectItemTrees: ProjectSelector.getResolvedProjectItemTrees(state),
})

FlyoutOutlineContainer.propTypes = {
  note: PropTypes.shape({}),
  resolvedProjectItemTrees: PropTypes.arrayOf(PropTypes.shape({})),
  onNoteLinked: PropTypes.func,
  ...i18nPropTypes,
}

export default connect(mapStateToProps, {
  expandCollapseProjectItem,
  createProjectItem,
  requestNote,
})(translate()(FlyoutOutlineContainer))
