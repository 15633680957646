import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { propTypes as i18nPropTypes, translate } from 'i18n'

import { requestEmailVerification } from 'actions/profiles'
import ProfileSelector from 'selectors/profile'
import modifyClassName from 'helper/modifyClassName'
import FormTextInput from 'components/common/FormTextInput'

import { SecondaryButton } from 'components/common/Button'
import Spinner from 'components/common/Spinner'

import './style.less'

export const extractDomain = email => email.substring(email.lastIndexOf('@') + 1)


class EducationVerificationContainer extends Component {
  constructor() {
    super()

    this.state = {
      isEditing: false,
      loading: false,
      value: '',
      error: null,
    }
  }

  requestVerification = async () => {
    this.setState({ loading: true, isEditing: false, error: null })
    try {
      await this.props.requestEmailVerification(this.state.value)
    } catch (error) {
      this.setState({ error: 'request failed' })
    }
    this.setState({ loading: false, value: '' })
  }

  renderVerificationForm = () => {
    const { t } = this.props
    if (this.props.profile.verifiedEmail) {
      return <div>{t('education.form.denied')}</div>
    }
    return <div>{t('education.form.empty')}</div>
  }

  renderVerified = () => <div>{this.props.t('education.form.verified')}</div>

  renderPendingMail = () => <div>{this.props.t('education.form.pendingMail')}</div>

  renderPendingDomain = () => <div>{this.props.t('education.form.pendingDomain', { domain: extractDomain(this.props.profile.verifiedEmail) })}</div>

  renderDomainNotVerified = () => <div>{this.props.t('education.form.domainDenied', { domain: extractDomain(this.props.profile.verifiedEmail) })}</div>

  renderFunctions = {
    NOT_VERIFIED: this.renderVerificationForm,
    VERIFIED: this.renderVerified,
    DOMAIN_NOT_VERIFIED: this.renderDomainNotVerified,
    PENDING_MAIL: this.renderPendingMail,
    PENDING_DOMAIN: this.renderPendingDomain,
  }

  renderEmail = () => {
    const { profile, t } = this.props
    return (
      <div className="EducationVerificationContainer__Section">
        <div className="EducationVerificationContainer__Label">
          {t('common.email')}
:
        </div>
        <div className="">{profile.verifiedEmail}</div>
        <button onClick={() => this.setState({ isEditing: true, error: null, value: profile.verifiedEmail })} className="EducationVerificationContainer__ChangeButton">
          { profile.verifiedEmail ? t('education.form.changeMail') : t('education.form.addMail')}
        </button>
      </div>
    )
  }

  renderForm = () => (
    <div className="EducationVerificationContainer__Section">
      <div className="EducationVerificationContainer__Label">
        {this.props.t('common.email')}
:
      </div>
      <FormTextInput autoFocus input={{ value: this.state.value, onChange: e => this.setState({ value: e.target.value }) }} meta={{}} />
      <SecondaryButton className="EducationVerificationContainer__RequestButton" onClick={this.requestVerification}>{this.props.t('common.send')}</SecondaryButton>
    </div>
  )

  renderContent = () => {
    const { profile, t } = this.props
    const { isEditing, error } = this.state

    const status = profile.verifiedEmail ? profile.educationVerificationStatus : 'UNKNOWN'
    return (
      <div className="EducationVerificationContainer__Content">
        <div className="EducationVerificationContainer__Section">
          <div className="EducationVerificationContainer__Label">
            {t('education.statusLabel')}
:
          </div>
          <div className={modifyClassName('EducationVerificationContainer__StatusBadge', { [status]: true })}>
            {t(`education.status.${status}`)}
          </div>
        </div>
        { !isEditing && this.renderEmail() }
        { isEditing && this.renderForm() }
        { error && <div className="EducationVerificationContainer__Error">{t('common.error')}</div>}
        <div className="EducationVerificationContainer__Section">
          { this.renderFunctions[profile.educationVerificationStatus]() }
        </div>

      </div>
    )
  }

  render() {
    return (
      <div className="EducationVerificationContainer">
        <Spinner show={this.state.loading}>{this.renderContent()}</Spinner>
      </div>
    )
  }
}


EducationVerificationContainer.propTypes = {
  profile: PropTypes.shape({}),
  ...i18nPropTypes,
}

const mapStateToProps = state => ({
  profile: ProfileSelector.getProfile(state),
})

export default connect(mapStateToProps, {
  requestEmailVerification,
})(translate()(EducationVerificationContainer))
