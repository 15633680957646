import { handleActions } from 'redux-actions'

import { receivedImportResponse, resetImport } from 'actions/import'

const initialState = {
  result: {},
}

const reducer = handleActions({
  [resetImport]: () => ({ ...initialState }),
  [receivedImportResponse]: (state, action) => ({
    result: action.payload,
  }),
}, { ...initialState })

export default reducer
