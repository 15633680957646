import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import { DropTarget } from 'react-dnd'

import modifyClassName from 'helper/modifyClassName'
import Spinner from 'components/common/Spinner'

import FontIcon from 'components/common/FontIcon'
import { KEY_CODE_ENTER } from 'helper/keyboard'

import {
  updateReferenceCollection,
  deleteReferenceCollection,
  selectReferenceCollection,
} from 'actions/reference-collections'

import './style.less'

class ReferenceCollectionItemComponent extends Component {
  static propTypes = {
    ...i18nPropTypes,
    collection: PropTypes.shape({
      name: PropTypes.string,
    }).isRequired,
    active: PropTypes.bool,
    editable: PropTypes.bool,
    deleteReferenceCollection: PropTypes.func,
    updateReferenceCollection: PropTypes.func,
    selectReferenceCollection: PropTypes.func,
    className: PropTypes.string,
  }

  static defaultProps = {
    active: false,
    editable: false,
    className: '',
  }

  state = {
    isLoading: false,
    isEditing: false,
    value: '',
  }

  onKeyDown = (e) => {
    if (e.keyCode === KEY_CODE_ENTER) {
      e.stopPropagation()
      e.preventDefault()
      this.update()
    }
  }

  handleDelete = async () => {
    if (window.confirm(this.props.t('references.collections.deleteConfirmation'))) {
      this.setState({ isLoading: true })
      try {
        await this.props.deleteReferenceCollection(this.props.collection)
      } catch (error) {
        console.log(error)
        // TODO handle error
        this.setState({ isLoading: false })
      }
    }
  }

  handleSelect = () => this.props.selectReferenceCollection(this.props.collection.id)

  handleEdit = () => {
    const { isEditing, value } = this.state
    if (isEditing) {
      if (value !== this.props.collection.name) this.update()
      this.setState({ isEditing: false })
    } else {
      this.setState({ isEditing: true, value: this.props.collection.name })
    }
  }

  update = async () => {
    this.setState({ isLoading: true })
    try {
      await this.props.updateReferenceCollection({
        ...this.props.collection,
        name: this.state.value,
      })
    } catch (error) {
      console.log(error)
    }
    this.setState({ isLoading: false, isEditing: false })
  }


  handleOnChange = (e) => {
    const { value } = e.target
    this.setState({ value })
  }

  renderActions = () => (
    <div className="ReferenceCollectionItem__Actions">
      <button className="ReferenceCollectionItem__Action" onClick={this.handleEdit}><FontIcon icon={FontIcon.Icons.faPencil} /></button>
      <button className="ReferenceCollectionItem__Action" onClick={this.handleDelete}><FontIcon icon={FontIcon.Icons.faTrashAlt} /></button>
    </div>
  )

  renderIcon = () => (
    <div className={modifyClassName('ReferenceCollectionItem__Icon', { spin: this.state.isLoading })}>
      <Spinner show={this.state.isLoading}>
        <FontIcon icon={FontIcon.Icons.faFolder} />
      </Spinner>
    </div>
  )

  renderInput = () => (
    <div className="ReferenceCollectionItem__Content">
      { this.renderIcon() }
      <input autoFocus value={this.state.value} onKeyDown={this.onKeyDown} onChange={this.handleOnChange} />
    </div>
  )

  renderContent = () => {
    const {
      collection, active, editable, dragOver, className,
    } = this.props
    const { isEditing, isLoading } = this.state

    const clsName = modifyClassName('ReferenceCollectionItem', { active, dragOver })
    return (
      <div className={`${clsName} ${className}`}>
        { !isEditing && (
        <button onClick={this.handleSelect} className="ReferenceCollectionItem__Content">
          {this.renderIcon()}
          <div className="ReferenceCollectionItem__Label">
            {collection.name}
          </div>
        </button>
        ) }
        { isEditing && this.renderInput() }
        { editable && !isLoading && this.renderActions()}
      </div>
    )
  }

  render() {
    const { connectDropTarget } = this.props
    return connectDropTarget ? connectDropTarget(this.renderContent()) : this.renderContent()
  }
}

function collect(dndConnect, monitor) {
  return {
    connectDropTarget: dndConnect.dropTarget(),
    dragOver: monitor.isOver(),
    item: monitor.getItem(),
  }
}

const target = {
  drop(props) {
    // return the collection id so that the DragSource can handle the reference update
    return { collection: props.collection }
  },
}

const mapStateToProps = () => ({})


const connectFunc = connect(
  mapStateToProps,
  {
    updateReferenceCollection,
    deleteReferenceCollection,
    selectReferenceCollection,
  },
)

export const ReferenceCollectionItem = connectFunc(translate()(ReferenceCollectionItemComponent))

export const ReferenceCollectionItemDropzone = connectFunc(translate()(DropTarget(['REFERENCE'], target, collect)(ReferenceCollectionItemComponent)))
