import {
  CognitoUserPool, CognitoUser, AuthenticationDetails, CognitoUserAttribute,
} from 'amazon-cognito-identity-js'

const userPoolId = 'eu-central-1_GWtYctIN2'
const clientId = '3e91ljd8hgdbkr0vh5s8sh11sj'
const poolData = {
  UserPoolId: userPoolId, // Your user pool id here
  ClientId: clientId, // Your client id here
}
const userPool = new CognitoUserPool(poolData)

const login = (username, password, resolve, reject) => {
  const authenticationData = {
    Username: username,
    Password: password,
  }

  const authenticationDetails = new AuthenticationDetails(authenticationData)

  const userData = {
    Username: username,
    Pool: userPool,
  }
  const cognitoUser = new CognitoUser(userData)
  cognitoUser.authenticateUser(authenticationDetails, {
    onSuccess(result) {
      resolve(result.getIdToken().getJwtToken())
    },

    onFailure(err) {
      if (err.code === 'UserNotFoundException' || err.code === 'NotAuthorizedException') {
        reject(err)
      } else {
        cognitoUser.confirmRegistration(prompt('Bitte Verifizierungscode eingeben', ''), true, (registrationError) => {
          if (registrationError) {
            reject(registrationError)
            return
          }
          reject('User confirmed! Please login again')
        })
      }
    },
    newPasswordRequired() {
      const newPassword = prompt('Neues Passwort')
      const data = {}
      cognitoUser.completeNewPasswordChallenge(newPassword, data, this)
    },
  })
}

export const cognitoLogin = (username, password) => new Promise((resolve, reject) => {
  // the cognito sdk takes too long to return this function so that the UI lacks
  // hack: timeout.. :/
  setTimeout(() => login(username, password, resolve, reject), 20)
})

export const cognitoRegister = values => new Promise((resolve, reject) => {
  const attributeList = []

  const dataEmail = {
    Name: 'email',
    Value: values.email.trim(),
  }

  const attributeEmail = new CognitoUserAttribute(dataEmail)
  attributeList.push(attributeEmail)

  userPool.signUp(values.email.trim(), values.password, attributeList, null, (err, result) => {
    if (err) {
      reject(err)
      return
    }
    const cognitoUser = result.user
    // console.log(`user name is ${cognitoUser.getUsername()}`)
    resolve(cognitoUser)
  })
})

export const cognitoForgotPassword = values => new Promise((resolve, reject) => {
  const userData = {
    Username: values.email.trim(),
    Pool: userPool,
  }
  const cognitoUser = new CognitoUser(userData)
  cognitoUser.forgotPassword({
    onSuccess() {
      resolve()
    },
    onFailure(err) {
      reject(err)
    },
  })
})

export const cognitoNewPassword = values => new Promise((resolve, reject) => {
  const userData = {
    Username: values.email.trim(),
    Pool: userPool,
  }
  const cognitoUser = new CognitoUser(userData)
  cognitoUser.confirmPassword(values.verificationCode, values.newPassword, {
    onSuccess() {
      resolve()
    },
    onFailure(err) {
      reject(err)
    },
  })
})

export const cognitoGetSession = () => new Promise((resolve, reject) => {
  const cognitoUser = userPool.getCurrentUser()

  if (cognitoUser != null) {
    cognitoUser.getSession((err, session) => {
      if (err) {
        reject(err)
        return
      }
      if (!session.isValid()) {
        reject('session not valid')
        return
      }
      resolve(session)
    })
  } else {
    reject('no user found')
  }
})

export const cognitoLogout = () => {
  const cognitoUser = userPool.getCurrentUser()
  cognitoUser.signOut()
}

export const cognitoGetUser = () => {
  const cognitoUser = userPool.getCurrentUser()
  return cognitoUser
}


export const cognitoGetToken = () => cognitoGetSession().then(session => session.getIdToken().getJwtToken())
