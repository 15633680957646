import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'

import Spinner from 'components/common/Spinner'
import FontIcon from 'components/common/FontIcon'

import modifyClassName from 'helper/modifyClassName'

import './style.less'


export default class SearchField extends Component {
  static propTypes = {
    isSearching: PropTypes.bool,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    query: PropTypes.string,
    autoFocus: PropTypes.bool,
    shouldGrow: PropTypes.bool,
    onChange: PropTypes.func,
  }

  state = { active: false }

  handleFocus = () => {
    this.setState({ active: true })
    const { onFocus } = this.props
    if (onFocus) onFocus()
  }

  handleBlur = () => {
    this.setState({ active: false })
    const { onBlur } = this.props
    if (onBlur) onBlur()
  }

  render() {
    const { active } = this.state
    const {
      query, isSearching, shouldGrow, autoFocus, onChange,
    } = this.props

    return (
      <div className={modifyClassName('SearchField', { active: (active || !isEmpty(query)) && shouldGrow })}>
        <div className={modifyClassName('SearchField__InputGroup', { active })}>
          <div className={modifyClassName('SearchField__Icon', { active })}>
            <Spinner show={isSearching}>
              <FontIcon icon={FontIcon.Icons.faSearch} />
            </Spinner>
          </div>
          <input
            className="SearchField__Input"
            type="text"
            onBlur={this.handleBlur}
            onFocus={this.handleFocus}
            onChange={onChange}
            value={query}
            autoFocus={autoFocus}
          />
        </div>
      </div>
    )
  }
}
