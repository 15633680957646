
export default class EntitySyncQueue {
  constructor(action) {
    this.action = action
    this.status = 'SYNCED'
    this.syncEntity = null
    this.nextEntity = null
    this.subscriber = null
  }

  performAction = async () => {
    let newEntity
    if (this.syncEntity) {
      try {
        this.setState('SYNCING')
        newEntity = await this.action(this.syncEntity)
        if (this.nextEntity) {
          this.syncEntity = this.nextEntity
          this.nextEntity = null
          newEntity = await this.performAction()
        } else {
          this.syncEntity = null
        }
        this.setState('SYNCED')
      } catch (e) {
        // console.error(e)
        this.setState('ERROR')
        if (this.nextEntity) {
          this.syncEntity = this.nextEntity
          this.nextEntity = null
        }
        // we don't need the error down the road, it would be an uncaught error for our reference form
        // throw e
      }
    }
    return newEntity
  }

  push = async (entity) => {
    if (!this.syncEntity) {
      this.syncEntity = entity
      return this.performAction()
    }

    this.nextEntity = entity
    return entity
  }

  retry = () => this.performAction()

  setState = (state) => {
    this.state = state
    if (this.subscriber) {
      this.subscriber(state)
    }
  }

  subscribe = (subscriber) => {
    this.subscriber = subscriber
    return this
  }
}
