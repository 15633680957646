import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import { uniq } from 'lodash'

import { DragSource } from 'react-dnd'
import { ReferenceDragImage } from 'helper/DragImages'

import { OutlinedButton } from 'components/common/Button'
import AddReferenceButton from 'containers/references/AddReferenceButton'
import modifyClassName from 'helper/modifyClassName'
import ReferenceFileDownload from 'components/references/ReferenceFileDownload'
import ReferenceUrlIcon from 'components/references/ReferenceUrlIcon'
import FontIcon from 'components/common/FontIcon'

import './style.less'


class ReferenceListItem extends Component {
  static propTypes = {
    reference: PropTypes.shape({}),
    onSelect: PropTypes.func,
    onDelete: PropTypes.func,
    onDuplicate: PropTypes.func,
    isExternalReference: PropTypes.bool,
    style: PropTypes.shape({}),
    ...i18nPropTypes,
  }

  static defaultPropTypes = {
    reference: {},
    onSelect: () => {},
  }

  state = {
    isHovered: false,
  }

  componentDidMount() {
    this.initDnD()
  }

  onSelect = () => this.props.onSelect && this.props.onSelect(this.props.reference)

  onMouseEnter = () => this.setState({ isHovered: true })

  onMouseLeave = () => this.setState({ isHovered: false })

  handleDelete = (e) => {
    e.preventDefault()
    e.stopPropagation()
    return this.props.onDelete && this.props.onDelete(this.props.reference)
  }

  initDnD() {
    const { connectDragPreview } = this.props
    if (!connectDragPreview) { return }
    const img = new Image()
    img.onload = () => this.props.connectDragPreview && connectDragPreview(img)
    img.src = ReferenceDragImage
  }

  renderActions = () => {
    const {
      onDelete, onDuplicate, isExternalReference, reference, t,
    } = this.props
    return (
      <div className="ReferenceListItem__Actions">
        {onDelete && (
        <OutlinedButton onClick={this.handleDelete} circleModifier="circleSmall" title={t('common.delete')}>
          <FontIcon icon={FontIcon.Icons.faTrashAlt} />
        </OutlinedButton>
        )}
        {onDuplicate && (
        <OutlinedButton onClick={this.handleDelete} circleModifier="circleSmall">
          <FontIcon icon={FontIcon.Icons.faClone} />
        </OutlinedButton>
        )}
        {isExternalReference && (
        <AddReferenceButton reference={reference} />
        )}
      </div>
    )
  }

  renderItem() {
    const {
      reference, t, style,
    } = this.props

    const { isHovered: hovered } = this.state

    const {
      isInitial, title, author = [], issued = {}, editor = [], importReference,
    } = reference


    // TODO should be part of selector
    const authorString = author.filter(a => !!a).map(a => a.literal || `${a.given || ''} ${a.family || ''}`).join(', ') || editor.filter(a => !!a).map(a => a.literal || `${a.given || ''} ${a.family || ''}`).join(', ')

    let { type } = reference

    type = this.props.t(`references:types.${type || 'article'}.name`)

    const year = issued.raw || ''

    return (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
      <div style={style} onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave} onClick={this.onSelect} className="ReferenceListItem">
        <div className="ReferenceListItem__Title">{title || importReference}</div>

        <div className="ReferenceListItem__Author">{authorString}</div>
        {isInitial && <div className="ReferenceListItem__ExampleTip">{t('common.example')}</div> }
        <div className="ReferenceListItem__Col ReferenceListItem__Type">{type}</div>
        <div className="ReferenceListItem__Col">
          {year}
        </div>
        <div className={modifyClassName('ReferenceListItem__Icon', { hovered })}>
          <ReferenceFileDownload reference={reference} />
        </div>
        <div className={modifyClassName('ReferenceListItem__Icon', 'last', { hovered })}>
          <ReferenceUrlIcon reference={reference} />
        </div>
        { hovered && this.renderActions()}
      </div>
    )
  }

  render() {
    const { connectDragSource } = this.props
    return connectDragSource ? connectDragSource(this.renderItem()) : this.renderItem()
  }
}


const referenceSource = {
  beginDrag(props) {
    return props.reference
  },
  endDrag(props, monitor) {
    if (monitor.didDrop()) {
      const collectionId = monitor.getDropResult().collection.id
      const collections = [...props.reference.collections || []]
      collections.push(collectionId)
      props.onUpdate({
        ...props.reference,
        collections: uniq(collections),
      })
    }
  },
}

function collect(con, monitor) {
  return {
    connectDragSource: con.dragSource(),
    isDragging: monitor.isDragging(),
    connectDragPreview: con.dragPreview(),
  }
}

export const PlainReferenceListItem = translate()(ReferenceListItem)

export default DragSource('REFERENCE', referenceSource, collect)(PlainReferenceListItem)
