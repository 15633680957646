const CLEAR_LOCAL_STORAGE = 'CLEAR_LOCAL_STORAGE'

export default store => next => (action) => {
  if (action.type !== CLEAR_LOCAL_STORAGE) {
    const ret = next(action)
    const state = store.getState()
    try {
      // eslint-disable-next-line
      localStorage.setItem(`AURATIKUM_${process.env.VERSION_LABEL}`, JSON.stringify({
        expandedProjectItems: state.projectItem.expandedProjectItems,
        scrollRestoration: state.scrollRestoration,
      }))
    } catch (error) {
      console.log('Error while using localstorage.', error)
    }
    return ret
  }
  return next(action)
}
