import { createAction } from 'redux-actions'
import * as api from 'api'

export const states = {
  ONLINE: 'ONLINE',
  OFFLINE: 'OFFLINE',
  UNKNOWN: 'UNKOWN',
}

export const changeConnectionState = createAction('CHANGE_CONNECTION_STATE')

export const checkConnection = () => async (dispatch, getState) => {
  try {
    await api.ping()
    if (getState().connection.state !== states.ONLINE) dispatch(changeConnectionState(states.ONLINE))
  } catch (e) {
    dispatch(changeConnectionState(states.OFFLINE))
  }
}
