import ProfileSelector from 'selectors/profile'

import { updateProfileNoCSL } from 'actions/profiles'
import { get } from 'lodash'
import tracker from 'tracking/tracker'

const onboardingSteps = ['WELCOME', 'ADD_NOTE', 'CREATE_NOTE', 'FILTER1', 'WRITE', 'TRANSFORM_NOTE', 'EXPORT', 'FINISHED', 'FILTER2', 'PROJECT', 'DONE', 'FINISHED2']

const jumpToNextStep = (store, profile, currentTourStep) => {
  const p = { ...profile }

  const tourIndex = onboardingSteps.indexOf(currentTourStep)
  tracker.logEvent('FM_TOUR', { step: tourIndex })

  p.tourStep = get(onboardingSteps, `['${tourIndex + 1}']`, 'FINISHED2')

  store.dispatch(updateProfileNoCSL(p))
}

const processAction = {
  WELCOME: action => action.type === '@@router/LOCATION_CHANGE' && action.payload.location.pathname.includes('/projects/'),
  ADD_NOTE: action => action.type === '@@router/LOCATION_CHANGE' && action.payload.location.pathname.includes('/notes/'),
  CREATE_NOTE: action => action.type === '@@router/LOCATION_CHANGE' && action.payload.location.pathname.includes('/projects/'),
  WRITE: action => action.type === '@@router/LOCATION_CHANGE' && action.payload.location.pathname.includes('write'),
  FINISHED: action => action.type === '@@router/LOCATION_CHANGE' && action.payload.location.pathname.includes('/projects/'),
  PROJECT: action => action.type === '@@router/LOCATION_CHANGE' && action.payload.location.pathname.includes('write'),
  FILTER1: action => action.type === 'ACTIVATE_FILTER',
  FILTER2: action => action.type === 'ACTIVATE_FILTER',
  TRANSFORM_NOTE: action => action.type === 'RECEIVE_ENTITIES' && get(Object.keys(get(action, 'payload.entities')), '[0]') === 'projectItems' && get(action, 'payload.result', []).length === 1,
  DONE: action => action.type === '@@router/LOCATION_CHANGE',
}

export default store => next => (action) => {
  const profile = ProfileSelector.getProfile(store.getState())
  const currentTourStep = get(profile, 'tourStep')

  if (currentTourStep === 'FINISHED2') return next(action)

  if (action.type === 'JUMP_TO_NEXT_STEP' || (processAction[currentTourStep] && processAction[currentTourStep](action, store))) {
    jumpToNextStep(store, profile, currentTourStep)
  }

  if (currentTourStep === 'EXPORT' && action.type === 'SET_EXPORTING') {
    profile.tourStep = 'DONE'
    store.dispatch(updateProfileNoCSL(profile))
  }

  return next(action)
}
