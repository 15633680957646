
export const addToChildren = (obj, id, index) => {
  const children = [...obj.children]
  children.splice(index, 0, id)
  return {
    ...obj,
    children,
  }
}


const removeFromArray = (array, id) => {
  const list = [...array]
  const oldIndex = list.findIndex(i => i === id)
  list.splice(oldIndex, 1)
  return list
}

export const removeFromChildren = (obj, id) => ({
  ...obj,
  children: removeFromArray(obj.children, id),
})

export const moveInChildren = (obj, id, index) => {
  const children = [...obj.children]
  const oldIndex = children.findIndex(i => i === id)
  const newIndex = oldIndex > index ? index : index - 1 // the removed object changes the indices
  children.splice(newIndex, 0, children.splice(oldIndex, 1)[0])
  return {
    ...obj,
    children,
  }
}

export const isItemInTraversedChildren = (data, searchedItemId, parentId) => {
  if (parentId === searchedItemId) {
    return parentId
  }
  const children = data[parentId] && data[parentId].children
  if (!children) {
    return null
  }
  let res
  children.forEach((child) => {
    res = res || isItemInTraversedChildren(data, searchedItemId, child)
  })
  return res
}
