import React from 'react'
import PropTypes from 'prop-types'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import Flyout from 'containers/common/Flyout'
import ColorButton from 'components/common/ColorButton'

import './style.less'

const ColorListFlyout = ({
  colors,
  colorLabels,
  selectedColors,
  onSelect,
  t,
}) => (
  <div className="ColorListFlyout">
    <div className="ColorListFlyout__Content">
      { colors.map((color) => {
        let isSelected = false
        if (selectedColors.includes(color.toString())) {
          isSelected = true
        }
        return (
          <ColorButton
            className="ColorListFlyout__Button"
            key={color}
            color={color}
            value={isSelected ? color : ''}
            onChange={() => onSelect(color)}
            tooltipText={colorLabels[color] || t('noteDetailView.unnamedColor')}
            tooltipPlacement="bottom"
          />
        )
      })}
    </div>
  </div>
)

ColorListFlyout.propTypes = {
  colors: PropTypes.array,
  colorLabels: PropTypes.shape({}),
  selectedColors: PropTypes.array,
  onSelect: PropTypes.func,
  ...i18nPropTypes,
}

export default Flyout(translate()(ColorListFlyout))
