import React, { Component } from 'react'
import { connect } from 'react-redux'

import NotesOverview from 'containers/notes-overview/NotesOverview'

import Page from 'components/page/Page'
import PageHeader from 'components/page/PageHeader'
import PageContent from 'components/page/PageContent'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import { OutlinedButton } from 'components/common/Button'
import { push } from 'connected-react-router'

class NotesOverviewPage extends Component {
  navigateToProjects = () => this.props.push('/projects')

  render() {
    const { t } = this.props
    return (
      <Page>
        <PageHeader>
          <div className="ProjectsOverviewPage__HeaderActions">
            <OutlinedButton className="ProjectsOverviewPage__HeaderButton" onClick={this.navigateToProjects}>{t('common.projects')}</OutlinedButton>
          </div>
        </PageHeader>
        <PageContent>
          <NotesOverview />
        </PageContent>
      </Page>
    )
  }
}

NotesOverviewPage.propTypes = {
  ...i18nPropTypes,
}

export default connect(null, {
  push,
})(translate()(NotesOverviewPage))
