import React from 'react'
import PropTypes from 'prop-types'
import modifyClassName from '../../../helper/modifyClassName'
import { AuratikumFontIcons } from '../../../helper/utils'

import Icon from '../../common/Icon'
import { OutlinedButton } from '../../common/Button'

import './style.less'

const ProjectCardMenu = ({
  onEditClick, onDeleteClick, className = '', visible,
}) => (
  <div className={`${modifyClassName('ProjectCardMenu', { visible })} ${className}`}>
    <div className={modifyClassName('ProjectCardMenu__Item', { visible })}>
      <OutlinedButton className="ProjectCardMenu__Button" onClick={onEditClick} circleModifier="circleMedium">
        <Icon icon={AuratikumFontIcons.EDIT} />
      </OutlinedButton>
    </div>
    <div className={modifyClassName('ProjectCardMenu__Item', { visible })}>
      <OutlinedButton className="ProjectCardMenu__Button" onClick={onDeleteClick} circleModifier="circleMedium">
        <Icon icon={AuratikumFontIcons.DELETE} />
      </OutlinedButton>
    </div>
  </div>
)

ProjectCardMenu.propTypes = {
  className: PropTypes.string,
  visible: PropTypes.bool,
  onEditClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
}

export default ProjectCardMenu
