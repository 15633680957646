import { createAction } from 'redux-actions'
import { push } from 'connected-react-router'

export const cleanNavigationStack = createAction('CLEAN_NAVIGATION_STACK')
export const popNavigationItem = createAction('POP_NAVIGATION_ITEM')

export const navigateToNavigationPathItem = ({ index, url }) => async (dispatch) => {
  dispatch(cleanNavigationStack((index)))
  dispatch(push(url))
}
