import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { propTypes as i18nPropTypes, translate } from 'i18n'
import tracker from 'tracking/tracker'

import { PrimaryButton, PremiumButton } from 'components/common/Button'
import { wasAlreadyAdded } from 'selectors/references'

import { createReference } from 'actions/references'
import { showModal } from 'actions/modal'
import { push } from 'connected-react-router'
import { isReferenceCountPremiumRestricted } from 'selectors/subscriptions'

import { InfoSimpleToast } from 'containers/toasts/SimpleToast'
import { PAYWALL_MODAL_ID } from 'containers/modal/PaywallModal'

import './style.less'

const ADDING_TO_LIB_TOAST_ID = 'ADDING_TO_LIB_TOAST_ID'

class AddReferenceButton extends Component {
  static propTypes = {
    ...i18nPropTypes,
    reference: PropTypes.shape({}),
    className: PropTypes.string,
    alreadyAdded: PropTypes.bool,
    createReference: PropTypes.func,
    showModal: PropTypes.func,
  }

  state = {
    isLoading: false,
  }

  handleAddToUserLib = async (e) => {
    e.preventDefault()
    e.stopPropagation()
    const {
      reference, isPremiumRestricted, t,
    } = this.props
    if (isPremiumRestricted) {
      this.props.showModal(PAYWALL_MODAL_ID)
      return
    }
    this.setState({ isLoading: true })
    try {
      const res = await this.props.createReference(reference)
      this.props.showModal('ADDING_TO_LIB_TOAST_ID', { content: t('references.addToUserLibSuccess'), data: { level: 'success' } })
      tracker.logEvent('CREATE_REFERENCE', { creation: 'FROM_LIB', lib: reference.fromLibrary, id: res.id })
      this.props.push(`/references/lib/${res.id}`)
    } catch (error) {
      this.props.showModal('ADDING_TO_LIB_TOAST_ID', { content: t('references.addToUserLibError'), data: { level: 'danger' } })
    }
    this.setState({ isLoading: false })
  }

  render() {
    const {
      className, alreadyAdded, isPremiumRestricted, t,
    } = this.props
    const { isLoading } = this.state

    const Button = isPremiumRestricted ? PremiumButton : PrimaryButton
    return (
      <React.Fragment>
        <Button
          className={`AddReferenceButton ${className || ''}`}
          disabled={alreadyAdded}
          isLoading={isLoading}
          onClick={this.handleAddToUserLib}
        >
          { alreadyAdded ? t('references.alreadyAddedToLib') : t('references.addToUserLib')}
        </Button>
        <InfoSimpleToast
          id={ADDING_TO_LIB_TOAST_ID}
          timeout={3000}
        />
      </React.Fragment>
    )
  }
}


const mapStateToProps = (state, { reference }) => ({
  alreadyAdded: wasAlreadyAdded(reference)(state),
  isPremiumRestricted: isReferenceCountPremiumRestricted(state),
})

export default connect(mapStateToProps, {
  createReference,
  showModal,
  push,
})(translate()(AddReferenceButton))
