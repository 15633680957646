import { handleActions } from 'redux-actions'
import {
  keys, values, set, get,
} from 'lodash'

import { updateEntity, receiveEntities, removeEntity } from 'actions/entities'

const reducer = handleActions({
  [updateEntity]: (state, action) => ({
    ...state,
    [action.payload.entityType]: {
      ...state[action.payload.entityType],
      [action.payload.data.id]: { ...action.payload.data },
    },
  }),
  [receiveEntities]: (state, action) => {
    const result = { ...state }
    keys(action.payload.entities).forEach((entityTypeKey) => {
      const entityType = get(state, `['${entityTypeKey}']`, {})
      result[entityTypeKey] = { ...entityType }
      values(action.payload.entities[entityTypeKey]).forEach((entity) => {
        set(result, `${entityTypeKey}.${entity.id}`, { ...entity })
      })
    })

    // TODO / refactor not good in a generic entity reducer
    const { projectItems = {} } = result
    values(projectItems).forEach((item) => {
      if (item.children) {
        item.children.forEach((childId) => {
          result.projectItems[childId] = { ...result.projectItems[childId], parentId: item.id }
        })
      }
    })

    return result
  },
  [removeEntity]: (state, action) => {
    const { [action.payload.id]: removed, ...rest } = state[action.payload.entityType]
    return {
      ...state,
      [action.payload.entityType]: { ...rest },
    }
  },
}, {})


export default reducer
