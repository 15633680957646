import * as _ from 'lodash'
import { ObjectId as BsonObjectId } from 'bson'
import validUrl from 'valid-url'

import { validateEmail } from './validators'
import { ReferenceSortAttribute } from './constants'


export const ObjectId = () => new BsonObjectId().toHexString()

export const sleep = time => new Promise(resolve => setTimeout(resolve, time))

export const sortReferencesBy = (references, sortBy = ReferenceSortAttribute.PERSON, sortOrder = 'asc') => {
  if (!references) { return references }
  switch (sortBy) {
    case ReferenceSortAttribute.PERSON:
      return [...references].sort((referenceA, referenceB) => {
        const personA = referenceA.author[0] || referenceA.editor[0] || {}
        const personB = referenceB.author[0] || referenceB.editor[0] || {}
        const familyA = personA.family || personA.literal || ''
        const familyB = personB.family || personB.literal || ''
        return sortOrder === 'asc' ? familyB.localeCompare(familyA) : familyA.localeCompare(familyB)
      })
    case ReferenceSortAttribute.CREATED:
      return [...references].sort((referenceA, referenceB) => (sortOrder === 'desc' ? new Date(referenceB.createdAt) - new Date(referenceA.createdAt) : new Date(referenceA.createdAt) - new Date(referenceB.createdAt)))
    case ReferenceSortAttribute.UPDATED:
      return [...references].sort((referenceA, referenceB) => (sortOrder === 'desc' ? new Date(referenceB.updatedAt) - new Date(referenceA.updatedAt) : new Date(referenceA.updatedAt) - new Date(referenceB.updatedAt)))
    default:
      return references
  }
}

let cachedFilteredReferences = []
let cachedQuery
let cachedReferencesCount

export const filterReferencesBy = (references, query) => {
  if (query === '') return references

  if (references.length !== cachedReferencesCount || query !== cachedQuery) {
    cachedFilteredReferences = references.filter(
      (reference) => {
        let foundMatch = false
        _.each(reference, (referenceEntry) => {
          if (_.isArray(referenceEntry) || _.isObject(referenceEntry)) {
            if (JSON.stringify(_.values(referenceEntry)).toLowerCase().includes(query.toLowerCase())) {
              foundMatch = true
            }
          } else if (referenceEntry !== undefined && JSON.stringify(referenceEntry).toLowerCase().includes(query.toLowerCase())) {
            foundMatch = true
          }
        })
        return foundMatch
      },
    )
    cachedQuery = query
    cachedReferencesCount = references.length
  }

  return cachedFilteredReferences
}

export const toggleTagsInFilter = (filter, item) => {
  if (!filter.tags) return [item]

  const index = filter.tags.indexOf(item)
  if (index === -1) {
    filter.tags.push(item)
  } else {
    filter.tags.splice(index, 1)
  }

  return filter.tags
}

export const toggleColorsInFilter = (filter, colorAsArray) => {
  const color = colorAsArray.toString()

  if (!filter.colors) return [color]

  const index = filter.colors.indexOf(color)
  if (index === -1) {
    filter.colors.push(color)
  } else {
    filter.colors.splice(index, 1)
  }

  return filter.colors
}

export const AuratikumFontIcons = {
  CLIPBOARD: 'A',
  NOTES: 'B',
  REFERENCE: 'C',
  QUESTIONMARK: 'D',
  CROWN: 'E',
  GRIDLIST: 'F',
  EDITLINES: 'G',
  BOOK: 'H',
  THREEDOTS: 'I',
  UNIVERSITY: 'J',
  DELETE: 'K',
  ADD: 'L',
  EDIT: 'M',
  COMMENT: 'N',
  DRAGANDDROP: 'O',
  GLASSES: 'P',
  FOOTERNOTE: 'Q',
  EXPAND: 'R',
  MOVE: 'S',
  UNDO: 'T',
  BOARDADD: 'U',
  UPLOAD: 'V',
  CLIP: 'W',
  ADDCIRCLE: 'X',
  CLOSE: 'Y',
  SEARCH: 'Z',
  SCIENCE: 'a',
  CLOSE_CIRCLE: 'b',
  ORDER: 'c',
  LIST: 'd',
  GRID: 'e',
  FILTER: 'f',
  ARROW_DOWN: 'g',
  ARROW_RIGHT: 'h',
  CLOSE_BOLD: 'i',
  DOWNLOAD: 'j',
  ATTACHMENT: 'k',
}

export const mapProjectTypeToIcon = (type) => {
  const match = type.toLowerCase()
  if (type) {
    if (['master', 'bachelor', 'dissertation', 'habilitation', 'seminar',
      'course', 'presentation', 'defense', 'vortrag', 'verteidigung', 'lehrveranstaltung', 'hausarbeit'].some(t => match.includes(t))) {
      return AuratikumFontIcons.UNIVERSITY
    }
    if (['book', 'buch'].some(t => match.includes(t))) {
      return AuratikumFontIcons.BOOK
    }
  }
  return AuratikumFontIcons.SCIENCE
}


export const getDefaultInlineCite = (reference) => {
  const { author, editor, issued = {} } = reference
  if (author && author.length > 0) {
    const { family, literal } = author[0]
    return `(${family || literal || ''} ${issued.raw || ''})`.trim()
  }
  if (editor && editor.length > 0) {
    const { family, literal } = editor[0]
    return `(${family || literal || ''} ${issued.raw || ''})`.trim()
  }
  return '()'
}

export const sortByValue = values => (a, b) => {
  if (values.indexOf(a) > values.indexOf(b)) return 1
  if (values.indexOf(a) < values.indexOf(b)) return -1
  return 0
}


export const isURL = url => validUrl.isUri(url)

const TOP_PARTITION_SIZE = 5
const MY_PARTITION_SIZE = 1
export const partitionScores = (list, myInstituteId) => {
  if (!list) {
    return list
  }
  const myIndex = _.findIndex(list, { id: myInstituteId })
  const myPartitionStartIndex = Math.max(TOP_PARTITION_SIZE, myIndex - MY_PARTITION_SIZE)
  const myPartitionEndIndex = myIndex + MY_PARTITION_SIZE + 1
  return {
    top: list.slice(0, TOP_PARTITION_SIZE),
    my: list.slice(myPartitionStartIndex, myPartitionEndIndex),
    topToMy: list.slice(TOP_PARTITION_SIZE, myPartitionStartIndex),
    myToRest: list.slice(Math.max(myPartitionStartIndex, myPartitionEndIndex), list.length),
  }
}

export const browsers = {
  CHROME: 'CHROME',
  SAFARI: 'SAFARI',
  FIREFOX: 'FIREFOX',
}

export const checkExtensionAvailable = () => document.getElementById('aur-ext')
export const checkSupportedBrowserForExtension = browser => [browsers.CHROME, browsers.FIREFOX].includes(browser)

export const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime)
export const isSafari = navigator.userAgent.toLowerCase().includes('safari')
export const isFirefox = navigator.userAgent.toLowerCase().includes('firefox')

export const getBrowser = () => {
  if (isChrome) return browsers.CHROME
  if (isSafari) return browsers.SAFARI
  if (isFirefox) return browsers.FIREFOX
  return null
}

export const getPercentage = (value, total) => value * 100 / total

export const splitEmails = (text) => {
  if (_.isEmpty(text)) {
    return null
  }
  let emails = text.split(';')
  if (emails.length === 1) {
    emails = text.split(',')
  }
  return emails.map(mail => mail.trim()).filter(email => email && !validateEmail(email))
}

export const filterEntityByIds = (entityValue, ids = [], includeIds = true) => {
  const remaining = {}
  if (!entityValue) {
    return remaining
  }

  const idIsNumber = _.isNumber(_.head(ids))

  _.forOwn(entityValue, (inspectedValue, inspectedKey) => {
    const key = idIsNumber ? parseInt(inspectedKey, 10) : inspectedKey
    const isFilterSatisfied = _.includes(ids, key)
    if ((isFilterSatisfied && includeIds) || (!isFilterSatisfied && !includeIds)) {
      remaining[key] = inspectedValue
    }
  })
  return remaining
}
