import React from 'react'
import PropTypes from 'prop-types'
import modifyClassName from 'helper/modifyClassName'

import FontIcon from 'components/common/FontIcon'
import Toast from '../Toast'

import './style.less'

const ModifiedBigToast = modifier => WrappedComponent => ({ ...props }) => <WrappedComponent modifier={modifier} {...props} />

const modifierIconMap = {
  success: FontIcon.Icons.faCheck,
  warning: FontIcon.Icons.faExclamationTriangle,
  danger: FontIcon.Icons.faExclamationCircle,
}

const BigToast = ({
  content, contentFunc, modifier, data,
}) => {
  const mod = modifier || data.level

  return (
    <div className={modifyClassName('BigToast', { [mod]: !!mod })}>
      <div className="BigToast__Icon"><FontIcon icon={modifierIconMap[mod] || FontIcon.Icons.faInfoCircle} /></div>
      <div className="BigToast__Content">{content || (contentFunc && contentFunc(this.props.data))}</div>
    </div>
  )
}


BigToast.propTypes = {
  content: PropTypes.any,
  contentFunc: PropTypes.func,
  modifier: PropTypes.string,
  data: PropTypes.shape({ level: PropTypes.string }),
}

BigToast.defaultPropTypes = {}

export const InfoBigToast = Toast(BigToast)
export const SuccessBigToast = Toast(ModifiedBigToast('success')(BigToast))
export const WarningBigToast = Toast(ModifiedBigToast('warning')(BigToast))
export const DangerBigToast = Toast(ModifiedBigToast('danger')(BigToast))
