import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { AuratikumFontIcons } from 'helper/utils'
import Icon from 'components/common/Icon'
import { Manager, Reference } from 'react-popper'
import { SmallTag } from 'components/common/Tag'
import ColorListFlyout from 'components/common/ColorListFlyout'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import ProfileSelector from 'selectors/profile'
import { parseColor } from 'helper/colorHandler'
import './style.less'

const COLOR_LIST_FLYOUT_ID = 'COLOR_LIST_FLYOUT_ID'

class ToolbarColorFilter extends Component {
  static propTypes = {
    showFlyout: PropTypes.func,
    colors: PropTypes.array,
    colorLabels: PropTypes.shape({}),
    selectedColors: PropTypes.array,
    onSelect: PropTypes.func,
    ...i18nPropTypes,
  }

  showFlyout = () => this.props.showFlyout(COLOR_LIST_FLYOUT_ID)

  render() {
    const {
      colors,
      colorLabels,
      selectedColors = [],
      onSelect,
      t,
    } = this.props

    return (
      <div className="ToolbarColorFilter">
        <div>
          <Manager>
            <Reference>
              { ({ ref }) => (
                <div ref={ref} className="ToolbarColorFilter__Container">
                  { selectedColors.length === 0 && <button type="button" onClick={this.showFlyout} className="ToolbarColorFilter__Button">{t('common.color')}</button> }
                  { selectedColors.map((color, index) => {
                    const style = {
                      zIndex: (100 - index),
                      backgroundColor: parseColor(color),
                    }
                    if (index > 0) {
                      return <button type="button" key={color} onClick={this.showFlyout} className="ToolbarColorFilter__ColorIndication" style={style} />
                    }
                    return (
                      <button
                        key={color}
                        type="button"
                        className="ToolbarColorFilter__Item"
                        onSelect={this.showFlyout}
                        style={style}
                      />
                    )
                  })}
                  <button type="button" onClick={this.showFlyout}><Icon className="ToolbarColorFilter__Caret" icon={AuratikumFontIcons.ARROW_DOWN} /></button>
                </div>
              )}
            </Reference>

            <ColorListFlyout
              colorLabels={colorLabels}
              colors={colors}
              id={COLOR_LIST_FLYOUT_ID}
              selectedColors={selectedColors}
              onSelect={onSelect}
            />
          </Manager>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  colorLabels: ProfileSelector.getProfile(state).colorLabels || {},
})

export default connect(mapStateToProps, {})(translate()(ToolbarColorFilter))
