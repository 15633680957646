export const ProjectItemType = {
  ROOT: 'ROOT',
  HEADING: 'HEADING',
  NOTE_LINK: 'NOTE_LINK',
  NEW_NOTE_LINK: 'NEW_NOTE_LINK',
  TEXT: 'TEXT',
  NOTE: 'NOTE',
  COMMENT: 'COMMENT',
  IMAGE: 'IMAGE',
}

export const WEBAPP_ROOT_URL = process.env.WEBAPP_ROOT_URL || 'https://app.auratikum.de'

export const ReferenceSortAttribute = {
  PERSON: 'PERSON',
  UPDATED: 'UPDATED',
  CREATED: 'CREATED',
}
