import { handleActions } from 'redux-actions'

import { updateCitationCluster } from 'actions/citation'

const reducer = handleActions({
  [updateCitationCluster]: (state, action) => {
    const collection = action.payload.noteId ? 'noteClusters' : 'projectClusters'
    const key = action.payload.noteId || action.payload.projectId
    return {
      ...state,
      [collection]: {
        ...state[collection],
        [key]: {
          ...action.payload.citationData,
        },
      },
    }
  },
}, {
  noteClusters: {},
  projectClusters: {},
})

export default reducer
