import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import { connect } from 'react-redux'

import ModalHeader from 'components/common/ModalHeader'
import ModalFooter from 'components/common/ModalFooter'
import Modal from 'containers/modal/Modal'
import { PremiumButton, PremiumOutlinedButton } from 'components/common/Button'
import ProjectSelector from 'selectors/projects'
import modifyClassName from 'helper/modifyClassName'
import tracker from 'tracking/tracker'
import {
  reduxForm, SubmissionError, Field, propTypes as reduxPropTypes, submit,
} from 'redux-form'

import { createSurveyResponse } from 'api'
import { required } from 'helper/validators'

import './style.less'

export const MODAL_ID = 'SERVICE_MODAL'

export const FORM_NAME = 'PLATFORM_SERVICE'

const availableServices = ['tutoring', 'copyEditing', 'translating']

class ServiceModal extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      selectedService: null,
    }

    this.renderFuncs = {
      tutoring: this.renderTutoring,
      copyEditing: this.renderCopyEditing,
      translating: this.renderTranslating,
    }
  }

  componentDidMount() {
    tracker.logEvent('PLATFORM_VIEW')
  }

  onSelect = (selectedService) => {
    tracker.logEvent('PLATFORM_SERVICE_SELECT', { service: selectedService })

    this.setState({
      selectedService,
    })
  }

  submit = async (values) => {
    try {
      await createSurveyResponse(values)
      tracker.logEvent('PLATFORM_SERVICE_FORM_RESPONSE', { ...values, service: this.state.selectedService })
    } catch (err) {
      throw new SubmissionError({ _error: 'Fehler beim Senden der Antworten. Bitte kontaktiere unseren Support.' })
    }
    this.setState({ selectedService: 'DONE' })
  }

  renderOverviewItem = (service) => {
    const { t } = this.props
    return (
      <div key={service} className={modifyClassName('ServiceModal__Item', { disabled: !this.props.services.includes(service) })}>
        <div className="ServiceModal__ItemDetails">
          <div className="ServiceModal__ItemTitle">{t(`services.${service}.title`)}</div>
          <div className="ServiceModal__ItemPricing">{t(`services.${service}.subtitle`)}</div>
          <PremiumOutlinedButton disabled={!this.props.services.includes(service)} onClick={() => this.onSelect(service)}>{t('common.details')}</PremiumOutlinedButton>
        </div>
        <div className="ServiceModal__ItemDescription" dangerouslySetInnerHTML={{ __html: t(`services.${service}.description`, { interpolation: { escapeValue: false } }) }} />
      </div>
    )
  }

  renderOverview = () => (
    <div className="ServiceModal__Overview">
    Auratikum ist mehr als nur ein Werkzeug. Mit unseren Services bekommst du auch inhaltliche Unterstützung.
      <div className="ServiceModal__Items">
        { availableServices.map(service => this.renderOverviewItem(service))}
      </div>
    </div>
  )

  renderField = field => (
    <div className="ServiceModal__FormGroup">
      <div className="ServiceModal__Label">
        {this.props.t(`services.${this.state.selectedService}.questions.${field.input.name}`)}
        {field.meta.error && <span className="ServiceModal__InputError">{field.meta.error}</span>}
      </div>
      <textarea {...field.input} className="ServiceModal__Input" />
    </div>
  )

  renderDetails = () => {
    const { selectedService } = this.state
    const { error } = this.props
    if (this.props.services.includes(selectedService)) {
      return (
        <div className="ServiceModal__Details">
          <h1 className="ServiceModal__DetailHeading">Vielen Dank für Dein Interesse</h1>
          <div className="ServiceModal__DetailParagraph">Aktuell sammeln wir Feedback aller Nutzer und suchen entsprechend Umsetzungspartner für die Service-Plattform.</div>
          <div className="ServiceModal__DetailParagraph">Hilf uns, Auratikum zu verbessern und sende uns deine Antworten.</div>
          { error && <div className="ServiceModal__Error">{error}</div>}
          <form className="ServiceModal__Form">
            <Field className="ServiceModal__Input" name="why" component={this.renderField} validate={required} />
            <Field className="ServiceModal__Input" name="what" component={this.renderField} />
            <Field className="ServiceModal__Input" name="past" component={this.renderField} />
            <Field className="ServiceModal__Input" name="payment" component={this.renderField} />
            { error && <div className="ServiceModal__Error">{error}</div>}
          </form>
        </div>
      )
    }

    return (
      <div className="ServiceModal__Note">
        <div className="ServiceModal__NoteTitle">Vielen Dank!</div>
        <div className="ServiceModal__NoteSub">Dein Feedback ist für die Weiterentwicklung von Auratikum sehr wertvoll!</div>
        <PremiumButton onClick={this.props.hide}>Ok</PremiumButton>
      </div>
    )
  }

  render() {
    const { selectedService } = this.state

    return (
      <div className="ServiceModal">
        <ModalHeader className="ServiceModal__Header" title="Service Plattform" />

        <div className="ServiceModal__Container">
          { selectedService ? this.renderDetails() : this.renderOverview() }
        </div>
        {selectedService && this.props.services.includes(selectedService) && (
        <ModalFooter
          className=""
          confirmButtonCls={PremiumButton}
          confirmButtonLabel="Absenden"
          onConfirmButtonClick={this.props.handleSubmit(this.submit)}
          cancelButtonLabel="Zurück"
          cancelButtonCls={PremiumOutlinedButton}
          onCancelButtonClick={() => this.setState({ selectedService: null })}
          isLoading={this.props.submitting}
        />
        )}
      </div>

    )
  }
}

ServiceModal.propTypes = {
  ...i18nPropTypes,
  ...reduxPropTypes,
  services: PropTypes.array,
}

ServiceModal.defaultProps = {
  services: ['tutoring', 'copyEditing', 'translating'],
}

const mapStateToProps = state => ({
  project: ProjectSelector.getSelectedProject(state),
})

const form = { form: FORM_NAME }

export default Modal(connect(mapStateToProps, { submit })(translate()(reduxForm(form)(ServiceModal))))
