import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { propTypes as i18nPropTypes, translate } from 'i18n'

import Page from 'components/page/Page'
import PageHeader from 'components/page/PageHeader'
import PageContent from 'components/page/PageContent'

import ReferenceDetailForm from 'containers/reference-detail/ReferenceDetailForm'
import ReferenceDetailSidebar from 'containers/reference-detail/ReferenceDetailSidebar'
import AddReferenceButton from 'containers/references/AddReferenceButton'

import { getIsExternalLib, getReferenceFromPath } from 'selectors/references'

import './style.less'


const ReferenceDetailPage = ({ reference, isExternalLib }) => (
  <Page className="ReferenceDetailPage">
    <PageHeader>
      { isExternalLib && (
      <div className="ReferenceDetailPage__Actions">
        <AddReferenceButton reference={reference} />
      </div>
      ) }
    </PageHeader>
    <PageContent className="ReferenceDetailPage__Content">
      <div className="ReferenceDetailPage__Form">
        <ReferenceDetailForm />
      </div>
      { !isExternalLib && <ReferenceDetailSidebar /> }
    </PageContent>
  </Page>
)

ReferenceDetailPage.propTypes = {
  ...i18nPropTypes,
  isExternalLib: PropTypes.bool,
}

const mapStateToProps = state => ({
  isExternalLib: getIsExternalLib(state),
  reference: getReferenceFromPath(state),
})

export default connect(mapStateToProps, {})(translate()(ReferenceDetailPage))
