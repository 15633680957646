import React from 'react'
import PropTypes from 'prop-types'

import './style.less'

const Icon = ({ className = '', icon }) => (
  <div className={`Icon ${className}`}>
    { icon }
  </div>
)

Icon.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
}

export default Icon
