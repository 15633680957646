import React from 'react'
import PropTypes from 'prop-types'
import './style.less'

const ProjectAddCard = ({ children, onClick }) => (
  <div className="ProjectAddCard">
    <button type="button" className="ProjectAddCard__Card" onClick={onClick}>
      {children}
    </button>
  </div>
)

ProjectAddCard.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
}

export default ProjectAddCard
