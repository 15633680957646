import React from 'react'

import Spinner from 'components/common/Spinner'

import './style.less'

const AuthCheckLoading = () => (
  <div className="AuthCheckLoading">
    <div className="AuthCheckLoading__Title">Auratikum</div>
    <Spinner size="3x" />
  </div>
)

export default AuthCheckLoading
