import { handleActions } from 'redux-actions'

import { logout, changeAuth, states } from 'actions/authentication'


const reducer = handleActions({
  [changeAuth]: (state, action) => ({
    state: action.payload,
  }),
  [logout]: () => ({
    state: states.NOT_AUTHENTICATED,
  }),
}, { state: 'UNKNOWN' })

export default reducer
