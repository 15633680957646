import React from 'react'
import PropTypes from 'prop-types'
import FontIcon from 'components/common/FontIcon'
import modifyClassName from 'helper/modifyClassName'

import './style.less'

const FlyoutHeader = ({ onClose, title, className }) => (
  <div className={`${modifyClassName('FlyoutHeader', { noExitButton: !onClose })} ${className || ''}`}>
    <div className="FlyoutHeader__Title">{title}</div>
    {onClose && (
    <div>
      <button onClick={onClose}>
        {' '}
        <FontIcon icon={FontIcon.Icons.faTimes} />
      </button>
    </div>
    )}
  </div>
)

FlyoutHeader.propTypes = {
  onClose: PropTypes.func,
  title: PropTypes.string,
  className: PropTypes.string,
}

FlyoutHeader.defaultPropTypes = {
  className: '',
}

export default FlyoutHeader
