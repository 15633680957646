import React from 'react'
import PropTypes from 'prop-types'

import NavigationPath from 'containers/common/NavigationPath'


import './style.less'

const PageHeader = ({ icon, children, title }) => (
  <div className="PageHeader">
    { icon && <div className="PageHeader__Icon">{icon}</div>}
    { title && <div className="PageHeader__Title">{title}</div>}
    { !title && <NavigationPath /> }
    <div className="PageHeader__Children">{children}</div>
  </div>
)

PageHeader.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node,
  title: PropTypes.string,
}

export default PageHeader
