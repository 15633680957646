import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  without, get, compact, values, memoize,
} from 'lodash'
import { connect } from 'react-redux'
import { fieldPropTypes } from 'redux-form'
import { push } from 'connected-react-router'

import { propTypes as i18nPropTypes, translate } from 'i18n'

import ReferenceCitation from 'components/common/ReferenceCitation'
import { ADD_REFERENCE_MODAL_ID } from 'containers/modal/AddReferenceModal'
import { PAYWALL_MODAL_ID } from 'containers/modal/PaywallModal'
import { isReferenceCountPremiumRestricted } from 'selectors/subscriptions'

import { showModal } from 'actions/modal'

import Icon from 'components/common/Icon'

import { AuratikumFontIcons } from 'helper/utils'
import ReferenceFileDownload from 'components/references/ReferenceFileDownload'
import ReferenceUrlIcon from 'components/references/ReferenceUrlIcon'

import ReferenceSelectors from 'selectors/references'
import { createReference, findUserReference } from 'actions/references'
import './style.less'
import ReferencePicker from '../ReferencePicker'

class ReferenceSelectorField extends Component {
  handleSelection = async (ref) => {
    let reference = ref
    if (reference.fromLibrary) {
      reference = await this.props.findUserReference(reference)
      if (!reference) {
        // create new reference from external reference
        reference = await this.props.createReference(ref)
      }
    }
    if (this.props.input.value.indexOf(reference.id) < 0) {
      const newValue = [...this.props.input.value, reference.id]
      this.props.input.onChange(newValue)
    }
  }

  handleCreate = async () => {
    if (this.props.isPremiumRestricted) {
      this.props.showModal(PAYWALL_MODAL_ID)
      return
    }
    if (!this.props.formIsDirty || (this.props.formIsDirty && window.confirm(this.props.t('common.unsavedChangesPrompt')))) {
      this.props.showModal(ADD_REFERENCE_MODAL_ID)
    }
  }

  handleDelete = (e, referenceId) => {
    const newValue = without(this.props.input.value, referenceId)
    this.props.input.onChange(newValue)
    e.stopPropagation()
    e.preventDefault()
  }

  renderReference = (html, referenceId, isUncited) => {
    const reference = this.props.references[referenceId]
    return (
      <div className="ReferenceSelectorFieldItem" key={referenceId}>
        <ReferenceCitation className="ReferenceSelectorFieldItem__Label" html={html} reference={reference} isLink />
        <div className="ReferenceSelectorFieldItem__Controls">
          <div className="ReferenceSelectorFieldItem__Control">
            <ReferenceUrlIcon reference={reference} />
          </div>
          <div className="ReferenceSelectorFieldItem__Control">
            <ReferenceFileDownload reference={reference} />
          </div>
          { isUncited ? (
            <button onClick={e => this.handleDelete(e, referenceId)} type="button" className="ReferenceSelectorFieldItem__DeleteIcon">
              <Icon icon={AuratikumFontIcons.ADD} />
            </button>
          ) : <div className="ReferenceSelectorFieldItem__PlaceholderIcon" /> }
        </div>
      </div>
    )
  }


  renderBibliography = () => {
    const { citationCluster, input } = this.props
    if (get(citationCluster, 'bibliography.meta.referenceIds.length', 0) === 0) return null

    return (
      <React.Fragment>
        <div className="NoteDetailForm__Bibliography">
          { get(citationCluster, 'bibliography.meta.referenceIds', [])
            .map((referenceId, index) => this.renderReference(get(citationCluster, ['bibliographyEntries', index], null), referenceId, input.value.includes(referenceId)))}
        </div>
      </React.Fragment>
    )
  }

  renderSuggestion = reference => <div><ReferenceCitation className="ReferenceSelector__Reference--suggestList" reference={reference} /></div>

  render = () => (
    <div className="ReferenceSelectorField">
      { this.renderBibliography() }
      <ReferencePicker
        citationCluster={this.props.citationCluster}
        onCreate={this.handleCreate}
        onSelect={this.handleSelection}
        ignoreIds={this.props.excludeIds}
        hintTranslationKey={get(this.props, 'input.value.length', 0) - get(this.props, 'citationCluster.bibliography.meta.referenceIds.length', 0) === 0 ? 'noteDetailView.referenceSelectorField.hint' : undefined}
      />
    </div>
  )
}

ReferenceSelectorField.propTypes = {
  ...fieldPropTypes,
  ...i18nPropTypes,
  references: PropTypes.shape({}),
  formIsDirty: PropTypes.bool,
  isPremiumRestricted: PropTypes.bool,
}

const getExcludedIds = memoize((inputValue, citationCluster, references) => [...(inputValue || []),
  ...get(citationCluster, 'bibliography.meta.referenceIds', []),
  ...get(citationCluster, 'bibliography.meta.uncitedReferenceIds', []),
  ...compact(values(references).map(r => r.externalId))])

const mapStateToProps = (state, props) => {
  const references = ReferenceSelectors.getRaw(state)
  return {
    references,
    isPremiumRestricted: isReferenceCountPremiumRestricted(state),
    excludeIds: getExcludedIds(props.input.value, props.citationCluster, references),
  }
}

export default connect(mapStateToProps, {
  push,
  showModal,
  createReference,
  findUserReference,
})(translate()(ReferenceSelectorField))
