import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { DragSource } from 'react-dnd'
import { ScrollElement } from 'react-scroll'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import { AuratikumFontIcons } from 'helper/utils'
import ReferenceCitation from 'components/common/ReferenceCitation'

import { get } from 'lodash'

import Icon from 'components/common/Icon'
import { SmallTag } from 'components/common/Tag'
import Spinner from 'components/common/Spinner'

import FontIcon from 'components/common/FontIcon'
import { parseColor } from 'helper/colorHandler'
import modifyClassName from 'helper/modifyClassName'
import { NoteDragImage } from 'helper/DragImages'
import NoteLinkFlyout from 'containers/note-link/NoteLinkFlyout'
import { SimpleButtonToolTip } from 'components/common/Button'
import { PAYWALL_MODAL_ID } from 'containers/modal/PaywallModal'

import './style.less'

class NoteListItem extends Component {
  state = {
    isHover: false,
    isDuplicating: false,
    showNoteLinkFlyout: false,
  }

  componentDidMount() {
    const img = new Image()
    img.onload = () => this.props.connectDragPreview && this.props.connectDragPreview(img)
    img.src = NoteDragImage
  }

  onMouseEnter = () => this.setState({ isHover: true })

  onMouseLeave = () => this.setState({ isHover: false })

  onDelete = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (this.props.onDeleteNote) {
      this.props.onDeleteNote(this.props.note)
    }
  }

  onDuplicate = async (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (this.props.isPremiumRestricted) {
      this.props.showModal(PAYWALL_MODAL_ID)
      return
    }
    if (this.props.onDuplicateNote && !this.props.isPremiumRestricted && !this.state.isDuplicating) {
      this.setState({ isDuplicating: true })
      await this.props.onDuplicateNote(this.props.note)
      this.setState({ isDuplicating: false })
    }
  }

  handleLinkNote = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const { showNoteLinkFlyout } = this.state
    this.setState({
      showNoteLinkFlyout: !showNoteLinkFlyout,
    })
  }

  handleLinkNoteFlyoutOutsideClick = () => {
    this.setState({
      showNoteLinkFlyout: false,
    })
  }

  openNote = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (!this.state.showNoteLinkFlyout) this.props.onOpenNote(this.props.note)
  }

  renderTitleContent = (title, content, isHover) => (
    <div className="NoteListItem__TitleContent">
      <div className="NoteListItem__Title">
        {title}
      </div>
      { content && !isHover && (
        <div className="NoteListItem__Content" dangerouslySetInnerHTML={{ __html: content.replace(/<(?:.|\n)*?>/gm, '') }} />
      )}
    </div>
  )

  renderReference = reference => (
    <div className="NoteListItem__Reference">
      <Icon className="NoteListItem__ReferenceIcon" icon="C" />
      { reference ? (
        <ReferenceCitation
          className="NoteListItem__ReferenceCitation"
          reference={reference}
        />
      )
        : <div className="NoteListItem__ReferenceCitation">{this.props.t('common.noReference') }</div>
      }
    </div>
  )

  renderLinkCount = linkedNotes => (
    <div className="NoteListItem__LinkCount">
      <Icon className="NoteListItem__LinkCountIcon" icon="B" />
      <div className="NoteListItem__LinkCountNumber">{linkedNotes.length}</div>
    </div>
  )

  renderTags = tags => (
    <div className="NoteListItem__Tags">
      { tags && tags.slice(0, 6).map(tag => <SmallTag className="NoteListItem__Tags" key={tag} tag={tag} />)}
    </div>
  )

  renderActions = () => (
    <div className="NoteListItem__Actions">
      <NoteLinkFlyout
        showFlyout={this.state.showNoteLinkFlyout}
        onOutsideClick={this.handleLinkNoteFlyoutOutsideClick}
        note={this.props.note}
      >
        <SimpleButtonToolTip
          className="NoteListItem__ActionsButton"
          onClick={this.handleLinkNote}
          tooltipText={this.props.t('linkNoteFlyout.addToOutline')}
          tooltipPlacement="bottom"
        >
          <Icon icon={AuratikumFontIcons.BOARDADD} />
        </SimpleButtonToolTip>
      </NoteLinkFlyout>
      <button className="NoteListItem__ActionsButton" onClick={this.onDuplicate} type="button">
        <Spinner show={this.state.isDuplicating}><FontIcon icon={FontIcon.Icons.faClone} /></Spinner>
      </button>
      <button className="NoteListItem__ActionsButton" onClick={this.onDelete} type="button"><Icon icon="K" /></button>
    </div>
  )

  renderNoteItem = () => {
    const {
      note: {
        color,
        title,
        linkedNotes,
        content,
        tags,
      },
    } = this.props
    const reference = get(this.props, 'note.references[0]')
    const { isHover, showNoteLinkFlyout } = this.state
    const isHoverState = isHover || showNoteLinkFlyout

    return this.props.connectDragSource(
      <div
        className={modifyClassName('NoteListItem', { isHover: isHoverState })}
        style={{ borderLeftColor: parseColor(color) }}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        { this.renderTitleContent(title, content, isHoverState)}
        { !isHoverState && this.renderReference(reference) }
        { !isHoverState && this.renderLinkCount(linkedNotes) }
        { isHoverState && this.renderTags(tags) }
        { isHoverState && this.renderActions() }
      </div>,
    )
  }

  render = () => {
    const { name, style } = this.props

    return (
      <div name={name} style={style}>
        { /* draggable issue with firefox if using a navlink here */}
        { // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        }
        {' '}
        <div onClick={this.openNote} className="NoteListItem__Link">{this.renderNoteItem()}</div>
      </div>
    )
  }
}

NoteListItem.propTypes = {
  note: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    content: PropTypes.string,
    referenceId: PropTypes.string,
    reference: PropTypes.shape({}),
    color: PropTypes.string,
  }),
  name: PropTypes.string,
  onDeleteNote: PropTypes.func,
  onDuplicateNote: PropTypes.func,
  onOpenNote: PropTypes.func,
  connectDragSource: PropTypes.func,
  showModal: PropTypes.func,
  style: PropTypes.shape({}),
  ...i18nPropTypes,
}

const noteSource = {
  beginDrag(props) {
    return {
      item: props.note,
    }
  },
}

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  }
}


export default DragSource('NEW_NOTE_LINK', noteSource, collect)(ScrollElement(translate()(NoteListItem)))
