import React, { Component } from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'

import PropTypes from 'prop-types'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import { deleteReference, updateReference } from 'actions/references'
import ReferenceSelector from 'selectors/references'

import { showModal } from 'actions/modal'

import ReferenceListItem from 'components/references/ReferenceListItem'
import DeleteReferenceModal from 'containers/modal/DeleteReferenceModal'
import AddNewReferenceButton from 'containers/references/AddNewReferenceButton'

import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer'
import { ScrollRestoredVList } from 'containers/common/ScrollRestoration'

import './style.less'

const DELETE_MODAL_ID = 'DELETE_REFERENCE_CONFIRMATION_MODAL'
const SCROLL_CONTAINER_ID = 'USER_REFERENCE_LIST_SCROLL_ID'


class ReferenceListUser extends Component {
  static propTypes = {
    references: PropTypes.array,
    showModal: PropTypes.func,
    ...i18nPropTypes,
  }

  handleDelete = (reference) => {
    this.props.showModal(DELETE_MODAL_ID, { data: { ...reference } })
  }

  handleSelect = (reference) => {
    this.props.push(`/references/lib/${reference.id}`)
  }

  handleConfirmDeleteReference = async (reference) => {
    await this.props.deleteReference(reference)
  }

  renderEmptyState(key) {
    const { t, searchQuery } = this.props
    return (
      <div className="ReferenceListUser__NoResultContainer">
        <div className="ReferenceListUser__NoResultDescription">{t(key, { searchQuery })}</div>
      </div>
    )
  }

  renderRow = ({
    index, key, style,
  }) => {
    if (index >= this.props.references.length) {
      return <div key={key} style={style} />
    }
    return (
      <ReferenceListItem
        key={key}
        reference={this.props.references[index]}
        onSelect={this.handleSelect}
        onDelete={this.handleDelete}
        onUpdate={this.props.updateReference}
        style={style}
      />
    )
  }

  render() {
    const {
      references, className, searchQuery,
    } = this.props
    return (
      <div className={`${className || ''} ReferenceListUser`}>
        { references.length === 0 && searchQuery && this.renderEmptyState('references.noResultDescription') }
        { references.length === 0 && (!searchQuery || searchQuery === '') && this.renderEmptyState('references.emptyCollectionDescription') }

        {references.length > 0 && (
        <AutoSizer>
          {({ height, width }) => (
            <ScrollRestoredVList
              id={SCROLL_CONTAINER_ID}
              width={width}
              height={height}
              overscanRowCount={10}
              noRowsRenderer={() => <div className="norow" />}
              rowCount={references.length + 2}
              rowHeight={60}
              rowRenderer={this.renderRow}
            />
          )}
        </AutoSizer>
        )
        }

        <AddNewReferenceButton />
        <DeleteReferenceModal id={DELETE_MODAL_ID} onConfirm={this.handleConfirmDeleteReference} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  references: ReferenceSelector.getSortedFiltered(state),
  searchQuery: ReferenceSelector.getSearchQuery(state),
})


export default connect(mapStateToProps, {
  push,
  showModal,
  deleteReference,
  updateReference,
})(translate()(ReferenceListUser))
