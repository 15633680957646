import { handleActions } from 'redux-actions'

import { enableHelpSystem, openHelpSpot, closeHelpSpot } from 'actions/help'

const reducer = handleActions({
  [enableHelpSystem]: state => ({
    ...state,
    enabled: !state.enabled,
  }),
  [openHelpSpot]: (state, action) => ({
    ...state,
    openSpots: [...new Set([...state.openSpots, action.payload])],
  }),
  [closeHelpSpot]: (state, action) => {
    const openSpots = [...state.openSpots]
    openSpots.splice(openSpots.indexOf(action.payload), 1)
    return {
      ...state,
      openSpots,
    }
  },
}, {
  enabled: false,
  openSpots: [],
})

export default reducer
