import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { push } from 'connected-react-router'
import {
  reduxForm, SubmissionError, Field, propTypes as reduxPropTypes, getFormValues,
} from 'redux-form'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import tracker from 'tracking/tracker'
import { PrimaryButton } from 'components/common/Button'
import { newPassword } from 'actions/authentication'
import queryString from 'query-string'
import { get } from 'lodash'


class NewPassword extends Component {
  submit = async (values) => {
    if (values.newPassword !== values.repeatPassword) {
      throw new SubmissionError({ _error: this.props.t('auth.newPassword.passwordsDoNotMatch') })
    }
    try {
      await this.props.newPassword(values)
      this.props.push(`/login?email=${encodeURIComponent(values.email)}`)
      tracker.logEvent('NEW_PASSWORD_SET')
    } catch (err) {
      throw new SubmissionError({ _error: err.code })
    }
  }


  render() {
    const {
      handleSubmit, submitting, error, t, formValues,
    } = this.props
    const urlParam = formValues.email ? `?email=${encodeURIComponent(formValues.email)}` : ''
    return (
      <React.Fragment>
        <div className="AuthForm__Title">{t('auth.newPassword.title')}</div>
        <div className="AuthForm__Description">{t('auth.newPassword.intro')}</div>
        { error && <div className="AuthForm__Alert">{ this.props.t(`auth.errors.${error}`) || this.props.t('auth.errors.default') }</div> }
        <form className="AuthForm__Form" onSubmit={handleSubmit(this.submit)}>
          <Field className="AuthForm__Input" name="email" component="input" type="text" placeholder={t('common.email')} />
          <Field className="AuthForm__Input" name="verificationCode" component="input" type="text" placeholder={t('auth.newPassword.verificationCode')} />
          <Field className="AuthForm__Input" name="newPassword" component="input" type="password" placeholder={t('auth.newPassword.newPassword')} />
          <Field className="AuthForm__Input AuthForm__Input--single" name="repeatPassword" component="input" type="password" placeholder={t('auth.newPassword.repeatPassword')} />
          <PrimaryButton className="AuthForm__SubmitButton" type="submit" isLoading={submitting}>{t('auth.newPassword.title')}</PrimaryButton>
        </form>
        <NavLink to={`/reset${urlParam}`} className="AuthForm__Link">{t('auth.newPassword.sendMailAgain')}</NavLink>
      </React.Fragment>
    )
  }
}

const form = { form: 'SetNewPassword' }

NewPassword.propTypes = {
  ...reduxPropTypes,
  ...i18nPropTypes,
  newPassword: PropTypes.func,
  formValues: PropTypes.shape({}),
}

const mapStateToProps = (state, ownProps) => ({
  initialValues: {
    email: get(queryString.parse(ownProps.location.search), 'email'),
    verificationCode: get(queryString.parse(ownProps.location.search), 'verificationCode'),
  },
  formValues: getFormValues(form.form)(state) || {},
})

export default connect(mapStateToProps, {
  newPassword,
  push,
})(reduxForm(form)(translate()(NewPassword)))
