import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { AuratikumFontIcons } from 'helper/utils'
import Icon from 'components/common/Icon'
import { Manager, Reference } from 'react-popper'
import { get } from 'lodash'
import ProjectListFlyout from 'components/common/ProjectListFlyout'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import modifyClassName from 'helper/modifyClassName'

import './style.less'

const PROJECT_LIST_FLYOUT_ID = 'PROJECT_LIST_FLYOUT_ID'

class ToolbarProjectFilter extends Component {
  showFlyout = () => {
    this.props.showFlyout(PROJECT_LIST_FLYOUT_ID)
  }

  render() {
    const {
      projects,
      selectedProjectId,
      onSelect,
      t,
    } = this.props

    const selectedProject = get(projects.filter(project => project.id === selectedProjectId), '[0]')

    return (
      <div className="ToolbarProjectFilter">
        <div>
          <Manager>
            <Reference>
              { ({ ref }) => (
                <div ref={ref} className="ToolbarProjectFilter__Container">
                  <span className="ToolbarProjectFilter__ButtonText">
                    {selectedProject ? `${t('common.project')}: ${selectedProject.title}` : t('notesOverView.toolbar.selectNoProject') }
                  </span>
                  <button type="button" onClick={this.showFlyout} className={modifyClassName('ToolbarProjectFilter__Button', { default: !selectedProject })}>
                    <Icon className="ToolbarProjectFilter__Caret" icon={AuratikumFontIcons.ARROW_DOWN} />
                  </button>
                </div>
              )}
            </Reference>
            <ProjectListFlyout
              projects={projects}
              id={PROJECT_LIST_FLYOUT_ID}
              selectedProjectId={selectedProjectId}
              onSelect={onSelect}
            />
          </Manager>
        </div>
      </div>
    )
  }
}

ToolbarProjectFilter.propTypes = {
  showFlyout: PropTypes.func,
  projects: PropTypes.array,
  selectedProjectId: PropTypes.string,
  onSelect: PropTypes.func,
  ...i18nPropTypes,
}


export default translate()(ToolbarProjectFilter)
