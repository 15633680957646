import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { propTypes as i18nPropTypes, translate } from 'i18n'
import { push } from 'connected-react-router'
import SubscriptionSelector from 'selectors/subscriptions'
import NoteSelector from 'selectors/notes'
import ProjectSelector from 'selectors/projects'
import ReferenceSelector from 'selectors/references'


import modifyClassName from 'helper/modifyClassName'
import ConfirmationModal from '../ConfirmationModal'

import './style.less'

export const PAYWALL_MODAL_ID = 'PAYWALL_MODAL_ID'

class PaywallModal extends Component {
  static propTypes = {
    id: PropTypes.string,
    ...i18nPropTypes,
  }

  renderQuota = (quota, count, tKey) => {
    const { t } = this.props
    return (
      <div className={modifyClassName('PaywallModal__Quota', { reached: quota <= count })}>
        <div className="PaywallModal__QuotaLabel">{t(tKey)}</div>
        <div className="PaywallModal__QuotaValue">{`${count} / ${quota}`}</div>
      </div>
    )
  }

  renderContent = () => {
    const {
      quotas, noteCount, projectCount, referenceCount, t,
    } = this.props
    return (
      <React.Fragment>
        <div className="">{this.props.t('paywallModal.description')}</div>
        <div className="PaywallModal__SubDescription">{t('paywallModal.subDescription')}</div>
        {this.renderQuota(quotas.projects, projectCount, 'common.projects')}
        {this.renderQuota(quotas.notes, noteCount, 'common.notes')}
        {this.renderQuota(quotas.references, referenceCount, 'common.references')}
      </React.Fragment>
    )
  }

  handleConfirm = () => {
    this.props.push('/profile/premium')
  }

  render() {
    const { t, id } = this.props
    return (
      <ConfirmationModal
        className="PaywallModal"
        id={PAYWALL_MODAL_ID}
        title={t('paywallModal.title')}
        onConfirm={this.handleConfirm}
        okLabel={t('paywallModal.goToPremium')}
        cancelLabel={t('common.cancel')}
        contentFunc={this.renderContent}
      />
    )
  }
}


const mapStateToProps = state => ({
  quotas: SubscriptionSelector.getQuotas(state),
  noteCount: NoteSelector.getCount(state),
  projectCount: ProjectSelector.getCount(state),
  referenceCount: ReferenceSelector.getCount(state),
})

export default connect(mapStateToProps, {
  push,
})(translate()(PaywallModal))
