import { handleActions } from 'redux-actions'

import {
  writingSidebarVisibilityChangedAction,
} from 'actions/writing-sidebar'

const reducer = handleActions({
  [writingSidebarVisibilityChangedAction]: state => ({
    ...state,
    visible: !state.visible,
  }),
}, {
  visible: false,
})

export default reducer
