/* global window */
import * as Sentry from '@sentry/browser'

import createSentryMiddleware from 'redux-sentry-middleware'
import { cognitoGetUser } from 'integrations/AWSCognito'

const SENTRY_DNS = 'https://05697c578f1048b6889bf453eb369781@sentry.io/250252'

const MIDDLEWARE_WITH_SENTRY = () => next => (action) => {
  const { type, payload } = action

  if (type === 'LOGOUT' || (type === 'CHANGE_AUTH' && payload === 'NOT_AUTHENTICATED')) {
    (async () => {
      Sentry.configureScope((scope) => {
        scope.setUser({ email: 'john.doe@example.com' })
      })
    })()
  } else if (type === 'CHANGE_AUTH' && payload === 'AUTHENTICATED') {
    (async () => {
      const user = await cognitoGetUser()
      Sentry.configureScope((scope) => {
        scope.setUser({ email: user.username })
      })
    })()
  }

  try {
    return next(action)
  } catch (err) {
    Sentry.captureException(err)
    throw err
  }
}

const MIDDLEWARE_ERROR_LOG = () => next => (action) => {
  try {
    return next(action)
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err)
    throw err
  }
}

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: SENTRY_DNS,
    maxBreadcrumbs: 20,
    release: process.env.CI_PIPELINE_ID,
    environment: process.env.CI_ENVIRONMENT_NAME,
  })
}


const parseOptions = (options) => {
  let parsedOptions = { headers: {} }
  try {
    if (options) {
      parsedOptions = { ...options, ...parsedOptions }
      if (options.headers) {
        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of options.headers.entries()) {
          parsedOptions.headers[key] = value
        }
      }
    }
  } catch (e) {
    // ignore failed parsing
  }
  return parsedOptions
}


class SentryIntegration {
  constructor() {
    this.isProduction = process.env.NODE_ENV === 'production'

    if (this.isProduction) {
      window.onunhandledrejection = (evt) => {
        Sentry.captureException(evt.reason)
      }
    }
  }

  getMiddlewares() {
    return this.isProduction ? [
      MIDDLEWARE_WITH_SENTRY,
      createSentryMiddleware(Sentry, {
        stateTransformer: (state) => {
          // eslint-disable-next-line no-unused-vars
          const {
            entities, reference, notePaging, tag, ...newState
          } = state
          return newState
        },
      }),
    ] : []
  }

  logError(err) {
    if (this.isProduction) {
      if (err.name === 'NetworkException' || err.name === 'APIException') {
        const options = parseOptions(err.options)
        Sentry.withScope((scope) => {
          scope.setExtra('url', err.url)
          scope.setExtra('options', options)
          scope.setExtra('originalMessage', err.originalMessage)
          Sentry.captureException(err)
        })
      } else {
        Sentry.captureException(err)
      }
    }
  }
}

export default new SentryIntegration()
