import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  reduxForm,
  Field,
} from 'redux-form'
import { updateProfile } from 'actions/profiles'
import ProfileSelector from 'selectors/profile'
import { PrimaryButton } from 'components/common/Button'
import FormTextInput from 'components/common/FormTextInput'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import { noteColors, parseColor } from 'helper/colorHandler'
import './style.less'

export const FORM_NAME = 'ColorNamingForm'


class ColorNamingForm extends Component {
  static propTypes = {
    ...i18nPropTypes,
  }

  onSubmit = async (data) => {
    const { profile } = this.props
    const newProfile = { ...profile, colorLabels: data }
    await this.props.updateProfile(newProfile)
  }

  renderField = color => (
    <div key={color.toString()} className="ColorNamingForm__FieldContainer">
      <div
        className="ColorNamingForm__Color"
        style={{ backgroundColor: parseColor(color) }}
      />
      <Field className="ColorNamingForm__Field" name={color.toString()} component={FormTextInput} />
    </div>
  )

  render() {
    const { handleSubmit, t, submitting } = this.props

    return (
      <form className="ColorNamingForm">
        {noteColors.map(color => this.renderField(color))}
        <PrimaryButton className="ColorNamingForm__SubmitButton" type="button" onClick={handleSubmit(this.onSubmit)} isLoading={submitting}>{t('common.save')}</PrimaryButton>
      </form>
    )
  }
}


const form = {
  form: FORM_NAME,
}

const mapStateToProps = state => ({
  profile: ProfileSelector.getProfile(state),
  initialValues: ProfileSelector.getProfile(state).colorLabels || {},
})

export default connect(mapStateToProps, {
  updateProfile,
})(reduxForm(form)(translate()(ColorNamingForm)))
