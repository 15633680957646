import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { propTypes as i18nPropTypes, translate } from 'i18n'

import { NativeTypes } from 'react-dnd-html5-backend'
import { DropTarget } from 'react-dnd'

import Icon from 'components/common/Icon'
import Spinner from 'components/common/Spinner'

import { AuratikumFontIcons } from 'helper/utils'

import './style.less'

class FileDrop extends Component {
  static propTypes = {
    ...i18nPropTypes,
    connectDropTarget: PropTypes.func,
    isOver: PropTypes.bool,
    onDrop: PropTypes.func,
    isLoading: PropTypes.bool,
    text: PropTypes.string,
    textDrop: PropTypes.string,
    className: PropTypes.string,
    isMultiFileUpload: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
  }

  setRefs = (ref) => {
    this.fileInputEl = ref
  }

  handleClick = () => {
    if (!this.props.isLoading) {
      this.fileInputEl.click()
    }
  }

  handleOnChange = (event) => {
    const { files } = event.target
    this.props.onDrop([...files])
  }

  render() {
    const {
      connectDropTarget, isOver, isLoading, text, textDrop, className,
    } = this.props
    return connectDropTarget(
      <button type="button" className={`FileDrop ${className || ''}`} onClick={this.handleClick}>
        <input className="FileDrop__Input" type="file" ref={this.setRefs} onChange={this.handleOnChange} />
        {isLoading
          ? <div className="FileDrop__Subtitle"><Spinner /></div>
          : (
            <div className="FileDrop__Hint">
              <Icon icon={AuratikumFontIcons.DRAGANDDROP} className="FileDrop__Icon" />
              <div className="FileDrop__Text">{isOver ? textDrop : text}</div>
            </div>
          )}
      </button>,
    )
  }
}

const fileTarget = {
  drop(props, monitor) {
    if (!props.isLoading) {
      const { files } = monitor.getItem()
      if (!props.isMultiFileUpload && files.length > 1) alert(props.t('common.fileDropFileCountError'))
      else props.onDrop(files)
    }
  },
}


export default translate()(DropTarget(NativeTypes.FILE, fileTarget, (con, monitor) => ({
  connectDropTarget: con.dropTarget(),
  isOver: monitor.isOver(),
  canDrop: monitor.canDrop(),
}))(FileDrop))
