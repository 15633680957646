import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { propTypes as i18nPropTypes, translate } from 'i18n'

import { DragSource } from 'react-dnd'
import PureWrapper from 'components/PureWrapper'
import ReferenceCitation from 'components/common/ReferenceCitation'
import { parseColor } from 'helper/colorHandler'
import { ScrollElement } from 'react-scroll'
import { NoteDragImage } from 'helper/DragImages'

import ThreeDotsButton from 'components/common/ThreeDotsButton'
import { OutlinedButton } from 'components/common/Button'

import { AuratikumFontIcons } from 'helper/utils'
import Icon from 'components/common/Icon'

import modifyClassName from 'helper/modifyClassName'

import NoteGridItemMenu from '../NoteGridItemMenu'

import './style.less'

class NoteGridItem extends PureComponent {
  static propTypes = {
    note: PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string,
      content: PropTypes.string,
      referenceId: PropTypes.string,
      reference: PropTypes.shape({}),
      color: PropTypes.string,
    }),
    name: PropTypes.string,
    onDeleteNote: PropTypes.func,
    onDuplicateNote: PropTypes.func,
    connectDragSource: PropTypes.func,
    onOpenNote: PropTypes.func,
    isPremiumRestricted: PropTypes.bool,
    style: PropTypes.shape({}),
    ...i18nPropTypes,
  }

  state = {
    isHovering: false,
    isShowingMenu: false,
    showNoteLinkFlyout: false,
  }

  componentDidMount() {
    const img = new Image()
    img.onload = () => this.props.connectDragPreview && this.props.connectDragPreview(img)
    img.src = NoteDragImage
  }

  handleLinkNote = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const { showNoteLinkFlyout } = this.state
    this.setState({
      showNoteLinkFlyout: !showNoteLinkFlyout,
    })
  }

  handleLinkNoteFlyoutOutsideClick = () => {
    this.setState({
      showNoteLinkFlyout: false,
    })
  }

  onMouseEnter = () => {
    this.setState({ isHovering: true })
  }

  onMouseLeave = () => {
    this.setState({
      isHovering: false,
      isShowingMenu: false,
    })
  }

  onMenuClick = (event) => {
    event.stopPropagation()
    event.preventDefault()
    const { isShowingMenu } = this.state
    this.setState({
      isShowingMenu: !isShowingMenu,
    })
  }

  onMenuDeleteClick = (event) => {
    event.preventDefault()
    event.stopPropagation()
    this.props.onDeleteNote(this.props.note)
  }

  onMenuDuplicateClick = async (event) => {
    event.preventDefault()
    event.stopPropagation()
    await this.props.onDuplicateNote(this.props.note)
  }

  openNote = (event) => {
    event.preventDefault()
    event.stopPropagation()
    if (!this.state.showNoteLinkFlyout) this.props.onOpenNote(this.props.note)
  }

  renderReference() {
    const { note } = this.props
    if (get(note, 'references.length', 0) === 0) {
      return null
    }

    return (
      <div className="NoteGrid__ReferenceContainer">
        <Icon className="NoteGrid__ReferenceIcon" icon={AuratikumFontIcons.REFERENCE} />
        <ReferenceCitation className="NoteGrid__Reference" reference={get(note, 'references[0]')} />
      </div>
    )
  }

  renderHeader = () => {
    const { note: { title } } = this.props
    return (
      <h2 className="NoteGrid__Header">
        {title}
      </h2>
    )
  }

  renderMenu = () => {
    const { isShowingMenu } = this.state
    const { note } = this.props

    return (
      <div className="NoteGrid__OverlayContainer">
        {this.renderHeader()}
        <NoteGridItemMenu
          visible={isShowingMenu}
          onDeleteClick={this.onMenuDeleteClick}
          onDuplicateClick={this.onMenuDuplicateClick}
          isPremiumRestricted={this.props.isPremiumRestricted}
          onLinkNoteClick={this.handleLinkNote}
          showNoteLinkFlyout={this.state.showNoteLinkFlyout}
          note={note}
          onLinkNoteFlyoutOutsideClick={this.handleLinkNoteFlyoutOutsideClick}
        />
      </div>
    )
  }

  renderOverlay = () => {
    const { note, t } = this.props
    const { linkedNotes = [] } = note
    const { isShowingMenu } = this.state

    if (isShowingMenu) {
      return this.renderMenu()
    }
    return (
      <div className="NoteGrid__OverlayContainer">
        {this.renderHeader()}

        <ThreeDotsButton onClick={this.onMenuClick} className="NoteGrid__ThreeDotsButton" />
        <div className="NoteGrid__OverlayContent">
          <OutlinedButton onClick={this.openNote} className="NoteGrid__OpenNoteButton">
            {t('notesOverView.openNote')}
          </OutlinedButton>
        </div>
        <div className="NoteGrid__OverlayFooter">
          <div className="NoteGrid__OverlayUsedInProjects">
            <Icon className="NoteGrid__UsedInProjectsIcon" icon={AuratikumFontIcons.NOTES} />
            {linkedNotes.length}
          </div>
        </div>
      </div>
    )
  }

  renderNoteContent = () => {
    const { note: { content } } = this.props

    return (
      <React.Fragment>
        <div className="NoteGrid__Content">
          {this.renderHeader()}

          <div className="NoteGrid__Text">
            <div dangerouslySetInnerHTML={{ __html: content ? content.replace(/<(?:.|\n)*?>/gm, '') : null }} />
          </div>
        </div>
        <div className="NoteGrid__ReferenceWrapper">
          {this.renderReference()}
        </div>
      </React.Fragment>
    )
  }


  render = () => {
    const {
      note, name, style,
    } = this.props
    const { color } = note
    const { isHovering } = this.state

    return this.props.connectDragSource(
      <div style={style} className="NoteGrid">
        <div
          className={modifyClassName('NoteGrid__Container')}
          style={{ borderLeftColor: parseColor(color) }}
          name={name}
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
        >
          { /* draggable issue with firefox if using a navlink here */}
          { /* eslint-disable jsx-a11y/no-static-element-interactions */ }
          { /* eslint-disable jsx-a11y/click-events-have-key-events */ }
          <div className="NoteGrid__Link" onClick={this.openNote}>
            { isHovering ? this.renderOverlay() : this.renderNoteContent() }
          </div>
        </div>
      </div>,
    )
  }
}

const noteSource = {
  beginDrag(props) {
    return {
      item: props.note,
    }
  },
}

function collect(con, monitor) {
  return {
    connectDragSource: con.dragSource(),
    isDragging: monitor.isDragging(),
    connectDragPreview: con.dragPreview(),
  }
}

export default PureWrapper(ScrollElement(DragSource('NEW_NOTE_LINK', noteSource, collect)(translate()(NoteGridItem))))
