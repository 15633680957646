import { values, get } from 'lodash'
import { createSelector } from 'reselect'
import moment from 'moment'


export const npsSelector = state => state.entities.netPromoterScore

export const getNetPromoterScore = createSelector(
  npsSelector,
  netPromoterScore => values(netPromoterScore),
)

export const getActiveModal = state => get(state, 'netPromoterScore.activeModal')

export const getCurrentNetPromoterScore = createSelector(
  getNetPromoterScore,
  (netPromoterScore) => {
    const sortedNPS = values(netPromoterScore).sort((a, b) => moment(b.createdAt) - moment(a.createdAt))

    if (sortedNPS.length > 0) {
      return sortedNPS[0]
    }

    return null
  },
)

export default {
  getNetPromoterScore,
  getActiveModal,
  getCurrentNetPromoterScore,
}
