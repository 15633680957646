import React from 'react'

import AuthForm from 'components/auth/AuthForm'

import './style.less'


const AuthPage = () => (
  <div className="AuthPage">
    <div className="AuthPage__Wrapper">
      <AuthForm />
      <div className="AuthPage__BottomText">
        <div className="AuthPage__BottomTextLeft">{`Version ${process.env.VERSION_LABEL}`}</div>
      </div>
    </div>
  </div>
)

export default AuthPage
