import React from 'react'
import PropTypes from 'prop-types'

import './style.less'

// header without title can be used as placeholder
const ModalHeader = ({ className = '', title }) => (
  <div className={`ModalHeader ${className}`}>
    {title
    && (
    <div className="ModalHeader__Title">
      {title}
    </div>
    )}
  </div>
)

ModalHeader.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
}

export default ModalHeader
