import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import { Popper, Manager, Reference } from 'react-popper'
import Measure from 'react-measure'

import './style.less'

const inlineFlyoutRoot = document.getElementById('root-inline-flyout')

class FlyoutReference extends Component {
  static propTypes = {
    placement: PropTypes.string,
    children: PropTypes.element,
    showFlyout: PropTypes.bool,
    component: PropTypes.element,
    flyoutClassName: PropTypes.string,
    referenceClassName: PropTypes.string,
    onOutsideClick: PropTypes.func,
  }

  componentDidMount = () => {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount = () => {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  setFlyoutRef = (node) => {
    this.flyoutRef = node
  }

  handleClickOutside = (e) => {
    const { onOutsideClick } = this.props
    if (onOutsideClick && this.flyoutRef && !this.flyoutRef.contains(e.target)) {
      onOutsideClick(e)
    }
  }

  renderFlyout = () => (
    <Popper placement={this.props.placement} modifiers={{ preventOverflow: { boundariesElement: 'window', enabled: true } }}>
      {({ ref, style, scheduleUpdate }) => (
        <div ref={this.setFlyoutRef}>
          <div ref={ref} className={`FlyoutReference ${this.props.flyoutClassName || ''}`} style={style}>
            <Measure
              offset
              bounds
              onResize={scheduleUpdate}
            >
              {({ measureRef }) => (
                <div ref={measureRef}>
                  {this.props.component}
                </div>
              )
            }
            </Measure>
          </div>
        </div>
      )}
    </Popper>
  )

  render = () => (
    <Manager>
      <Reference>
        { ({ ref }) => <div ref={ref} className={`${this.props.referenceClassName || ''}`}>{this.props.children}</div> }
      </Reference>
      { (this.props.showFlyout && this.props.component) && ReactDOM.createPortal(this.renderFlyout(), inlineFlyoutRoot)}
    </Manager>
  )
}

export default FlyoutReference
