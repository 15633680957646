/* global document, window, event */
import 'whatwg-fetch'
import 'core-js/stable'
import 'regenerator-runtime/runtime'

import React from 'react'

import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'

import Authentication from 'containers/Authentication'

import PaymantCallback from 'containers/subscription/PaymentCallback'
import VerifyEmailCallback from 'containers/profile/VerifyEmailCallback'
import tracker from 'tracking/tracker'
import GoogleAnalyticsTracker from 'tracking/GaTracker'
import FacebookTracker from 'tracking/FacebookTracker'
import AuratikumTracker from 'tracking/AuratikumTracker'

import 'font-awesome/less/font-awesome.less'
import 'less/style.less'

import store, { history } from 'store'
import 'i18n' // initialized i18next instance

import tinymce from 'tinymce/tinymce'

import CitationPlugin from 'integrations/CitationPlugin'
import FormulaPlugin from 'integrations/FormulaPlugin'

import sbjs from 'sourcebuster'
import 'react-virtualized/styles.css'

import { hotjar } from 'react-hotjar'

window.tinymce = tinymce

sbjs.init({
  domain: 'auratikum.com',
  lifetime: 24, // months,
})

// import Perf from 'react-addons-perf'
// if (process.env.NODE_ENV === 'development') {
//   window.Perf = Perf
// }


// load data from localstorage
store.dispatch({ type: 'INIT' })

const rootElement = document.getElementById('root')

// const history = syncHistoryWithStore(hashHistory, store)

// TODO: refactor: https://github.com/reactjs/redux/issues/227
// history.listen(location => store.dispatch(changeLocation(location)))

// Google Analytics has a separate DEV account
const google = new GoogleAnalyticsTracker()
tracker.register(google)

if (process.env.ENABLE_TRACKING) {
  hotjar.initialize('1086600')

  const facebook = new FacebookTracker()
  const auratikum = new AuratikumTracker()
  tracker.register(facebook)
  tracker.register(auratikum)
}

tracker.logEvent('WEBAPP_START')

// prevent default drop, so browsers won't open a file locally
window.addEventListener('dragover', (e) => {
  const ev = e || event
  ev.preventDefault()
}, false)

window.addEventListener('drop', (e) => {
  const ev = e || event
  ev.preventDefault()
}, false)

if (module.hot) {
  module.hot.accept()
}

CitationPlugin()
FormulaPlugin()

render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Switch>
        <Route path="/payment" component={PaymantCallback} />
        <Route path="/verify-email" component={VerifyEmailCallback} />
        <Route path="/" component={Authentication} />
      </Switch>
    </ConnectedRouter>
  </Provider>,
  rootElement,
)
