import i18n from 'i18n'

// this action does not dispatch any action currently
// eslint-disable-next-line import/prefer-default-export
export const changeLanguage = lang => async () => {
  i18n.changeLanguage(lang, (err) => {
    if (err) return console.log('something went wrong loading language file', err)
    return null
  })
}
