import React from 'react'
import PropTypes from 'prop-types'
import { isObject, isArray } from 'lodash'

import { propTypes as i18nPropTypes, translate } from 'i18n'

const Row = ({ attribute, entity, t }) => {
  const item = entity[attribute]
  const translationKey = attribute === 'type' ? 'common.type' : `references:types.${entity.type || 'article'}.${attribute}`
  if (isObject(item)) {
    let text = ''
    if (item.raw) text = item.raw
    if (isArray(item)) {
      // is author
      item.forEach((author) => {
        if (author.family) text += `${author.family}, ${author.given} & `
        else if (author.literal) text += `${author.literal} & `
      })
    }
    return (
      <tr>
        <td>{t(translationKey)}</td>
        <td>{text}</td>
      </tr>
    )
  }
  return (
    <tr>
      <td>{t(translationKey)}</td>
      <td>{item}</td>
    </tr>
  )
}


Row.propTypes = {
  attribute: PropTypes.string,
  entity: PropTypes.shape({}),
  ...i18nPropTypes,
}

export default translate()(Row)
