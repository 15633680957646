import { connect } from 'react-redux'

import PaymentForm from 'components/subscription/PaymentForm'
import { getFormValues } from 'redux-form'

const mapStateToProps = (state, ownProps) => ({
  formValues: getFormValues(ownProps.form)(state) || {},

})


export default connect(mapStateToProps)(PaymentForm)
