import { handleActions } from 'redux-actions'

import { showModalAction, hideModalAction } from 'actions/modal'

const reducer = handleActions({
  [showModalAction]: (state, action) => ({
    ...state,
    [action.payload.name]: { show: true, ...action.payload },
  }),
  [hideModalAction]: (state, action) => ({
    ...state,
    [action.payload.name]: { show: false },
  }),
}, {})

export default reducer
