import {
  values, get, sortBy, groupBy, isInteger, compact,
} from 'lodash'
import { createSelector } from 'reselect'
import ProfileSelector, { getProfile } from 'selectors/profile'
import ProjectSelector from 'selectors/projects'
import NotesSelector from 'selectors/notes'
import ReferenceSelector from 'selectors/references'
import FeatureSelector from 'selectors/features'

export const subscriptionsSelector = state => state.entities.subscriptions
export const voucherSelector = state => state.entities.vouchers
export const planSelector = state => state.entities.subscriptionPlans

export const getSubscriptions = createSelector(
  subscriptionsSelector,
  subscriptions => values(subscriptions).sort((a, b) => new Date(b.begin) - new Date(a.begin)),
)

export const getActiveSubscriptions = createSelector(
  getProfile,
  profile => get(profile, 'subscriptions'),
)

const maxQuota = attr => (reducedMax, value) => {
  let quota = 0
  if (value) {
    if (isInteger(value)) {
      quota = value
    } else {
      quota = get(value, `quotas.${attr}`, Number.MAX_SAFE_INTEGER)
    }
  }
  return Math.max(reducedMax, quota)
}

export const getNoteQuota = createSelector(
  getActiveSubscriptions,
  ProfileSelector.getMaxNoteCount,
  (subscriptions, maxNoteCount) => [maxNoteCount, subscriptions.activeSubscription, subscriptions.activeTemporarySubscription].reduce(maxQuota('notes')),
)

export const getProjectQuota = createSelector(
  getActiveSubscriptions,
  ProfileSelector.getMaxProjectCount,
  (subscriptions, maxProjectCount) => [maxProjectCount, subscriptions.activeSubscription, subscriptions.activeTemporarySubscription].reduce(maxQuota('projects')),
)

export const getReferenceQuota = createSelector(
  getActiveSubscriptions,
  ProfileSelector.getMaxReferenceCount,
  (subscriptions, maxReferenceCount) => [maxReferenceCount, subscriptions.activeSubscription, subscriptions.activeTemporarySubscription].reduce(maxQuota('references')),
)

export const getQuotas = createSelector(
  getNoteQuota,
  getProjectQuota,
  getReferenceQuota,
  (notes, projects, references) => ({ notes, projects, references }),
)

export const hasSubscription = createSelector(
  getProfile,
  profile => !!get(profile, 'subscriptions.activeSubscription') || !!get(profile, 'subscriptions.activeTemporarySubscription'),
)

export const getVoucher = createSelector(
  voucherSelector,
  vouchers => values(vouchers)[0],
)

const groupByName = plan => plan.name

const groupFilterPlans = (list, condition) => {
  const plans = list.filter(condition)
  const containsValid = plans.reduce((valid, plan) => valid || plan.isValid, false)
  if (containsValid) {
    return groupBy(plans, groupByName)
  }
  return null
}

export const getSubscriptionPlans = createSelector(
  planSelector,
  getVoucher,
  (plans, voucher) => {
    const planList = compact(sortBy(values(plans)
      .map((plan) => {
        const isValid = (!voucher && !plan.requiresVoucher) || (voucher && voucher.validPlans.includes(plan.id))
        if (!isValid && plan.requiresVoucher) return null // we only want to have valid plans in the voucher-offer section
        const price = isValid && voucher && voucher.discount > 0 ? plan.price * (1 - (voucher.discount / 100)) : plan.price
        const monthlyPrice = plan.duration > 1 ? (Math.round((price / plan.duration) * 100.0) / 100.0) : price
        const originalMonthlyPrice = plan.duration > 1 ? (Math.round((plan.price / plan.duration) * 100.0) / 100.0) : plan.price
        const originalPrice = plan.price
        return {
          ...plan, originalMonthlyPrice, originalPrice, price, monthlyPrice, isValid,
        }
      }),
    ['duration', 'originalMonthlyPrice']))

    const result = {}
    const educationPlans = groupFilterPlans(planList, plan => !plan.requiresVoucher && plan.requiresEducationVerification)
    if (educationPlans) result.EDUCATION = educationPlans

    const standardPlans = groupFilterPlans(planList, plan => !plan.requiresVoucher && !plan.requiresEducationVerification)
    if (standardPlans) result.STANDARD = standardPlans

    const voucherPlans = groupFilterPlans(planList, plan => plan.requiresVoucher)
    if (voucherPlans) result.VOUCHER = voucherPlans

    return result
  },
)

const isProjectCountPremiumRestricted = createSelector(
  state => FeatureSelector.featureEnabled(state, 'payment'),
  ProjectSelector.getNoneInitialCount,
  getProjectQuota,
  (paymentFeatureEnabled, projectCount, projectQuota) => paymentFeatureEnabled && projectQuota && projectCount >= projectQuota,
)

export const isNotesCountPremiumRestricted = createSelector(
  state => FeatureSelector.featureEnabled(state, 'payment'),
  NotesSelector.getNoneInitialCount,
  getNoteQuota,
  (paymentFeatureEnabled, noteCount, noteQuota) => paymentFeatureEnabled && noteQuota && noteCount >= noteQuota,
)

export const isReferenceCountPremiumRestricted = createSelector(
  state => FeatureSelector.featureEnabled(state, 'payment'),
  ReferenceSelector.getNoneInitialCount,
  getReferenceQuota,
  (paymentFeatureEnabled, referenceCount, referenceQuota) => paymentFeatureEnabled && referenceQuota && referenceCount >= referenceQuota,
)

export const getRemainingQuotaCounts = createSelector(
  getQuotas,
  ReferenceSelector.getNoneInitialCount,
  NotesSelector.getNoneInitialCount,
  ProjectSelector.getNoneInitialCount,
  (quotas, referencesCount, notesCount, projectsCount) => ({
    notes: Math.max(0, quotas.notes - notesCount),
    references: Math.max(0, quotas.references - referencesCount),
    projects: Math.max(0, quotas.projects - projectsCount),
  }),
)

export default {
  getSubscriptions,
  hasSubscription,
  getVoucher,
  getSubscriptionPlans,
  isProjectCountPremiumRestricted,
  isNotesCountPremiumRestricted,
  isReferenceCountPremiumRestricted,
  getQuotas,
  getRemainingQuotaCounts,
}
