import React from 'react'
import PropTypes from 'prop-types'
import { supportedLanguages } from 'i18n'

import { SegmentBar } from 'components/common/Bar'

import './style.less'

const FormLanguageSelectorInput = ({ input, className = '' }) => (
  <SegmentBar
    className={`FormLanguageSelectorInput ${className}`}
    selectedValue={input.value}
    values={supportedLanguages}
    translationKeys={supportedLanguages.map(value => `common.languages.${value}`)}
    onSelect={input.onChange}
  />
)

FormLanguageSelectorInput.propTypes = {
  input: PropTypes.shape({}),
  className: PropTypes.string,
}

export default FormLanguageSelectorInput
