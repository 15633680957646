import { get } from 'lodash'

export const selector = state => state.flyout

export const isVisible = (state, id) => get(selector(state), `['${id}'].show`, false)

export const getData = (state, id) => get(selector(state), `['${id}'].data`, {})

export default {
  isVisible,
  getData,
}
