
export const selector = state => state.help

export const isEnabled = state => selector(state).enabled
export const getOpenSpots = state => selector(state).openSpots

export default {
  isEnabled,
  getOpenSpots,
}
