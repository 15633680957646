import {
  values, get, isEqual, flatten, compact,
} from 'lodash'
import { createSelector } from 'reselect'

import { getSelectedNoteId, getSelectedProjectId } from 'selectors/path'
import ReferenceSelector from 'selectors/references'

export const notesSelector = state => state.entities.notes

export const referenceSelector = state => state.entities.references

const projectsSelector = state => state.entities.projects || {}

export const getFilter = createSelector(
  getSelectedProjectId,
  state => state.noteCollection,
  (projectId, noteCollection) => {
    const { filters } = noteCollection
    return projectId ? filters[projectId] : filters.ALL_NOTES
  },
)
export const isFilterActive = createSelector(
  getFilter,
  filter => (filter ? filter.active : false),
)

export const getAll = createSelector(
  notesSelector,
  referenceSelector,
  (notes, references) => values(notes)
    .map(note => ({ ...note, reference: references[note.referenceIds[0]] })),
)

const equalReferences = (val1, val2) => {
  let result = true
  val1.forEach((value, index) => {
    result = result && isEqual(value, val2[index])
  })
  return result
}

const denormalizedNoteCache = {}
const noteCache = {}
export const getRawDenormalized = createSelector(
  notesSelector,
  referenceSelector,
  (notes, references) => {
    const result = {}
    values(notes).forEach((note) => {
      const cachedNote = noteCache[note.id]
      if (cachedNote && isEqual(note, cachedNote) && equalReferences(cachedNote.references, denormalizedNoteCache[note.id].references)) {
        result[note.id] = denormalizedNoteCache[note.id]
      } else {
        const refs = compact(note.referenceIds.map(id => get(references, `['${id}']`)))
        let inlineRefs = []
        if (note.inlineCitations && note.inlineCitations.length > 0) {
          inlineRefs = flatten(note.inlineCitations
            .map(citation => citation.items.map(item => item.referenceId)))
            .map(id => (references[id] ? { ...references[id], cited: true } : null))
        }
        result[note.id] = { ...note, references: [...inlineRefs, ...refs] }
        denormalizedNoteCache[note.id] = result[note.id]
      }
    })
    return result
  },
)


export const getById = (state, id) => {
  if (id) {
    const note = get(notesSelector(state), `['${id}']`)
    const refs = note.referenceIds.map(i => ReferenceSelector.getById(state, i))

    if (note) return { ...note, references: refs }
  }
  return null
}
export const getSelectedNote = createSelector(
  getSelectedNoteId,
  notesSelector,
  (id, notes) => get(notes, `['${id}']`),
)

export const getCreatedInProject = createSelector(
  getSelectedNote,
  projectsSelector,
  (note, projects) => (note && note.createdInProject ? projects[note.createdInProject] : null),
)

export const getUsedInProjects = createSelector(
  getSelectedNote,
  projectsSelector,
  (note, projects) => {
    if (note && note.usedInProjects) {
      return note.usedInProjects.map(projectId => projects[projectId]).filter(project => project !== undefined)
    }
    return null
  },
)

export const getOverviewType = state => state.noteCollection.overviewType
export const getOrderBy = state => state.noteCollection.orderBy
export const getOrder = state => state.noteCollection.order

const pagedNoteIds = state => state.notePaging.noteIds

const sortBy = (orderBy, order) => (a, b) => {
  if (order === 'asc') {
    return a[orderBy] > b[orderBy] ? 1 : -1
  }
  if (order === 'desc') {
    return a[orderBy] < b[orderBy] ? 1 : -1
  }
  return 0
}

const pagedNotesCache = {}
export const getPagedNotes = createSelector(
  pagedNoteIds,
  getRawDenormalized,
  getOrderBy,
  getOrder,
  getFilter,
  isFilterActive,
  (ids, notes, orderBy, order, filter, filterIsActive) => {
    const orderedNotes = ids.map((id) => {
      const result = { ...notes[id] }

      const cachedNote = pagedNotesCache[id]
      if (cachedNote && isEqual(result, cachedNote)) return cachedNote

      pagedNotesCache[id] = result
      return result
    }).sort(sortBy(orderBy, order))
    let filteredNotes = orderedNotes
    if (filterIsActive) {
      if (filter.projectId) {
        filteredNotes = orderedNotes.filter(note => note.createdInProject === filter.projectId
                        || note.usedInProjects.indexOf(filter.projectId) >= 0)
      }
      if (filter.tags && filter.tags.length > 0) {
        const tags = filter.tags.map(tag => tag.tag)
        filteredNotes = filteredNotes.filter(note => note.tags.filter(tag => tags.includes(tag)).length > 0)
      }

      if (filter.colors && filter.colors.length > 0) {
        filteredNotes = filteredNotes.filter(note => filter.colors.includes(note.color))
      }
    }
    return filteredNotes
  },
)

export const getCount = createSelector(
  notesSelector,
  notes => values(notes).length,
)

export const getNoneInitialCount = createSelector(
  notesSelector,
  notes => values(notes).filter(note => !note.isInitial).length,
)

export const hasInitialNotes = createSelector(
  notesSelector,
  notes => values(notes).filter(note => note.isInitial).length > 0,
)

export default {
  getAll,
  getRawDenormalized,
  getRaw: state => notesSelector(state),
  getById,
  getCount,
  getNoneInitialCount,
  hasInitialNotes,
  getSelectedNote,
  getOverviewType,
  getOrderBy,
  getOrder,
  getPagedNotes,
  getFilter,
  isFilterActive,
  getCreatedInProject,
  getUsedInProjects,
}
