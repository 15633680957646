import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'

import FlyoutOutlineHeading from 'containers/note-link/FlyoutOutlineHeading'

import './style.less'

class OutlineHeadingItem extends PureComponent {
  render() {
    const {
      item,
      level = 0,
      lastHeadingPrefix = '',
      expandedProjectItems,
      expandCollapseProjectItem,
      handleLinkItem,
      isLinking,
      linkingSuccess,
    } = this.props
    let headingCounter = 0

    return (
      <Fragment key={item.id}>
        {(item.type === 'HEADING' || item.type === 'ROOT') && item.children.map((childItem, childIndex) => {
          const isFirst = childIndex === 0
          const isLast = childIndex === item.children.length - 1
          const headingPrefix = `${lastHeadingPrefix}${headingCounter + 1}.`
          const expandChildren = expandedProjectItems.includes(childItem.id) && childItem.children.length > 0
          if (childItem.type === 'HEADING') headingCounter += 1
          return (
            <Fragment key={childItem.id}>
              <div className="OutlineItemContainer">
                <FlyoutOutlineHeading
                  item={childItem}
                  isFirst={isFirst}
                  isLast={isLast}
                  level={level}
                  headingPrefix={headingPrefix}
                  className="OutlineItemContainer__Item"
                  handleClick={expandCollapseProjectItem}
                  handleLinkItem={handleLinkItem}
                  isLinking={isLinking}
                  linkingSuccess={linkingSuccess}
                />

                <div className="OutlineItemContainer__Children">
                  { expandChildren && (
                    <OutlineHeadingItem
                      item={childItem}
                      index={0}
                      lastHeadingPrefix={headingPrefix}
                      level={level + 1}
                      expandedProjectItems={expandedProjectItems}
                      expandCollapseProjectItem={expandCollapseProjectItem}
                      handleLinkItem={handleLinkItem}
                      isLinking={isLinking}
                      linkingSuccess={linkingSuccess}
                    />
                  )
                  }
                </div>
              </div>
            </Fragment>
          )
        })}
      </Fragment>
    )
  }
}

OutlineHeadingItem.propTypes = {
  item: PropTypes.shape({
    expanded: PropTypes.bool,
  }),
  level: PropTypes.number,
  lastHeadingPrefix: PropTypes.string,
  expandedProjectItems: PropTypes.array,
  expandCollapseProjectItem: PropTypes.func,
  handleLinkItem: PropTypes.func,
  isLinking: PropTypes.bool,
  linkingSuccess: PropTypes.bool,
}

export default OutlineHeadingItem
