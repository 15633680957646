import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { isEmpty, get } from 'lodash'

import { propTypes as i18nPropTypes, translate } from 'i18n'
import { AuratikumFontIcons } from 'helper/utils'

import ConfirmationModal from 'containers/modal/ConfirmationModal'
import Icon from 'components/common/Icon'


class DeleteHeadingModal extends Component {
  static propTypes = {
    id: PropTypes.string,
    onConfirm: PropTypes.func,
    ...i18nPropTypes,
  }

  renderDeleteModalContent = (data) => {
    const { t } = this.props
    return (
      <React.Fragment>
        <Icon key="icon" className="ConfirmationModal__Icon" icon={AuratikumFontIcons.DELETE} />
        <div key="title" className="ConfirmationModal__Title">{t('deleteHeadingModal.contentTitle', { heading: data.title })}</div>
        <div key="subtitle" className="ConfirmationModal__SubTitle">{t('modal.cannotBeUndone')}</div>
        {!isEmpty(data.children) && (
        <div className="ConfirmationModal__Additional">
          {this.props.t('deleteHeadingModal.subElementsDeleted', {
            elements: data.children.map((child) => {
              const title = child.title || get(child, 'note.title')
              return title ? `"${title}"` : `<${t(`deleteProjectItemModal.itemType.${child.type}`)}>`
            }).join(', '),
          })}
        </div>
        )}
      </React.Fragment>
    )
  }

  render() {
    const { t, id, onConfirm } = this.props
    return (
      <ConfirmationModal
        id={id}
        title={t('deleteHeadingModal.title')}
        onConfirm={onConfirm}
        contentFunc={this.renderDeleteModalContent}
        okLabel={t('common.delete')}
        cancelLabel={t('common.cancel')}
      />
    )
  }
}

export default translate()(DeleteHeadingModal)
