import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { showModal } from 'actions/modal'
import { connect } from 'react-redux'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import { PAYWALL_MODAL_ID } from 'containers/modal/PaywallModal'
import { PremiumOutlinedButton, OutlinedButtonTooltip } from 'components/common/Button'
import FontIcon from 'components/common/FontIcon'

class DuplicateButton extends Component {
  state = { isLoading: false, mounted: true }

  componentWillUnmount() {
    this.setState({ mounted: false })
  }

  onClick = async (e) => {
    const { onClick, enablePaywall } = this.props
    if (enablePaywall) {
      this.props.showModal(PAYWALL_MODAL_ID)
      return
    }
    if (!this.state.isLoading) {
      this.setState({ isLoading: true })
      await onClick(e)
      if (this.state.mounted) this.setState({ isLoading: false })
    }
  }

  render() {
    const {
      enablePaywall, className, ...props
    } = this.props

    const Button = enablePaywall ? PremiumOutlinedButton : OutlinedButtonTooltip

    return (
      <React.Fragment>
        <Button {...props} onClick={this.onClick} isLoading={this.state.isLoading}>
          <FontIcon icon={FontIcon.Icons.faClone} />
        </Button>
      </React.Fragment>
    )
  }
}

DuplicateButton.propTypes = {
  ...i18nPropTypes,
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,
  enablePaywall: PropTypes.bool,
  showModal: PropTypes.func,
}

export default connect(null, {
  showModal,
})(translate()(DuplicateButton))
