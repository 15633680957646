import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import { change, formValueSelector, submit } from 'redux-form'
import { Sticky } from 'react-sticky'
import { isEmpty, findIndex } from 'lodash'
import Spinner from 'components/common/Spinner'

import modifyClassName from 'helper/modifyClassName'
import TextEditor from 'containers/common/TextEditor'

import './style.less'


const form = 'NoteDetailForm'
const selector = formValueSelector(form)

class Editor extends Component {
  static propTypes = {
    className: PropTypes.string,
    noteId: PropTypes.string,
    onSave: PropTypes.func,
    tabIndex: PropTypes.string,
    input: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
    }),
    ...i18nPropTypes,
  }

  state = {
    editorLoading: true,
    toolbarVisible: false,
  }

  editor = null

  componentWillUpdate(nextProps) {
    if (nextProps.noteId !== this.props.noteId && this.props.input.value !== this.editor.getContent()) {
      this.props.input.onChange(this.editor.getContent())
      this.props.onSave()
    }
  }

  handleEditorChange = (content) => {
    if (this.props.input.value !== content) {
      this.props.input.onChange(content)
    }
  }

  handleSave = () => {
    this.props.onSave()
  }

  handleSetup = (editor) => {
    editor.on('blur', () => this.setState({ toolbarVisible: false }))
    editor.on('focus', () => this.setState({ toolbarVisible: true }))
    editor.on('init', () => {
      this.setState({ editorLoading: false })
      editor.save()
    })
    this.editor = editor
  }

  handleSaveCitation = async ({ citation, index }) => {
    const { inlineCitations = [], updateForm, submitForm } = this.props
    const newInlineCitations = [...inlineCitations]
    const inlineCitationsIndex = findIndex(newInlineCitations, c => citation.id === c.id)

    if (inlineCitationsIndex >= 0) {
      newInlineCitations[inlineCitationsIndex] = citation
    } else {
      // edge case, the content has an invalid citation which is not available in inlineCitations any more
      // handle it lite addCitation
      newInlineCitations.splice(index, 0, citation)
    }
    await updateForm(form, 'inlineCitations', newInlineCitations)
    return submitForm(form)
  }

  handleAddCitation = async ({ citation, index }) => {
    const { inlineCitations = [], updateForm } = this.props
    inlineCitations.splice(index, 0, citation)

    return updateForm(form, 'inlineCitations', inlineCitations)
  }

  handleRemoveCitations = async (removedCitationIds, isCleanup) => {
    const { inlineCitations = [], submitForm, updateForm } = this.props
    const newInlineCitations = inlineCitations.filter(citation => !removedCitationIds.includes(citation.id))
    await updateForm(form, 'inlineCitations', newInlineCitations)
    if (isCleanup) return submitForm(form)
    return null
  }

  handleEmptyClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (!this.state.editorLoading) {
      this.editor.focus()
    }
  }

  renderToolbar = ({ style }) => <div style={style} className={modifyClassName('editorToolbar', { visible: this.state.toolbarVisible })} />

  render() {
    const {
      className, tabIndex, t, input = {}, noteId,
    } = this.props
    const { editorLoading, toolbarVisible } = this.state
    const isValueEmpty = isEmpty(input.value)

    return (
      <div className={`${className} editor ${isValueEmpty && !toolbarVisible ? 'editor--empty' : ''}`}>
        { editorLoading && (
        <div className="editor__Loading">
          <Spinner show={editorLoading} />
          {` ${t('noteDetailView.initializingEditor')}`}
        </div>
        ) }
        <Sticky relative>
          { this.renderToolbar }
        </Sticky>
        { !editorLoading && isValueEmpty && !toolbarVisible && <button type="button" onClick={this.handleEmptyClick} className="editor__placeholder">{t('noteDetailView.emptyContentLabel')}</button>}
        <TextEditor
          id={noteId}
          toolbarClass=".editorToolbar"
          value={this.props.input.value}
          onChange={this.handleEditorChange}
          onSave={this.handleSave}
          onSetup={this.handleSetup}
          onAddCitation={this.handleAddCitation}
          onSaveCitation={this.handleSaveCitation}
          onRemoveCitation={this.handleRemoveCitations}
          tabIndex={tabIndex}
          citationCluster={this.props.citationCluster}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  inlineCitations: selector(state, 'inlineCitations'),
})

const mapDispatchToProps = {
  updateForm: change,
  submitForm: submit,
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(Editor))
