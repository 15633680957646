import React from 'react'
import PropTypes from 'prop-types'

import modifyClassName from 'helper/modifyClassName'

import './style.less'


const ScoreItem = ({ score }) => (
  <tr className={modifyClassName('ScoreItem', { selected: score.isMyScore })}>
    <td className="ScoreItem__Cell">{`${score.order + 1}.`}</td>
    <td className="ScoreItem__Cell">{score.educationalInstitute}</td>
    <td className={modifyClassName('ScoreItem__Cell', 'right')}>{score.count}</td>
  </tr>
)

ScoreItem.propTypes = {
  score: PropTypes.shape({}).isRequired,
}

export default ScoreItem
