import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'

import LocalStorageDump from 'middlewares/LocalStorageDump'
import LocalStorageLoad from 'middlewares/LocalStorageLoad'
import welcomeTourMiddleware from 'middlewares/welcomeTourMiddleware'

import SentryIntegration from 'integrations/Sentry'

import createRootReducer from 'reducers'
import { routerMiddleware } from 'connected-react-router'

import { createBrowserHistory } from 'history'

export const history = createBrowserHistory()

const enhancers = [
  applyMiddleware(
    routerMiddleware(history), // for dispatching history actions
    thunk,
    welcomeTourMiddleware,
    LocalStorageLoad,
    LocalStorageDump,
    ...SentryIntegration.getMiddlewares(),
  ),
]

/* eslint-disable no-underscore-dangle */
if (window && window.__REDUX_DEVTOOLS_EXTENSION__ && process.env.NODE_ENV !== 'production') {
  enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__())
}
/* eslint-enable */

const store = createStore(
  createRootReducer(history),
  compose(...enhancers),
)

export default store
