import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'

import HelpSpot from 'containers/HelpSpot'
import withTooltip from 'containers/common/Tooltip'

import modifyClassName from 'helper/modifyClassName'

import FlyoutReference from 'containers/common/FlyoutReference'

import './style.less'

class NavBarButton extends Component {
  static propTypes = {
    children: PropTypes.string,
    expanded: PropTypes.bool,
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    linkPath: PropTypes.string,
    onClick: PropTypes.func,
    premium: PropTypes.bool,
    profile: PropTypes.bool,
    selected: PropTypes.bool,
    helpSystemEnabled: PropTypes.bool,
    translationKey: PropTypes.string,
    menuComponent: PropTypes.element,
    push: PropTypes.func.isRequired,
    className: PropTypes.string,
  }

  constructor(props) {
    super(props)

    this.state = { showMenu: false }
  }

  handleMouseEnter = () => {
    clearTimeout(this.timeout)
    this.setState({ showMenu: true })
  }

  handleMouseLeave = () => {
    this.timeout = setTimeout(() => this.setState({ showMenu: false }), 250)
  }

  handleOnClick = (e) => {
    const { onClick, linkPath } = this.props
    clearTimeout(this.timeout)
    this.setState({ showMenu: false })

    if (onClick) {
      onClick(e)
    } else if (linkPath) {
      this.props.push(linkPath)
    }
  }

  renderMenuComponent = () => (
    // eslint-disable-next-line
    <div onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave} onClick={this.handleMouseLeave}>
      {this.props.menuComponent}
    </div>
  )

  render = () => {
    const {
      children,
      expanded,
      icon,
      premium,
      profile,
      selected,
      helpSystemEnabled,
      translationKey,
      className,
    } = this.props

    return (
      <FlyoutReference
        showFlyout={this.state.showMenu}
        placement="right-start"
        component={this.renderMenuComponent()}
        flyoutClassName="NavBarButton__Menu"
      >
        <button
          onClick={this.handleOnClick}
          className={`${modifyClassName('NavBarButton', { premium }, { profile }, { selected, helpSystemEnabled })} ${className}`}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
        >
          <div className={modifyClassName('NavBarButton__Icon', { selected }, { premium })}>
            { profile ? <div className={modifyClassName('NavBarButton__Icon__Circle', { selected })}><div>{icon}</div></div> : icon}
            { translationKey
            && (
            <HelpSpot
              className="NavBarButton__HelpSpot"
              translationKey={translationKey}
            />
            )
            }
          </div>
          <div className={modifyClassName('NavBarButton__Text', { expanded }, { selected }, { premium })}>
            {children}
          </div>
        </button>
      </FlyoutReference>
    )
  }
}

const mapDispatchToProps = {
  push,
}

export default withTooltip(connect(null, mapDispatchToProps)(NavBarButton))
