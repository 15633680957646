import React from 'react'
import PropTypes from 'prop-types'

import { translate, propTypes as i18nPropTypes } from 'i18n'

import './style.less'

const EarlyBirdStatus = ({
  t, limit, institute, isEarlyBird,
}) => {
  const hasReachedGoal = institute && institute.count >= limit
  return isEarlyBird && (
    <div className="EarlyBirdStatus">
      <div className="EarlyBirdStatus__Title">
        {t(hasReachedGoal ? 'challenge.reachedGoalEarlyBirdTitle' : 'challenge.earlyBirdTitle')}
      </div>
      <div>
        {t(hasReachedGoal ? 'challenge.reachedGoalEarlyBird' : 'challenge.earlyBird', { score: limit })}
      </div>
    </div>
  )
}

EarlyBirdStatus.propTypes = {
  institute: PropTypes.shape({}),
  limit: PropTypes.number,
  isEarlyBird: PropTypes.bool,
  ...i18nPropTypes,
}

export default translate()(EarlyBirdStatus)
