import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { propTypes as i18nPropTypes, translate } from 'i18n'

import { connect } from 'react-redux'
import { get } from 'lodash'
import { Prompt } from 'react-router'

import { PrimaryButton } from 'components/common/Button'
import Icon from 'components/common/Icon'
import featureFlyouts, { propTypes as featureFlyoutPropTypes } from 'containers/onboarding/FeatureFlyout'

import { showModal } from 'actions/modal'
import { showFlyout } from 'actions/flyout'
import ProjectSelector from 'selectors/projects'
import { updateProject } from 'actions/projects'
import {
  expandAllProjectItems,
  collapseAllProjectItems,
  expandCollapseProjectItem,
  removeProjectItem,
} from 'actions/project-items'
import DeleteHeadingModal from 'containers/modal/DeleteHeadingModal'
import Spinner from 'components/common/Spinner'
import OutlineItem from 'containers/outline/OutlineItem'
import OutlineAddHeadingFlyout from 'containers/outline/OutlineAddHeadingFlyout'
import DeleteProjectItemModal from 'containers/modal/DeleteProjectItemModal'

import './style.less'


const DELETE_MODAL_ID = 'DELETE_HEADING_CONFIRMATION_MODAL'
const DELETE_NOTE_LINK_ID = 'DELETE_NOTE_LINK_CONFIRMATION_MODAL'
const HEADING_FLYOUT_ID = 'HEADING_FLYOUT'

export const CONTEXTS = {
  WRITING: 'WRITING',
}

class OutlineContainer extends Component {
  static propTypes = {
    resolvedProjectItemTree: PropTypes.shape({}),
    collapseAllProjectItems: PropTypes.func,
    expandAllProjectItems: PropTypes.func,
    removeProjectItem: PropTypes.func,
    context: PropTypes.string,
    ...i18nPropTypes,
    ...featureFlyoutPropTypes,
  }

  constructor(props, context) {
    super(props, context)
    this.state = {
      saving: false,
      allExpanded: false,
    }
  }

  onDeleteProjectItem = async (headingItem) => {
    this.setState({ saving: true })
    await this.props.removeProjectItem(headingItem)
    this.setState({ saving: false })
  }

  onProcessChanges = async (func) => {
    this.setState({ saving: true })
    await func()
    this.setState({ saving: false })
  }

  handleCollapseAll = () => {
    if (this.state.allExpanded) {
      this.props.collapseAllProjectItems()
      this.setState({ allExpanded: false })
    } else {
      this.props.expandAllProjectItems()
      this.setState({ allExpanded: true })
    }
  }

  handleDeletHeading = item => this.props.showModal(DELETE_MODAL_ID, { data: { ...item } })

  handleDeletNoteLink = item => this.props.showModal(DELETE_NOTE_LINK_ID, { data: { ...item } })

  handleAddNewHeading = (event, parent, index) => {
    this.headingButtonRef = event.target
    this.props.showFlyout(HEADING_FLYOUT_ID, {
      parent: parent || this.props.resolvedProjectItemTree,
      index: index || 0,
    })
    this.setState({})
  }

  handleFlyoutClose = (parent) => {
    if (parent && !parent.expanded) this.props.expandCollapseProjectItem(parent.id)
  }

  handleEditHeading = (event, headingItem) => {
    this.headingButtonRef = event.target.parentNode.parentNode.parentNode
    this.props.showFlyout(HEADING_FLYOUT_ID, { headingItem })
    this.setState({})
  }

  renderEmptyStructure() {
    const { resolvedProjectItemTree, t, addFeatureFlyout } = this.props
    if (resolvedProjectItemTree.children.length > 0) return null
    return (
      <div className="OutlineContainer__HintContainer">
        <div className="OutlineContainer__HintContainerTitle">{t('projectStructure.emptyTitle')}</div>
        <div className="OutlineContainer__HintContainerDescription">{t('projectStructure.emptyDescription')}</div>
        <PrimaryButton forwardRef={addFeatureFlyout('CREATE_HEADING')} onClick={this.handleAddNewHeading}>{t('projectStructure.addHeading')}</PrimaryButton>
      </div>
    )
  }

  renderDragDropHint() {
    const { resolvedProjectItemTree, t } = this.props
    if ((get(resolvedProjectItemTree, 'children.length') <= 2) && (get(resolvedProjectItemTree, 'children[0].children.length') === 0)) {
      return (
        <div className="OutlineContainer__HintContainer">
          <div className="OutlineContainer__HintContainerTitle OutlineContainer__HintContainerTitle--big"><Icon icon="O" /></div>
          <div className="OutlineContainer__HintContainerTitle">{t('projectStructure.noNotesTitle')}</div>
          <div className="OutlineContainer__HintContainerDescription">{t('projectStructure.noNotesDescription')}</div>
        </div>
      )
    }
    return null
  }

  render() {
    const { resolvedProjectItemTree, t } = this.props
    if (!resolvedProjectItemTree) return <div />
    return (
      <div className="OutlineContainer">
        <Prompt when={this.state.saving} message={t('projectStructure.unsavedChangesPrompt')} />
        <div className="OutlineContainer__Header">
          <div className="OutlineContainer__HeaderItem">
            { this.state.saving && (
              <span>
                <Spinner />
                {t('common.saving')}
              </span>
            )}
          </div>
          <div className="OutlineContainer__HeaderItem">
            <button type="button" onClick={this.handleCollapseAll}>{ this.state.allExpanded ? t('projectStructure.collapseAll') : t('projectStructure.expandAll') }</button>
          </div>
        </div>
        <div className="OutlineContainer__Items">
          <OutlineItem
            item={resolvedProjectItemTree}
            onAddHeading={this.handleAddNewHeading}
            onEditHeading={this.handleEditHeading}
            onDeleteHeading={this.handleDeletHeading}
            onProcessChanges={this.onProcessChanges}
            showHashLinks={this.props.context === CONTEXTS.WRITING}
            removeProjectItem={this.handleDeletNoteLink}
          />
          { this.renderEmptyStructure() }
          { this.renderDragDropHint() }
        </div>
        <OutlineAddHeadingFlyout id={HEADING_FLYOUT_ID} onClose={this.handleFlyoutClose} referenceElement={this.headingButtonRef} placement="bottom" />
        <DeleteHeadingModal id={DELETE_MODAL_ID} onConfirm={this.onDeleteProjectItem} />
        <DeleteProjectItemModal id={DELETE_NOTE_LINK_ID} onConfirm={this.onDeleteProjectItem} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  resolvedProjectItemTree: ProjectSelector.getResolvedProjectItemTreeForSelectedProject(state),
})

const flyoutConfig = {
  CREATE_HEADING: {
    placement: 'bottom',
  },
}

export default connect(mapStateToProps, {
  showModal,
  updateProject,
  expandAllProjectItems,
  collapseAllProjectItems,
  expandCollapseProjectItem,
  removeProjectItem,
  showFlyout,
})(translate()(featureFlyouts(flyoutConfig)(OutlineContainer)))
