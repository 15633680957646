import React from 'react'
import PropTypes from 'prop-types'
import modifyClassName from 'helper/modifyClassName'

import './style.less'

const FormSelectInput = ({
  className = '', label, options, input, meta: {
    touched, valid, error, form,
  },
}) => (
  <div className={`FormSelectInput ${className}`}>
    <label htmlFor={`${form}.${input.name}`} className="FormSelectInput__Label">
      {label}
      {touched && error && <span className="FormSelectInput__Error">{error}</span>}
    </label>
    <select id={`${form}.${input.name}`} className="FormSelectInput__Field" {...input}>
      {options.map(option => <option key={option.label || option.value} value={option.value}>{option.label || option.value}</option>)}
    </select>
    <div className={modifyClassName('FormSelectInput__Line', { valid: touched && valid })} />
  </div>
)

FormSelectInput.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  input: PropTypes.shape({}),
  meta: PropTypes.shape({}),
}

export default FormSelectInput
