import { handleActions } from 'redux-actions'
import { get } from 'lodash'

import {
  setOverviewType, sortNotes, setFilter, activateFilter,
} from 'actions/notes'

const reducer = handleActions({
  [activateFilter]: (state, action) => {
    const viewKey = action.payload.setForProject || 'ALL_NOTES'

    return {
      ...state,
      filters: {
        ...state.filters,
        [viewKey]: {
          ...state.filters[viewKey],
          projectId: get(state.filters, `['${action.payload.setForProject || 'ALL_NOTES'}'].projectId`, action.payload.setForProject),
          active: action.payload.active,
        },
      },
    }
  },
  [setFilter]: (state, action) => {
    const viewKey = action.payload.setForProject || 'ALL_NOTES'
    let newProjectId = action.payload.projectId
    if (newProjectId === null) newProjectId = get(state.filters, [viewKey, 'projectId'], action.payload.setForProject)
    return {
      ...state,
      filters: {
        ...state.filters,
        [viewKey]: {
          active: action.payload.active || get(state.filters, [viewKey, 'active']),
          projectId: newProjectId,
          colors: action.payload.colors || get(state.filters, [viewKey, 'colors']),
          tags: action.payload.tags || get(state.filters, [viewKey, 'tags']),
        },
      },
    }
  },
  [setOverviewType]: (state, action) => ({
    ...state,
    overviewType: action.payload,
  }),
  [sortNotes]: (state, action) => {
    let { order } = state
    if (state.orderBy === action.payload) {
      // if sort by same attribute, toggle order
      order = order === 'asc' ? 'desc' : 'asc'
    } else {
      order = 'desc'
    }
    return {
      ...state,
      orderBy: action.payload,
      order,
    }
  },
}, {
  overviewType: 'GRID', orderBy: 'createdAt', order: 'desc', filters: { ALL_NOTES: { active: false } },
})

export default reducer
