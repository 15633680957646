import React, { Component } from 'react'
import PropTypes from 'prop-types'

import modifyClassName from 'helper/modifyClassName'

import Spinner from 'components/common/Spinner'
import S3ImageField from 'containers/common/S3ImageField'
import FontIcon from 'components/common/FontIcon'

import emptyFileIcon from 'assets/icons/file-empty.svg'
import './style.less'


const fileType = (fileName) => {
  if (fileName) {
    const index = fileName.lastIndexOf('.')
    if (index >= 0) {
      return fileName.substring(index, fileName.length)
    }
  }
  return '.file'
}

export const isImage = file => ['jpg', 'jpeg', 'png', 'gif'].some(type => file.type.includes(type))

const modified = (modifier, WrapperComponent) => props => (<WrapperComponent modifier={modifier} {...props} />)

class FileIcon extends Component {
  static propTypes = {
    file: PropTypes.shape({
      fileName: PropTypes.string,
    }),
    onSelect: PropTypes.func,
    onDelete: PropTypes.func,
    isLoading: PropTypes.bool,
    className: PropTypes.string,
    modifier: PropTypes.string,
  }

  constructor(props) {
    super(props)
    this.state = { hover: false }
  }

  handleMouseEnter = () => this.setState({ hover: true })

  handleMouseLeave = () => this.setState({ hover: false })

  handleDelete = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (this.props.onDelete) {
      this.props.onDelete(this.props.file)
    }
  }

  handleSelect = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.props.onSelect(this.props.file)
  }

  renderPlaceholder() {
    return (
      <React.Fragment>
        <img alt="" className="FileIcon__Image" src={emptyFileIcon} />
        <div className="FileIcon__FileType">
          {fileType(this.props.file.fileName) }
        </div>
      </React.Fragment>
    )
  }

  render = () => {
    const { modifier, file } = this.props
    const usePlaceholder = !isImage(file)
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    return (
      <div
        className={`${modifyClassName('FileIcon', modifier)} ${this.props.className || ''}`}
        onClick={this.handleSelect}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        <div className="FileIcon__ImageContainer">
          <Spinner show={this.props.isLoading}>
            { usePlaceholder
              ? this.renderPlaceholder()
              : <S3ImageField file={file} className="FileIcon__Thumbnail" /> }
          </Spinner>
        </div>
        { this.props.onDelete
      && (
      <button
        className={modifyClassName('FileIcon__DeleteButton', { hover: this.state.hover })}
        onClick={this.handleDelete}
      >
        <FontIcon icon={FontIcon.Icons.faTrashAlt} />
      </button>
      )}
        <div className={modifyClassName('FileIcon__FileName', modifier, { hover: this.state.hover })}>{file.fileName}</div>
      </div>
    )
  }
}

export default FileIcon
export const FileIconColumn = modified('column', FileIcon)
