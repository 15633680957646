import { createAction } from 'redux-actions'
import uuidV4 from 'uuid/v4'

import * as api from 'api'
import { receiveEntities, removeEntity } from 'actions/entities'
import {
  isEqual,
  debounce,
  values,
  xor,
  uniq,
  concat,
  get,
  cloneDeep,
} from 'lodash'
import { normalize } from 'normalizr'
import { noteSchema } from 'api/schema'
import { udpateTags, requestTags } from 'actions/tags'
import { updateNoteCitationCluster } from 'actions/citation'
import { requestProjectItems } from 'actions/project-items'
import { ObjectId } from 'helper/utils'

import i18n from 'i18n'
import NotesSelector from 'selectors/notes'
import { push } from 'connected-react-router'
import tracker from 'tracking/tracker'

const SEARCH_DEBOUNCE_DURATION = 500

export const selectNote = createAction('SELECT_NOTE')
export const sortNotes = createAction('SORT_NOTES')
export const setOverviewType = createAction('SET_OVERVIEW_TYPE')
export const receiveNotePage = createAction('RECEIVE_NOTE_PAGE')
export const addNoteToPage = createAction('ADD_NOTE_TO_PAGE')
export const removeNoteFromPage = createAction('REMOVE_NOTE_FROM_PAGE')
export const noteSearch = createAction('NOTE_SEARCH')
export const suggestSearch = createAction('SUGGEST_SEARCH')
export const showSuggestions = createAction('SHOW_SUGGESTIONS')
export const isLoading = createAction('IS_LOADING')
export const setFilter = createAction('SET_NOTE_FILTER')
export const activateFilter = createAction('ACTIVATE_FILTER')

export const createNote = data => async (dispatch) => {
  const payload = await api.createNote(data)
  const result = await dispatch(receiveEntities(payload))
  const noteId = Object.keys(payload.entities.notes)[0]
  await dispatch(addNoteToPage({ noteId }))
  return result
}

export const duplicateNote = data => async (dispatch, getState) => {
  if (!window.confirm(i18n.t('noteDetailView.duplicateNoteConfirmation'))) {
    return null
  }
  tracker.logEvent('DUPLICATE_NOTE')

  const note = get(getState(), `entities.notes['${data.id}']`) // we want to use the unresolved note from the redux store

  if (!note) return null

  const noteDuplicate = cloneDeep(note)
  noteDuplicate.title = i18n.t('common.copiedTitle', { title: note.title })

  // create new ids for citations
  if (noteDuplicate.inlineCitations && noteDuplicate.inlineCitations.length > 0) {
    noteDuplicate.inlineCitations.forEach(citation => {
      const newId = ObjectId()
      noteDuplicate.content = noteDuplicate.content.replace(citation.id, newId)
      citation.id = newId
    })
  }

  const payload = await api.createNote(noteDuplicate)
  await dispatch(receiveEntities(payload))
  const createdNoteId = Object.keys(payload.entities.notes)[0]
  await dispatch(addNoteToPage({ noteId: createdNoteId }))
  const newNote = payload.entities.notes[createdNoteId]
  await dispatch(updateNoteCitationCluster(newNote))
  return dispatch(push(`/notes/${createdNoteId}`))
}

const linkAttributes = ['linkedNotes', 'previousNotes', 'followingNotes']

// when links change, the related notes need to be fetched again
const refreshNotes = async (data, dispatch, state, force) => {
  const note = NotesSelector.getById(state, data.id)
  let ids = []
  linkAttributes.forEach((attr) => {
    // TODO: can be optimized to only refresh those who actually changed
    // be careful: moving form linkedNotes to followingNotes need refresh on related as well
    if (force || xor(note[attr], data[attr]).length > 0) {
      ids = concat(ids, data[attr], note[attr])
    }
  })
  ids = uniq(ids)

  // eslint-disable-next-line
  for (const id of ids) {
    try {
      const payload = await api.requestNote(id)
      await dispatch(receiveEntities(payload))
    } catch (error) {
      console.log(error)
    }
  }
}

export const requestNote = id => async (dispatch) => {
  const payload = await api.requestNote(id)
  await dispatch(receiveEntities(payload))
}

export const updateNote = data => async (dispatch, getState) => {
  const payload = await api.updateNote(data)
  await refreshNotes(data, dispatch, getState())
  const entities = await dispatch(receiveEntities(payload))
  await dispatch(udpateTags(values(payload.entities.notes)[0].tags))
  return entities
}

export const deleteNote = (data, options) => async (dispatch, getState) => {
  await api.deleteNote(data, options)
  if (!options.dryRun) {
    await refreshNotes(data, dispatch, getState(), true)
    const projectIds = Object.keys(get(getState(), 'entities.projects', []))
    await Promise.all(projectIds.map(id => dispatch(requestProjectItems(id))))
    await dispatch(removeNoteFromPage({ noteId: data.id }))
    await dispatch(removeEntity({ entityType: 'notes', ...data }))
    await dispatch(requestTags())
    await dispatch(selectNote(null))
  }
}

export const requestNotes = () => async (dispatch) => {
  const payload = await api.requestNotes()
  const result = await dispatch(receiveEntities(payload))
  await dispatch(receiveNotePage({ noteIds: payload.result }))
  return result
}

export const uploadNoteAttachments = files => async () => Promise.all(
  files.map(async file => ({ file, ...(await api.uploadFileToS3(file)) })),
)

let lastRequestId
// extra function that can be debounced
const apiSearch = async (search, dispatch, getState) => {
  const requestId = uuidV4()
  let result
  lastRequestId = requestId

  // separate api call into two calls: search and suggestions
  // api calls should run in parallel
  // api.searchNoteSuggestions(search.q, search.tags).then((payload) => {
  //   if (lastRequestId === requestId) {
  //     dispatch(suggestSearch(payload.suggestions))
  //   }
  // })

  // tags are now part of the filter query, get it from there
  const tagFilter = get(getState(), 'noteCollection.filter.tags', []).map(tag => tag.tag)
  const searchNotesPayload = await api.searchNotes(search.q, tagFilter)
  if (lastRequestId === requestId) {
    const notes = normalize([...searchNotesPayload.notes], [noteSchema])
    result = await dispatch(receiveEntities(notes))
    if (search.q === '') {
      // show all available notes if nothing is searched
      const allNotes = getState().entities.notes
      await dispatch(receiveNotePage({ noteIds: allNotes ? Object.keys(allNotes) : [] }))
    } else {
      await dispatch(receiveNotePage({ noteIds: notes.result }))
    }
    dispatch(isLoading(false))
  }
  return result
}

const debouncedApiSearch = debounce(apiSearch, SEARCH_DEBOUNCE_DURATION)

export const searchNotes = search => async (dispatch, getState) => {
  if (!isEqual(getState().search, search)) {
    dispatch(isLoading(true))
    dispatch(noteSearch(search))
    return debouncedApiSearch(search, dispatch, getState)
  }
  return null
}
