import React from 'react'
import PropTypes from 'prop-types'
import { Line } from 'rc-progress'

import './style.less'


const ProgressBar = props => (
  <Line strokeWidth="10" trailWidth="10" strokeColor="#2dcfff" {...props} />
)

ProgressBar.propTypes = {

}

export default ProgressBar
