import { createSelector } from 'reselect'

import { getNoneInitialCount as getNoteCount } from 'selectors/notes'
import { getNoneInitialCount as getProjectCount } from 'selectors/projects'
import { getCount as getProjectItemCount } from 'selectors/project-items'

const showCreateNote = createSelector(
  getNoteCount,
  getProjectCount,
  (noteCount, projectCount) => noteCount === 0 && projectCount === 1,
)

const showCreateHeading = createSelector(
  getNoteCount,
  getProjectItemCount,
  (noteCount, projectItemCount) => noteCount > 2 && projectItemCount === 0,
)

export default {
  CREATE_NOTE: showCreateNote,
  CREATE_HEADING: showCreateHeading,
}
