import React from 'react'
import PropTypes from 'prop-types'
import { propTypes as i18nPropTypes, translate } from 'i18n'

import { PrimaryButton } from 'components/common/Button'
import FontIcon from 'components/common/FontIcon'

import './style.less'

const PaymentSuccess = ({ onClick, t }) => (
  <div className="PaymentSuccees">
    <h1 className="PaymentSuccees__Heading">{t('paymentSuccess.success')}</h1>
    <FontIcon className="PaymentSuccees__Icon" icon={FontIcon.Icons.faCheckCircle} size="5x" />
    <div className="PaymentSuccees__Message">{t('paymentSuccess.message')}</div>
    <PrimaryButton className="PaymentSuccees__Button" type="button" onClick={onClick}>
      {t('paymentSuccess.button')}
    </PrimaryButton>
  </div>
)

PaymentSuccess.propTypes = {
  onClick: PropTypes.func,
  ...i18nPropTypes,
}

export default translate()(PaymentSuccess)
