import { createAction } from 'redux-actions'
import { get } from 'lodash'

import * as api from 'api'

import { receiveEntities, removeEntity } from 'actions/entities'
import { requestProfile } from 'actions/profiles'
import { encryptPaymentData } from 'integrations/Adyen'
import tracker from 'tracking/tracker'
import SubscriptionSelector from 'selectors/subscriptions'

export const receivePaymentData = createAction('RECEIVE_PAYMENT_DATA')

export const requestSubscriptions = () => async (dispatch) => {
  const payload = await api.requestSubscriptions()
  await dispatch(receiveEntities(payload))
}

export const requestPaymentData = () => async (dispatch) => {
  const payload = await api.requestPaymentData()
  dispatch(receivePaymentData(payload))
  return payload
}

export const finalizePayment = data => async () => {
  await api.finalizePayment(data)
}

export const subscribe = data => async (dispatch) => {
  let payload
  if (data.method === 'FREE') {
    payload = await api.subscribe({ method: 'FREE', planId: data.plan.id, voucherId: get(data, 'voucher.id') })
  }
  if (data.method === 'CREDIT') {
    const encryptedPaymentData = encryptPaymentData(data)
    payload = await api.subscribe({
      paymentData: encryptedPaymentData, planId: data.plan.id, method: 'CREDIT', voucherId: get(data, 'voucher.id'),
    })
  }
  if (data.method === 'SEPA') {
    payload = await api.subscribe({
      method: 'SEPA', paymentData: { ownerName: data.ownerName, iban: data.iban }, planId: data.plan.id, voucherId: get(data, 'voucher.id'),
    })
  }

  tracker.logEvent('SUBSCRIBE', { price: data.plan.price })

  await dispatch(requestProfile())
  await dispatch(requestSubscriptions())
  await dispatch(requestPaymentData())

  return payload
}

export const unsubscribe = () => async (dispatch) => {
  const payload = await api.unsubscribe()
  await dispatch(requestProfile()) // alternatively: get current subscription
  return payload
}

export const requestSubscriptionPlans = countryCode => async (dispatch) => {
  const payload = await api.requestSubscriptionPlans(countryCode)
  await dispatch(receiveEntities(payload))
}

export const checkVoucher = code => async (dispatch, getState) => {
  const voucher = SubscriptionSelector.getVoucher(getState())
  if (voucher) {
    await dispatch(removeEntity({ entityType: 'vouchers', ...voucher }))
  }

  if (code && code.length > 2) {
    const payload = await api.checkVoucher(code)
    await dispatch(receiveEntities(payload))
  }
}
