import React, { Component } from 'react'
import PropTypes from 'prop-types'

import FontIcon from 'components/common/FontIcon'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import { AuratikumFontIcons } from 'helper/utils'
import modifyClassName from 'helper/modifyClassName'
import Icon from 'components/common/Icon'
import Spinner from 'components/common/Spinner'

import './style.less'

const iconMap = {
  tags: FontIcon.Icons.faTag,
  profile: FontIcon.Icons.faUser,
  collection: FontIcon.Icons.faFolder,
}

class InitialDataLoading extends Component {
  static propTypes = {
    notes: PropTypes.string,
    projs: PropTypes.string,
    refs: PropTypes.string,
    tags: PropTypes.string,
    profile: PropTypes.string,
    ...i18nPropTypes,
  }

  renderStatusIcon = (value) => {
    if (value === 'loaded') return null
    if (value === 'error') return <div className="InitialDataLoading__StatusIconContainer"><FontIcon icon={FontIcon.Icons.faExclamationTriangle} /></div>
    return <div className="InitialDataLoading__StatusIconContainer"><Spinner className="InitialDataLoading__LoadingIndicator" /></div>
  }

  renderText = (value, entity) => {
    if (value === 'loaded') return this.props.t('loading.loaded', { entity, defaultValue: `${entity} geladen` })
    if (value === 'error') return this.props.t('loading.error', { entity, defaultValue: `Fehler beim Laden (${entity})` })
    return this.props.t('loading.loading', { entity, defaultValue: `lade ${entity}` })
  }

  renderIcon = (icon, value) => {
    if (Object.keys(iconMap).includes(icon)) {
      return <div className={modifyClassName('InitialDataLoading__Icon', { visible: value === 'loaded' })}><FontIcon icon={iconMap[icon]} /></div>
    }

    return <Icon className={modifyClassName('InitialDataLoading__Icon', { visible: value === 'loaded' })} icon={icon} />
  }

  renderItem = (value, entity, icon) => (
    <div className={`InitialDataLoading__Item InitialDataLoading__Item--${value}`}>
      <div className="InitialDataLoading__IconContainer">
        {this.renderIcon(icon, value)}
        {this.renderStatusIcon(value)}
      </div>
      <div className="InitialDataLoading__TextContainer">
        {this.renderText(value, entity)}
      </div>
    </div>
  )

  render() {
    const {
      notes, projs, refs, tags, profile, collections, t,
    } = this.props
    return (
      <div className="InitialDataLoading">
        <div className="InitialDataLoading__Container">
          <h2>{t('loading.title')}</h2>
          { this.renderItem(notes, t('nav.notes', { defaultValue: 'Notizen' }), AuratikumFontIcons.CLIPBOARD) }
          { this.renderItem(projs, t('common.projects', { defaultValue: 'Projekte' }), AuratikumFontIcons.NOTES) }
          { this.renderItem(refs, t('nav.references', { defaultValue: 'Referenzen' }), AuratikumFontIcons.REFERENCE) }
          { this.renderItem(tags, t('common.tags', { defaultValue: 'Schlagwörter' }), 'tags') }
          { this.renderItem(profile, t('loading.userprofile', { defaultValue: 'Nutzerprofil' }), 'profile') }
          { this.renderItem(collections, t('common.collections', { defaultValue: 'Referenz-Sammlungen' }), 'collection') }
        </div>
      </div>
    )
  }
}

export default translate()(InitialDataLoading)
