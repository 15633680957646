import { handleActions } from 'redux-actions'

import {
  showSuggestions, noteSearch, suggestSearch, isLoading,
} from 'actions/notes'

const reducer = handleActions({
  [noteSearch]: (state, action) => ({
    ...state,
    query: action.payload,
  }),
  [suggestSearch]: (state, action) => ({
    ...state,
    suggestions: [...action.payload],
  }),
  [showSuggestions]: (state, action) => ({
    ...state,
    visible: action.payload,
  }),
  [isLoading]: (state, action) => ({
    ...state,
    isLoading: action.payload,
  }),
}, {
  suggestions: [],
  visible: false,
  isLoading: false,
  query: {
    q: '',
    tags: [],
  },
})

export default reducer
