import { handleActions } from 'redux-actions'

import { earlyBirdStatusChanged } from 'actions/challenge'


const reducer = handleActions({
  [earlyBirdStatusChanged]: (state, action) => ({
    ...action.payload,
  }),
}, { isEarlyBird: false })

export default reducer
