import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'

import modifyClassName from 'helper/modifyClassName'
import { propTypes as i18nPropTypes, translate } from 'i18n'

import FontIcon from 'components/common/FontIcon'
import Spinner from 'components/common/Spinner'
import { SimpleButtonToolTip } from 'components/common/Button'

import './style.less'


class FlyoutOutlineHeading extends Component {
  handleClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const { item, handleClick } = this.props
    handleClick(item.id)
  }

  handleLinkItem = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const { item, handleLinkItem } = this.props
    handleLinkItem(item)
  }

  renderButton = () => {
    const { isLinking, linkingSuccess, t } = this.props
    if (linkingSuccess) {
      return (
        <div className="FlyoutOutlineHeading__Button">
          <FontIcon icon={FontIcon.Icons.faCheck} />
        </div>
      )
    }
    return (
      <Spinner className="FlyoutOutlineHeading__Button" show={isLinking}>
        <SimpleButtonToolTip
          className="FlyoutOutlineHeading__Button"
          onClick={this.handleLinkItem}
          tooltipText={t('linkNoteFlyout.addNoteHere')}
          tooltipPlacement="right"
        >
          <FontIcon icon={FontIcon.Icons.faArrowToRight} />
        </SimpleButtonToolTip>
      </Spinner>
    )
  }

  render() {
    const {
      className, item, headingPrefix, level,
    } = this.props
    return (
      <div className={`${className} FlyoutOutlineHeading FlyoutOutlineHeading--level${level}`}>
        <div className="FlyoutOutlineHeading__Actions">
          {this.renderButton()}
        </div>
        <button
          type="button"
          className={modifyClassName('FlyoutOutlineHeading__Text', { childless: isEmpty(item.children) })}
          onClick={this.handleClick}
        >
          {`${headingPrefix} `}
          {item.title}
        </button>
      </div>
    )
  }
}

FlyoutOutlineHeading.propTypes = {
  className: PropTypes.string,
  headingPrefix: PropTypes.string,
  level: PropTypes.number,
  item: PropTypes.shape({
    text: PropTypes.string,
    id: PropTypes.string,
    children: PropTypes.array,
    editing: PropTypes.bool,
    expanded: PropTypes.bool,
    selected: PropTypes.bool,
  }),
  handleClick: PropTypes.func,
  handleLinkItem: PropTypes.func,
  isLinking: PropTypes.bool,
  linkingSuccess: PropTypes.bool,
  ...i18nPropTypes,
}

export default translate()(FlyoutOutlineHeading)
