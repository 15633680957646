import React, { Component } from 'react'
import PropTypes from 'prop-types'
import modifyClassName from 'helper/modifyClassName'
import Textarea from 'react-textarea-autosize'
import { isEmpty } from 'lodash'

import ReferenceUrlIcon from 'components/references/ReferenceUrlIcon'
import './style.less'


const EXPANDABLE_ROW_COUNT = 4

const commonPropTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  meta: PropTypes.shape({}),
  input: PropTypes.shape({}),
  readOnly: PropTypes.bool,
}

const Container = ({
  className = '', label, meta: {
    valid, touched, error, form,
  }, input, children, readOnly, onOverlayClick,
}) => (
  <div className={`ReferenceAttributeInput ${className}`}>
    { (label || (touched && error)) && (
    <label htmlFor={`${form}.${input.name}`} className="ReferenceAttributeInput__Label">
      {label}
      {touched && error && <span className="ReferenceAttributeInput__Error">{error}</span>}
    </label>
    )}
    <div className={modifyClassName('ReferenceAttributeInput__Field', { valid: touched && valid, readOnly })}>
      { readOnly ? input.value : children }
      { onOverlayClick && !readOnly && <div onClick={onOverlayClick} className="ReferenceAttributeInput__Overlay" /> }
    </div>
  </div>
)

Container.propTypes = {
  ...commonPropTypes,
  children: PropTypes.element,
}


const ReferenceAttributeInput = ({
  className = '', label, autoFocus, placeholder, meta, input, readOnly, onOverlayClick, ...props
}) => (
  <Container {...{
    className, label, meta, input, readOnly, onOverlayClick,
  }}
  >
    <Textarea id={`${meta.form}.${input.name}`} {...{ autoFocus }} className="ReferenceAttributeInput__Input" type="text" {...input} placeholder={placeholder} autoComplete="new-password" {...props} />
  </Container>
)

ReferenceAttributeInput.propTypes = {
  ...commonPropTypes,
  placeholder: PropTypes.string,
  autoFocus: PropTypes.bool,
}

ReferenceAttributeInput.defaultPropTypes = {
  autoFocus: false,
}

export const ReferenceAttributeSelect = ({
  className = '', label, meta, input, options, readOnly,
}) => (
  <Container {...{
    className, label, meta, input, readOnly,
  }}
  >
    <div className="ReferenceAttributeInput__SelectContainer">
      <select id={`${meta.form}.${input.name}`} {...input} className="ReferenceAttributeInput__Select">
        {options.map(option => <option key={option.label || option.value} value={option.value}>{option.label || option.value}</option>)}
      </select>
    </div>
  </Container>
)

ReferenceAttributeSelect.propTypes = {
  ...commonPropTypes,
  options: PropTypes.arrayOf(PropTypes.shape({})),
}

export const ReferenceLinkAttribute = props => (
  <div className="ReferenceAttributeInput__LinkContainer">
    <ReferenceAttributeInput {...props} />
    <ReferenceUrlIcon className="ReferenceAttributeInput__LinkIcon" reference={props.input && { [props.input.name]: props.input.value }} />
  </div>
)

ReferenceLinkAttribute.propTypes = {
  ...commonPropTypes,
  name: PropTypes.string,
}

export class ReferenceExpandableAttributeInput extends Component {
  state = { isExpanded: false }

  onFocus = (...params) => {
    const { input } = this.props
    this.setState({ isExpanded: true })
    if (!input || !input.onFocus) {
      return
    }
    input.onFocus(...params)
  }

  handleBlur = () => {
    this.setState({ isExpanded: false })
  }

  handleOverlayClick = (...params) => {
    this.onFocus(...params)
    if (!this.textArea) { return }
    this.textArea.focus()
  }

  handleInputRef = ref => (this.textArea = ref)

  toggleExpanded = () => {
    this.setState({ isExpanded: !this.state.isExpanded })
  }

  render() {
    const { input } = this.props
    const { isExpanded } = this.state
    const isExpandable = !isEmpty(input.value)
    return (
      <ReferenceAttributeInput
        {...this.props}
        onOverlayClick={isExpandable && !isExpanded && this.handleOverlayClick}
        inputRef={this.handleInputRef}
        maxRows={isExpandable && !isExpanded ? EXPANDABLE_ROW_COUNT : undefined}
        minRows={isExpandable ? EXPANDABLE_ROW_COUNT : undefined}
        onBlur={this.handleBlur}
        onFocus={this.onFocus}
      />
    )
  }
}

ReferenceExpandableAttributeInput.propTypes = ReferenceAttributeInput.propTypes

export default ReferenceAttributeInput
