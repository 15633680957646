import React, { PureComponent } from 'react'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import ReferenceCitation from 'components/common/ReferenceCitation'

import './style.less'

class WritingBibliography extends PureComponent {
  static propTypes = {
    citationCluster: PropTypes.shape({}),
    ...i18nPropTypes,
  }

  render() {
    const { citationCluster, t } = this.props
    const refIds = get(citationCluster, 'bibliography.meta.referenceIds', [])
    return (
      <div className="WritingBibliography">
        <div className="WritingBibliography__Header">
          {t('writing.bibliography')}
        </div>
        <div className="WritingBibliography__Container">
          {refIds.map((id, index) => (
            <ReferenceCitation
              className="WritingBibliography__Citation"
              key={id}
              html={citationCluster.bibliographyEntries[index]}
              reference={{ id }}
              isLink
            />
          ))}
        </div>
      </div>
    )
  }
}

export default translate()(WritingBibliography)
