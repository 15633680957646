import React from 'react'
import PropTypes from 'prop-types'
import SnapSplitPane from 'components/common/SnapSplitPane'

import OutlineContainer from 'containers/outline/OutlineContainer'

import NotesOverview from 'containers/notes-overview/NotesOverview'

import './style.less'

const StructureWorkSpace = ({ project }) => (
  <div className="StructureWorkSpace">
    <SnapSplitPane
      defaultSize={460}
      minSize={40}
      primary="second"
    >
      <NotesOverview project={project} />
      <OutlineContainer />
    </SnapSplitPane>
  </div>
)

StructureWorkSpace.propTypes = {
  project: PropTypes.shape({}),
}

export default StructureWorkSpace
