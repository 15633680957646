import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { debounce } from 'lodash'

import { fieldPropTypes } from 'redux-form'
import Autosuggest from 'react-autosuggest'
import * as api from 'api'

import FontIcon from 'components/common/FontIcon'
import Spinner from 'components/common/Spinner'
import './style.less'


class StyleSelectorField extends Component {
  constructor() {
    super()

    this.state = {
      suggestions: [],
      value: '',
      loading: false,
      showInput: false,
    }


    this.debouncedApiSearch = debounce(this.apiSearch, 500)
  }

  onFieldChange = (event, { newValue }) => {
    this.setState({ value: newValue })
  }

  // Autosuggest will call this function every time you need to update suggestions.
  onSuggestionsFetchRequested = async ({ value }) => {
    this.setState({ loading: true })
    this.debouncedApiSearch(value)
  }

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    })
  }

  getSuggestionValue = style => style.title

  apiSearch = async (query) => {
    const result = await api.searchStyles(query)
    this.setState({ suggestions: result, loading: false })
  }

  toggleShowInput = (event) => {
    event.preventDefault()
    event.stopPropagation()
    const { showInput } = this.state
    this.setState({ showInput: !showInput })
  }

  handleSelection = (event, { suggestion }) => {
    this.props.input.onChange(suggestion)
    this.setState({ value: '', showInput: false })
    event.stopPropagation()
    event.preventDefault()
    return null
  }

  renderSuggestion(style) {
    return (
      <div>
        {style.title}
      </div>
    )
  }

  renderInput() {
    if (!this.state.showInput) return <div />

    const inputProps = {
      placeholder: 'Finde Zitierungsstil',
      value: this.state.value,
      onChange: this.onFieldChange,
      onBlur: () => this.setState({ value: '', showInput: false }),
      autoFocus: true,
      id: `${this.props.input.name}`,
    }


    return (
      <div className="AutoSuggestComponentWrapper">
        <Autosuggest
          suggestions={this.state.suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={this.getSuggestionValue}
          renderSuggestion={this.renderSuggestion}
          onSuggestionSelected={this.handleSelection}
          inputProps={inputProps}
          highlightFirstSuggestion
          shouldRenderSuggestions={() => true}
        />
        { this.state.loading && <Spinner className="AutoSuggestComponentWrapper__Spinner" /> }
      </div>
    )
  }

  render() {
    return (
      <div className="StyleSelector">
        <label htmlFor={`${this.props.input.name}`} className="StyleSelector__Label">{this.props.label}</label>
        <div className="StyleSelector__Current" onClick={this.toggleShowInput}>
          { this.props.input.value.title || '[kein Zitierungsstil gewählt]' }
          <div className="StyleSelector__ArrowDown"><FontIcon icon={FontIcon.Icons.faCaretDown} /></div>
        </div>
        <div className="StyleSelector__AutoSuggest">
          {this.renderInput()}
        </div>
      </div>
    )
  }
}

StyleSelectorField.propTypes = {
  ...fieldPropTypes,
  styles: PropTypes.array,
}

export default StyleSelectorField
