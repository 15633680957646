import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { debounce, get } from 'lodash'

import { setScrollPosition } from 'actions/scroll-restoration'
import { getScrollPositions } from 'selectors/scroll-restoration'

import List from 'react-virtualized/dist/commonjs/List'
import Grid from 'react-virtualized/dist/commonjs/Grid'

const DEBOUNCE_TIME = 1000

const ScrollRestored = (WrappedComponent) => {
  const wrapper = class extends Component {
    static propTypes = {
      id: PropTypes.string.isRequired,
      className: PropTypes.string,
      children: PropTypes.node,
      setScrollPosition: PropTypes.func,
      positions: PropTypes.shape({}),
      scrollToTopHandler: PropTypes.func,
    }

    static defaultProps = {
      className: '',
      scrollToTopHandler: undefined,
    }

    state = {
      style: { scrollBehavior: 'auto' },
      scrollTop: undefined,
    }

    constructor(props) {
      super(props)
      this.container = React.createRef()
      this.debouncedScrollStoreUpdate = debounce(this.updateStorePosition, DEBOUNCE_TIME)
    }

    componentDidMount() {
      const { positions, id, scrollToTopHandler } = this.props
      this.position = positions[id] || 0

      setTimeout(() => {
        if (this.container.current) {
          this.container.current.scrollTop = this.position
        }
        this.setState({ style: {}, scrollTop: this.position })
      }, 0)

      if (scrollToTopHandler) {
        scrollToTopHandler(this.scrollToTop)
      }
    }

    componentWillUnmount() {
      this.props.setScrollPosition({ id: this.props.id, position: this.position })
    }

    updateStorePosition = () => {
      this.props.setScrollPosition({ id: this.props.id, position: this.position })
    }

    scrollToTop = () => {
      if (!this.container || !this.container.current) {
        return
      }
      this.container.current.scrollTop = 0
    }

    handleScroll = ({ scrollTop }) => {
      this.position = get(this.container, 'current.scrollTop', scrollTop)
      this.debouncedScrollStoreUpdate()
    }

    render() {
      const { className, children } = this.props
      const { style, scrollTop } = this.state
      const props = {
        onScroll: this.handleScroll,
        style,
      }
      if (WrappedComponent) {
        return <WrappedComponent {...props} {...this.props} scrollTop={scrollTop}>{children}</WrappedComponent>
      }
      return <div ref={this.container} {...props} className={className}>{children}</div>
    }
  }

  const mapStateToProps = state => ({
    positions: getScrollPositions(state),
  })

  return connect(mapStateToProps, {
    setScrollPosition,
  })(wrapper)
}

export default ScrollRestored()
export const ScrollRestoredVList = ScrollRestored(List)
export const ScrollRestoredVGrid = ScrollRestored(Grid)
