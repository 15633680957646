import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { translate } from 'i18n'
import modifyClassName from 'helper/modifyClassName'

import Flyout from 'containers/common/Flyout'
import Icon from 'components/common/Icon'

import './style.less'


class SortFlyout extends Component {
  handleSelect = name => () => {
    const { onSelect } = this.props
    return onSelect && onSelect(name)
  }

  renderItem = ({ name, translationKey }) => {
    const {
      t, orderBy, order,
    } = this.props
    return (
      <div key={`sortFlyout_${name}`} className={modifyClassName('SortFlyout__Row', { selected: orderBy === name })}>
        <Icon icon="g" className={modifyClassName('SortFlyout__Icon', { rotated: orderBy === name && order === 'desc', selected: orderBy === name })} />
        <button type="button" onClick={this.handleSelect(name)}>{t(translationKey)}</button>
      </div>
    )
  }

  render() {
    const {
      attributes,
    } = this.props
    return (
      <div className="SortFlyout">
        {attributes && attributes.map(this.renderItem)}
      </div>
    )
  }
}

SortFlyout.propTypes = {
  t: PropTypes.func.isRequired,
  order: PropTypes.string,
  orderBy: PropTypes.string,
  onSelect: PropTypes.func,
  attributes: PropTypes.arrayOf(PropTypes.shape({ translationKey: PropTypes.string, name: PropTypes.string })),
}

export default Flyout(translate()(SortFlyout))
