import React from 'react'
import PropTypes from 'prop-types'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import {
  withRouter, Route, Switch, Redirect,
} from 'react-router-dom'

import HelpSpot from 'containers/HelpSpot'

import './style.less'

const ProjectDetailTabs = ({ onSelectView, match, t }) => (
  <Switch>
    <Route path={`${match.path}/structure`}>
      <div className="ProjectDetailTabs ProjectDetailTabs--structure">
        <button type="button" className="ProjectDetailTabs__Tab" onClick={() => onSelectView('write')}>
          <div className="ProjectDetailTabs__Icon">
            <HelpSpot
              className="ProjectDetailTabs__HelpSpot"
              translationKey="helpSpot.projectDetailView"
            />
            G
          </div>
          <div className="ProjectDetailTabs__Title">{t('projectDetailView.navWriting')}</div>
        </button>
      </div>
    </Route>
    <Route path={`${match.path}/write`}>
      <div className="ProjectDetailTabs ProjectDetailTabs--write">
        <button type="button" className="ProjectDetailTabs__Tab" onClick={() => onSelectView('structure')}>
          <div className="ProjectDetailTabs__Icon">F</div>
          <div className="ProjectDetailTabs__Title">{t('projectDetailView.navStructure')}</div>
        </button>
      </div>
    </Route>
    <Redirect to="/structure" />
  </Switch>
)

ProjectDetailTabs.propTypes = {
  match: PropTypes.shape({ path: PropTypes.string }),
  onSelectView: PropTypes.func,
  ...i18nPropTypes,
}

export default withRouter(translate()(ProjectDetailTabs))
