import { createAction } from 'redux-actions'
import tracker from 'tracking/tracker'
import HelpSelector from 'selectors/help'

export const enableHelpSystem = createAction('ENABLE_HELP_SYSTEM')
export const openHelpSpot = createAction('OPEN_HELP_SPOT')
export const closeHelpSpot = createAction('CLOSE_HELP_SPOT')

export const toggleHelpSystem = () => async (dispatch, getState) => {
  if (!HelpSelector.isEnabled(getState())) {
    tracker.logEvent('HELPSPOTS_ACTIVATED')
  }

  dispatch(enableHelpSystem())
}
