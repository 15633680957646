import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { showModal } from 'actions/modal'
import { connect } from 'react-redux'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import { PAYWALL_MODAL_ID } from 'containers/modal/PaywallModal'
import { PrimaryButton } from 'components/common/Button'
import FontIcon from 'components/common/FontIcon'
import modifyClassName from 'helper/modifyClassName'

import './style.less'


class AddButton extends Component {
  state = { isLoading: false, mounted: true }

  componentWillUnmount() {
    this.setState({ mounted: false })
  }

  onClick = async (e) => {
    const { onClick, enablePaywall } = this.props
    if (enablePaywall) {
      this.props.showModal(PAYWALL_MODAL_ID)
      return
    }
    this.setState({ isLoading: true })
    await onClick(e)
    if (this.state.mounted) this.setState({ isLoading: false })
  }

  render() {
    const {
      enablePaywall, className, forwardRef, ...props
    } = this.props

    return (
      <React.Fragment>
        <PrimaryButton forwardRef={forwardRef} className={`${modifyClassName('AddButton', { paywallEnabled: enablePaywall })} ${className || ''}`} {...props} onClick={this.onClick} isLoading={this.state.isLoading}>
          <FontIcon icon={FontIcon.Icons.faPlusLight} />
        </PrimaryButton>
      </React.Fragment>
    )
  }
}

AddButton.propTypes = {
  ...i18nPropTypes,
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,
  enablePaywall: PropTypes.bool,
  showModal: PropTypes.func,
  forwardRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
}

export default connect(null, {
  showModal,
})(translate()(AddButton))
