import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import { AuratikumFontIcons } from 'helper/utils'
import Icon from 'components/common/Icon'

import ConfirmationModal from '../ConfirmationModal'

class DeleteReferenceModal extends Component {
  static propTypes = {
    id: PropTypes.string,
    onConfirm: PropTypes.func,
    ...i18nPropTypes,
  }

  renderDeleteModalContent = data => (
    <React.Fragment>
      <Icon key="icon" className="ConfirmationModal__Icon" icon={AuratikumFontIcons.DELETE} />
      <div key="title" className="ConfirmationModal__Title">{this.props.t('deleteReferenceModal.contentTitle', { reference: data.title })}</div>
      <div key="subtitle" className="ConfirmationModal__SubTitle">{this.props.t('modal.cannotBeUndone')}</div>
    </React.Fragment>
  )

  render() {
    const { t, id, onConfirm } = this.props
    return (
      <ConfirmationModal
        id={id}
        title={t('deleteReferenceModal.title')}
        onConfirm={onConfirm}
        contentFunc={this.renderDeleteModalContent}
        okLabel={t('common.delete')}
        cancelLabel={t('common.cancel')}
      />
    )
  }
}

export default translate()(DeleteReferenceModal)
