import adyenEncrypt from 'adyen-cse-js'

const key = process.env.ADYEN_CSE_KEY

const getPaymentData = (data) => {
  let paymentData
  if (data.method === 'SEPA') {
    paymentData = {
      iban: data.iban,
      bic: data.bic,
      accountholder: data.holder,
      generationtime: new Date().toISOString(),
    }
  }

  if (data.method === 'CREDIT') {
    paymentData = {
      number: data.ccNumber,
      cvc: data.ccCvc,
      holderName: data.ccHolder,
      expiryMonth: data.ccExpMonth,
      expiryYear: data.ccExpYear,
      generationtime: new Date().toISOString(),
    }
  }
  return paymentData
}

export const validatePaymentData = (data) => {
  const paymentData = getPaymentData(data)

  const cse = adyenEncrypt.createEncryption(key, {})
  const validation = cse.validate(paymentData)
  return validation
}

export const encryptPaymentData = (data) => {
  const paymentData = getPaymentData(data)

  const cse = adyenEncrypt.createEncryption(key, {})
  const encryptedPaymentData = cse.encrypt(paymentData)
  return encryptedPaymentData
}
