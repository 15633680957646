import React from 'react'
import PropTypes from 'prop-types'

import modifyClassName from 'helper/modifyClassName'

import './style.less'

const ModifiedTag = modifier => WrappedComponent => props => <WrappedComponent modifier={modifier ? { [modifier]: true } : {}} {...props} />

const Tag = ({
  className = '', modifier, tag, onSelect, onDelete, selected, style = {},
}) => (
  <div style={style} className={`${modifyClassName('Tag', modifier, { selected })} ${className}`}>
    <button
      type="button"
      className={modifyClassName('Tag__SelectButton', modifier, { withDelete: !!onDelete })}
      onClick={onSelect}
    >
      {tag}
    </button>
    { onDelete
      && (
      <button type="button" className={modifyClassName('Tag__DeleteButton', modifier)} onClick={() => onDelete(tag)}>
        <span className={modifyClassName('Tag__DeleteButtonIcon', modifier)}>L</span>
      </button>
      ) }
  </div>
)

Tag.propTypes = {
  className: PropTypes.string,
  modifier: PropTypes.shape(),
  onSelect: PropTypes.func,
  tag: PropTypes.string,
  onDelete: PropTypes.func,
  selected: PropTypes.bool,
  style: PropTypes.shape({}),
}

export default Tag
export const SmallTag = ModifiedTag('small')(Tag)
