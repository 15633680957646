import { createAction } from 'redux-actions'
import { requestScores, requestEarlyBirdStatus } from 'api'
import { receiveEntities } from './entities'

export const earlyBirdStatusChanged = createAction('EARLY_BIRD_STATUS_CHANGED')

export const fetchScores = () => async (dispatch) => {
  const payload = await requestScores()
  await dispatch(receiveEntities(payload))
}

export const getEarlyBirdStatus = () => async (dispatch) => {
  const payload = await requestEarlyBirdStatus()
  await dispatch(earlyBirdStatusChanged(payload))
}
