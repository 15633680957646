import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { connect } from 'react-redux'

import ReferenceCitation from 'components/common/ReferenceCitation'
import { PrimaryButton } from 'components/common/Button'
import { showModal } from 'actions/modal'

import { propTypes as i18nPropTypes, translate } from 'i18n'
import { createReference, findUserReference } from 'actions/references'

import Flyout from 'containers/common/Flyout'
import ReferenceSelector from 'selectors/references'
import { getCitationsOfSelectedNoteOrProject } from 'selectors/general'
import FormTextInput from 'components/common/FormTextInput'
import FormSelectInput from 'components/common/FormSelectInput'
import Spinner from 'components/common/Spinner'

import { ObjectId } from 'helper/utils'
import { Locators } from 'helper/citation'
import ReferencePicker from 'containers/note-detail/ReferencePicker'

import './style.less'


class AddCitationFlyout extends Component {
  static propTypes = {
    onAddCitation: PropTypes.func,
    onSaveCitation: PropTypes.func,
    references: PropTypes.shape({}),
    ...i18nPropTypes,
  }

  static defaultProps = {
    references: {},
  }

  constructor(props, context) {
    super(props, context)
    this.state = {}

    let citation = {
      id: ObjectId(),
      items: [],
    }
    let isNew = true
    if (props.data.citationId) {
      const c = props.citations[props.data.citationId]
      citation.id = props.data.citationId

      if (c && c.items) {
        citation = {
          ...c,
          items: c.items.map((item) => {
            let extra = 'none'
            if (item.suppressAuthor) extra = 'SUPPRESS_AUTHOR'
            if (item.authorOnly) extra = 'AUTHOR_ONLY'
            return {
              ...item,
              extra,
            }
          }),
        }
      }
      isNew = false
    }
    this.state = {
      citation,
      isNew,
      isSelectingReference: isNew || get(citation, 'items.length', 0) === 0,
      isSaving: false,
    }
  }

  handleSave = async (e) => {
    e.stopPropagation()
    e.preventDefault()
    const { onSaveCitation, onAddCitation } = this.props

    const citation = { ...this.state.citation }
    citation.items = citation.items.map(item => ({
      ...item,
      label: item.locator && item.locator !== '' ? item.label || Locators.PAGE : undefined,
      suppressAuthor: item.extra === 'SUPPRESS_AUTHOR',
      authorOnly: item.extra === 'AUTHOR_ONLY',
    }))
    this.setState({ isSaving: true })
    if (this.state.isNew) {
      await onAddCitation({ citation })
    } else {
      await onSaveCitation({ citation })
    }
    this.setState({ isSaving: false })
    this.props.hide()
  }

  handleAddReference = (e) => {
    e.stopPropagation()
    e.preventDefault()
    this.setState({ isSelectingReference: true })
  }

  handleRemoveReference = (e, index) => {
    e.stopPropagation()
    e.preventDefault()
    const { citation } = this.state
    const items = [...citation.items]
    items.splice(index, 1)
    this.setState({
      isSelectingReference: items.length === 0,
      citation: {
        ...citation,
        items,
      },
    })
  }

  handleSelection = async (ref) => {
    let reference = ref
    if (reference.fromLibrary) {
      reference = await this.props.findUserReference(reference)
      if (!reference) {
        // create new reference from external reference
        reference = await this.props.createReference(ref)
      }
    }

    const { citation } = this.state
    const items = [...citation.items]
    items.push({
      referenceId: reference.id,
      label: Locators.PAGE,
    })
    this.setState({
      isSelectingReference: false,
      citation: {
        ...citation,
        items,
      },
    })
  }

  stopPropagation = (e) => {
    e.stopPropagation()
  }

  handleInputChange = (index, attr) => (e) => {
    const { citation } = this.state
    const newCitation = { ...citation }
    newCitation.items[index][attr] = e.target.value
    this.setState({ citation: newCitation })
  }


  renderReference = reference => (<div><ReferenceCitation className="ReferenceSelector__Reference--suggestList" reference={reference} /></div>)

  renderItem = (item, index) => {
    const { t } = this.props
    const {
      locator, extra = 'none', prefix, label, suffix,
    } = item
    return (
      <div key={item.referenceId} className="AddCitationFlyout__Item">
        { this.renderReference(this.props.references[item.referenceId]) }
        <div className="AddCitationFlyout__FormRow">
          <div className="AddCitationFlyout__FormCol">
            <FormTextInput
              label={t('citation.prefix')}
              input={{
                name: 'prefix',
                value: prefix,
                onChange: this.handleInputChange(index, 'prefix'),
                onFocus: this.stopPropagation,
                onBlur: this.stopPropagation,
                onClick: this.stopPropagation,
                type: 'text',
              }}
              meta={{ form: 'citation' }}
              className="AddCitationFlyout__Input"
            />
            <FormTextInput
              label={t('citation.suffix')}
              input={{
                value: suffix,
                name: 'suffix',
                onChange: this.handleInputChange(index, 'suffix'),
                onFocus: this.stopPropagation,
                onBlur: this.stopPropagation,
                onClick: this.stopPropagation,
                type: 'text',
              }}
              meta={{ form: 'citation' }}
              className="AddCitationFlyout__Input"
            />
          </div>
          <div className="AddCitationFlyout__FormCol">
            <div className="AddCitationFlyout__FormLocatorContainer">
              <select className="AddCitationFlyout__FormLocator" onChange={this.handleInputChange(index, 'label')} value={label || Locators.PAGE}>
                { Object.keys(Locators).map(locatorKey => (
                  <option key={locatorKey} value={Locators[locatorKey]}>{t(`citation.locators.${locatorKey}`)}</option>)) }
              </select>
              <FormTextInput
                label=""
                input={{
                  value: locator,
                  onChange: this.handleInputChange(index, 'locator'),
                  onFocus: this.stopPropagation,
                  onBlur: this.stopPropagation,
                  onClick: this.stopPropagation,
                  type: 'text',
                }}
                meta={{ form: 'citation' }}
                className="AddCitationFlyout__Input"
              />
            </div>
            <FormSelectInput
              label={t('citation.extra')}
              input={{
                value: extra,
                onChange: this.handleInputChange(index, 'extra'),
                onFocus: this.stopPropagation,
                onBlur: this.stopPropagation,
                onClick: this.stopPropagation,
              }}
              meta={{ form: 'citation' }}
              options={[{
                value: 'none',
                label: t('citation.extras.NONE'),
              }, {
                value: 'SUPPRESS_AUTHOR',
                label: t('citation.extras.SUPPRESS_AUTHOR'),
              },
              {
                value: 'AUTHOR_ONLY',
                label: t('citation.extras.AUTHOR_ONLY'),
              }]}
            />
          </div>
        </div>
        <button className="AddCitationFlyout__RemoveReference" type="button" onClick={e => this.handleRemoveReference(e, index)}>
          {this.props.t('citation.removeReference')}
        </button>
      </div>
    )
  }

  renderItems = () => {
    const { citation, isNew } = this.state
    return (
      <div className="AddCitationFlyout__ItemsContainer">
        { citation.items.map((item, index) => this.renderItem(item, index)) }
        <button className="AddCitationFlyout__AddReferenceButton" type="button" onClick={this.handleAddReference}>{this.props.t('citation.addAdditional')}</button>
        <PrimaryButton className="AddCitationFlyout__SubmitButton" type="button" onClick={this.handleSave}>
          <Spinner show={this.state.isSaving}>{isNew ? this.props.t('common.add') : this.props.t('common.save')}</Spinner>
        </PrimaryButton>
      </div>
    )
  }

  renderAutoSuggest = () => (
    <ReferencePicker
      className="AddCitationFlyout__ReferencePicker"
      onSelect={this.handleSelection}
      ignoreIds={this.state.citation.items.map(i => i.referenceId)}
      displayAutoSuggest
      disableCreate
    />
  )

  render() {
    const { isSelectingReference } = this.state
    const { data: { width } } = this.props
    return (
      <div className="AddCitationFlyout" style={{ width }}>
        <div className="AddCitationFlyout__Content">
          <div className="AddCitationFlyout__Title">{this.props.t('addcitationflyout.title')}</div>
          { isSelectingReference ? this.renderAutoSuggest() : this.renderItems()}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  references: ReferenceSelector.getRaw(state),
  citations: getCitationsOfSelectedNoteOrProject(state),
})

export default connect(mapStateToProps, {
  showModal,
  createReference,
  findUserReference,
})(translate()(Flyout(AddCitationFlyout)))
