import React, { Component } from 'react'
import { connect } from 'react-redux'

import PropTypes from 'prop-types'
import { propTypes as i18nPropTypes, translate } from 'i18n'

import AddButton from 'components/common/AddButton'

import { ADD_REFERENCE_MODAL_ID } from 'containers/modal/AddReferenceModal'

import SubscriptionSelector from 'selectors/subscriptions'
import { showModal } from 'actions/modal'


class AddNewReferenceButton extends Component {
  static propTypes = {
    ...i18nPropTypes,
    showModal: PropTypes.func,
  }

  handleAdd = () => this.props.showModal(ADD_REFERENCE_MODAL_ID)

  render() {
    const { isPremiumRestricted } = this.props
    return (
      <div className="ReferenceListUser__AddButton">
        <AddButton onClick={this.handleAdd} enablePaywall={isPremiumRestricted} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  isPremiumRestricted: SubscriptionSelector.isReferenceCountPremiumRestricted(state),
})


export default connect(mapStateToProps, {
  showModal,
})(translate()(AddNewReferenceButton))
