import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { propTypes as i18nPropTypes, translate } from 'i18n'

import { connect } from 'react-redux'
import { get } from 'lodash'

import { deleteAccount } from 'actions/profiles'
import ModalFooter from 'components/common/ModalFooter'
import ModalHeader from 'components/common/ModalHeader'
import { DangerButton } from 'components/common/Button'
import FontIcon from 'components/common/FontIcon'
import Input from 'components/common/Input'
import { cognitoGetUser } from 'integrations/AWSCognito'
import { doLogout } from 'actions/authentication'

import Modal from '../Modal'

import './style.less'

export const MODAL_ID = 'DELETE_ACCOUNT_MODAL'


class DeleteAccountModal extends Component {
    state = {
      value: '',
      email: cognitoGetUser().username,
      isLoading: false,
    }

    isConfirmed = () => {
      const { value, email } = this.state
      return value.toLowerCase() === email.toLowerCase()
    }

    handleDelete = async () => {
      try {
        this.setState({ isLoading: true })
        await this.props.deleteAccount()
        await this.props.doLogout()
      } catch (error) {
        this.setState({ error: error.message, isLoading: false })
      }
    }

    render() {
      const { t } = this.props
      const { value, isLoading, error } = this.state
      return (
        <div className="DeleteAccountModal">
          <ModalHeader title={t('deleteAccountModal.title')} />

          <div className="DeleteAccountModal__Content">
            <FontIcon className="DeleteAccountModal__Icon" icon={FontIcon.Icons.faExclamationTriangle} />

            <div className="DeleteAccountModal__Title">{t('deleteAccountModal.description')}</div>

            <div className="DeleteAccountModal__InputLabel">{t('deleteAccountModal.inputTitle')}</div>

            <Input
              className="DeleteAccountModal__Input"
              placeholder={t('common.email')}
              value={value}
              onChange={e => this.setState({ value: e.target.value })}
            />

            <div className="DeleteAccountModal__Undone">{t('modal.cannotBeUndone')}</div>


            { error && <div className="DeleteAccountModal__Error">{t('deleteAccountModal.error')}</div>}
          </div>
          <ModalFooter
            className=""
            isLoading={isLoading}
            confirmButtonLabel={this.isConfirmed() ? t('deleteAccountModal.deleteLabel') : undefined}
            confirmButtonCls={DangerButton}
            onConfirmButtonClick={this.handleDelete}
            cancelButtonLabel={t('common.cancel')}
            onCancelButtonClick={() => this.props.hide()}
          />
        </div>
      )
    }
}


const mapStateToProps = (state, ownProps) => ({
  okLabel: get(ownProps, 'data.okLabel') || ownProps.okLabel,
  title: get(ownProps, 'data.title') || ownProps.title,
})

DeleteAccountModal.propTypes = {
  hide: PropTypes.func,
  project: PropTypes.shape({}),
  ...i18nPropTypes,
}

export default Modal(connect(mapStateToProps, {
  deleteAccount,
  doLogout,
})(translate()(DeleteAccountModal)))
