import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { push } from 'connected-react-router'
import { isEqual, keys, get } from 'lodash'

import ReferenceCitation from 'components/common/ReferenceCitation'
import Icon from 'components/common/Icon'
import ReferenceFileDownload from 'components/references/ReferenceFileDownload'
import ReferenceUrlIcon from 'components/references/ReferenceUrlIcon'

import { parseColor } from 'helper/colorHandler.js'
import { AuratikumFontIcons } from 'helper/utils'
import { getCitationClusterForSelectedProject } from 'selectors/citation'
import ReferenceSelector from 'selectors/references'
import striptags from 'striptags'

import { CITATION_ID_ATTR } from 'helper/citation'

import './style.less'

const ROW_HEIGHT = 70
class WritingPortalElements extends Component {
  static propTypes = {
    itemId: PropTypes.string,
    references: PropTypes.shape({}),
    note: PropTypes.shape({
      id: PropTypes.string,
      color: PropTypes.string,
      title: PropTypes.string,
    }),
    push: PropTypes.func.isRequired,
    rootElementId: PropTypes.string,
    citationCluster: PropTypes.shape({
      citations: PropTypes.shape({}),
    }),
    rootElementDimensions: PropTypes.shape({
      height: PropTypes.number,
    }),
  }

  constructor(props) {
    super(props)

    this.offsetMarker = 0
    this.state = { citationRefElements: [] }
    this.updateCitationRefElements()
  }

  componentDidMount() {
    this.updateCitationRefElements()
  }

  componentDidUpdate = (prevProps) => {
    const { rootElementId, references, rootElementDimensions } = this.props
    const { rootElementId: prevRootElementId, references: prevReferences, rootElementDimensions: prevRootElementDimensions } = prevProps

    const areReferencesEqual = references && isEqual(keys(references).sort(), keys(prevReferences).sort())

    if (rootElementId !== prevRootElementId || !areReferencesEqual || rootElementDimensions.height !== prevRootElementDimensions.height) {
      this.updateCitationRefElements()
    }
  }

  /**
   * Really, really, really bad design. This waits 500ms until the content is rendered to determine the
   * position of the inline citations.
   */
  updateCitationRefElements = () => setTimeout(() => {
    const { rootElementId } = this.props
    let parent
    let citationRefElements = []

    if (rootElementId) {
      parent = document.getElementById(rootElementId)
      const inlineCitations = parent !== null ? parent.getElementsByClassName('InlineCitation') : []
      citationRefElements = Array.from(inlineCitations)
      this.offsetMarker = 0
    }
    this.setState({ citationRefElements })
  }, 500)

  calculateOffsetTop = (preferedTop) => {
    // initial: First Reference can be placed on prefered top
    if (this.offsetMarker === 0) {
      this.offsetMarker = preferedTop + ROW_HEIGHT
      return preferedTop
    }

    // at least second: First Reference is already placed, we need to check positioning
    // next Reference fits without overlapping ?
    if (preferedTop >= this.offsetMarker) {
      this.offsetMarker = preferedTop + ROW_HEIGHT
      return preferedTop
    }

    // overlapping Reference detected
    const difference = this.offsetMarker - preferedTop
    // calculate new minimum top position
    const newTop = preferedTop + difference
    this.offsetMarker = newTop + ROW_HEIGHT
    return newTop
  }

  handleOpenReference = reference => () => this.props.push(`/references/lib/${reference.id}`)

  renderCitation = (citation, index, offsetTop) => {
    const { references } = this.props
    const reference = references[get(citation, 'referenceIds[0]')]
    if (!reference) return null
    return this.renderReference(reference, index, offsetTop)
  }

  renderReference = (reference, index, offsetTop) => (
    <div
      className="WritingPortalElements__Reference"
      id={`SidebarReference-${this.props.itemId}-${reference.id}-${index}`}
      key={`SidebarReference-${this.props.itemId}-${reference.id}-${index}`}
      style={{ top: this.calculateOffsetTop(offsetTop) }}
      title={striptags(reference.citation)}
    >
      <div className="WritingPortalElements__ReferenceHeader">
        <div className="WritingPortalElements__ReferenceIcon">
          <Icon icon={AuratikumFontIcons.REFERENCE} />
        </div>
        <div className="WritingPortalElements__ReferenceActions">
          <ReferenceUrlIcon reference={reference} />
          <ReferenceFileDownload reference={reference} />
        </div>
      </div>
      <button type="button" className="WritingPortalElements__ReferenceButton" onClick={this.handleOpenReference(reference)}>
        <ReferenceCitation className="WritingPortalElements__ReferenceContent" reference={reference} />
      </button>
    </div>
  )

  renderNote = () => (
    <NavLink className="WritingPortalElements__Link" to={`/notes/${this.props.note.id}`}>
      <div className="WritingPortalElements__Note" id={`SidebarNote-${this.props.itemId}-${this.props.note.id}`} style={{ borderColor: parseColor(this.props.note.color), top: this.calculateOffsetTop(0) }}>
        { this.props.note.title }
      </div>
    </NavLink>
  )

  render() {
    this.offsetMarker = 0
    const {
      itemId, note, citationCluster,
    } = this.props
    const { citationRefElements } = this.state
    return (
      <div id={`WritingPortalElements-${itemId}`} className="WritingPortalElements">
        { note && this.renderNote() }
        { citationRefElements.map((ref, index) => {
          const inlineCitationRefValue = get(ref.attributes, [CITATION_ID_ATTR, 'value'])
          const citation = get(citationCluster, ['citations', `${inlineCitationRefValue}-${itemId}`])
          if (citation) {
            return this.renderCitation(citation, index, ref.offsetTop - 40)
          }

          return null
        })
        }
      </div>
    )
  }
}

const mapStateToProps = state => ({
  citationCluster: getCitationClusterForSelectedProject(state),
  references: ReferenceSelector.getRaw(state),
})

export default connect(mapStateToProps, { push })(WritingPortalElements)
// export default WritingPortalElements
