import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Popper, Reference, Manager } from 'react-popper'
import modifyClassName from 'helper/modifyClassName'

import './style.less'

export default (WrappedComponent) => {
  class Tooltip extends Component {
    static propTypes = {
      tooltipPlacement: PropTypes.string,
      tooltipText: PropTypes.string,
      tooltipDisabled: PropTypes.bool,
      tooltipTimeout: PropTypes.number,
      children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    }

    static defaultProps = {
      tooltipTimeout: 500,
    }

    constructor(props) {
      super(props)
      this.state = {
        isVisible: false,
      }
      this.timer = null
    }

    componentDidMount() {
      document.addEventListener('mousedown', this.handleClickOutside)
    }

    componentWillUnmount() {
      document.removeEventListener('mousedown', this.handleClickOutside)
    }

    handleClickOutside = () => {
      if (this.state.isVisible) {
        this.hideTooltip()
      }
    }

    showTooltip = () => {
      this.timer = setTimeout(() => {
        this.setState({ isVisible: true })
      }, this.props.tooltipTimeout)
    }

    hideTooltip = () => {
      clearTimeout(this.timer)
      this.setState({ isVisible: false })
    }

    render() {
      const { isVisible } = this.state
      return (
        <Manager>
          <Reference>
            {({ ref }) => (
              <div
                ref={ref}
                onMouseEnter={this.showTooltip}
                onMouseLeave={this.hideTooltip}
              >
                <WrappedComponent {...this.props}>{this.props.children}</WrappedComponent>
              </div>
            )}
          </Reference>
          {(!this.props.tooltipDisabled && this.state.isVisible) && (
            <Popper placement={this.props.tooltipPlacement || 'right'}>
              {({
                placement,
                ref,
                style,
                arrowProps,
              }) => (
                <div className={modifyClassName('Tooltip__Container', { isVisible })} data-placement={placement} ref={ref} style={style}>
                  {this.props.tooltipText}
                  <div className="Tooltip__Arrow" ref={arrowProps.ref} style={arrowProps.style} />
                </div>
              )}
            </Popper>
          )}
        </Manager>
      )
    }
  }

  return Tooltip
}
