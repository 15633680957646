import React from 'react'
import PropTypes from 'prop-types'

import modifyClassName from 'helper/modifyClassName'

import withTooltip from 'containers/common/Tooltip'
import Spinner from 'components/common/Spinner'

import './style.less'

const ModifiedButton = modifier => WrappedComponent => ({ ...props }) => <WrappedComponent modifier={modifier} {...props} />

const Button = ({
  disabled, modifier, type = 'button', circleModifier = '', className = '', onClick, children,
  onMouseEnter, onMouseLeave, asDiv = false, forwardRef, isLoading, title,
}) => {
  const common = {
    className: `${modifyClassName('Button', modifier, circleModifier, { isDiv: asDiv, disabled })} ${className || ''}`,
    onMouseEnter,
    onMouseLeave,
    disabled: disabled || isLoading,
  }
  const content = <Spinner show={isLoading}>{children}</Spinner>
  return asDiv
    ? <div {...common}>{content}</div>
    // eslint-disable-next-line react/button-has-type
    : <button {...common} ref={forwardRef} onClick={onClick} type={type} title={title}>{content}</button>
}


Button.propTypes = {
  modifier: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  circleModifier: PropTypes.string,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  asDiv: PropTypes.bool,
  title: PropTypes.string,
  forwardRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  isLoading: PropTypes.bool,
}

export const OutlinedButton = ModifiedButton('outline')(Button)
export const OutlinedSecondaryButton = ModifiedButton('outline-secondary')(Button)
export const OutlinedAddButton = ModifiedButton('outline-add')(Button)
export const PremiumOutlinedButton = ModifiedButton('outline-premium')(Button)
export const PrimaryButton = ModifiedButton('primary')(Button)
export const SecondaryButton = ModifiedButton('secondary')(Button)
export const CancelButton = ModifiedButton('cancel')(Button)
export const PremiumButton = ModifiedButton('premium')(Button)
export const WhiteOutlinedButton = ModifiedButton('whiteOutline')(Button)
export const SimpleButton = ModifiedButton('simple')(Button)
export const DangerButton = ModifiedButton('danger')(Button)


export const OutlinedButtonTooltip = withTooltip(OutlinedButton)
export const SimpleButtonToolTip = withTooltip(SimpleButton)
