import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import intervalPlural from 'i18next-intervalplural-postprocessor'
import XHR from 'i18next-xhr-backend'
import PropTypes from 'prop-types'
import { isNumber, isDate } from 'lodash'
import moment from 'moment'
import numeral from 'numeral'

import 'numeral/locales/de'
import 'moment/locale/de'
import 'numeral/locales/en-gb'
import 'moment/locale/en-gb'

import { initReactI18next, withTranslation } from 'react-i18next'

export const supportedLanguages = ['de', 'en']

moment.locale('en-gb')
numeral.locale('en-gb')

i18n
  .use(XHR)
  .use(intervalPlural)
  .use(LanguageDetector)
  .use(initReactI18next) // bind react-i18next to the instance
  .init({
    fallbackLng: 'en',
    ns: ['translation', 'references'],
    defaultNS: 'translation',
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react!!
      format: (value, format) => {
        if (format && isNumber(value)) {
          try {
            return numeral(value).format(format)
          } catch (error) {
            moment.locale('en-gb')
            numeral.locale('en-gb')
            return numeral(value).format(format)
          }
        }
        if (format && isDate(value)) return moment(value).format(format)
        if (format && moment.isMoment(value)) return value.format(format)
        return value
      },
    },

    // react i18next special options (optional)
    react: {
      bindI18n: 'languageChanged',
      useSuspense: false,
    },
  })

i18n.on('languageChanged', (lng) => {
  let l = lng
  if (l === 'en') l = 'en-gb'
  moment.locale(l)
  numeral.locale(l)
})

export default i18n

export const propTypes = {
  t: PropTypes.func,
}
export const translate = withTranslation
