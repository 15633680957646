import { isObject } from 'lodash'
import { handleActions } from 'redux-actions'

import {
  setCslXml, setReferencesSearchQuery, updateExternalSearch, clearReferenceSearch, sortReferences,
} from 'actions/references'
import { selectReferenceCollection } from 'actions/reference-collections'

const reducer = handleActions({
  [setCslXml]: (state, action) => ({ ...state, csl: action.payload }),
  [selectReferenceCollection]: (state, action) => ({
    ...state,
    selectedCollection: isObject(action.payload) ? action.payload.id : action.payload,
  }),
  [setReferencesSearchQuery]: (state, action) => ({ ...state, query: action.payload }),
  [clearReferenceSearch]: state => ({ ...state, query: '', libraries: {} }),
  [updateExternalSearch]: (state, { payload: { libraryKey, ...rest } }) => ({
    ...state,
    libraries: {
      ...state.libraries,
      [libraryKey]: {
        ...state.libraries[libraryKey],
        ...rest,
      },
    },
  }),
  [sortReferences]: (state, action) => (!action.payload ? state : ({
    ...state,
    orderBy: action.payload,
    order: state.orderBy !== action.payload || state.order === 'asc' ? 'desc' : 'asc',
  })),
}, {
  csl: null,
  selectedCollection: null,
  libraries: [],
  query: '',
  orderBy: null,
  order: null,
})

export default reducer
