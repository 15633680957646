import React from 'react'
import PropTypes from 'prop-types'

import { propTypes as i18nPropTypes } from 'i18n'

import Icon from 'components/common/Icon'
import { AuratikumFontIcons } from 'helper/utils'
import modifyClassName from 'helper/modifyClassName'

import './style.less'

const WritingSidebar = ({ visible, toggleSidebar }) => (
  <div className="WritingSidebar">
    <button type="button" className={modifyClassName('WritingSidebar__Button', { visible })} onClick={toggleSidebar}>
      <Icon icon={AuratikumFontIcons.ARROW_DOWN} className={modifyClassName('WritingSidebar__Icon', { visible })} />
    </button>
    <div id="writing-sidebar-portal-container" className={modifyClassName('WritingSidebar__Content', { visible })} />
  </div>
)

WritingSidebar.propTypes = {
  visible: PropTypes.bool,
  toggleSidebar: PropTypes.func,
  ...i18nPropTypes,
}

export default WritingSidebar
