import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import AuthCheckLoading from 'components/loading/AuthCheckLoading'
import AuthPage from 'containers/pages/AuthPage'
import { checkAuth, states } from 'actions/authentication'
import AuthSelector from 'selectors/auth'
import App from 'containers/App'

class Authentication extends Component {
  componentDidMount() {
    this.props.checkAuth()
    this.timer = setInterval(this.checkAuth, 10000)
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  checkAuth = () => {
    if (this.props.authState === states.AUTHENTICATED) this.props.checkAuth()
  }

  render() {
    const { authState } = this.props

    if (authState === states.NOT_AUTHENTICATED) {
      return <AuthPage />
    }

    if (authState === states.AUTHENTICATED) {
      return <App />
    }

    return <AuthCheckLoading />
  }
}

Authentication.propTypes = {
  authState: PropTypes.string,
  checkAuth: PropTypes.func,
}

const mapStateToProps = state => ({
  authState: AuthSelector.getAuthState(state),
})

export default connect(mapStateToProps, {
  checkAuth,
})(Authentication)
