import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { HotKeys } from 'react-hotkeys'
import {
  isDirty as formIsDirty,
  isSubmitting as formIsSubmitting,
  hasSubmitFailed as formHasSubmitFailed,
  getFormValues, submit, change, reset, SubmissionError,
} from 'redux-form'
import { goBack, push } from 'connected-react-router'
import { Prompt } from 'react-router'
import { debounce, values } from 'lodash'
import {
  selectNote, updateNote, deleteNote, duplicateNote,
} from 'actions/notes'

import { propTypes as i18nPropTypes, translate } from 'i18n'

import { showNps } from 'actions/net-promoter-score'

import Page from 'components/page/Page'
import PageHeader from 'components/page/PageHeader'
import PageContent from 'components/page/PageContent'
import NoteDetailForm, { FORM_NAME } from 'components/note-detail/NoteDetailForm'
import RetryToast from 'containers/toasts/RetryToast'
import HelpSpot from 'containers/HelpSpot'

import NoteSelector from 'selectors/notes'
import SubscriptionSelector from 'selectors/subscriptions'
import { getCitationClusterForSelectedNote } from 'selectors/citation'
import ServiceModal, { MODAL_ID as SERVICE_MODAL_ID } from 'containers/modal/ServiceModal'
import Spinner from 'components/common/Spinner'

import { showModal, hideModal } from 'actions/modal'
import { updateNoteCitationCluster } from 'actions/citation'
import DeleteNoteModal from 'containers/modal/DeleteNoteModal'

import './style.less'

const DEBOUNCE_TIME = 1000
const DELETE_MODAL_ID = 'DELETE_NOTE_CONFIRMATION_MODAL'

const RETRY_TOAST_ID = 'NOTE_RETRY_TOAST'

class NoteDetailPage extends Component {
  constructor(props) {
    super(props)
    this.debouncedSubmit = debounce(this.handleDebouncedSave, DEBOUNCE_TIME)
  }

  componentDidMount() {
    if (this.props.note === undefined) {
      this.props.push('/notes')
    } else {
      this.props.selectNote(this.props.note.id)
      this.props.updateNoteCitationCluster(this.props.note)
    }
  }

  componentWillUnmount() {
    this.props.selectNote(null)
  }

  handleDebouncedSave = async () => {
    if (!this.props.isSubmitting) {
      await this.props.submit(FORM_NAME)
      this.props.showNps()
    }
  }

  handleEditorSave = () => {
    this.props.submit(FORM_NAME)
  }

  deleteNote = async (note) => {
    await this.props.deleteNote(note, { force: true })
    this.props.goBack()
  }

  handleDeleteNote = async (note) => {
    try {
      await this.props.deleteNote(note, { dryRun: true })
      this.props.showModal(DELETE_MODAL_ID, { data: { ...note, usedIn: undefined } })
    } catch (err) {
      this.props.showModal(DELETE_MODAL_ID, { data: { ...note, usedIn: err.data } })
    }
  }

  handleDuplicateNote = async (note) => {
    await this.props.duplicateNote(note)
  }

  handleServiceClick = () => {
    this.props.showModal(SERVICE_MODAL_ID)
  }

  updateNote = async (data) => {
    try {
      const action = await this.props.updateNote(data)
      const result = values(action.payload.entities.notes)[0]
      this.props.hideModal(RETRY_TOAST_ID)
      return result
    } catch (error) {
      this.props.showModal(RETRY_TOAST_ID)
      throw new SubmissionError({ _error: 'Note update failed' })
    }
  }

  renderSavingIndicator() {
    return (
      <div className="NoteDetailPage__SavingIndicator">
        <Spinner />
        {this.props.t('common.saving')}
      </div>
    )
  }

  render() {
    const {
      note,
      isSubmitting,
      isDirty,
      hasSubmitFailed,
      t,
    } = this.props

    const handlers = {
      escape: this.props.goBack,
    }

    if (!note) return <div />

    return (
      <HotKeys focused attach={window} handlers={handlers} className="NoteDetailPage__Content">
        <Page className="NoteDetailPage">
          <Prompt when={this.props.isDirty} message={t('common.unsavedChangesPrompt')} />
          <PageHeader>
            <div className="NoteDetailPage__SaveStatus">
              <HelpSpot
                className="NoteDetailPage__HelpSpot"
                translationKey="helpSpot.noteDetailViewSave"
              />
              { !isDirty && <div>{this.props.t('noteDetailView.noChanges')}</div> }
              { (isSubmitting || (isDirty && !hasSubmitFailed)) && this.renderSavingIndicator() }
              { isDirty && !isSubmitting && hasSubmitFailed && this.props.t('common.unsavedChanges') }
            </div>
          </PageHeader>
          <PageContent>
            <NoteDetailForm
              note={note}
              initialValues={note}
              onSubmit={this.updateNote}
              debouncedSubmit={this.debouncedSubmit}
              formValues={this.props.formValues}
              onSaveEditor={this.handleEditorSave}
              onDeleteNote={this.handleDeleteNote}
              onDuplicateNote={this.handleDuplicateNote}
              reset={this.props.reset}
              isDirty={this.props.isDirty}
              isSubmitting={this.props.isSubmitting}
              createdInProject={this.props.createdInProject}
              usedInProjects={this.props.usedInProjects}
              onServiceClick={this.handleServiceClick}
              isPremiumRestricted={this.props.isPremiumRestricted}
              updateNoteCitationCluster={this.props.updateNoteCitationCluster}
              citationCluster={this.props.citationCluster}
            />
          </PageContent>
          <RetryToast
            id={RETRY_TOAST_ID}
            onRetry={this.handleDebouncedSave}
            text={t('toasts.noteUpdateRetry')}
            isLoading={this.props.isSubmitting}
          />

          <DeleteNoteModal
            id={DELETE_MODAL_ID}
            onConfirm={this.deleteNote}
          />
          <ServiceModal id={SERVICE_MODAL_ID} services={['tutoring']} />

        </Page>
      </HotKeys>
    )
  }
}


const mapStateToProps = (state) => {
  const formValues = getFormValues(FORM_NAME)(state)
  return {
    note: NoteSelector.getSelectedNote(state),
    createdInProject: NoteSelector.getCreatedInProject(state),
    usedInProjects: NoteSelector.getUsedInProjects(state),
    isDirty: formIsDirty(FORM_NAME)(state),
    isSubmitting: formIsSubmitting(FORM_NAME)(state),
    hasSubmitFailed: formHasSubmitFailed(FORM_NAME)(state),
    formValues: formValues || {},
    isPremiumRestricted: SubscriptionSelector.isNotesCountPremiumRestricted(state),
    citationCluster: getCitationClusterForSelectedNote(state),
  }
}

NoteDetailPage.propTypes = {
  isDirty: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  submit: PropTypes.func,
  updateNote: PropTypes.func,
  deleteNote: PropTypes.func,
  duplicateNote: PropTypes.func,
  showModal: PropTypes.func,
  hideModal: PropTypes.func,
  selectNote: PropTypes.func,
  reset: PropTypes.func,
  formValues: PropTypes.shape({}),
  goBack: PropTypes.func,
  push: PropTypes.func,
  note: PropTypes.shape({}),
  showNps: PropTypes.func,
  createdInProject: PropTypes.shape({}),
  usedInProjects: PropTypes.arrayOf(PropTypes.shape({})),
  isPremiumRestricted: PropTypes.bool,
  updateNoteCitationCluster: PropTypes.func,
  ...i18nPropTypes,
}

export default connect(mapStateToProps, {
  push,
  goBack,
  updateNote,
  deleteNote,
  duplicateNote,
  showModal,
  hideModal,
  submit,
  change,
  reset,
  showNps,
  selectNote,
  updateNoteCitationCluster,
})(translate()(NoteDetailPage))
