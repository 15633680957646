import * as api from 'api'
import { createAction } from 'redux-actions'
import { updateProfile } from './profiles'

export const receiveLatestReleaseNote = createAction('RECEIVE_LATEST_RELEASE_NOTE')

export const requestReleaseNote = languageCode => async (dispatch) => {
  const payload = await api.requestReleaseNote(languageCode)
  return dispatch(receiveLatestReleaseNote(payload))
}

export const releaseNoteShown = version => async dispatch => dispatch(updateProfile({ version }))
