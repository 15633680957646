/* global document */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { range } from 'lodash'
import { connect } from 'react-redux'

import ProfileSelector from 'selectors/profile'
import SubscriptionSelector from 'selectors/subscriptions'
import NPSSelector from 'selectors/net-promoter-score'
import {
  closeNpsModal, showNpsModal, updateNps, sendFeedbackEmail, requestNps,
} from 'actions/net-promoter-score'
import { sendInvitationEmails } from 'actions/profiles'
import Rating from 'react-rating'
import { sleep } from 'helper/utils'
import tracker from 'tracking/tracker'
import SentryIntegration from 'integrations/Sentry'
import { propTypes as i18nPropTypes, translate } from 'i18n'

import { PrimaryButton } from 'components/common/Button'
import FontIcon from 'components/common/FontIcon'

import './style.less'


class NetPromoterScoreContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedRating: 0,
      feedbackText: '',
      emails: [],
      submittedText: null,
      fadeOut: false,
      dirty: false,
    }
  }

  componentDidMount() {
    this.props.requestNps()
      .catch(() => {})
  }

  onSubmit = () => {
    this.props.currentNps.rating = this.state.selectedRating - 1
    this.props.updateNps(this.props.currentNps)

    tracker.logEvent('USER_RATING', { id: this.props.profile.id, rating: this.props.currentNps.rating })

    if (this.props.currentNps.rating >= 9) {
      this.props.showNpsModal('RECOMMENDATION')
    } else if (this.props.currentNps.rating >= 7) {
      this.openSubmitModal(this.props.t('netpromoterscore.feedback.message'))
    } else {
      this.props.showNpsModal('FEEDBACK')
    }
  }

  onSubmitFeedback = async () => {
    this.props.currentNps.feedback = this.state.feedbackText
    await this.props.updateNps(this.props.currentNps)
    this.props.sendFeedbackEmail(this.props.currentNps)

    this.openSubmitModal(this.props.t('netpromoterscore.feedback.message'))
  }

  onSendEmails = async () => {
    this.props.currentNps.inviteEmails = this.state.emails
    await this.props.updateNps(this.props.currentNps)
    await this.props.sendInvitationEmails(this.state.emails)

    this.openSubmitModal(this.props.t('netpromoterscore.recommendation.message'))
  }

  onChangeRating = (rating) => {
    this.setState({
      selectedRating: rating,
      dirty: true,
    })
  }

  onChangeFeedback = (event) => {
    this.setState({
      feedbackText: event.target.value,
    })
  }

  onChangeEmails = (event) => {
    const emailsString = event.target.value
    this.setState({ emails: emailsString ? emailsString.split(',') : [] })
  }

  openSubmitModal = async (submittedText) => {
    this.setState({ submittedText })
    await this.props.showNpsModal('SUBMITTED')
    await sleep(5000)
    this.setState({ fadeOut: true })
    await sleep(2000)
    this.closeModal()
  }

  closeModal = () => {
    this.props.closeNpsModal()
  }

  renderCloseButton = () => (
    <button onClick={this.closeModal}>
      <FontIcon className="NetPromoterScore__CloseButton" icon={FontIcon.Icons.faTimes} />
    </button>
  )

  renderRequest = () => {
    const { profile, t } = this.props

    let title = t('netpromoterscore.request.title')
    if (profile && profile.firstName) {
      title = t('netpromoterscore.request.titlewithname', { name: profile.firstName })
    }
    return (
      <div className="NetPromoterScore">
        <div className="NetPromoterScore__Box">
          <div className="NetPromoterScore__Header">
            <div className="NetPromoterScore__Title">{title}</div>
            {this.renderCloseButton()}
          </div>
          <div className="NetPromoterScore__Body">
            <div className="NetPromoterScore__Content">{t('netpromoterscore.request.content')}</div>
            <div className="NetPromoterScore__RatingContainer">
              <Rating
                className="NetPromoterScore__RatingDots"
                initialRating={this.state.selectedRating}
                stop={11}
                onChange={this.onChangeRating}
                emptySymbol={<FontIcon icon={FontIcon.Icons.faCircle} size="2x" />}
                fullSymbol={<FontIcon icon={FontIcon.Icons.faCircleSolid} size="2x" />}
              />
              <div className="NetPromoterScore__RatingNumbers">
                {range(11).map(n => <span key={n} className="NetPromoterScore__Number">{n}</span>)}
              </div>
              <div className="NetPromoterScore__Explanation">
                <span>{t('netpromoterscore.request.unlikely')}</span>
                <span>{t('netpromoterscore.request.likely')}</span>
              </div>
            </div>
            <PrimaryButton className="NetPromoterScore__Button" type="button" onClick={this.onSubmit} disabled={!this.state.dirty}>
              {t('netpromoterscore.request.button')}
            </PrimaryButton>
          </div>
        </div>
      </div>
    )
  }

  renderFeedback = () => (
    <div className="NetPromoterScore">
      <div className="NetPromoterScore__Box">
        <div className="NetPromoterScore__Header">
          <div className="NetPromoterScore__Title">{this.props.t('netpromoterscore.feedback.title')}</div>
          {this.renderCloseButton()}
        </div>
        <div className="NetPromoterScore__Body">
          <div className="NetPromoterScore__Content">
            <div>{this.props.t('netpromoterscore.feedback.content')}</div>
            <textarea className="NetPromoterScore__TextArea" id="feedback-text-area" name="textarea" value={this.state.value} onChange={this.onChangeFeedback} />
          </div>
          <PrimaryButton className="NetPromoterScore__Button" type="button" onClick={this.onSubmitFeedback}>
            {this.props.t('netpromoterscore.feedback.button')}
          </PrimaryButton>
        </div>
      </div>
    </div>
  )

  renderRecommendation = () => {
    const { t } = this.props
    const buttonEnabled = this.state.emails.length > 0
    return (
      <div className="NetPromoterScore">
        <div className="NetPromoterScore__Box">
          <div className="NetPromoterScore__Header">
            <div className="NetPromoterScore__Title">{t('netpromoterscore.recommendation.title')}</div>
            {this.renderCloseButton()}
          </div>
          <div className="NetPromoterScore__Body">
            <div className="NetPromoterScore__Content">
              <div>{t('netpromoterscore.recommendation.description')}</div>
              <textarea className="NetPromoterScore__TextArea" id="feedback-text-area" placeholder={t('netpromoterscore.recommendation.placeholder')} name="recommendationEmails" onChange={this.onChangeEmails} />
              <div className="NetPromoterScore__Hint">{t('netpromoterscore.recommendation.content')}</div>
            </div>
            <PrimaryButton disabled={!buttonEnabled} className="NetPromoterScore__Button" type="button" onClick={this.onSendEmails}>
              {t('netpromoterscore.recommendation.button')}
            </PrimaryButton>
          </div>
        </div>
      </div>
    )
  }

  renderSubmitted = () => (
    <div className={`NetPromoterScore ${this.state.fadeOut && 'Hidden'}`}>
      <div className="NetPromoterScore__Box">
        <div className="NetPromoterScore__Header">
          <div className="NetPromoterScore__Title">
            <FontIcon className="NetPromoterScore__CheckIcon" icon={FontIcon.Icons.faCheck} />
            {this.state.submittedText}
          </div>
        </div>
        <div className="NetPromoterScore__Body">
          <div className="NetPromoterScore__SubmittedContainer">
            {this.props.t('netpromoterscore.submitted.title')}
          </div>
        </div>
      </div>
    </div>
  )

  render() {
    const { activeModal } = this.props

    if (activeModal === 'NPS_REQUEST') {
      return this.renderRequest()
    } if (activeModal === 'FEEDBACK') {
      return this.renderFeedback()
    } if (activeModal === 'RECOMMENDATION') {
      return this.renderRecommendation()
    } if (activeModal === 'SUBMITTED') {
      return this.renderSubmitted()
    }

    return <div className="NetPromoterScore Hidden" />
  }
}

NetPromoterScoreContainer.propTypes = {
  activeModal: PropTypes.string,
  closeNpsModal: PropTypes.func,
  showNpsModal: PropTypes.func,
  updateNps: PropTypes.func,
  sendInvitationEmails: PropTypes.func,
  currentNps: PropTypes.shape({}),
  profile: PropTypes.shape({}),
  sendFeedbackEmail: PropTypes.func,
  requestNps: PropTypes.func,
  ...i18nPropTypes,
}

const mapStateToProps = (state) => {
  const currentNps = NPSSelector.getCurrentNetPromoterScore(state)
  const activeModal = NPSSelector.getActiveModal(state)
  const profile = ProfileSelector.getProfile(state)
  const hasSubscription = SubscriptionSelector.hasSubscription(state)

  return {
    currentNps,
    activeModal,
    profile,
    hasSubscription,
  }
}

export default connect(
  mapStateToProps,
  {
    closeNpsModal, updateNps, showNpsModal, sendInvitationEmails, sendFeedbackEmail, requestNps,
  },
)(translate()(NetPromoterScoreContainer))
