import { handleActions } from 'redux-actions'

import { changeConnectionState } from 'actions/connection'


const reducer = handleActions({
  [changeConnectionState]: (state, action) => ({
    state: action.payload,
  }),
}, { state: 'UNKNOWN' })

export default reducer
