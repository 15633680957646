import { get } from 'lodash'

export const selector = state => state.modal

export const isVisible = (state, id) => get(selector(state), `['${id}'].show`, false)
export const getContent = (state, id) => get(selector(state), `['${id}'].content`)
export const getData = (state, id) => get(selector(state), `['${id}'].data`)

export default {
  isVisible,
  getContent,
  getData,
}
