import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { propTypes as i18nPropTypes, translate } from 'i18n'

import FontIcon from 'components/common/FontIcon'
import { verifyEmail } from 'api'

import { PrimaryButton } from 'components/common/Button'
import AuthCheckLoading from 'components/loading/AuthCheckLoading'

import './style.less'

class VerifyEmailCallback extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
    }
  }

  componentDidMount() {
    const { search = '' } = this.props.location
    const params = new URLSearchParams(search)
    const construct = {}

    // eslint-disable-next-line
    for (const key of params.keys()) {
      const value = params.get(key)
      construct[key] = value
    }
    verifyEmail(construct.token)
      .then(() => this.setState({ isLoading: false }),
        error => this.setState({ isLoading: false, error }))
  }

  handleNext = () => {
    this.props.changeUrl('/profile/premium')
  }

  renderFail = () => (
    <React.Fragment>
      <FontIcon className="VerifyEmailCallbackContainer__Icon VerifyEmailCallbackContainer__Icon--failed" icon={FontIcon.Icons.faTimesCircle} size="5x" />
      <div>{this.props.t('education.callback.failed.title')}</div>
      <div>{this.props.t('education.callback.failed.description')}</div>
      <PrimaryButton className="VerifyEmailCallbackContainer__Button" type="button" onClick={this.handleNext}>
        {this.props.t('education.callback.nextButton')}
      </PrimaryButton>
    </React.Fragment>
  )

renderSuccess = () => (
  <React.Fragment>
    <FontIcon className="VerifyEmailCallbackContainer__Icon" icon={FontIcon.Icons.faCheckCircle} size="5x" />
    <div>{this.props.t('education.callback.success.title')}</div>
    <div>{this.props.t('education.callback.success.description')}</div>
    <PrimaryButton className="VerifyEmailCallbackContainer__Button" type="button" onClick={this.handleNext}>
      {this.props.t('education.callback.nextButton')}
    </PrimaryButton>
  </React.Fragment>
)

render() {
  if (this.state.isLoading) return <AuthCheckLoading />

  return (
    <div className="VerifyEmailCallbackContainer">
      <div className="VerifyEmailCallbackContainer__Wrapper">
        { this.state.error ? this.renderFail() : this.renderSuccess() }
      </div>
    </div>
  )
}
}

VerifyEmailCallback.propTypes = {
  location: PropTypes.shape({}),
  changeUrl: PropTypes.func,
  ...i18nPropTypes,
}

const mapDispatchToProps = {
  changeUrl: push,
}

export default connect(null, mapDispatchToProps)(translate()(VerifyEmailCallback))
