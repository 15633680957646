import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { get } from 'lodash'
import { propTypes as i18nPropTypes, translate } from 'i18n'

import HTML5Backend from 'react-dnd-html5-backend'
import TouchBackend from 'react-dnd-touch-backend'
import { DragDropContext } from 'react-dnd'
import { shouldUseTouch, isUnsupportedBrowser } from 'helper/deviceFeatures'
import { showModal, hideModal } from 'actions/modal'
import { Route, Switch, Redirect } from 'react-router-dom'
import { changeLanguage } from 'actions/i18n'

import NetPromoterScoreContainer from 'containers/NetPromoterScoreContainer'
import ConfirmationModal from 'containers/modal/ConfirmationModal'

import InitialDataLoading from 'components/loading/InitialDataLoading'

import NavBar from 'containers/navigation/NavBar'
import ReferencesPage from 'containers/pages/ReferencesPage'
import ReferenceDetailPage from 'containers/pages/ReferenceDetailPage'
import ProjectOverviewPage from 'containers/pages/ProjectsOverviewPage'
import NotesOverviewPage from 'containers/pages/NotesOverviewPage'
import NoteDetailPage from 'containers/pages/NoteDetailPage'
import ProjectDetailPage from 'containers/pages/ProjectDetailPage'
import ProfilePage from 'containers/pages/ProfilePage'
import ReleaseNoteContainer from 'containers/ReleaseNoteContainer'
import AddReferenceModal, { ADD_REFERENCE_MODAL_ID } from 'containers/modal/AddReferenceModal'
import PaywallModal from 'containers/modal/PaywallModal'

import OfflineToast from 'containers/toasts/OfflineToast'

import { cognitoGetUser } from 'integrations/AWSCognito'

import { requestProjects } from 'actions/projects'
import { requestProjectItems } from 'actions/project-items'
import { requestReferences } from 'actions/references'
import { requestNotes } from 'actions/notes'
import { requestTags } from 'actions/tags'
import { requestProfile } from 'actions/profiles'
import { requestReferenceCollections } from 'actions/reference-collections'
import ProjectSelector from 'selectors/projects'

import './style.less'

const MODAL_ID = 'START_HINT_MODAL'

class App extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      refs: 'loading',
      projs: 'loading',
      notes: 'loading',
      tags: 'loading',
      profile: 'loading',
      collections: 'loading',
    }
  }

  componentDidMount() {
    const { t } = this.props

    if (shouldUseTouch()) {
      this.props.showModal(MODAL_ID, { content: t('hint.mobile', { defaultValue: 'Auratikum ist zur Zeit nicht für mobile Geräte optimiert. Bitte öffne Auratikum auf dem Computer.' }) })
    }
    if (isUnsupportedBrowser()) {
      this.props.showModal(MODAL_ID, { content: t('hint.unsupported', { defaultValue: 'Auratikum wurde mit den neusten Technologien entwickelt. Dein Browser ist zu alt dafür - bitte öffne Auratikum in einem neueren Browser oder kontaktiere unseren Support.' }) })
    }

    this.props.requestReferences()
      .then(() => this.setState({ refs: 'loaded' }))
      .catch(() => {
        this.setState({ refs: 'error' })
      })

    this.props.requestProjects()
      .then(async (payload) => {
        const ids = Object.keys(get(payload, 'payload.entities.projects', []))
        await Promise.all(ids.map(id => this.props.requestProjectItems(id)))
        this.setState({ projs: 'loaded' })
      })
      .catch(() => {
        this.setState({ projs: 'error' })
      })

    this.props.requestNotes().then(() => {
      this.setState({ notes: 'loaded' })
    })
      .catch(() => {
        this.setState({ notes: 'error' })
      })

    this.props.requestTags()
      .then(() => this.setState({ tags: 'loaded' }))
      .catch(() => {
        this.setState({ tags: 'error' })
      })

    this.props.requestReferenceCollections()
      .then(() => this.setState({ collections: 'loaded' }))
      .catch(() => {
        this.setState({ collections: 'error' })
      })

    this.props.requestProfile()
      .then((res) => {
        const id = Object.keys(res.payload.entities.profiles)[0]
        const profile = res.payload.entities.profiles[id]
        const { firstName, language } = profile
        const user = cognitoGetUser()
        if (window && window.Intercom) {
        // shutdown existing guest intercom session
          window.Intercom('shutdown')
          // boot intercom messenger up again with needed user meta
          window.Intercom('boot', {
            user_id: id, name: firstName, email: user.username, app_id: 'dcyqa8l9',
          })
        }
        this.setState({ profile: 'loaded' })
        // Change language in browser
        this.props.changeLanguage(language)
      })
      .catch(() => {
        this.setState({ profile: 'error' })
      })
  }

  hideTouchDeviceHint = () => {
    this.props.hideModal(MODAL_ID)
  }

  render() {
    const { t, location } = this.props

    const renderResearchGatePixel = location.search.includes('registered=true')

    const {
      refs, projs, notes, tags, profile,
    } = this.state
    if (refs !== 'loaded' || projs !== 'loaded' || notes !== 'loaded' || tags !== 'loaded' || profile !== 'loaded') {
      // the state contains loading information for the intial data loading component
      return <InitialDataLoading {...this.state} />
    }
    return (
      <div className="AppContainer">
        <div className="AppContainer__ViewPort">
          <NavBar />
          <OfflineToast />
          <div className="AppContainer__PageWrapper">
            <Switch>
              <Route path="/projects/:projectId" component={ProjectDetailPage} />
              <Route path="/projects" component={ProjectOverviewPage} />
              <Route path="/notes/:noteId" component={NoteDetailPage} />
              <Route path="/notes" component={NotesOverviewPage} />
              <Route path="/references/:library/:referenceId" component={ReferenceDetailPage} />
              <Route path="/references" component={ReferencesPage} />
              <Route path="/profile" component={ProfilePage} />
              <Redirect to="/projects" />
            </Switch>
          </div>
          { renderResearchGatePixel && (
            <img
              className="AppContainer__ResearchGatePixel"
              src="https://pubads.g.doubleclick.net/activity;xsp=4453130;ord=1;num=1"
              role="presentation"
            />
          ) }
        </div>

        <ConfirmationModal
          id={MODAL_ID}
          title={t('hint.title', { defaultValue: 'Achtung' })}
          cancelLabel={t('common.ok', { defaultValue: 'OK' })}
        />
        <PaywallModal />
        <AddReferenceModal id={ADD_REFERENCE_MODAL_ID} />
        <NetPromoterScoreContainer />
        <ReleaseNoteContainer />
      </div>
    )
  }
}

App.propTypes = {
  showModal: PropTypes.func,
  hideModal: PropTypes.func,
  location: PropTypes.shape({}),
  requestProjects: PropTypes.func,
  requestProjectItems: PropTypes.func,
  requestReferences: PropTypes.func,
  requestNotes: PropTypes.func,
  requestTags: PropTypes.func,
  requestProfile: PropTypes.func,
  requestReferenceCollections: PropTypes.func,
  changeLanguage: PropTypes.func,
  ...i18nPropTypes,
}

const mapStateToProps = (state, ownProps) => ({
  authState: state.authentication.state,
  location: ownProps.location,
  projects: ProjectSelector.getMyProjects(state),
})

const mapDispatchToProps = {
  showModal,
  hideModal,
  requestProjects,
  requestProjectItems,
  requestReferences,
  requestNotes,
  requestTags,
  requestProfile,
  requestReferenceCollections,
  changeLanguage,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(
  DragDropContext(
    shouldUseTouch() ? TouchBackend : HTML5Backend,
  )(translate()(App)),
))
