import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { propTypes as i18nPropTypes, translate } from 'i18n'

import Icon from 'components/common/Icon'

import { parseColor } from 'helper/colorHandler'
import modifyClassName from 'helper/modifyClassName'
import { AuratikumFontIcons } from 'helper/utils'

import './style.less'

const NoteLink = ({
  note, onDeleteNoteLink, light, t,
}) => (
  <NavLink
    to={`/notes/${note.id}`}
    className={modifyClassName('NoteLink', { light })}
    key={note.id}
    style={{ borderLeftColor: parseColor(note.color || 'transparent') }}
  >
    <Icon className="NoteLink__Icon" icon={AuratikumFontIcons.NOTES} />
    { note.title
      ? <div className="NoteLink__Label">{note.title}</div>
      : (
        <div className="NoteLink__Label NoteLink__Label--placeholder">
          {t('noteDetailView.noteSelectorField.emptyTitle')}
        </div>
      )
    }
    { onDeleteNoteLink
      && (
      <button type="button" className="NoteLink__Remove" onClick={e => onDeleteNoteLink(e, note.id)}>
        <Icon icon={AuratikumFontIcons.ADD} />
      </button>
      )
    }
  </NavLink>
)

NoteLink.propTypes = {
  ...i18nPropTypes,
  note: PropTypes.shape({}),
  onDeleteNoteLink: PropTypes.func,
  light: PropTypes.bool,
}

NoteLink.defaultProps = {
  light: false,
}

export default translate()(NoteLink)
