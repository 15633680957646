import React from 'react'
import PropTypes from 'prop-types'
import { CancelButton, PrimaryButton } from 'components/common/Button'

import './style.less'

// if a label for a button is not set it will not be rendered
const ModalFooter = ({
  className = '', confirmButtonLabel, confirmButtonType, onConfirmButtonClick,
  cancelButtonLabel, onCancelButtonClick, isLoading, confirmButtonCls, cancelButtonCls,
}) => {
  const ConfirmButton = confirmButtonCls
  const CButton = cancelButtonCls
  return (
    <div className={`ModalFooter ${className}`}>
      {cancelButtonLabel
      && (
      <div className="ModalFooter__Button">
        <CButton className="ConfirmationModal__Button" onClick={onCancelButtonClick}>{cancelButtonLabel}</CButton>
      </div>
      )}
      {confirmButtonLabel
      && (
      <div className="ModalFooter__Button">
        <ConfirmButton className="ConfirmationModal__Button" type={confirmButtonType} onClick={onConfirmButtonClick} isLoading={isLoading}>{confirmButtonLabel}</ConfirmButton>
      </div>
      )}
    </div>
  )
}


ModalFooter.propTypes = {
  className: PropTypes.string,
  confirmButtonLabel: PropTypes.string,
  confirmButtonType: PropTypes.string,
  onConfirmButtonClick: PropTypes.func,
  cancelButtonLabel: PropTypes.string,
  onCancelButtonClick: PropTypes.func,
  isLoading: PropTypes.bool,
  confirmButtonCls: PropTypes.any,
  cancelButtonCls: PropTypes.any,
}

ModalFooter.defaultProps = {
  confirmButtonCls: PrimaryButton,
  cancelButtonCls: CancelButton,
}

export default ModalFooter
