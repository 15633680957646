import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { requestReleaseNote, releaseNoteShown } from 'actions/release-note'
import ConfirmationModal from 'containers/modal/ConfirmationModal'
import i18n, { propTypes as i18nPropTypes, translate } from 'i18n'
import { showModal, hideModal } from 'actions/modal'

import './style.less'

const MODAL_ID = 'RELEASE_NOTE_MODAL'
class ReleaseNoteContainer extends Component {
  static propTypes = {
    releaseNote: PropTypes.shape({}),
    requestReleaseNote: PropTypes.func,
    showModal: PropTypes.func,
    releaseNoteShown: PropTypes.func,
    ...i18nPropTypes,
  }

  componentDidMount() {
    this.request()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.releaseNote) this.props.showModal(MODAL_ID, { data: { ...nextProps.releaseNote } })
  }

  request = async () => {
    this.props.requestReleaseNote(i18n.language || 'en')
      .then((res) => {
        if (res.payload && res.payload.version) this.props.releaseNoteShown(res.payload.version)
      })
      .catch(() => {})
  }

  handleButtonClick = async () => {
    await this.request()
    await this.props.hideModal(MODAL_ID)
  }

  renderReleaseNoteModalContent = data => (
    <React.Fragment>
      <div className="ReleaseNoteContainer" dangerouslySetInnerHTML={{ __html: data.content }} />
    </React.Fragment>
  )

  render() {
    if (this.props.releaseNote.content) {
      const { releaseNote, t } = this.props
      const date = new Date(releaseNote.createdAt).toLocaleDateString('de-DE')
      return (
        <ConfirmationModal
          id={MODAL_ID}
          title={t('releasenote.title', { version: releaseNote.version, date })}
          contentFunc={this.renderReleaseNoteModalContent}
          okLabel={t('common.ok')}
          onConfirm={this.handleButtonClick}
          onCancel={this.handleButtonClick}
        />
      )
    }

    return null
  }
}

const mapStateToProps = (state) => {
  const { releaseNote } = state

  return { releaseNote }
}

export default connect(
  mapStateToProps,
  {
    requestReleaseNote, showModal, hideModal, releaseNoteShown,
  },
)(translate()(ReleaseNoteContainer))
