import { get, fromPairs } from 'lodash'
import { createSelector } from 'reselect'

import { getSelectedNote } from 'selectors/notes'
import { getInlineCitationsForSelectedProject } from 'selectors/projects'


// eslint-disable-next-line import/prefer-default-export
export const getCitationsOfSelectedNoteOrProject = createSelector(
  getSelectedNote,
  getInlineCitationsForSelectedProject,
  (note, projectCitations) => {
    if (note && note.id) return fromPairs(get(note, 'inlineCitations', []).map(citation => ([citation.id, citation])))

    if (projectCitations) return fromPairs(projectCitations.citations.map(citation => ([citation.id, citation])))
    return {}
  },
)
