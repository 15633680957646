import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { isArray, isEmpty } from 'lodash'

import { translate } from 'i18n'
import modifyClassName from 'helper/modifyClassName'

import ScoreItem from 'components/challenge/ScoreItem'
import FontIcon from 'components/common/FontIcon'
import Table from 'components/common/Table'
import TableBody from 'components/common/TableBody'

import './style.less'


class ScoreBoard extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    className: PropTypes.string,
    scores: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.shape({})),
      PropTypes.shape({
        my: PropTypes.arrayOf(PropTypes.shape({})),
        myToRest: PropTypes.arrayOf(PropTypes.shape({})),
        top: PropTypes.arrayOf(PropTypes.shape({})),
        topToMy: PropTypes.arrayOf(PropTypes.shape({})),
      }),
    ]),
    boxed: PropTypes.bool,
  }

  static defaultProps = {
    className: '',
  }

  state = {
    isShowingTopToMy: false,
    isShowingMyToRest: false,
  }

  showMore = stateKey => () => this.setState({ [stateKey]: true })

  renderMore = (list, stateKey, between = false) => {
    const { t } = this.props
    const { [stateKey]: isShowing } = this.state
    return isShowing ? this.renderItems(list) : (
      <tr>
        <td colSpan="3">
          <div className={modifyClassName('ScoreBoard__MoreContainer', { between })}>
            {between && <FontIcon icon={FontIcon.Icons.faEllipsisV} />}
            <button type="button" className="ScoreBoard__MoreButton" onClick={this.showMore(stateKey)}>{t('challenge.showMore')}</button>
            {between && <FontIcon icon={FontIcon.Icons.faEllipsisV} />}
          </div>
        </td>
      </tr>
    )
  }

  renderPartitionedItems = () => {
    const { scores } = this.props
    return scores && (
      <React.Fragment>
        {!isEmpty(scores.top) && this.renderItems(scores.top)}
        {!isEmpty(scores.topToMy) && this.renderMore(scores.topToMy, 'isShowingTopToMy', true)}
        {!isEmpty(scores.my) && this.renderItems(scores.my)}
        {!isEmpty(scores.myToRest) && this.renderMore(scores.myToRest, 'isShowingMyToRest')}
      </React.Fragment>
    )
  }

  renderItems = items => !isEmpty(items) && items.map(score => <ScoreItem key={score.id} score={score} />)

  render() {
    const {
      t, scores, className, boxed,
    } = this.props
    return (
      <div className={`ScoreBoard ${className}`}>
        <Table boxed={boxed}>
          <thead>
            <tr>
              <th />
              <th>{t('challenge.scoreName')}</th>
              <th><div className="ScoreBoard__ValueHeader">{t('challenge.scoreValue')}</div></th>
            </tr>
          </thead>
          <TableBody>
            {isArray(scores) ? this.renderItems(scores) : this.renderPartitionedItems()}
          </TableBody>
        </Table>
      </div>
    )
  }
}

export default translate()(ScoreBoard)
