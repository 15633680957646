import { createAction } from 'redux-actions'

import * as api from 'api'
import { receiveEntities, removeEntity } from 'actions/entities'

class ExportException extends Error {
  constructor(message, data) {
    super(message)
    this.message = message
    this.name = 'ExportException'
    this.data = data
  }
}

export const selectProjectAction = createAction('SELECT_PROJECT')
export const setExporting = createAction('SET_EXPORTING')

export const updateProject = data => async (dispatch) => {
  const project = { ...data }
  const payload = await api.updateProject(project)
  dispatch(receiveEntities(payload))
  return payload
}

export const createProject = data => async (dispatch) => {
  const payload = await api.createProject({ ...data })
  dispatch(receiveEntities(payload))
  const rootItemPayload = await api.requestProjectItems(payload.result)
  dispatch(receiveEntities(rootItemPayload))
  return payload
}

export const deleteProject = data => async (dispatch) => {
  await api.deleteProject(data)
  dispatch(removeEntity({ entityType: 'projects', ...data }))
}

export const requestProjects = () => async (dispatch) => {
  const payload = await api.requestProjects()
  return dispatch(receiveEntities(payload))
}

export const exportProject = (project, format, outline) => async (dispatch) => {
  dispatch(setExporting(true))
  try {
    await api.exportProject(project, format, outline)
    dispatch(setExporting(false))
  } catch (error) {
    dispatch(setExporting(false))
    if (error.status === 203) {
      throw new ExportException(error.message, error.data)
    } else {
      throw new Error(error)
    }
  }
}
