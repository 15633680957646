import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch, Redirect } from 'react-router-dom'

import modifyClassName from 'helper/modifyClassName'

import Login from 'components/auth/Login'
import Register from 'components/auth/Register'
import Reset from 'components/auth/Reset'
import NewPassword from 'components/auth/NewPassword'

import './style.less'


const AuthForm = ({ initialRedirect, light }) => (
  <div className={modifyClassName('AuthForm', { light })}>
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/register" component={Register} />
      <Route path="/reset" component={Reset} />
      <Route path="/new-password" component={NewPassword} />
      <Redirect to={`/${initialRedirect || 'login'}`} />
    </Switch>
  </div>
)

AuthForm.propTypes = {
  initialRedirect: PropTypes.oneOf(['login', 'register']),
  light: PropTypes.bool,
}

export default AuthForm
