import React from 'react'
import PropTypes from 'prop-types'

import Icon from '../Icon'
import { AuratikumFontIcons } from '../../../helper/utils'

import './style.less'

const ThreeDotsButton = ({ onClick, className }) => (
  <button type="button" className={`ThreeDotsButton ${className}`} onClick={onClick}>
    <Icon className="ThreeDotsButton__Icon" icon={AuratikumFontIcons.THREEDOTS} />
  </button>
)

ThreeDotsButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
}

export default ThreeDotsButton
