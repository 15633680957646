import { handleActions } from 'redux-actions'

import { showNpsModal } from 'actions/net-promoter-score'

const reducer = handleActions({
  [showNpsModal]: (state, action) => ({
    ...state,
    activeModal: action.payload,
  }),
}, { activeModal: null })

export default reducer
