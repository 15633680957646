import React from 'react'
import PropTypes from 'prop-types'

import { propTypes as i18nPropTypes, translate } from 'i18n'
import modifyClassName from 'helper/modifyClassName'
import Flyout from 'containers/common/Flyout'

import './style.less'

const ProjectListFlyout = ({
  projects, selectedProjectId, onSelect, t,
}) => (
  <div className="ProjectListFlyout">
    <div className="ProjectListFlyout__Content">
      <button type="button" className={modifyClassName('ProjectListFlyout__Item', { selected: !selectedProjectId })} onClick={() => onSelect()}>{t('notesOverView.toolbar.selectNoProject')}</button>
      { projects.map(project => <button type="button" key={project.id} className={modifyClassName('ProjectListFlyout__Item', { selected: project.id === selectedProjectId })} onClick={() => onSelect(project)}>{project.title}</button>)}
    </div>
  </div>
)

ProjectListFlyout.propTypes = {
  data: PropTypes.shape({}),
  selectedProject: PropTypes.shape({
    id: PropTypes.string,
  }),
  onSelect: PropTypes.func,
  ...i18nPropTypes,
}

export default Flyout(translate()(ProjectListFlyout))
