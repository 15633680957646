import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import { fieldPropTypes } from 'redux-form'
import { without, get } from 'lodash'

import FontIcon from 'components/common/FontIcon'
import FileDrop from 'components/common/FileDrop'
import { FileIconColumn, isImage } from 'components/common/FileIcon'
import S3ImageField from 'containers/common/S3ImageField'
import { downloadFileFromS3 } from 'api'
import Spinner from 'components/common/Spinner'
import { push } from 'connected-react-router'

import { uploadNoteAttachments, updateNote } from 'actions/notes'
import { createReferenceFromFile } from 'actions/references'

import modifyClassName from 'helper/modifyClassName'

import './style.less'

class NoteFilesField extends Component {
  static propTypes = {
    ...fieldPropTypes,
    ...i18nPropTypes,
    uploadNoteAttachments: PropTypes.func,
  }

  state = {
    isUploading: false,
    isDownloading: [],
    errorMessage: null,
  }

  downloadFile = async (file) => {
    this.setState({ isDownloading: [...this.state.isDownloading, file.fileId] })
    await downloadFileFromS3(file)
    this.setState({ isDownloading: without(this.state.isDownloading, file.fileId) })
  }

  handleDelete = async (file) => {
    const newValue = this.props.input.value.filter(f => f.fileId !== file.fileId)
    this.props.input.onChange(newValue)
  }

  createReference = async (file) => {
    this.setState({ isUploading: true })
    try {
      const reference = await this.props.createReferenceFromFile(file)
      await this.props.updateNote({
        ...this.props.formValues,
        referenceIds: [...this.props.formValues.referenceIds, reference.id],
      })
      this.props.push(`/references/lib/${reference.id}`)
    } catch (error) {
      this.setState({ errorMessage: error.message, isUploading: false })
    }
  }

  uploadFile = async (file) => {
    this.setState({ isUploading: true })
    try {
      const uploadedFiles = await this.props.uploadNoteAttachments([file])
      this.setState({ isUploading: false, errorMessage: null })
      const newValues = uploadedFiles.map(fileObj => ({
        type: fileObj.file.type,
        fileId: fileObj.fileId,
        fileName: fileObj.file.name,
      }))
      this.props.input.onChange([...newValues, ...this.props.input.value])
    } catch (error) {
      this.setState({ errorMessage: error.message, isUploading: false })
    }
  }

  handleDrop = async (files) => {
    this.setState({ isUploading: true })
    const file = files[0]
    if ((file.type.includes('pdf') || file.name.endsWith('pdf').length > 0)
        && window.confirm(this.props.t('references.attachmentIsPdfConfirmation'))) {
      return this.createReference(file)
    }
    return this.uploadFile(file)
  }

  handleSelectFile = (file) => {
    if (isImage(file)) {
      this.setState({ selectedFile: this.state.selectedFile && this.state.selectedFile.fileId === file.fileId ? null : file })
    } else {
      this.setState({ selectedFile: null })
      this.downloadFile(file)
    }
  }

  handleDownloadFile = () => {
    this.downloadFile(this.state.selectedFile)
  }

  handleCloseImage = () => {
    this.setState({ selectedFile: null })
  }

  render() {
    const files = this.props.input.value || []
    const { meta: { touched, error }, t } = this.props
    const { isDownloading, selectedFile } = this.state

    return (
      <div className={`NoteFilesField ${this.props.className || ''}`}>
        {touched && error && <span className="NoteFilesField__Error">{error}</span>}
        {this.state.errorMessage && <div className="NoteFilesField__Error">{this.state.errorMessage}</div>}

        <div className="NoteFilesField__FilesContainer">
          <div className="NoteFilesField__Files">
            <FileDrop
              className="NoteFilesField__FileDrop"
              onDrop={this.handleDrop}
              isLoading={this.state.isUploading}
              text={t('noteDetailView.files.fileDrop')}
              textDrop={t('references.files.fileFieldTextDrop')}
            />
            { files.map(file => (
              <FileIconColumn
                key={file.fileId}
                className={modifyClassName('NoteFilesField__File', { selected: file.fileId === get(this.state, 'selectedFile.fileId') })}
                file={file}
                isLoading={isDownloading.includes(file.fileId)}
                onSelect={this.handleSelectFile}
                onDelete={this.handleDelete}
              />
            ))}
          </div>
        </div>
        { this.state.selectedFile && (
        <div className="NoteFilesField__Overlay">
          <div className="NoteFilesField__OverlayToolbar">
            <div className="NoteFilesField__OverlayName">{selectedFile.fileName}</div>
            <div className="NoteFilesField__OverlayActions">
              <Spinner show={isDownloading.includes(selectedFile.fileId)}>
                <button onClick={this.handleDownloadFile}>
                  <FontIcon icon={FontIcon.Icons.faDownload} />
                </button>
              </Spinner>
              <button onClick={this.handleCloseImage}>
                <FontIcon icon={FontIcon.Icons.faTimes} />
              </button>
            </div>
          </div>
          <S3ImageField file={this.state.selectedFile} />
        </div>
        ) }
      </div>
    )
  }
}

const mapStateToProps = () => ({})

export default connect(mapStateToProps, {
  uploadNoteAttachments,
  createReferenceFromFile,
  updateNote,
  push,
})(translate()(NoteFilesField))
