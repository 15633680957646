import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import { Trans } from 'react-i18next'

import FontIcon from 'components/common/FontIcon'
import FeatureFlag from 'containers/FeatureFlag'

import {
  checkExtensionAvailable, checkSupportedBrowserForExtension, getBrowser, browsers,
} from 'helper/utils'

import './style.less'

const { CHROME, FIREFOX } = browsers

const urlMap = {
  [CHROME]: 'https://chrome.google.com/webstore/detail/auratikum-web-clipper/mfopapajhfhifagbjldlpilclhbakjck',
  [FIREFOX]: 'https://addons.mozilla.org/en-US/firefox/addon/auratikum-web-clipper/',
}

class ExtensionHint extends Component {
    state = {
      isVisible: false,
    }

    componentDidMount() {
      const timeout = this.props.shouldDelayRendering ? 200 : 0
      setTimeout(() => {
        this.setState({ isVisible: !checkExtensionAvailable() && checkSupportedBrowserForExtension(getBrowser()) })
      }, timeout)
    }

    render() {
      const { className, t, i18nKey } = this.props
      if (this.state.isVisible) {
        return (
          <FeatureFlag feature="extensionHint">
            <div className={`ExtensionHint ${className || ''}`}>
              <div className="ExtensionHint__Icon">
                <FontIcon icon={FontIcon.Icons.faLightbulb} />
              </div>
              <div>
                <div className="ExtensionHint__Hint">
                  {t('common.hint')}
                        :
                </div>
                <Trans i18nKey={i18nKey || 'browserExtension.defaultHintDescription'}>
                        Install the
                  <a href={urlMap[getBrowser()]} rel="noopener noreferrer" target="_blank">Auratikum Extension</a>
                        to add references and notes directly from other sites.
                </Trans>
              </div>
            </div>
          </FeatureFlag>
        )
      }
      return null
    }
}


ExtensionHint.propTypes = {
  ...i18nPropTypes,
  i18nKey: PropTypes.string,
  className: PropTypes.string,
  shouldDelayRendering: PropTypes.bool,
}

export default translate()(ExtensionHint)
