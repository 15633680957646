import {
  get,
  pick,
  groupBy,
  fromPairs,
  toPairs,
  flatMap,
} from 'lodash'
import i18n from 'i18n'


export const roleFields = [
  'author',
  'editor',
  'collection-editor',
  'composer',
  'container-author',
  'director',
  'editorial-director',
  'interviewer',
  'illustrator',
  'original-author',
  'recipient',
  'reviewed-author',
  'translator',
]

const personAttributes = [
  'family',
  'given',
  'literal',
  'dropping-particle',
  'non-dropping-particle',
  'suffix',
  'comma-suffix',
  'static-ordering',
  'parse-names',
  'type',
]

const sanatizePerson = person => pick(person, personAttributes)

export const referenceToFormValues = (reference, source) => {
  const type = get(reference, 'type', 'article')
  const attributes = Object.keys(i18n.t(`references:types.${type}`, { returnObjects: true }))
  const ref = pick(reference, [...attributes, 'id'])
  return {
    ...ref,
    accessed: get(reference, 'accessed.raw'),
    issued: get(reference, 'issued.raw'),
    submitted: get(reference, 'submitted.raw'),
    type,
    source,
    roles: flatMap(roleFields, role => get(reference, role, []).map(person => ({
      ...sanatizePerson(person),
      type: person ? person.type || (person.literal ? 'organization' : 'person') : null,
      role,
    }))),
    ...fromPairs(roleFields.map(role => [role, undefined])),
  }
}

export const formValuesToReference = (formValues) => {
  const roleGroups = groupBy(formValues.roles, 'role')
  const sanatizedRoleGroups = fromPairs(toPairs(roleGroups).map(([role, persons]) => [role, persons.map(sanatizePerson)]))
  const clearRoleGroups = fromPairs(roleFields.map(role => [role, []]))
  return {
    ...formValues,
    accessed: { raw: formValues.accessed },
    issued: { raw: formValues.issued },
    submitted: { raw: formValues.submitted },
    ...clearRoleGroups,
    ...sanatizedRoleGroups,
    roles: undefined,
  }
}
