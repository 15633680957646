import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { hideModal } from 'actions/modal'
import ModalSelector from 'selectors/modal'

import Icon from '../../../components/common/Icon'
import './style.less'
import { AuratikumFontIcons } from '../../../helper/utils'


export default (WrappedComponent) => {
  const Toast = class Toast extends Component {
    static propTypes = {
      id: PropTypes.string.isRequired,
      className: PropTypes.string,
      onClose: PropTypes.func,
      hide: PropTypes.func,
      isVisible: PropTypes.bool,
      disableCloseButton: PropTypes.bool,
      data: PropTypes.shape({}),
      timeout: PropTypes.number,
    }

    static defaultProps = {
      className: '',
      title: '',
      children: null,
      disableCloseButton: false,
      hotKeyHandlers: {},
      timeout: null,
    }

    constructor(props) {
      super(props)
      this.state = {}
      this.el = document.createElement('div')
    }

    componentDidMount() {
    // The portal element is inserted in the DOM tree after
    // the Modal's children are mounted, meaning that children
    // will be mounted on a detached DOM node. If a child
    // component requires to be attached to the DOM tree
    // immediately when mounted, for example to measure a
    // DOM node, or uses 'autoFocus' in a descendant, add
    // state to Modal and only render the children when Modal
    // is inserted in the DOM tree.
      const modalRoot = document.getElementById('root-toast')
      modalRoot.appendChild(this.el)
    }

    componentDidUpdate(prevProps) {
      if (!prevProps.isVisible && this.props.isVisible && this.props.timeout) {
        setTimeout(() => this.hide(), this.props.timeout)
      }
    }

    componentWillUnmount() {
      const modalRoot = document.getElementById('root-toast')
      modalRoot.removeChild(this.el)
    }

    handleClose = () => {
      const {
        onClose, hide, id, data,
      } = this.props
      if (onClose) onClose(data)
      else hide(id)
    }

    hide = () => {
      this.props.hide(this.props.id)
    }

    render() {
      const { isVisible, className, disableCloseButton } = this.props
      if (!isVisible) return null

      return ReactDOM.createPortal(
        <div className={`Toast ${className}`}>
          { !disableCloseButton && (
          <button className="Toast__CloseButton" onClick={this.handleClose}>
            <Icon icon={AuratikumFontIcons.CLOSE_BOLD} className="Toast__CloseButtonIcon" />
          </button>
          )}
          <div className="Toast__Content">
            <WrappedComponent {...this.props} onCancel={this.handleClose} hide={this.hide} />
          </div>
        </div>,
        this.el,
      )
    }
  }

  const mapStateToProps = (state, ownProps) => ({
    isVisible: ModalSelector.isVisible(state, ownProps.id) || (ownProps.isVisible),
    content: ModalSelector.getContent(state, ownProps.id) || ownProps.text,
    data: ModalSelector.getData(state, ownProps.id) || {},
  })

  return connect(mapStateToProps, {
    hide: hideModal,
  })(Toast)
}
