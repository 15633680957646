
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import uuidV4 from 'uuid/v4'

import {
  get, difference, isEmpty, debounce,
} from 'lodash'

import { translate } from 'i18n'

import { showFlyout } from 'actions/flyout'
import { showModal } from 'actions/modal'
import {
  updateDomCitations, CITATION_PLACEHOLDER, CITATION_ID_ATTR,
} from 'helper/citation'

import { Editor } from '@tinymce/tinymce-react'
import { WarningSimpleToast } from 'containers/toasts/SimpleToast'

import AddCitationFlyout from 'components/note-detail/AddCitationFlyout'
import AddFormulaFlyout from 'components/note-detail/AddFormulaFlyout'
import FontIcon from 'components/common/FontIcon'

import './style.less'


const CITATION_FLYOUT_ID = 'ADD_CITATION_FLYOUT'
const FORMULA_FLYOUT_ID = 'ADD_FORMULA_FLYOUT'
const PASTE_TOAST_ID = 'PASTE_TOAST'

class TextEditor extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    toolbarClass: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    onSave: PropTypes.func,
    onSetup: PropTypes.func,
    onAddCitation: PropTypes.func,
    onSaveCitation: PropTypes.func,
    onRemoveCitation: PropTypes.func,
    showFlyout: PropTypes.func,
    showModal: PropTypes.func,
    onShowFlyout: PropTypes.func,
    onHideFlyout: PropTypes.func,
    plugins: PropTypes.arrayOf(PropTypes.string),
    className: PropTypes.string,
    tabIndex: PropTypes.string,
    id: PropTypes.string,
    citationCluster: PropTypes.shape({
      citations: PropTypes.array,
      citationIds: PropTypes.array,
      type: PropTypes.string,
    }),
    customSettings: PropTypes.shape({}),
  }

  static defaultProps = {
    plugins: ['aur-citations', 'aur-formula'],
  }

  contentCitations = null

  constructor(props) {
    super(props)
    this.handleShowInsertCitationFlyout = this.handleOpenFlyout(CITATION_FLYOUT_ID)
    this.handleShowInsertFormulaFlyout = this.handleOpenFlyout(FORMULA_FLYOUT_ID)
    this.debouncedContentCitationsUpdate = debounce(this.updateCurrentCitations, 800)
  }

  componentDidUpdate(prevProps) {
    if (this.props.citationCluster !== prevProps.citationCluster) {
      this.updateCitationRendering()
    }
  }

  updateCitationRendering = () => {
    if (this.editor && this.editor.dom) {
      const { citations, type } = this.props.citationCluster
      const citationEls = this.editor.dom.select('.IntextCitation')
      updateDomCitations(citationEls, citations, type)
    }
  }

  handleSaveCitation = async ({ citation }) => {
    const { onSaveCitation } = this.props
    if (onSaveCitation) {
      const inlineCitationIds = this.queryContentCitations()
      let index = inlineCitationIds.indexOf(citation.id)
      if (index === -1) {
        // we are in a project item with the hacked id.. not good to have this logic here
        const cleanedId = citation.id.substring(0, citation.id.indexOf('-'))
        index = inlineCitationIds.indexOf(cleanedId)
      }
      await onSaveCitation({ citation, index })
    }
  }

  handleAddCitation = async ({ citation }) => {
    const { onAddCitation } = this.props
    this.editor.insertContent(`
      <span 
        class="IntextCitation mceNonEditable"
        contenteditable=false
        ${CITATION_ID_ATTR}=${citation.id} 
      >
        ${CITATION_PLACEHOLDER}
      </span>
    `)
    const content = this.editor.getContent()
    if (onAddCitation) {
      const inlineCitationIds = this.queryContentCitations()
      const index = inlineCitationIds.indexOf(citation.id)
      await onAddCitation({ citation, index, content })
    }
  }

  handleAddFormula = async (formula, formulaElement, elementId) => {
    if (!formula || !formulaElement) {
      return
    }
    if (!elementId) {
      const newElement = `
        <span id=${uuidV4()} class="TextEditor__Formula mceNonEditable" contenteditable=false data-mce-formula="${formula}">
          ${formulaElement}
        </span>
      `
      this.editor.insertContent(newElement)
      this.editor.insertContent('<p/>') // placeholder to allow for editing below formula
      return
    }
    const element = this.editor.contentDocument.getElementById(elementId)
    if (!element) {
      return
    }
    this.editor.dom.setAttrib(element, 'data-mce-formula', formula)
    this.editor.dom.setHTML(element, formulaElement)
    this.editor.save()
  }

  handleOpenFlyout = flyoutId => (editor, options = {}) => {
    const { onShowFlyout, showFlyout: showFlyoutAction } = this.props
    const referenceElement = editor.selection.getNode()
    const width = editor.targetElm.clientWidth
    if (onShowFlyout) { onShowFlyout(flyoutId) }
    showFlyoutAction(flyoutId, { referenceElement, width, ...options })
  }

  handleSetup = (editor) => {
    const { onSetup } = this.props
    this.editor = editor
    if (onSetup) {
      onSetup(editor)
    }
  }

  handlePasteAlert = (key) => {
    this.props.showModal(PASTE_TOAST_ID, { content: this.props.t(key) })
  }

  queryContentCitations = () => {
    const { id } = this.props
    if (!id || !this.editor || !this.editor.contentDocument) {
      return null
    }
    const referenceElements = this.editor.dom.$(`span[${CITATION_ID_ATTR}]`, this.editor.contentDocument.getElementById(id))
    return referenceElements && referenceElements.toArray().map(element => get(element, ['attributes', CITATION_ID_ATTR, 'value']))
  }

  updateCurrentCitations = () => {
    const { onRemoveCitation } = this.props
    const currentCitations = this.queryContentCitations()

    let diff
    if (currentCitations && this.contentCitations) {
      diff = difference(this.contentCitations, currentCitations)
    }
    this.contentCitations = currentCitations || []
    if (!isEmpty(diff) && onRemoveCitation) {
      onRemoveCitation(diff)
    }
  }

  onEditorChange = (content) => {
    const { onChange } = this.props
    if (onChange) {
      onChange(content)
    }
    this.debouncedContentCitationsUpdate()
  }

  handleEditorPrePaste = (plugin, args) => {
    if (args.content.includes('<img')) {
      this.handlePasteAlert('editor.imagePasteError')
      // eslint-disable-next-line no-param-reassign
      args.content = args.content.replace(/<img[^>]*>/gi, '')
    }
  }

  handleEditorPostPaste = (plugin, args) => {
    const intextCitationNodes = args.node.querySelectorAll('.IntextCitation')
    if (intextCitationNodes.length > 0) {
      this.handlePasteAlert('editor.citationPasteError')
      intextCitationNodes.forEach((node) => {
        node.remove()
      })
    }
  }

  onInit = () => {
    const { citationCluster, onRemoveCitation } = this.props

    this.contentCitations = this.queryContentCitations()
    this.updateCitationRendering()

    // check if citations in cluster still match citations in HTML
    const diff = difference(citationCluster.citationIds, this.contentCitations)
    if (!isEmpty(diff) && onRemoveCitation) {
      onRemoveCitation(diff, true)
    }
  }

  render = () => {
    const {
      toolbarClass, value, onSave, onHideFlyout, className, tabIndex, id, customSettings,
    } = this.props
    return (
      <div className={className} id={id}>
        <Editor
          tabIndex={tabIndex}
          value={value}
          onEditorChange={this.onEditorChange}
          init={{
            // add valid elements in order to prevent tinymce from auto removing svg tags:
            extended_valid_elements: 'svg[*],defs[*],pattern[*],desc[*],metadata[*],g[*],mask[*],path[*],line[*],marker[*],rect[*],circle[*],ellipse[*],polygon[*],polyline[*],linearGradient[*],radialGradient[*],stop[*],image[*],view[*],text[*],textPath[*],title[*],tspan[*],glyph[*],symbol[*],switch[*],use[*]',
            fixed_toolbar_container: toolbarClass,
            inline: true,
            branding: false,
            height: 300,
            min_height: 300,
            resize: false,
            statusbar: false,
            plugins: [
              'advlist autolink link lists autoresize save',
              'table paste textpattern',
              this.props.plugins.join(' '),
            ],
            autoresize_bottom_margin: 0,
            autoresize_min_height: 100,
            fontsize_formats: '8pt 10pt 12pt 14pt 18pt 24pt 36pt',
            toolbar1: `bold italic underline strikethrough | bullist numlist | outdent indent | link unlink blockquote | forecolor backcolor table | fontsizeselect | removeformat | ${this.props.plugins.includes('aur-citations') ? 'addCitation' : ''} ${this.props.plugins.includes('aur-formula') ? 'addFormula' : ''}`,
            content_css: [],
            menubar: false,
            toolbar_items_size: 'small',
            save_onsavecallback: onSave,
            setup: this.handleSetup,
            citation_showInsertCitationFlyout: this.handleShowInsertCitationFlyout,
            formula_showInsertFormulaFlyout: this.handleShowInsertFormulaFlyout,
            paste_preprocess: this.handleEditorPrePaste,
            paste_postprocess: this.handleEditorPostPaste,
            init_instance_callback: this.onInit,
            contextmenu: 'paste | copy | link',
            ...customSettings,
          }}
        />
        <AddCitationFlyout
          id={CITATION_FLYOUT_ID}
          onAddCitation={this.handleAddCitation}
          onSaveCitation={this.handleSaveCitation}
          onHideFlyout={onHideFlyout}
        />
        <AddFormulaFlyout id={FORMULA_FLYOUT_ID} onHideFlyout={onHideFlyout} onAddFormula={this.handleAddFormula} />
        <WarningSimpleToast
          id={PASTE_TOAST_ID}
          disableCloseButton
          timeout={3000}
          icon={<FontIcon icon={FontIcon.Icons.faInfoCircle} />}
        />
      </div>
    )
  }
}

const mapDispatchToProps = {
  showFlyout,
  showModal,
}

export default connect(null, mapDispatchToProps)(translate()(TextEditor))
