import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import IconFont from 'assets/IconFont'


const FontIcon = props => <FontAwesomeIcon {...props} />

FontIcon.Icons = IconFont

export default FontIcon
