import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import PaymentSuccess from 'components/subscription/PaymentSuccess'
import { push } from 'connected-react-router'
import { propTypes as i18nPropTypes, translate } from 'i18n'

import { PrimaryButton } from 'components/common/Button'

import AuthCheckLoading from 'components/loading/AuthCheckLoading'
import { finalizePayment } from 'actions/subscriptions'
import FontIcon from 'components/common/FontIcon'

import './style.less'

class PaymentCallback extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      authResultStatus: '',
    }
  }

  componentDidMount() {
    const { search = '' } = this.props.location
    const params = new URLSearchParams(search)
    const construct = {}
    for (const key of params.keys()) {
      const value = params.get(key)
      construct[key] = value
    }

    if (construct.authResult === 'AUTHORISED') {
      this.props.finalizePayment(construct)
        .then(() => this.setState({ isLoading: false, authResultStatus: construct.authResult }), error => this.setState({ isLoading: false, error }))
    }
  }

  handleNext = () => {
    this.props.changeUrl('/profile/premium')
  }

  renderFail = () => (
    <div className="PaymentCallbackContainer__FailContainer">
      <FontIcon className="PaymentCallbackContainer__Icon" icon={FontIcon.Icons.faTimesCircle} size="5x" />
      <div>{this.props.t('paymentCallback.message')}</div>
      <div>{this.props.t('paymentCallback.submessage')}</div>
      <PrimaryButton className="PaymentCallbackContainer__Button" type="button" onClick={this.handleNext}>
        {this.props.t('paymentCallback.button')}
      </PrimaryButton>
    </div>
  )

  render() {
    if (this.state.isLoading) return <AuthCheckLoading />

    return (
      <div className="PaymentCallbackContainer">
        <div className="PaymentCallbackContainer__Wrapper">
          { this.state.authResultStatus === 'AUTHORISED' && !this.state.error ? <PaymentSuccess onClick={this.handleNext} /> : this.renderFail() }
        </div>
      </div>
    )
  }
}

PaymentCallback.propTypes = {
  location: PropTypes.shape({}),
  changeUrl: PropTypes.func,
  ...i18nPropTypes,
}

const mapDispatchToProps = {
  changeUrl: push,
  finalizePayment,
}

export default connect(null, mapDispatchToProps)(translate()(PaymentCallback))
