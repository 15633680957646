import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'

import { propTypes as i18nPropTypes, translate } from 'i18n'
import { AuratikumFontIcons } from 'helper/utils'
import { ProjectItemType } from 'helper/constants'

import ConfirmationModal from 'containers/modal/ConfirmationModal'
import Icon from 'components/common/Icon'


class DeleteProjectItemModal extends Component {
  static propTypes = {
    id: PropTypes.string,
    onConfirm: PropTypes.func,
    ...i18nPropTypes,
  }

  renderDeleteModalContent = (data) => {
    const { t } = this.props
    const title = data.title || get(data, 'note.title')
    return (
      <React.Fragment>
        <Icon key="icon" className="ConfirmationModal__Icon" icon={AuratikumFontIcons.DELETE} />
        <div key="title" className="ConfirmationModal__Title">
          {t('deleteProjectItemModal.contentTitle', { type: t(`deleteProjectItemModal.itemType.${data.type}`), heading: title && `"${title}"` })}
        </div>
        <div key="subtitle" className="ConfirmationModal__SubTitle">{t('modal.cannotBeUndone')}</div>
        {data.type === ProjectItemType.NOTE_LINK && (
          <div className="ConfirmationModal__Additional">
            {t('deleteProjectItemModal.noteNotDeleted')}
          </div>
        )}
      </React.Fragment>
    )
  }

  render() {
    const { t, id, onConfirm } = this.props
    return (
      <ConfirmationModal
        id={id}
        title={t('deleteProjectItemModal.title')}
        onConfirm={onConfirm}
        contentFunc={this.renderDeleteModalContent}
        okLabel={t('common.delete')}
        cancelLabel={t('common.cancel')}
      />
    )
  }
}

export default translate()(DeleteProjectItemModal)
