import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { propTypes as i18nPropTypes, translate } from 'i18n'

import modifyClassName from 'helper/modifyClassName'

import './style.less'

const ModifiedBar = modifier => WrappedComponent => ({ ...props }) => <WrappedComponent modifier={modifier} {...props} />

class Bar extends Component {
  onSelect = (event, value) => {
    event.preventDefault()
    event.stopPropagation()
    if (this.props.onSelect) {
      this.props.onSelect(value)
    }
  }

  render = () => {
    const {
      modifier, className, usedForPremium, borderless, selectedValue,
      translationKeys = [], icons = [], t, labels, values,
    } = this.props
    return (
      <div className={`Bar ${className || ''} Bar--${modifier}`}>
        { values.map((value, i) => (
          <button
            className={modifyClassName('Bar__Tab', {
              selected: value === selectedValue, borderless, premiumSelected: value === selectedValue && usedForPremium, [modifier]: !!modifier,
            })}
            key={value}
            onClick={e => this.onSelect(e, value)}
          >
            { icons[i] ? icons[i] : t(translationKeys[i]) || labels[i] || value }
          </button>
        ))
        }
      </div>
    )
  }
}

Bar.propTypes = {
  ...i18nPropTypes,
  modifier: PropTypes.string,
  className: PropTypes.string,
  usedForPremium: PropTypes.bool,
  borderless: PropTypes.bool,
  onSelect: PropTypes.func,
  selectedValue: PropTypes.string,
  values: PropTypes.arrayOf(PropTypes.string),
  labels: PropTypes.arrayOf(PropTypes.string),
  translationKeys: PropTypes.arrayOf(PropTypes.string),
  icons: PropTypes.arrayOf(PropTypes.node),
}

export const TabBar = ModifiedBar('tabBar')(translate()(Bar))
export const SegmentBar = ModifiedBar('segmentBar')(translate()(Bar))
export const FlatTabBar = ModifiedBar('flat')(translate()(Bar))
