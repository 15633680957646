import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { propTypes as i18nPropTypes, translate } from 'i18n'

import modifyClassName from 'helper/modifyClassName'
import AddButton from 'components/common/AddButton'

import NoteGridItem from 'components/notes-overview/NoteGridItem'
import NoteListItem from 'components/notes-overview/NoteListItem'

import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer'

import { ScrollRestoredVList, ScrollRestoredVGrid } from 'containers/common/ScrollRestoration'
import featureFlyouts, { propTypes as featureFlyoutPropTypes } from 'containers/onboarding/FeatureFlyout'

import './style.less'

const NoteCollection = modifier => () => {
  const wrapper = class extends Component {
    state = {
      columnWidth: 1,
      columnCount: 1,
    }

    handleImportClick = (e) => {
      e.preventDefault()
      e.stopPropagation()
      this.props.push('/profile/import')
      return false
    }

    renderRow = ({
      index, key, style,
    }) => {
      if (index >= this.props.notes.length) {
        return <div key={key} style={style} />
      }
      const {
        notes, onOpenNote, onDeleteNote, onDuplicateNote, isPremiumRestricted,
      } = this.props
      const note = notes[index]
      return (
        <NoteListItem
          key={key}
          note={note}
          onOpenNote={onOpenNote}
          onDeleteNote={onDeleteNote}
          onDuplicateNote={onDuplicateNote}
          showModal={this.props.showModal}
          name={note.id}
          isPremiumRestricted={isPremiumRestricted}
          style={style}
        />
      )
    }

    renderCell = ({
      columnIndex, key, rowIndex, style,
    }) => {
      const {
        notes, onOpenNote, onDeleteNote, onDuplicateNote, isPremiumRestricted,
      } = this.props
      const note = notes[rowIndex * this.state.columnCount + columnIndex]
      if (!note) return <div style={style} key={key} />
      return (
        <NoteGridItem
          key={key}
          note={note}
          onOpenNote={onOpenNote}
          onDeleteNote={onDeleteNote}
          onDuplicateNote={onDuplicateNote}
          name={note.id}
          isPremiumRestricted={isPremiumRestricted}
          style={style}
        />
      )
    }

    onResize = ({ width }) => {
      const columnCount = Math.max(Math.floor(width / 300), 1)
      this.setState({ columnCount, columnWidth: Math.round(width / columnCount) })
    }

    render() {
      const {
        notes, onAddNote, isPremiumRestricted, id, addFeatureFlyout,
      } = this.props
      return (
        <div className="NoteCollection">

          <div className={modifyClassName('NoteCollection__Container', { [modifier]: true })} id="NoteCollection">
            { modifier === 'list'
                && (
                <AutoSizer>
                  {({ height, width }) => (
                    <ScrollRestoredVList
                      id={id}
                      width={width}
                      height={height}
                      overscanRowCount={10}
                      noRowsRenderer={() => <div className="norow" />}
                      rowCount={notes.length + 2}
                      rowHeight={73}
                      rowRenderer={this.renderRow}
                    />
                  )}
                </AutoSizer>
                )
                }

            { modifier === 'grid'

              && (
                <AutoSizer onResize={this.onResize}>
                  {({ height, width }) => (
                    <ScrollRestoredVGrid
                      id={id}
                      cellRenderer={this.renderCell}
                      columnWidth={this.state.columnWidth}
                      columnCount={this.state.columnCount}
                      height={height}
                      noContentRenderer={() => <div className="norow" />}
                      overscanColumnCount={0}
                      overscanRowCount={10}
                      rowHeight={this.state.columnWidth}
                      rowCount={Math.ceil(notes.length / this.state.columnCount)}
                      width={width}
                    />
                  )}
                </AutoSizer>
              )
              }
          </div>
          <AddButton forwardRef={addFeatureFlyout('CREATE_NOTE')} className="NoteCollection__AddButton" enablePaywall={isPremiumRestricted} onClick={onAddNote} />
        </div>
      )
    }
  }

  wrapper.propTypes = {
    notes: PropTypes.array,
    onAddNote: PropTypes.func,
    onOpenNote: PropTypes.func,
    onDeleteNote: PropTypes.func,
    onDuplicateNote: PropTypes.func,
    showModal: PropTypes.func,
    isPremiumRestricted: PropTypes.bool,
    id: PropTypes.string,
    ...i18nPropTypes,
    ...featureFlyoutPropTypes,
  }
  return wrapper
}

const flyoutConfig = {
  CREATE_NOTE: {
    placement: 'right',
  },
}

export const NoteCollectionGrid = translate()(featureFlyouts(flyoutConfig)(NoteCollection('grid')()))
export const NoteCollectionList = translate()(featureFlyouts(flyoutConfig)(NoteCollection('list')()))
