import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { propTypes as i18nPropTypes, translate } from 'i18n'

import { without } from 'lodash'

import { updateReference } from 'actions/references'
import { selectReferenceCollection } from 'actions/reference-collections'
import { push } from 'connected-react-router'

import FontIcon from 'components/common/FontIcon'
import Spinner from 'components/common/Spinner'

import './style.less'

class ReferenceDetailCollectionItem extends Component {
  static propTypes = {
    ...i18nPropTypes,
    collection: PropTypes.shape({
      name: PropTypes.string,
    }),
    onClick: PropTypes.func,
    onRemove: PropTypes.func,
  }

  state = {
    isLoading: false,
  }

  handleClick = async () => {
    await this.props.selectReferenceCollection(this.props.collection)
    this.props.push('/references')
  }

  handleRemove = async () => {
    const { reference, collection } = this.props
    this.setState({ isLoading: true })
    const collections = without(reference.collections.map(c => c.id), collection.id)
    await this.props.updateReference({
      ...this.props.reference,
      collections,
    })
  }

  render() {
    const { collection } = this.props
    return (
      <div key={collection.id} className="ReferenceDetailCollectionItem">
        <button onClick={this.handleClick} title={collection.name} className="ReferenceDetailCollectionItem__Content">
          <FontIcon className="ReferenceDetailCollectionItem__Icon" icon={FontIcon.Icons.faFolder} />
          <div className="ReferenceDetailCollectionItem__Name">{collection.name}</div>
        </button>
        <Spinner show={this.state.isLoading}>
          <button className="ReferenceDetailCollectionItem__Action" onClick={this.handleRemove}>
            <FontIcon icon={FontIcon.Icons.faTimes} />
          </button>
        </Spinner>
      </div>
    )
  }
}

const mapStateToProps = () => ({})


export default connect(mapStateToProps, {
  updateReference,
  selectReferenceCollection,
  push,
})(translate()(ReferenceDetailCollectionItem))
