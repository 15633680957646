import { values } from 'lodash'
import { library } from '@fortawesome/fontawesome-svg-core'

import * as icons from './fontAwesome'


// only import icons needed this makes bundle.js smaller
library.add(
  ...values(icons),
)

export default icons
