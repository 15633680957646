import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { get } from 'lodash'
import {
  reduxForm, SubmissionError, Field, propTypes as reduxPropTypes, getFormValues,
} from 'redux-form'

import { propTypes as i18nPropTypes, translate } from 'i18n'
import tracker from 'tracking/tracker'
import { login } from 'actions/authentication'
import queryString from 'query-string'

import { PrimaryButton } from 'components/common/Button'

class Login extends Component {
  submit = async (values) => {
    try {
      await this.props.login(values.email, values.password)
      tracker.logEvent('SIGNIN')
    } catch (err) {
      throw new SubmissionError({ _error: err.code })
    }
  }

  render() {
    const {
      handleSubmit, submitting, error, t, formValues,
    } = this.props
    const urlParam = formValues.email ? `?email=${encodeURIComponent(formValues.email)}` : ''
    return (
      <React.Fragment>
        <div className="AuthForm__Title">{t('auth.login.title')}</div>
        <div className="AuthForm__Description AuthForm__Description--unimportant">{t('auth.login.intro')}</div>
        { error && <div className="AuthForm__Alert">{ this.props.t(`auth.errors.${error}`) || this.props.t('auth.errors.default') }</div> }
        <form className="AuthForm__Form" onSubmit={handleSubmit(this.submit)}>
          <Field className="AuthForm__Input" name="email" component="input" type="text" placeholder={t('common.email')} />
          <Field className="AuthForm__Input" name="password" component="input" type="password" placeholder={t('common.password')} />
          <NavLink to={`/reset${urlParam}`} className="AuthForm__Link">{t('common.resetPassword')}</NavLink>
          <PrimaryButton className="AuthForm__SubmitButton" type="submit" isLoading={submitting}>{t('common.login')}</PrimaryButton>
        </form>
        <div className="AuthForm__Description AuthForm__Description--unimportant">{t('auth.login.help')}</div>
        <NavLink to="/register" className="AuthForm__Link">{t('common.register')}</NavLink>
      </React.Fragment>
    )
  }
}

const form = { form: 'Login' }

Login.propTypes = {
  ...reduxPropTypes,
  ...i18nPropTypes,
  login: PropTypes.func,
  formValues: PropTypes.shape({}),
}

const mapStateToProps = (state, ownProps) => ({
  initialValues: {
    email: get(queryString.parse(ownProps.location.search), 'email'),
  },
  formValues: getFormValues(form.form)(state) || {},
})

export default connect(mapStateToProps, {
  login,
})(reduxForm(form)(translate()(Login)))
