import React from 'react'
import PropTypes from 'prop-types'
import { propTypes as i18nPropTypes, translate } from 'i18n'

import { PrimaryButton } from 'components/common/Button'
import Icon from 'components/common/Icon'
import { AuratikumFontIcons } from 'helper/utils'
import HelpSpot from 'containers/HelpSpot'

import modifyClassName from 'helper/modifyClassName'

import './style.less'
import ProjectCard from '../ProjectCard'
import ProjectAddCard from '../ProjectAddCard'


const ProjectsGrid = ({
  children, onOpenProject, projects, title, onNewProject, onEditProject, onDeleteProject, isPremiumRestricted, t,
}) => (
  <div className="ProjectsGrid">
    <div className="ProjectsGrid__Title">{title}</div>
    <div className="ProjectsGrid__Grid">
      { onNewProject
      && (
      <ProjectAddCard
        onClick={onNewProject}
      >
        <PrimaryButton className={modifyClassName('ProjectsGrid__AddButton', { isPremiumRestricted })} circleModifier="circleBig" asDiv>
          <HelpSpot
            className="ProjectsGrid__HelpSpot"
            translationKey="helpSpot.projectGrid"
          />
          <Icon icon={AuratikumFontIcons.ADD} />
        </PrimaryButton>
        <div className="ProjectsGrid__AddLabel">{t('projectsOverview.newProject')}</div>
      </ProjectAddCard>
      )
      }
      { projects.map(project => (
        <ProjectCard
          key={project.id}
          project={project}
          onOpenProject={onOpenProject}
          onEditProject={onEditProject}
          onDeleteProject={onDeleteProject}
        />
      ))}
      {children}
    </div>
  </div>
)

ProjectsGrid.propTypes = {
  children: PropTypes.node,
  projects: PropTypes.array,
  title: PropTypes.string,
  onNewProject: PropTypes.func,
  onOpenProject: PropTypes.func,
  onEditProject: PropTypes.func,
  onDeleteProject: PropTypes.func,
  isPremiumRestricted: PropTypes.bool,
  ...i18nPropTypes,
}

export default (translate()(ProjectsGrid))
