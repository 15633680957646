import React from 'react'
import PropTypes from 'prop-types'
import { propTypes as i18nPropTypes, translate } from 'i18n'

import FontIcon from 'components/common/FontIcon'
import Spinner from 'components/common/Spinner'
import Toast from '../Toast'

import './style.less'

const RetryToast = ({
  content, contentFunc, onRetry, isLoading, t,
}) => (
  <div className="RetryToast">
    <div className="RetryToast__Icon"><FontIcon icon={FontIcon.Icons.faExclamationTriangle} /></div>
    <div className="RetryToast__Content">{content || (contentFunc && contentFunc(this.props.data))}</div>
    <div className="RetryToast__Action"><button className="RetryToast__Button" onClick={onRetry}>{ isLoading ? <Spinner /> : t('common.retry')}</button></div>
  </div>
)

RetryToast.propTypes = {
  content: PropTypes.any,
  contentFunc: PropTypes.func,
  onRetry: PropTypes.func,
  isLoading: PropTypes.bool,
  ...i18nPropTypes,
}

RetryToast.defaultPropTypes = {}

export default Toast(translate()(RetryToast))
