import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import WritingSidebarSelector from 'selectors/writing-sidebar'

import './style.less'

class WritingPortal extends Component {
  static propTypes = {
    children: PropTypes.node,
    isVisible: PropTypes.bool,
    top: PropTypes.number,
  }

  static defaultProps = {
    height: 0,
  }

  constructor(props) {
    super(props)
    this.el = document.createElement('div')
  }

  componentDidMount() {
  // The portal element is inserted in the DOM tree after
  // the Modal's children are mounted, meaning that children
  // will be mounted on a detached DOM node. If a child
  // component requires to be attached to the DOM tree
  // immediately when mounted, for example to measure a
  // DOM node, or uses 'autoFocus' in a descendant, add
  // state to Modal and only render the children when Modal
  // is inserted in the DOM tree.
    const portalContainer = document.getElementById('writing-sidebar-portal-container')
    portalContainer.appendChild(this.el)
  }

  componentWillUnmount() {
    const portalContainer = document.getElementById('writing-sidebar-portal-container')
    portalContainer.removeChild(this.el)
  }

  render() {
    const { isVisible, top } = this.props
    if (!isVisible) return null
    return ReactDOM.createPortal(
      <div className="WritingPortal" style={{ top }}>
        { this.props.children }
      </div>,
      this.el,
    )
  }
}

const mapStateToProps = state => ({
  isVisible: WritingSidebarSelector.isVisible(state),
})

export default connect(mapStateToProps, {})(WritingPortal)
