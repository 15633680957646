import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import { fieldPropTypes } from 'redux-form'

import FileDrop from 'components/common/FileDrop'
import FileIcon from 'components/common/FileIcon'

import Spinner from 'components/common/Spinner'

import { uploadReferenceFiles } from 'actions/references'
import { downloadFileFromS3 } from 'api'

import './style.less'


class ReferenceFileField extends Component {
  constructor() {
    super()
    this.state = {
      isUploading: false,
      isDownloading: false,
      errorMessage: null,
    }
  }

  handleDelete = async (file) => {
    const newValue = this.props.input.value.filter(f => f.fileId !== file.fileId)
    this.props.input.onChange(newValue)
  }

  handleUploadReferenceFiles = async (files) => {
    this.setState({ isUploading: true })
    try {
      // currently only first file is uploaded and used
      const payload = await this.props.uploadReferenceFiles([...files])
      this.setState({ isUploading: false, errorMessage: null })

      const newValue = [...this.props.input.value, {
        type: files[0].type,
        fileId: payload.fileId,
        fileName: files[0].name,
        url: payload.url,
      }]
      this.props.input.onChange(newValue)
    } catch (error) {
      this.setState({ errorMessage: error.message, isUploading: false })
    }
  }

  handleSelectFile = async (file) => {
    this.setState({ isDownloading: true })
    await downloadFileFromS3(file)
    this.setState({ isDownloading: false })
  }

  render() {
    const files = this.props.input.value
    const { meta: { touched, error }, t, readOnly } = this.props
    const file = files[0]

    return (
      <div className={`ReferenceFileField ${this.props.className || ''}`}>
        {touched && error && <span className="ReferenceFileField__Error">{error}</span>}
        {file
          && (
          <Spinner show={this.state.isDownloading}>
            <FileIcon
              file={file}
              onSelect={this.handleSelectFile}
              onDelete={this.handleDelete}
            />
          </Spinner>
          )}
        {!file && !readOnly
          && (
          <FileDrop
            onDrop={this.handleUploadReferenceFiles}
            isLoading={this.state.isUploading}
            text={t('references.files.fileFieldText')}
            textDrop={t('references.files.fileFieldTextDrop')}
          />
          )}
        {this.state.errorMessage && <div className="ReferenceFileField__Error">{this.state.errorMessage}</div>}
      </div>
    )
  }
}

ReferenceFileField.propTypes = {
  ...fieldPropTypes,
  ...i18nPropTypes,
  readOnly: PropTypes.bool,
}

const mapStateToProps = () => ({})

export default connect(mapStateToProps, {
  uploadReferenceFiles,
})(translate()(ReferenceFileField))
