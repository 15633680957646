import { createAction } from 'redux-actions'
import * as api from 'api'
import {
  cognitoLogin, cognitoForgotPassword, cognitoNewPassword, cognitoLogout, cognitoGetSession, cognitoRegister,
} from 'integrations/AWSCognito'
import { push } from 'connected-react-router'

import tracker from 'tracking/tracker'

export const states = {
  AUTHENTICATED: 'AUTHENTICATED',
  NOT_AUTHENTICATED: 'NOT_AUTHENTICATED',
  UNKNOWN: 'UNKOWN',
  EXPIRED: 'EXPIRED',
}

export const changeAuth = createAction('CHANGE_AUTH')
export const logout = createAction('LOGOUT')

export const checkAuth = () => async (dispatch, getState) => {
  try {
    const session = await cognitoGetSession()
    if (session.isValid() && getState().authentication.state !== states.AUTHENTICATED) {
      dispatch(changeAuth(states.AUTHENTICATED))
    }
    if (!session.isValid()) {
      dispatch(changeAuth(states.EXPIRED))
    }
  } catch (e) {
    dispatch(changeAuth(states.NOT_AUTHENTICATED))
  }
}

export const login = (username, password) => async (dispatch) => {
  try {
    await cognitoLogin(username.trim(), password)
    dispatch(changeAuth(states.AUTHENTICATED))
  } catch (e) {
    dispatch(changeAuth(states.NOT_AUTHENTICATED))
    throw e
  }
}

export const register = (values, token, trafficSource) => async (dispatch) => {
  await cognitoRegister(values)
  await cognitoLogin(values.email.trim(), values.password)
  await api.initializeUser(values.language, token, trafficSource)

  tracker.logEvent('SIGNUP')

  dispatch(changeAuth(states.AUTHENTICATED))
  dispatch(push('/projects?registered=true'))
}

export const resetPassword = values => async () => {
  await cognitoForgotPassword(values)
}

export const newPassword = values => async () => {
  await cognitoNewPassword(values)
}

export const doLogout = () => async (dispatch) => {
  cognitoLogout()
  dispatch(logout())
}
