import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Icon from 'components/common/Icon'
import { AuratikumFontIcons } from 'helper/utils'
import modifyClassName from 'helper/modifyClassName'

import './style.less'

class WritingAddBlockLine extends Component {
  static propTypes = {
    parent: PropTypes.shape({}),
    index: PropTypes.number,
    handleAddClick: PropTypes.func,
    handleMoveDropClick: PropTypes.func,
    moveAndDropActivated: PropTypes.bool,
    isVisible: PropTypes.bool,
  }

  static defaultProps = {
    handleAddClick: () => {},
    moveAndDropActivated: false,
  }

  handleAddClick = () => {
    const { parent, index, handleAddClick } = this.props
    handleAddClick(this.referenceElement, parent, index)
  }

  handleMoveDropClick = (e) => {
    const { parent, index, handleMoveDropClick } = this.props
    handleMoveDropClick(parent, index)
    e.preventDefault()
    e.stopPropagation()
  }

  render() {
    const { moveAndDropActivated, isVisible } = this.props
    return (
      <div ref={(r) => { this.referenceElement = r }} className={modifyClassName('WritingAddBlockLine', { hidden: !isVisible })}>
        <div className={modifyClassName('WritingAddBlockLine__Line', { isDropzone: moveAndDropActivated })}>
          <button onClick={moveAndDropActivated ? this.handleMoveDropClick : this.handleAddClick} className={modifyClassName('WritingAddBlockLine__LineIcon', { isDropzone: moveAndDropActivated })}>
            <Icon className="WritingAddBlockLineItem" icon={moveAndDropActivated ? AuratikumFontIcons.MOVE : AuratikumFontIcons.ADD} />
          </button>
        </div>
      </div>
    )
  }
}

export default WritingAddBlockLine
