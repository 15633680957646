import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { DragSource, DropTarget } from 'react-dnd'
import { get } from 'lodash'

import FontIcon from 'components/common/FontIcon'
import modifyClassName from 'helper/modifyClassName'
import Icon from 'components/common/Icon'

import { expandCollapseProjectItem } from 'actions/project-items'

import './style.less'

class OutlineHeading extends Component {
  handleClick = () => this.props.expandCollapseProjectItem(this.props.item.id)

  handleEditClick = event => this.props.onEditClick(event, this.props.item)

  handleDeleteClick = () => this.props.onDeleteClick(this.props.item)

  handleMoveClick = () => null

  render() {
    const {
      connectDropTarget, className, dragOver, connectDragSource, item, headingPrefix, level, showHashLink,
    } = this.props

    return (connectDropTarget(
      connectDragSource(<div
        className={`${className} OutlineItem OutlineItem--level${level}`}
      >

        <div className={`${modifyClassName('OutlineHeading', { dragOver }, { noChildren: this.props.item.children.length === 0 })} OutlineHeading--level${level}`}>
          <div className="OutlineHeading__Actions">
            <button className="OutlineHeading__Action" onClick={this.handleDeleteClick}><Icon icon="K" /></button>
            <button className="OutlineHeading__Action" onClick={this.handleEditClick}><Icon icon="M" /></button>
            {showHashLink && <a className="OutlineHeading__Action OutlineHeading__ActionScroll" href={`#${item.id}`}><FontIcon icon={FontIcon.Icons.faArrowAltCircleRight} aria-hidden="true" /></a> }
          </div>

          { /* <button> is not draggable in firefox https://bugzilla.mozilla.org/show_bug.cgi?id=568313 */}
          { // eslint-disable-next-line jsx-a11y/no-static-element-interactions
          }
          {' '}
          <div title={item.title} className="OutlineHeading__Text" onClick={this.handleClick}>
            {headingPrefix}
            {' '}
            {item.title}
          </div>
        </div>
      </div>),
    ))
  }
}

// DragSource
const headingSource = {
  beginDrag(props) {
    // props.onItemClick(props.item)

    return {
      item: props.item,
    }
  },
}

function collect(dragConnect, monitor) {
  return {
    connectDragSource: dragConnect.dragSource(),
    isDragging: monitor.isDragging(),
  }
}

// Drop Zone
function collectDrop(dragConnect, monitor) {
  return {
    connectDropTarget: dragConnect.dropTarget(),
    dragOver: monitor.isOver(),
  }
}

const target = {
  drop(props, monitor) {
    let index = 0
    const item = get(monitor.getItem(), 'item')
    if (get(item, 'children[0].type') === 'COMMENT') index = 1 // assuming there is only one comment as a first child
    if (monitor.getItemType() === 'HEADING') index = props.item.children.length
    props.onDrop(monitor.getItemType(), props.item, monitor.getItem().item, index)
  },
}

OutlineHeading.propTypes = {
  connectDropTarget: PropTypes.func,
  dragOver: PropTypes.bool,
  connectDragSource: PropTypes.func,
  className: PropTypes.string,
  headingPrefix: PropTypes.string,
  level: PropTypes.number,
  item: PropTypes.shape({
    text: PropTypes.string,
    id: PropTypes.string,
    children: PropTypes.array,
    editing: PropTypes.bool,
    expanded: PropTypes.bool,
    selected: PropTypes.bool,
  }),
  expandCollapseProjectItem: PropTypes.func,
  onDeleteClick: PropTypes.func,
  onEditClick: PropTypes.func,
  showHashLink: PropTypes.bool,
}

const OutlineHeadingDrag = DragSource('HEADING', headingSource, collect)(OutlineHeading)
const OutlineHeadingDrop = DropTarget(['NOTE_LINK', 'NEW_NOTE_LINK', 'HEADING'], target, collectDrop)(OutlineHeadingDrag)


const mapStateToProps = () => ({})

export default connect(mapStateToProps, {
  expandCollapseProjectItem,
})(OutlineHeadingDrop)
