import ReactGA from 'react-ga'
import uuid from 'uuid/v4'
import { get } from 'lodash'

// extract GA label from eventData for first mile events
const gaResolvers = {
  FM_CONTENT_TYPE_SELECT: e => get(e, 'selection'),
  FM_TOUR: e => `STEP_${get(e, 'step', 'UNKNOWN')}`,
  FM_USER_TURN: e => get(e, 'type'),
  FM_IMPORT_SELECT: e => get(e, 'type'),
}

export default class GoogleAnalyticsTracker {
  constructor() {
    let trackingId = 'UA-28958316-9'
    if (!process.env.ENABLE_TRACKING) {
      trackingId = 'UA-28958316-11'
    }
    ReactGA.initialize(trackingId, { debug: false })
    ReactGA.set({ anonymizeIp: true })
    ReactGA.plugin.require('ecommerce')
  }

  logEvent(appEvent, eventData) {
    const category = 'USER_ACTIVITY'

    if (appEvent === 'SUBSCRIBE') {
      const price = get(eventData, 'plan.price', 0.0) / 100.0
      ReactGA.plugin.execute('ecommerce', 'addTransaction', {
        id: uuid(),
        revenue: price,
      })
      ReactGA.plugin.execute('ecommerce', 'send')
      ReactGA.plugin.execute('ecommerce', 'clear')
    } else if (appEvent.startsWith('FM_')) {
      const resolver = get(gaResolvers, appEvent, () => null)
      const label = resolver(eventData)
      ReactGA.event({ category, action: appEvent, label })
    } else {
      ReactGA.event({ category, action: appEvent })
    }
  }
}
