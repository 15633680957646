import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Flyout from 'containers/common/Flyout'

import './style.less'


class NavigationPathFlyout extends Component {
  static propTypes = {
    navigationItems: PropTypes.arrayOf(PropTypes.shape({})),
    onNavigate: PropTypes.func.isRequired,
  }

  renderNavigationItem = ({
    title, url, icon, index,
  }) => (
    <button
      className="NavigationPathFlyout__Button"
      onClick={this.props.onNavigate({ index, url })}
      key={index}
    >
      <div className="NavigationPathFlyout__Icon">{icon}</div>
      <div className="NavigationPathFlyout__Title">{title}</div>
    </button>
  )

  render() {
    const { navigationItems } = this.props
    return (
      <div className="NavigationPathFlyout">
        {navigationItems.map(this.renderNavigationItem)}
      </div>
    )
  }
}

export default Flyout(NavigationPathFlyout)
