import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { reduxForm, Field } from 'redux-form'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import { Trans } from 'react-i18next'

import { validatePaymentData } from 'integrations/Adyen'
import { get } from 'lodash'

import FormTextInput from 'components/common/FormTextInput'
import { PremiumButton } from 'components/common/Button'

import MethodSelector from './methodSelector'
import PaypalForm from './paypalForm'

import './style.less'

const validate = (values, props) => {
  const errors = {}
  if (values.method === 'CREDIT') {
    if (!values.ccNumber) errors.ccNumber = props.t('common.required')
    if (!values.ccHolder) errors.ccHolder = props.t('common.required')
    if (!values.ccExpMonth) errors.ccExpMonth = props.t('common.required')
    if (!values.ccExpYear) errors.ccExpYear = props.t('common.required')
    if (!values.ccCvc) errors.ccCvc = props.t('common.required')

    let validation
    try {
      validation = validatePaymentData(values)
    } catch (error) {
      errors.ccCvc = props.t('common.invalid')
      return errors
    }

    if (!validation.valid) {
      if (!get(validation, 'number', true)) errors.ccNumber = props.t('common.invalid')
      if (!get(validation, 'holderName', true)) errors.ccHolder = props.t('common.invalid')
      if (!get(validation, 'expiryMonth', true)) errors.ccExpMonth = props.t('common.invalid')
      if (!get(validation, 'expiryYear', true)) errors.ccExpYear = props.t('common.invalid')
      if (!get(validation, 'cvc', true)) errors.ccCvc = props.t('common.invalid')
    }
  }

  if (values.method === 'SEPA') {
    if (!values.iban) errors.iban = props.t('common.required')
    if (!values.ownerName) errors.ownerName = props.t('common.required')
  }

  return errors
}

class PaymentForm extends Component {
  required = value => (value ? undefined : this.props.t('common.required'))

  renderOrderButton = () => <PremiumButton disabled={this.props.invalid} className="PaymentForm__OrderButton" type="submit" isLoading={this.props.isLoading}>{this.props.t('paymentform.orderButton')}</PremiumButton>

  renderCheckbox = ({
    input, label, type, meta: { touched, error },
  }) => (
    <div className="PaymentForm__ToSContainer">
      <label htmlFor={input.name}>
        <input {...input} type={type} />
        <span>{label}</span>
      </label>
      {touched && error && <div className="PaymentForm__FieldError">{error}</div>}
    </div>
  )

  renderInput = (label, name, modifier, placeholder) => (
    <div
      className={`PaymentForm__FormGroup ${modifier && `PaymentForm__FormGroup--${modifier}`}`}
    >
      <Field key={name} name={name} label={label} placeholder={placeholder} component={FormTextInput} />
    </div>
  )

  renderAGBCheckbox = () => (
    <Field
      component={this.renderCheckbox}
      type="checkbox"
      name="acceptedTerms"
      validate={[this.required]}
      label={(
        <span className="PaymentForm__AGB">
          <Trans i18nKey="paymentform.tos.trans">
            index0<a href={this.props.t('paymentform.tos.link')} target="_blank" rel="noopener noreferrer">index1</a>index2
          </Trans>
        </span>
)}
    />
  )

  renderCreditCardFields = () => (
    <div className="PaymentForm__FieldContainer">
      { this.renderInput(this.props.t('paymentform.fields.ccNumber'), 'ccNumber') }
      { this.renderInput(this.props.t('paymentform.fields.ccHolder'), 'ccHolder') }
      <div className="PaymentForm__RowFieldContainer">
        { this.renderInput(this.props.t('paymentform.fields.ccExpMonth'), 'ccExpMonth', 'small', '01') }
        { this.renderInput(this.props.t('paymentform.fields.ccExpYear'), 'ccExpYear', 'small', '2020') }
        { this.renderInput(this.props.t('paymentform.fields.ccCvc'), 'ccCvc', 'small', '123') }
      </div>
    </div>
  )

  renderPaypal = () => (parseInt(this.props.plan.duration, 10) < 12
    ? <div className="PaymentForm__FieldContainer">{this.props.t('paymentform.paypal.monthError')}</div>
    : <div className="PaymentForm__FieldContainer"><PaypalForm plan={this.props.plan} voucher={this.props.voucher} canSubmit={this.props.valid} /></div>)


  renderSepa = () => (
    <div className="PaymentForm__FieldContainer">
      { this.renderInput(this.props.t('paymentform.fields.sepaOwner'), 'ownerName') }
      { this.renderInput(this.props.t('paymentform.fields.sepaIBAN'), 'iban') }
    </div>
  )

  render() {
    const { handleSubmit, formValues, error } = this.props

    return (
      <div>
        <form onSubmit={handleSubmit}>
          <Field name="method" component={MethodSelector} inactiveMethods={this.props.plan.currency !== 'EUR' ? ['SEPA'] : []} />
          {formValues.method === 'CREDIT' && this.renderCreditCardFields() }
          {formValues.method === 'SEPA' && this.renderSepa() }
          { (formValues.method !== 'PAYPAL' || (formValues.method === 'PAYPAL' && this.props.plan.duration === 'years')) && this.renderAGBCheckbox() }
          <div />
          { formValues.method !== 'PAYPAL' && this.renderOrderButton() }
        </form>

        {/* {formValues.method === 'PAYPAL' && this.renderPaypal() } */}
        { error && <div className="PaymentForm__Error">{error}</div> }
      </div>
    )
  }
}

const form = {
  enableReinitialize: true,
  initialValues: {
    method: 'CREDIT',
  },
  validate,
}

const mapStateToProps = (state, props) => ({
  validate: values => validate(values, props.t),
})

PaymentForm.propTypes = {
  handleSubmit: PropTypes.func,
  formValues: PropTypes.shape({}),
  plan: PropTypes.shape({}),
  voucher: PropTypes.shape({}),
  ...i18nPropTypes,
}

const connectedComponent = connect(mapStateToProps, null)(PaymentForm)
const formComponent = reduxForm(form)(connectedComponent)
const translatedComponent = translate()(formComponent)

export default translatedComponent
