/* global document */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ReactDOM from 'react-dom'

import uuidV4 from 'uuid/v4'
import { propTypes as i18nPropTypes, translate } from 'i18n'


import tracker from 'tracking/tracker'
import FontIcon from 'components/common/FontIcon'

import HelpSelector from 'selectors/help'
import { openHelpSpot, closeHelpSpot } from 'actions/help'

import './style.less'

const helpSpotRoot = document.getElementById('root-helpspot')

class HelpSpot extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      id: uuidV4(),
      style: {},
    }
  }

  show = async (e) => {
    if (this.props.openSpots.indexOf(this.state.id) > -1) return

    e.preventDefault()
    e.stopPropagation()
    const bodyRect = document.body.getBoundingClientRect()
    const targetRect = e.currentTarget.getBoundingClientRect()

    const top = targetRect.top - bodyRect.top
    const bottom = bodyRect.height - targetRect.bottom
    const left = targetRect.left - bodyRect.left
    const right = bodyRect.width - targetRect.right

    const r = 15

    if (top < bodyRect.height / 2 && left < bodyRect.width / 2) {
      this.setState({ style: { top: top + r, left: left + r } })
    }
    if (top > bodyRect.height / 2 && left < bodyRect.width / 2) {
      this.setState({ style: { bottom: bottom + r, left: left + r } })
    }
    if (top > bodyRect.height / 2 && left > bodyRect.width / 2) {
      this.setState({ style: { bottom: bottom + r, right: right + r } })
    }
    if (top < bodyRect.height / 2 && left > bodyRect.width / 2) {
      this.setState({ style: { top: top + r, right: right + r } })
    }

    // id would be nice to track ids but we don't have one (this.state.id is different every time)
    tracker.logEvent('HELPSPOT_HOVERED', { id: this.props.title })

    this.props.openHelpSpot(this.state.id)
  }

  hide = () => {
    this.props.closeHelpSpot(this.state.id)
  }

  leaveMouse = () => {
    if (!this.props.keepOpen) this.hide()
  }


  render() {
    const {
      t, visible, className, translationKey,
    } = this.props

    const title = t(`${translationKey}.title`, { defaultValue: '' })
    const content = t(`${translationKey}.content`, { defaultValue: '' })

    if (!visible) return <div className="HelpSpot--inactive" />

    return (
      <div className={`HelpSpot ${className}`}>
        <div onMouseEnter={this.show} onMouseLeave={this.leaveMouse}>
          <div className="HelpSpot__Bullet" />
        </div>
        { this.props.openSpots.indexOf(this.state.id) > -1 && ReactDOM.createPortal(
          <div style={this.state.style} className="HelpContent" onClick={this.hide}>
            { title !== ''
            && (
            <div className="HelpContent__Header">
              { this.props.keepOpen && <div className="HelpContent__Close" onClick={this.hide}><FontIcon icon={FontIcon.Icons.faTimes} /></div>}
              { title }
            </div>
            )
            }
            { content !== ''
            && <div className="HelpContent__Content">{content}</div>
            }
          </div>,
          helpSpotRoot,
        )
        }
      </div>
    )
  }
}

HelpSpot.propTypes = {
  visible: PropTypes.bool,
  openSpots: PropTypes.array,
  closeHelpSpot: PropTypes.func,
  className: PropTypes.string,
  keepOpen: PropTypes.bool,
  translationKey: PropTypes.string,
  openHelpSpot: PropTypes.func,
  ...i18nPropTypes,
}

const mapStateToProps = state => ({
  visible: HelpSelector.isEnabled(state),
  openSpots: HelpSelector.getOpenSpots(state),
})


export default connect(mapStateToProps, {
  openHelpSpot,
  closeHelpSpot,
})(translate()(HelpSpot))
