import React from 'react'
import PropTypes from 'prop-types'

import './style.less'

const ColoredLabel = modifier => WrappedComponent => ({ ...props }) => <WrappedComponent modifier={modifier} {...props} />

const RoundLabel = ({ className, modifier, title }) => (
  <div className={`RoundLabel RoundLabel--${modifier} ${className}`}>
    {title}
  </div>
)

RoundLabel.propTypes = {
  className: PropTypes.string,
  modifier: PropTypes.string,
  title: PropTypes.string,
}

// eslint-disable-next-line import/prefer-default-export
export const GreyRoundLabel = ColoredLabel('grey')(RoundLabel)
