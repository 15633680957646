import SentryIntegration from 'integrations/Sentry'
import FileSaver from 'file-saver'

import { cognitoGetToken as getToken } from 'integrations/AWSCognito'


class APIException extends Error {
  constructor(message, {
    status, data, url, options,
  }) {
    super(`API request failed (status=${status}): ${message}`)
    this.message = message
    this.name = 'APIException'
    this.data = data
    this.status = status
    this.url = url
    this.options = options
  }
}

class NetworkException extends Error {
  constructor(url, options, originalMessage = '') {
    super('Request failed')
    this.name = 'NetworkException'
    this.url = url
    this.options = options
    this.originalMessage = originalMessage
  }
}

class Connector {
  async authenticatedRequest(...params) {
    const token = await getToken()
    return this.request(token, ...params)
  }

  async doFetch(url, options, logError = true) {
    let resp = null
    try {
      resp = await fetch(url, options)
    } catch (err) {
      const exc = new NetworkException(url, options, err.message)
      if (logError) {
        SentryIntegration.logError(exc)
      }
      throw exc
    }
    return resp
  }

  async request(token, url, method, data, logError = true) {
    const headerOptions = {
      'content-type': 'application/json',
      'Cache-Control': 'no-cache',
    }

    if (token) {
      headerOptions.Authorization = token
    }

    const headers = new Headers(headerOptions)
    const options = {
      method: method || 'GET',
      headers,
    }

    if ((['POST', 'PATCH', 'PUT'].indexOf(method) >= 0) && data) options.body = JSON.stringify(data)

    const resp = await this.doFetch(url, options, logError)

    if (resp.status === 204) return null

    const json = await resp.json()

    if (!resp.ok) {
      const message = json.error || 'Unknown error'
      throw new APIException(message, {
        status: resp.status, data: json.data || json, url, options,
      })
    }

    return json.data
  }

  async cslRequest(name) {
    const headers = new Headers({ })
    const options = {
      method: 'GET',
      headers,
    }
    const filename = name.substr(-3) === 'csl' ? name : `${name}.csl`
    const url = `https://assets.auratikum.de/styles/${filename}`

    const resp = await this.doFetch(url, options)

    if (resp) {
      try {
        return await resp.text()
      } catch (error) {
        SentryIntegration.logError(new APIException('csl parsing failed', { url }))
      }
    }

    return null
  }

  async downloadFileThroughAPI(token, url, filename) {
    const headers = new Headers({
      // 'lcp-principal': 'test',
      Authorization: token,
      'Cache-Control': 'no-cache',
      Accept: 'application/octet-stream',
    })
    const options = {
      method: 'GET',
      headers,
    }

    const resp = await this.doFetch(url, options)

    if (!resp.ok) {
      let message
      let json
      try {
        json = await resp.json()
        message = json.error || 'Unknown error'
      } catch (err) {
        message = 'Unknown error'
      }

      SentryIntegration.logError(new APIException(message, {
        status: resp.status, data: json.data, url, options,
      }))
    }

    const contentDisposition = resp.headers.get('content-disposition')
    const downloadedFileName = contentDisposition ? contentDisposition.substr(contentDisposition.indexOf('=') + 1) : filename

    const blob = await resp.blob()


    FileSaver.saveAs(blob, downloadedFileName)

    // special case handling export containing issues
    if (resp.status === 203) {
      throw new APIException('File contains errors!', { status: 203 })
    }
  }

  async downloadFromS3(url, fileName) {
    const options = {
      method: 'GET',
    }
    const resp = await fetch(url, options)

    if (!resp.ok) {
      let message
      let json
      try {
        json = await resp.json()
        message = json.error || 'Unknown error'
      } catch (err) {
        message = 'Unknown error'
      }
      throw new APIException(message, {
        status: resp.status, data: json.data, url, options,
      })
    }

    const blob = await resp.blob()

    const contentDisposition = resp.headers.get('content-disposition')
    const downloadedFileName = contentDisposition ? contentDisposition.substr(contentDisposition.indexOf('=') + 1) : fileName
    FileSaver.saveAs(blob, downloadedFileName)
  }

  async uploadToS3(url, file) {
    const headers = new Headers({
      Accept: 'application/octet-stream',
    })

    const options = {
      method: 'put',
      credentials: 'same-origin',
      body: file,
      headers,
    }

    const resp = await fetch(url, options)

    if (!resp.ok) {
      throw new APIException('S3 Upload Error', { status: resp.status, url, options })
    }
  }
}

export default new Connector()
