import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Trans } from 'react-i18next'

import { propTypes as i18nPropTypes, translate } from 'i18n'
import ProfileSelector from 'selectors/profile'

import FontIcon from 'components/common/FontIcon'
import ReferralList from 'components/profile/ReferralList'
import ReferralInviteForm from 'containers/profile/ReferralInviteForm'

import './style.less'


const ReferralContainer = ({ referrals, t }) => (
  <div className="ReferralContainer">
    <div className="ReferralContainer__Title">
      {t('referral.title')}
    </div>
    <div className="ReferralContainer__SubTitle">
      <Trans i18nKey="referral.subtitle">
          line1
        <br />
          line2
      </Trans>
    </div>
    <div className="ReferralContainer__Steps">
      <div className="ReferralContainer__Step">
        <div className="ReferralContainer__StepIcon">
          <FontIcon icon={FontIcon.Icons.faUsers} />
        </div>
        <div className="ReferralContainer__StepTitle">{t('referral.steps.invite.title')}</div>
        <div className="ReferralContainer__StepText">
          {t('referral.steps.invite.description')}
        </div>
      </div>

      <div className="ReferralContainer__Step">
        <div className="ReferralContainer__StepIcon">
          <FontIcon icon={FontIcon.Icons.faUserPlus} />
          <div className="ReferralContainer__StepIconBadge">{t('referral.steps.badge')}</div>
        </div>
        <div className="ReferralContainer__StepTitle">{t('referral.steps.register.title')}</div>
        <div className="ReferralContainer__StepText">
          <Trans i18nKey="referral.steps.register.description">
              You get
            <strong className="ReferralContainer__StrongText">1 month Auratikum Premium for free</strong>
              .
            <br />
              If you have premium already, the license will extend accordingly.
          </Trans>
        </div>
      </div>

      <div className="ReferralContainer__Step">
        <div className="ReferralContainer__StepIcon">
          <FontIcon icon={FontIcon.Icons.faMoneyBillAlt} />
          <div className="ReferralContainer__StepIconBadge">{t('referral.steps.badge')}</div>
        </div>
        <div className="ReferralContainer__StepTitle">{t('referral.steps.premium.title')}</div>
        <div className="ReferralContainer__StepText">
          {t('referral.steps.premium.description')}
        </div>
      </div>
    </div>
    <ReferralInviteForm />
    <ReferralList className="ReferralContainer__List" referrals={referrals} />
  </div>
)


ReferralContainer.propTypes = {
  profile: PropTypes.shape({}),
  referrals: PropTypes.array,
  ...i18nPropTypes,
}


const mapStateToProps = state => ({
  profile: ProfileSelector.getProfile(state),
  referrals: ProfileSelector.getReferrals(state),
})


export default connect(mapStateToProps)(translate()(ReferralContainer))
