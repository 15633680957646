import { get } from 'lodash'

export default class FacebookTracker {
  /* eslint-disable */
  constructor() {
    !function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;
    n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,
    document,'script','https://connect.facebook.net/en_US/fbevents.js')
    fbq('init', '1782481675405585')
  }

  logEvent(appEvent, eventData) {
    switch (appEvent) {
      case 'SIGNUP':
        fbq('track', 'CompleteRegistration')
        break
      case 'SUBSCRIBE':
        const price = get(eventData, 'plan.price', 0.0) / 100.0    
        fbq('track', 'Purchase', { 'value': price, 'currency': 'EUR' })
        break
      default:
        fbq('trackCustom', appEvent, eventData)  
    }
  }
  /* eslint-enable */
}
