import { handleActions } from 'redux-actions'

import { selectNote } from 'actions/notes'

const reducer = handleActions({
  [selectNote]: (state, action) => ({
    selectedNoteId: action.payload,
  }),
}, { selectedNoteId: null })

export default reducer
