
import React from 'react'
import modifyClassName from 'helper/modifyClassName'

import withTooltip from 'containers/common/Tooltip'
import {
  parseColor,
  isSelected,
  isNoColor,
} from 'helper/colorHandler'

import './style.less'

const ColorButton = withTooltip(({
  color,
  value,
  onChange,
  className,
}) => (
  <div
    className={`${modifyClassName('ColorButton', {
      selected: isSelected(color, value),
    })} ${className}`}
  >
    <button
      type="button"
      className={modifyClassName('ColorButton__Button', {
        selected: isSelected(color, value),
        noColor: isNoColor(color),
      })}
      onClick={() => onChange(color || 'transparent')}
      style={{ backgroundColor: isNoColor(color) ? 'transparent' : parseColor(color) }}
    />
  </div>
))

export default ColorButton
