import React from 'react'
import PropTypes from 'prop-types'
import { Trans } from 'react-i18next'

import { translate } from 'i18n'

import './style.less'


const ChallengerHeader = ({ t, className, scoreLimit }) => (
  <div className={`ChallengeHeader ${className}`}>
    <div className="ChallengeHeader__Title">
      {t('challenge.claim')}
    </div>
    <div className="ChallengeHeader__Info">
      <Trans i18nKey="challenge.explanation" values={{ score: scoreLimit }}>
        <div>line1</div>
        <div>line2</div>
      </Trans>
    </div>
  </div>
)

ChallengerHeader.propTypes = {
  t: PropTypes.func.isRequired,
  className: PropTypes.string,
  scoreLimit: PropTypes.number,
}

export default translate()(ChallengerHeader)
