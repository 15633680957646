import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { handleHotkey } from 'helper/eventHandler'
import ModalFooter from 'components/common/ModalFooter'
import ModalHeader from 'components/common/ModalHeader'

import Modal from '../Modal'

import './style.less'

class ConfirmationModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
    }

    this.props.attachHotKeyHandlers({
      enter: handleHotkey(this.handleConfirm),
    })
  }

  handleCancel = async () => {
    this.props.onCancel(this.props.data)
    await this.props.hide()
  }

  handleConfirm = async () => {
    await this.setState({ loading: true })
    await this.props.onConfirm(this.props.data)
    await this.setState({ loading: false })
    await this.props.hide()
  }

  render() {
    const {
      content, contentFunc, okLabel, cancelLabel, title,
    } = this.props
    const { loading } = this.state

    const cancel = cancelLabel ? cancelLabel.toUpperCase() : null
    const confirm = okLabel ? okLabel.toUpperCase() : null
    return (
      <div className="ConfirmationModal">
        <ModalHeader title={title} />
        <div className="ConfirmationModal__Content">{content || (contentFunc && contentFunc(this.props.data))}</div>
        <ModalFooter
          className=""
          isLoading={loading}
          confirmButtonLabel={confirm}
          onConfirmButtonClick={this.handleConfirm}
          cancelButtonLabel={cancel}
          onCancelButtonClick={this.handleCancel}
        />
      </div>
    )
  }
}

ConfirmationModal.propTypes = {
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  okLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  content: PropTypes.node,
  contentFunc: PropTypes.func,
  data: PropTypes.shape({}),
  hide: PropTypes.func,
  title: PropTypes.string,
  attachHotKeyHandlers: PropTypes.func,
}

ConfirmationModal.defaultPropTypes = {
  cancelLabel: '',
  contentFunc: () => '',
}


export default Modal(ConfirmationModal)
