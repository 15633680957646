import * as api from 'api'
import { receiveEntities } from 'actions/entities'
import NPSSelector from 'selectors/net-promoter-score'
import NoteSelector from 'selectors/notes'
import ProfileSelector from 'selectors/profile'
import { createAction } from 'redux-actions'
import moment from 'moment'

// use these setting for test.
// const NEW_NPS_TIME_FRAME = 0.00216667
// const SNOOZE_TIME_FRAME = 0.00138889

const NEW_NPS_TIME_FRAME = 2160 // 3 months
const SNOOZE_TIME_FRAME = 24 // 1 day

/* Duration from last snooze in hour */
const snoozeDuration = (currentNps, snoozeTime) => {
  const lastPopupCloseDate = currentNps.popUpClosedOn.sort((a, b) => new Date(b).getTime() - new Date(a).getTime())[0]

  return (Math.abs(moment() - moment(lastPopupCloseDate)) / 3600000) > snoozeTime
}

export const showNpsModal = createAction('SHOW_NPS_MODAL')

export const requestNps = () => async (dispatch) => {
  const payload = await api.requestNps()
  return dispatch(receiveEntities(payload))
}

export const createNps = () => async (dispatch) => {
  const payload = await api.createNps()
  const result = await dispatch(receiveEntities(payload))
  return result
}

export const closeNpsModal = () => async (dispatch, getState) => {
  const currentNps = NPSSelector.getCurrentNetPromoterScore(getState())
  // update popUpClosedOn
  currentNps.popUpClosedOn.push(new Date())
  const payload = await api.updateNps(currentNps)
  await dispatch(receiveEntities(payload))

  const result = await dispatch(showNpsModal(null))

  return result
}

export const updateNps = data => async (dispatch) => {
  const payload = await api.updateNps(data)
  const result = await dispatch(receiveEntities(payload))

  return result
}

export const sendFeedbackEmail = currentNps => async (dispatch, getState) => {
  const profile = ProfileSelector.getProfile(getState())

  const result = await api.sendFeedbackEmail(profile, currentNps)
  return result
}

export const showNps = () => async (dispatch, getState) => {
  const nps = NPSSelector.getNetPromoterScore(getState())
  const currentNps = NPSSelector.getCurrentNetPromoterScore(getState())
  const noteCount = NoteSelector.getNoneInitialCount(getState())

  // only consider to display net promoter score modal if user has more than 5 notes and after submitting save button
  if (noteCount > 5) {
    // show modal if user does not have any nps yet
    // create new net promoter score incase user snoozes the pop-up for 3 months once closing it for the third time
    if (nps.length === 0
      || ((currentNps.popUpClosedOn.length === 3 || currentNps.rating !== null) && snoozeDuration(currentNps, NEW_NPS_TIME_FRAME))) {
      await dispatch(createNps())
      await dispatch(showNpsModal('NPS_REQUEST'))
    }

    // user has not seen popup for 24 hours (1140 seconds)
    if (currentNps && currentNps.popUpClosedOn.length < 3
      && currentNps.popUpClosedOn.length > 0
      && snoozeDuration(currentNps, SNOOZE_TIME_FRAME)
      && currentNps.rating == null) {
      await dispatch(showNpsModal('NPS_REQUEST'))
    }

    if (currentNps && currentNps.popUpClosedOn.length === 0) {
      await dispatch(showNpsModal('NPS_REQUEST'))
    }
  }
}
