import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { connectRouter } from 'connected-react-router'

import entities from './entities'
import noteDetailReducer from './NoteDetailReducer'
import noteCollectionReducer from './NoteCollectionReducer'
import authenticationReducer from './AuthenticationReducer'
import archiveSearchReducer from './ArchiveSearchReducer'
import projectReducer from './ProjectReducer'
import projectItemReducer from './ProjectItemReducer'
import tagReducer from './TagReducer'
import notePagingReducer from './NotePagingReducer'
import modalReducer from './ModalReducer'
import helpReducer from './HelpReducer'
import importReducer from './ImportReducer'
import reference from './ReferenceReducer'
import payment from './PaymentReducer'
import netPromoterScore from './NetPromoterScoreReducer'
import releaseNote from './ReleaseNoteReducer'
import flyoutReducer from './FlyoutReducer'
import connection from './ConnectionReducer'
import writingSidebar from './WritingSidebarReducer'
import scrollRestoration from './ScrollRestorationReducer'
import onboarding from './OnboardingReducer'
import navigationReducer from './NavigationReducer'
import challengeReducer from './ChallengeReducer'
import citationReducer from './CitationReducer'

const allReducers = {
  form: formReducer,
  noteDetail: noteDetailReducer,
  project: projectReducer,
  projectItem: projectItemReducer,
  authentication: authenticationReducer,
  tag: tagReducer,
  notePaging: notePagingReducer,
  search: archiveSearchReducer,
  onboarding,
  entities,
  connection,
  modal: modalReducer,
  flyout: flyoutReducer,
  help: helpReducer,
  import: importReducer,
  noteCollection: noteCollectionReducer,
  reference,
  payment,
  netPromoterScore,
  releaseNote,
  writingSidebar,
  scrollRestoration,
  navigation: navigationReducer,
  challenge: challengeReducer,
  citation: citationReducer,
}

// const rootReducer =

// export default rootReducer

export default (history) => {
  const reducers = combineReducers({
    router: connectRouter(history),
    ...allReducers,
  })

  return (state, action) => {
    let resultState = state
    if (action.type === 'LOGOUT') {
      resultState = undefined
    }
    return reducers(resultState, action)
  }
}
