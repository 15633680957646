import { handleActions } from 'redux-actions'

import { receiveLatestReleaseNote } from 'actions/release-note'

const reducer = handleActions({
  [receiveLatestReleaseNote]: (state, action) => ({
    ...action.payload,
  }),
}, {})

export default reducer
