import * as api from 'api'
import { createAction } from 'redux-actions'

import { receiveEntities, removeEntity } from 'actions/entities'

import { requestReferences } from 'actions/references'

export const selectReferenceCollection = createAction('SELECT_REFERENCE_COLLECTION')

export const requestReferenceCollections = () => async (dispatch) => {
  const payload = await api.requestReferenceCollections()
  await dispatch(receiveEntities(payload))
}

export const createReferenceCollection = data => async (dispatch) => {
  const payload = await api.createReferenceCollection(data)
  dispatch(receiveEntities(payload))
  return payload
}

export const deleteReferenceCollection = data => async (dispatch) => {
  await api.deleteReferenceCollection(data.id)
  await dispatch(requestReferences())
  return dispatch(removeEntity({ entityType: 'referenceCollections', ...data }))
}

export const updateReferenceCollection = data => async (dispatch) => {
  const payload = await api.updateReferenceCollection(data)
  return dispatch(receiveEntities(payload))
}
