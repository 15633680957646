import React from 'react'
import PropTypes from 'prop-types'

import modifyClassName from 'helper/modifyClassName'
import { AuratikumFontIcons } from 'helper/utils'
import { propTypes as i18nPropTypes, translate } from 'i18n'

import Icon from 'components/common/Icon'
import { OutlinedButtonTooltip } from 'components/common/Button'
import NoteLinkFlyout from 'containers/note-link/NoteLinkFlyout'
import DuplicateButton from 'components/common/DuplicateButton'

import './style.less'

const NoteGridItemMenu = ({
  onDeleteClick,
  isPremiumRestricted,
  onDuplicateClick,
  className = '',
  visible,
  onLinkNoteClick,
  showNoteLinkFlyout,
  onLinkNoteFlyoutOutsideClick,
  note,
  t,
}) => (
  <div className={`${modifyClassName('NoteGridItemMenu', { visible })} ${className}`}>
    <div className={modifyClassName('NoteGridItemMenu__Item', { visible })}>
      <NoteLinkFlyout
        showFlyout={showNoteLinkFlyout}
        onOutsideClick={onLinkNoteFlyoutOutsideClick}
        note={note}
      >
        <OutlinedButtonTooltip
          className="ActionToolbar__Button"
          onClick={onLinkNoteClick}
          circleModifier="circleMedium"
          tooltipText={t('linkNoteFlyout.addToOutline')}
          tooltipPlacement="bottom"
        >
          <Icon className="ActionToolbar__BoardAddIcon" icon={AuratikumFontIcons.BOARDADD} />
        </OutlinedButtonTooltip>
      </NoteLinkFlyout>
    </div>
    <div className={modifyClassName('NoteGridItemMenu__Item', { visible })}>
      <DuplicateButton
        className="ActionToolbar__Button"
        onClick={onDuplicateClick}
        circleModifier="circleMedium"
        tooltipText={t('notes.duplicate')}
        tooltipPlacement="bottom"
        enablePaywall={isPremiumRestricted}
      />

    </div>
    <div className={modifyClassName('NoteGridItemMenu__Item', { visible })}>
      <OutlinedButtonTooltip
        className="ActionToolbar__Button"
        onClick={onDeleteClick}
        circleModifier="circleMedium"
        tooltipText={t('notes.delete')}
        tooltipPlacement="bottom"
      >
        <Icon icon={AuratikumFontIcons.DELETE} />
      </OutlinedButtonTooltip>
    </div>
  </div>
)

NoteGridItemMenu.propTypes = {
  className: PropTypes.string,
  visible: PropTypes.bool,
  onDeleteClick: PropTypes.func,
  onDuplicateClick: PropTypes.func,
  isPremiumRestricted: PropTypes.bool,
  onLinkNoteClick: PropTypes.func,
  note: PropTypes.shape({}),
  ...i18nPropTypes,
}

export default translate()(NoteGridItemMenu)
