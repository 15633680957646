import {
  flattenDeep, isString, keys, map, flatten,
} from 'lodash'

export default (className, ...modifierParams) => {
  const modifiers = flatten(map(
    flattenDeep(modifierParams),
    param => (isString(param)
      ? [param]
      : keys(param).filter(modifierName => param[modifierName])
    ),
  ))
  return `${className} ${modifiers.map(mod => `${className}--${mod}`).join(' ')}`
}
