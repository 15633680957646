import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { without, values, get } from 'lodash'
import { connect } from 'react-redux'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import { push } from 'connected-react-router'

import { fieldPropTypes, initialize } from 'redux-form'
import AutoSuggestButton from 'components/common/AutoSuggestButton'
import NoteLink from 'components/common/NoteLink'
import NoteSelector from 'selectors/notes'
import SubscriptionSelector from 'selectors/subscriptions'
import { createNote } from 'actions/notes'


import moment from 'moment'

import './style.less'

class NoteSelectorField extends Component {
  constructor() {
    super()

    this.state = {
      suggestions: [],
    }
  }

  // Autosuggest will call this function every time you need to update suggestions.
  onSuggestionsFetchRequested = ({ value, reason }) => {
    let suggestions = []
    const notLinked = note => this.props.input.value.indexOf(note.id) < 0 && this.props.exclude.indexOf(note.id) < 0

    if (get(value, 'length', 0) > 0 && reason !== 'suggestion-selected') {
      suggestions = values(this.props.notes)
        .filter(n => this.props.exclude.indexOf(n.id) < 0
          && this.props.input.value.indexOf(n.id) < 0
          && n.title
          && (n.id === value.toLowerCase()
            || n.title.toLowerCase().indexOf(value.toLowerCase()) >= 0)).slice(0, 5)
    } else {
      suggestions = values(this.props.notes)
        .filter(notLinked)
        .sort((a, b) => moment(b.updatedAt).diff(moment(a.updatedAt)))
        .slice(0, 3)
    }
    this.setState({ suggestions })
  }

  getSuggestionValue = note => note.title

  handleSelection = (suggestion) => {
    const newValue = [...this.props.input.value, suggestion.id]
    this.props.input.onChange(newValue)
  }

  handleDelete = (e, noteId) => {
    const newValue = without(this.props.input.value, noteId)
    this.props.input.onChange(newValue)
    e.stopPropagation()
    e.preventDefault()
  }

  handleCreateLinkedNote = async (value) => {
    const { selectedNote } = this.props
    const emptyNote = {
      title: value || '',
      content: '',
      color: 'transparent',
      createdInProject: selectedNote.createdInProject,
    }
    if (this.props.linkedNote) {
      emptyNote.linkedNotes = [this.props.linkedNote.id]
    }
    const res = await this.props.createNote(emptyNote)
    const newNote = values(res.payload.entities.notes)[0]

    const newValue = [...this.props.input.value, newNote.id]
    this.props.input.onChange(newValue)
  }

  renderSuggestion = note => note.title

  renderNotes = () => (
    <div className="NoteSelector__List">
      { this.props.input.value.map((noteId) => {
        const note = this.props.notes[noteId]
        return note ? <NoteLink key={note.id} note={note} onDeleteNoteLink={this.handleDelete} /> : null
      })
    }
    </div>
  )

render = () => (
  <div className="NoteSelector">
    { this.props.input.value !== '' && this.renderNotes() }
    { !this.props.disabled && (
    <AutoSuggestButton
      title={this.props.t('noteDetailView.noteSelectorField.linkNote')}
      suggestions={this.state.suggestions}
      onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
      getSuggestionValue={this.getSuggestionValue}
      renderSuggestion={this.renderSuggestion}
      onSuggestionSelected={this.handleSelection}
      helpSpotTranslationKey="noteDetailView.noteSelectorField.helpSpot"
      createLabel={!this.props.isPremiumRestricted && this.props.t('noteDetailView.noteSelectorField.createNote')}
      onCreateSelected={this.handleCreateLinkedNote}
      formValue={this.props.input.value}
    />
    )}
  </div>
)
}

NoteSelectorField.propTypes = {
  ...fieldPropTypes,
  ...i18nPropTypes,
  notes: PropTypes.shape({}),
  disabled: PropTypes.bool,
  linkedNote: PropTypes.shape({}),
  isPremiumRestricted: PropTypes.bool,
}


const mapStateToProps = state => ({
  notes: NoteSelector.getRaw(state),
  selectedNote: NoteSelector.getSelectedNote(state),
  isPremiumRestricted: SubscriptionSelector.isNotesCountPremiumRestricted(state),
})

export default connect(mapStateToProps, {
  initialize,
  createNote,
  push,
})(translate()(NoteSelectorField))
