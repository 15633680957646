import React from 'react'
import PropTypes from 'prop-types'

import { propTypes as i18nPropTypes, translate } from 'i18n'
import { isURL } from 'helper/utils'
import FontIcon from 'components/common/FontIcon'

import './style.less'

// Component displays an external link icon and opens a new window
// according to url or doi

const onClick = (e) => {
  e.stopPropagation()
  return false
}

const ReferenceUrlIcon = ({ reference, t, className }) => {
  if (!reference) return null
  let url = reference.URL || (reference.DOI && `https://doi.org/${reference.DOI}`)
  if (!url) return null
  if (!url.toLowerCase().startsWith('http')) url = `https://${url}`
  return !isURL(url) ? null : (
    <a className={`ReferenceUrlIcon ${className}`} onClick={onClick} href={url} rel="noopener noreferrer" target="_blank" title={t('common.openLink')}>
      <FontIcon icon={FontIcon.Icons.faExternalLink} />
    </a>
  )
}

ReferenceUrlIcon.propTypes = {
  ...i18nPropTypes,
  reference: PropTypes.shape({}),
  className: PropTypes.string,
}

ReferenceUrlIcon.defaultProps = {
  className: '',
}

export default translate()(ReferenceUrlIcon)
