import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Popper } from 'react-popper'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import FeatureFlyoutSelector from 'selectors/feature-flyouts'
import './style.less'

export const propTypes = {
  addFeatureFlyout: PropTypes.func,
}

const featureFlyouts = config => (WrappedComponent) => {
  class FeatureFlyout extends Component {
    static propTypes = {
      ...i18nPropTypes,
    }

    constructor(props) {
      super(props)
      this.elements = {}
    }

    addFeatureFlyout = key => (ref) => {
      if (ref && (!this.elements[key] || this.elements[key] !== ref)) {
        this.elements[key] = ref
        // if (ref) { // need to be moved to component did update
        //   ref.style.zIndex = '100'
        // }
        this.setState({})
      }
    }

    render() {
      const { t } = this.props

      return (
        <React.Fragment>
          <WrappedComponent {...this.props} addFeatureFlyout={this.addFeatureFlyout} />

          {Object.keys(config).map((key) => {
            const { placement: configPlacement } = config[key] // should get title & description from i18n locale files through key
            if (!this.props[key].isVisible) return null
            return (
              <Popper
                key={key}
                referenceElement={this.elements[key]}
                placement={configPlacement}
              >
                {({
                  placement, ref, style, arrowProps,
                }) => (
                  <div className="FeatureFlyout__Container" ref={ref} style={style} data-test={key} data-placement={placement}>
                    <div className="FeatureFlyout__Title">{t(`featureFlyouts.${key}.title`)}</div>
                    <div className="FeatureFlyout__Description">{t(`featureFlyouts.${key}.description`)}</div>
                    <div className="FeatureFlyout__Arrow" ref={arrowProps.ref} style={arrowProps.style} />
                  </div>
                )
            }
              </Popper>
            )
          })}

        </React.Fragment>
      )
    }
  }
  const mapStateToProps = (state) => {
    const result = {}
    Object.keys(config).forEach((key) => {
      if (config[key].isVisible) {
        result[key] = { isVisible: config[key].isVisible(state) }
      } else if (FeatureFlyoutSelector[key]) {
        result[key] = { isVisible: FeatureFlyoutSelector[key](state) }
      } else {
        result[key] = { isVisible: true }
      }
    })
    return result
  }

  return connect(mapStateToProps)(translate()(FeatureFlyout))
}

export default featureFlyouts
