import React from 'react'
import PropTypes from 'prop-types'

import modifyClassName from 'helper/modifyClassName'
import wordIcon from 'assets/iconWord.png'
import texIcon from 'assets/iconTex.png'

import './style.less'

const ImageButton = (image, alt) => WrappedComponent => ({ ...props }) => <WrappedComponent image={image} alt={alt} {...props} />

const Button = ({
  image, alt, type = 'button', className = '', onClick, isLoading,
}) => (
  <div className={`ImageButton ${className}`}>
    <button type={type} className={modifyClassName('ImageButton__Item', { loading: isLoading })} onClick={onClick} disabled={isLoading}>
      <img className="ImageButton__Image" src={image} alt={alt} />
    </button>
  </div>
)

Button.propTypes = {
  image: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  alt: PropTypes.string,
  isLoading: PropTypes.bool,
}

export const ExportButtonWord = ImageButton(wordIcon, 'MS-Word')(Button)
export const ExportButtonBibtex = ImageButton(texIcon, 'BibTeX')(Button)
